import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import { filterSelectOptions } from '@utils';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { formatISO, subMonths } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subject, takeUntil } from 'rxjs';
import {
	FormularioRelatorio,
	IapmDTO,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
	Relatorios,
} from '../../../interfaces';
import { GraficosPrecipitacaoService } from '../../../services';
import { Agrupamento } from '../../../submodulos/monitoramento/interfaces/estacao-monitorada';
import moment from 'moment';
import { EPeriodosBusca } from '../../../enum';
import { formatarDataPeriodo } from '../../../utils';

@Component({
	selector: 'seira-pluviometria-tabela-iapm',
	templateUrl: './pluviometria-tabela-iapm.component.html',
	styleUrls: ['./pluviometria-tabela-iapm.component.scss'],
})
export class PluviometriaTabelaIapmComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	form?: FormGroup<any> | undefined;
	isLoading = false;
	tableData: Relatorios<IapmDTO> = {
		data: [],
		municipio: '',
	};
	idValue?: number;
	titleTable = '';
	titlePeriodo = '';
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];
	agrupamento: Select<string>[];

	descricaoRelatorio =
		'Define-se como IAPM o índice que mede a anomalia da precipitação mensal em comparação com a média histórica, utilizado para identificar padrões fora do comum em determinada região.';

	private _destroyed = new Subject();

	constructor(
		private readonly service: GraficosPrecipitacaoService,
		private toastr: ToastrService
	) {
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.MUNICIPIO_POSTO,
			Agrupamento.MUNICIPIO,
			Agrupamento.SUBBACIA
		);
	}

	ngOnInit(): void {
		this.form = this.inputs.form;
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.setValue('mensal');
		this.handleSetValidatorsByAgrupamento(
			this.inputs.form?.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.handleObserveAgrupamento();
		this.observarMudancasPeriodoBusca();

		this.gerarRelatorio();
	}

	ngOnDestroy(): void {
		this._destroyed.next(undefined);
	}

	observarMudancasPeriodoBusca() {
		const periodoBusca = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		);

		this.lidarComPeriodo(periodoBusca?.value);
		periodoBusca?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: (periodo: PeriodosLabel | null) => {
				this.lidarComPeriodo(periodo);
			},
		});
	}

	lidarComPeriodo(periodo: PeriodosLabel | null) {
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);

		if (dataInicio && dataFim) {
			const hoje = new Date();
			dataFim.setValue(moment(hoje));

			switch (periodo) {
				case 'anual':
					if (dataFim?.value) {
						const hoje = new Date();
						if (dataFim.value.year() == hoje.getFullYear()) {
							dataFim?.setValue(moment(hoje));
						} else {
							dataFim?.setValue(dataFim.value.endOf('year'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.ANUAL,
							'year'
						);
						dataInicio?.setValue(fim);
					}
					break;
				case 'mensal':
					if (dataFim?.value) {
						const hoje = new Date();
						if (
							dataFim.value.year() === hoje.getFullYear() &&
							dataFim.value.month() >= hoje.getMonth()
						) {
							dataFim.setValue(moment(hoje));
						} else {
							dataFim.setValue(dataFim.value.endOf('month'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.MENSAL,
							'month'
						);
						dataInicio?.setValue(fim);
					}
					break;
			}
		}
	}

	gerarRelatorio() {
		if (this.form?.invalid) {
			return;
		}

		const agrupamento = this.form?.get('agrupamento')?.value;
		const periodoBusca = this.form?.get('periodoBusca')?.value;
		const dataInicio = this.form?.get('dataInicio')?.value;
		const dataFim = this.form?.get('dataFim')?.value;

		if (
			!this.idValue ||
			!agrupamento ||
			!periodoBusca ||
			!dataInicio ||
			!dataFim
		) {
			return;
		}

		let dataFinalFixed = '';
		const dateFinal = new Date(dataFim);
		if (dateFinal.getMonth() === new Date().getMonth()) {
			dataFinalFixed = formatISO(subMonths(dateFinal, 1));
		} else {
			dataFinalFixed = formatISO(dateFinal);
		}

		this.titlePeriodo = formatarDataPeriodo(
			dataInicio,
			dateFinal,
			periodoBusca
		);
		this.handleFetchIAPM(
			this.idValue!,
			agrupamento,
			this.handleFormatPeriodoBusca(periodoBusca),
			formatISO(new Date(dataInicio)),
			dataFinalFixed
		);
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: `${'IAPM'} - ${this.titleTable} - ${this.titlePeriodo}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.tableData) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}
		const tableData: (string | number)[][] = [];
		tableData.push(['IAPM (mm)', 'Data']);

		this.tableData?.data!.forEach(dado => {
			tableData.push([dado.iapm.toFixed(2).replace('.', ','), dado.periodo]);
		});
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData: (string | number)[][] = [];
		tableData.push(['IAPM (mm)', 'Data']);

		this.tableData!.data.forEach(dado => {
			tableData.push([dado.iapm.toFixed(2).replace('.', ','), dado.periodo]);
		});
		DocumentExporter.gerarCSV(
			tableData,
			`iapm_${this.titleTable.toLowerCase()}_${this.titlePeriodo.toLowerCase()}`
		);
	}

	exportarTXT() {
		let txtData = '';

		txtData += `Nome: ${this.tableData!.data[0].nome}\n\n`;

		this.tableData!.data.forEach(dado => {
			txtData +=
				`IAPM (mm): ${dado.iapm.toFixed(2).replace('.', ',')}\n` +
				`Data: ${dado.periodo}\n`;
		});

		DocumentExporter.gerarTXT(
			txtData,
			`iapm_${this.titleTable.toLowerCase()}_${this.titlePeriodo.toLowerCase()}`
		);
	}

	private getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	private handleObserveAgrupamento() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.handleSetValidatorsByAgrupamento(value);
				},
			});
	}

	private handleSetValidatorsByAgrupamento(agrupamentoValue: string) {
		switch (agrupamentoValue) {
			case 'MICRORREGIAO':
				this.inputs.form
					.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				this.idValue = this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)
					?.value;

				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MESORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.REGIAO_PLUVIOMETRICA
				);

				break;
			case 'MESORREGIAO':
				this.inputs.form
					.get(FormularioRelatorio.MESORREGIAO)
					?.setValidators(Validators.required);
				this.idValue = this.inputs.form.get(FormularioRelatorio.MESORREGIAO)
					?.value;

				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MICRORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.REGIAO_PLUVIOMETRICA
				);
				break;
			case 'BACIA':
				this.inputs.form
					.get(FormularioRelatorio.BACIA)
					?.setValidators(Validators.required);
				this.idValue = this.inputs.form.get(FormularioRelatorio.BACIA)?.value;

				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MICRORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MESORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.REGIAO_PLUVIOMETRICA
				);
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.inputs.form
					.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.setValidators(Validators.required);
				this.idValue = this.inputs.form.get(
					FormularioRelatorio.REGIAO_PLUVIOMETRICA
				)?.value;

				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MICRORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MESORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
				break;
		}
	}

	private handleFetchIAPM(
		id: number,
		agrupamento: string,
		periodo: string,
		dataInicial: string,
		dataFinal: string
	) {
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.service
			.gerarGraficoIAPM(id, agrupamento, periodo, dataInicial, dataFinal)
			.subscribe({
				next: values => {
					this.tableData = {
						municipio: '',
						data: values,
					};
					this.titleTable = values[0].nome.toUpperCase();
				},
				error: err => {
					this.toastr.error('Erro ao buscar as informações');
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	private handleFormatPeriodoBusca(periodo: string) {
		if (periodo === 'anual') return 'ANUAL';
		if (periodo === 'mensal') return 'MENSAL';
		return 'DIARIO';
	}

	private handleRemoveValidatorsFromFormControl(
		formControl: FormularioRelatorio
	) {
		this.inputs.form.get(formControl)?.setValidators(null);
		this.inputs.form.get(formControl)?.reset();
	}
}
