<div class="mt-10">
	<label for="legenda" class="text-muted fw-semibold my-1"
		>{{
			mapaNome + (valores[0].unidade ? ' (' + valores[0].unidade + ')' : '')
		}}
	</label>
	<div class="bg-white rounded shadow-lg d-flex flex-column card" id="legenda">
		<div class="card-body">
			<div class="d-flex align-items-center">
				<div
					*ngFor="let valor of valores; index as i"
					class="legenda-item shadow"
					[style]="borderRadius(i)"
					[style.backgroundColor]="valor.cor"></div>
			</div>
			<div class="d-flex align-items-center">
				<div *ngFor="let valor of valores; index as i" class="legenda-item">
					<div *ngIf="i === 0 && valor.unidade === '%'">
						< {{ valor.valorMin }}
					</div>
					<div *ngIf="i === 0 && valor.unidade !== '%'">
						< {{ valor.valorMax }}
					</div>
					<div *ngIf="i > 0 && i < valores.length - 1">
						{{ valor.valorMin }} — {{ valor.valorMax }}
					</div>
					<div *ngIf="i === valores.length - 1">> {{ valor.valorMin }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
