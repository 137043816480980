import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import {
	DadosEvapotranspiracao,
	DadosMediaHistorica,
	DadosMicrorregiao,
	DadosRelatorioPluviometricoDiario,
	DadosRelatorioPluviometricoPorPosto,
	DadosRelatorioPrecipitacaoAnual,
	RelatorioPrecipitacaoAcumulada,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Moment } from 'moment';
import { FiltrosOpcoes } from '../interfaces/filtros-opcoes';
import { Observable } from 'rxjs';
import { RotasRelatorios } from '@home/submodulos/dados-meteorologicos/enum/RotasRelatorios';

import {
	DadosConsultaTodosOsSensores,
	DadosRelatorioVariaveis,
	RelatorioDesvio,
} from '@home/submodulos/dados-meteorologicos/interfaces/response';
import {
	TiposPeriodosVariaveisMeteorologicas,
	VariaveisMeteorologicasPorPeriodo,
} from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';
import {
	AgrupamentoResponse,
	DadosAgrupamento,
} from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import { DesvioDTO } from '../interfaces/desvioDTO';
import { RelatorioNDCResponse } from '../interfaces/relatorio-ndc';
import { RegiaoPluviometricaService } from './regiao-pluviometrica.service';
import { RelatorioVECResponse } from '../interfaces';
import { ClimatologiaResponseDTO } from '@home/submodulos/dados-meteorologicos/interfaces/climatologia';

export interface DadosChuva {
	data: string;
	volume: number;
}

type DTOBuscaDesvio = {
	agrupamento: string;
	idEntidade: number;
	dataInicio: Date;
	dataFim: Date;
	incluirDetalhes: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class RelatoriosService {
	constructor(
		private http: HttpClientProtectedService,
		private readonly regioesService: RegiaoPluviometricaService
	) {}

	listarVariaveisMeteorologicas(
		tipoPeriodo: TiposPeriodosVariaveisMeteorologicas,
		dataInicio?: string,
		dataFim?: string
	) {
		return this.http.get<VariaveisMeteorologicasPorPeriodo[]>(
			Rotas.RELATORIO_VARIAVEIS_METEOROLOGICAS,
			{ tipoPeriodo, dataInicio, dataFim }
		);
	}

	buscarRelatorioDesvio({
		agrupamento,
		idEntidade,
		dataFim,
		dataInicio,
		incluirDetalhes,
	}: DTOBuscaDesvio): Observable<RelatorioDesvio[]> {
		return this.http.get('desvio', {
			agrupamento,
			id: idEntidade,
			dataInicio: dataInicio.toISOString(),
			dataFim: dataFim.toISOString(),
			incluirDetalhes,
		});
	}

	buscarRelatorioEvapotranspiracao(
		postoIds: number[],
		diaInicio: string,
		diaFim: string
	) {
		return this.http.post<any, Relatorios<DadosEvapotranspiracao>[]>(
			Rotas.ACOMPANHAMENTO_COLETA + RotasRelatorios.EVAPOTRANSPIRACAO,
			{
				postoIds,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioPluviometricos(
		postosIds: number[],
		diaInicio: string,
		diaFim: string
	) {
		return this.http.post<any, Relatorios<DadosRelatorioPluviometricoDiario>[]>(
			Rotas.RELATORIOS + `/precipitacao/diaria`,
			{
				postosIds,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioPluviometricosPorPosto(
		postosIds: number[],
		diaInicio: string,
		diaFim: string
	) {
		return this.http.post<
			any,
			Relatorios<DadosRelatorioPluviometricoPorPosto>[]
		>(Rotas.RELATORIOS + `/precipitacao/resumo-pluviometrico`, {
			postosIds,
			diaInicio,
			diaFim,
		});
	}

	buscarRelatorioPluviometricosDoEstado(
		diaInicio: string,
		diaFim: string,
		postosIds: number[]
	): Observable<DadosMicrorregiao> {
		return this.http.post<any, any>(
			Rotas.RELATORIOS + `/precipitacao/resumo-pluviometrico-estado`,
			{
				diaInicio,
				diaFim,
				postosIds,
			}
		);
	}

	buscarRelatorioSudene(postos: string[], dataInicio: Date, dataFim: Date) {
		return this.http.get(Rotas.PLUVIOMETRIA + '/relatorio-sudene', {
			posto: postos.map(p => p.toUpperCase()),
			dataInicio: dataInicio.toISOString(),
			dataFim: dataFim.toISOString(),
		});
	}

	buscarGraficoSPI(diaInicio: Moment, diaFim: Moment) {
		return this.http.getBlob(Rotas.GRAFICOS + '/precipitacao/spi', {
			diaInicio: diaInicio.toISOString(),
			diaFim: diaFim.toISOString(),
		});
	}

	consultarOpcoesFiltros(): Observable<FiltrosOpcoes> {
		return this.http.get<FiltrosOpcoes>(
			Rotas.RELATORIOS + '/precipitacao/filtro-estacoes'
		);
	}

	buscarRelatorioMediaHistorica(postosIds: number[], diaFim: Moment) {
		return this.http.post<any, Relatorios<DadosMediaHistorica>[]>(
			Rotas.RELATORIOS + `/precipitacao/media-historica`,
			{
				postosIds,
				diaFim,
			}
		);
	}
	relatorioPrecipitacaoAcumulada(
		postosIds: number[],
		diaInicio: string | null,
		diaFim: string
	): Observable<RelatorioPrecipitacaoAcumulada> {
		return this.http.post<any, RelatorioPrecipitacaoAcumulada>(
			Rotas.RELATORIOS + `/precipitacao/acumulada`,
			{
				postosIds,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioPrecipitacaoAnual(
		postosIds: number[],
		diaInicio: string,
		diaFim: string
	): Observable<DadosRelatorioPrecipitacaoAnual> {
		return this.http.post<any, any>(Rotas.RELATORIOS + '/precipitacao/anual', {
			postosIds,
			diaInicio,
			diaFim,
		});
	}

	buscarRelatorioVariaveis(postoId: number, diaInicio: string, diaFim: string) {
		return this.http.post<any, DadosRelatorioVariaveis>(
			Rotas.ACOMPANHAMENTO_COLETA + RotasRelatorios.VARIAVEIS,
			{
				postoId,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioVariaveisTodosMunicipios(dados: DadosConsultaTodosOsSensores) {
		return this.http.post<DadosConsultaTodosOsSensores, any>(
			Rotas.ACOMPANHAMENTO_COLETA + RotasRelatorios.VARIAVEISTODOSPOSTOS,
			dados
		);
	}

	buscarRelatorioPorAgrupamento(dados: DadosAgrupamento) {
		return this.http.post<DadosAgrupamento, AgrupamentoResponse[]>(
			Rotas.RELATORIOS + '/precipitacao/agrupamento-precipitacao',
			dados
		);
	}

	buscarRelatorioNDCPorAgrupamento(dados: DadosAgrupamento) {
		return this.http.post<DadosAgrupamento, RelatorioNDCResponse[]>(
			Rotas.RELATORIOS + '/precipitacao/agrupamento-ndc',
			dados
		);
	}

	buscarRelatorioVECPorAgrupamento(dados: DadosAgrupamento) {
		return this.http.post<DadosAgrupamento, RelatorioVECResponse[]>(
			Rotas.RELATORIOS + '/precipitacao/agrupamento-valores-extremos-chuva',
			dados
		);
	}

	relatorioMapaInterpolacaoDesvio(
		diaInicio: string,
		diaFim: string
	): Observable<DesvioDTO[]> {
		return this.http.post<any, any>(
			Rotas.CLIMATOLOGIA + '/desvio-por-municipio',
			{
				diaInicio,
				diaFim,
			}
		);
	}

	buscarClimatologia(diaInicio: any, diaFim: any): Observable<any> {
		return this.http.post<any, any[]>(`climatologia`, {
			diaInicio,
			diaFim,
		});
	}

	buscarClimatologiaPorMunicipio(
		agrupamento: string,
		diaInicio: string,
		diaFim: string,
		idMunicipio?: number,
		idEstacao?: number,
		idMicrorregiao?: number
	): Observable<ClimatologiaResponseDTO[]> {
		return this.http.post<any, ClimatologiaResponseDTO[]>(
			`climatologia-por-agrupamento`,
			{
				agrupamento,
				diaInicio,
				diaFim,
				climatologiaBuscaId: { idMunicipio, idEstacao, idMicrorregiao },
			}
		);
	}
	buscarRelatorioSPIPorRegiao(
		diaInicio: string,
		diaFim: string,
		idRegiao: number
	): Observable<any[]> {
		return this.http.get<any>(
			Rotas.RELATORIOS + '/precipitacao/spi/por-regiao',
			{
				diaInicio,
				diaFim,
				idRegiao,
			}
		);
	}

	buscarDadosTabelaPluviometriaObservada(diaInicio: string, diaFim: string) {
		return this.http.get<any[]>(
			Rotas.PLUVIOMETRIA + '/tabela-pluviometria-observada',
			{
				diaInicio,
				diaFim,
			}
		);
	}
}
