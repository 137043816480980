import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import { getBase64ImageFromURL } from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import * as moment from 'moment/moment';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subscription } from 'rxjs';
import { formatarDataPeriodo } from '../../../utils';

export const PERIODO_MINIMO_SPI_EM_ANOS = 10;

@Component({
	selector: 'seira-pluviometro-grafico-spi',
	templateUrl: './pluviometro-grafico-spi.component.html',
	styleUrls: ['./pluviometro-grafico-spi.component.scss'],
})
export class PluviometroGraficoSpiComponent
	implements InstanciaRelatorio, OnInit, OnDestroy
{
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];

	private subscription = new Subscription();
	private inputs = inject(INPUTS_RELATORIOS);

	carregandoRelatorio = false;
	graficoSPIUrl?: string;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: async () => {
				await this.exportarPDF(this.graficoSPIUrl);
			},
		},
	];
	descricaoRelatorio =
		'Define-se como SPI o Índice de Precipitação Padronizado, que mede a anomalia da precipitação em diferentes escalas temporais, ajudando a identificar secas ou excessos de chuva em uma região.';

	periodoTitulo = '';
	constructor(
		private readonly relatoriosService: RelatoriosService,
		private readonly toastr: ToastrService
	) {}

	ngOnInit() {
		setTimeout(() => {
			this.definirPeriodoPadrao();
		});
		setTimeout(() => {
			this.gerarRelatorio();
		}, 1);
	}

	private definirPeriodoPadrao() {
		const anoAtual = moment().startOf('year');
		const anoInicial = moment(anoAtual).subtract(
			PERIODO_MINIMO_SPI_EM_ANOS,
			'year'
		);

		this.inputs.form.get('dataInicio')?.setValue(anoInicial);
		this.inputs.form.get('dataFim')?.setValue(anoAtual);
	}

	gerarRelatorio() {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');

		this.carregandoRelatorio = true;
		this.inputs.setLoading(true);
		this.periodoTitulo = formatarDataPeriodo(dataInicio, dataFim);
		this.relatoriosService.buscarGraficoSPI(dataInicio, dataFim).subscribe({
			next: grafico => {
				this.graficoSPIUrl = URL.createObjectURL(grafico);
			},
			error: () => {
				this.carregandoRelatorio = false;
				this.toastr.error('Ocorreu um erro ao gerar o relatório');
			},
			complete: () => {
				this.carregandoRelatorio = false;
				this.inputs.setLoading(false);
			},
		});
	}

	getPeriodo() {
		const anoInicio = this.getFormItemValue('dataInicio').year();
		const anoFim = this.getFormItemValue('dataFim').year();
		return `${anoInicio} à ${anoFim}`;
	}

	async exportarPDF(graficoSPIUrl?: string) {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: `Relatório - Índice Padronizado de Precipitação (SPI) - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!graficoSPIUrl) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		} else {
			documentDefinition.content.push({
				image: await getBase64ImageFromURL(graficoSPIUrl),
				alignment: 'center',
				width: 500,
				height: 250,
			});
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	private getFormItemValue(name: string) {
		return this.inputs.form.get(name)!.value;
	}
}
