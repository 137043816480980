<label class="text-center text-tertiary fw-semibold w-100">
	QUANTIS {{ quantisTitulo }} - {{ periodoTitulo }}
</label>
<seira-card-info aditionalClasses="mt-2" [texto]="descricaoRelatorio" />
<div
	*ngIf="loading"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>
<ng-container #grafico *ngIf="gerou && !loading && resultado">
	<seira-grafico-quantis
		(chartInstance)="onChartCreated($event)"
		[useMaximumLimit]="useMaximumLimit"
		[isAnual]="isAnual"
		[isPeriodosPluviometricos]="isPeriodosPluviometricos"
		[dado]="resultado"></seira-grafico-quantis>
</ng-container>
<ng-container *ngIf="!gerou && !loading">
	<seira-sem-dados />
</ng-container>
