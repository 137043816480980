import { Microrregiao } from '@shared/enum/microrregiao';

export interface MunicipiosPaths {
	d: string;
	geocodigo: string;
	microrregiao: string;
	name: string;
}
export const municipioSvgPaths = [
	{
		d: 'm 165121.12,174255.49 -1072.01,-2227.09 -7.89,-722.3 1630.04,-1080.14 1915.96,-5814.8 -755.21,-1850.06 -1186.38,-326.1 278.03,-1344.72 -245.83,-1428.73 490.99,-677.98 -65.07,-182.56 710.52,-59.53 338.49,286.41 199.16,-865.84 828.82,419.35 1473.61,-179.25 2201.87,2439.51 2428.42,-146.26 2876.4,3224.56 -228.66,2661 -1359.91,838.72 -1388.17,539.08 -118.97,772.56 -609.29,543.06 -763.1,468.96 -1410.51,-155.44 -699.34,-526.51 -1240.28,908.83 -399.63,929.99 -1762.81,159.41 -259.63,651.53 166.95,809.61 -958.31,819.54 -558.68,-156.77 -449.58,1273.29 z',
		geocodigo: '2500106',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Agua Branca - PB',
	},
	{
		d: 'm 122621.65,137780.62 264.22,-892.96 607.33,-120.39 -1871.27,-1345.38 939.9,-112.45 1810.8,-1916.87 588.15,-1410.7 1949.59,-1733.16 621.79,107.15 3523.65,1129.09 1444.04,120.39 3827.32,-270.54 3081.97,342.63 2276.8,5435.78 -2396.43,246.06 -478.49,-289.05 -1860.1,-9.92 -442.34,279.79 -276.72,-201.08 -699.99,31.75 -1256.06,1472.38 -1075.96,-19.18 -404.23,780.51 -420,-41.67 -58.49,-278.47 -760.47,451.77 -438.4,-35.72 -2051.36,824.16 84.13,1105.95 303.66,347.25 4010.04,691.22 -4224.97,-248.04 -907.7,712.37 -2126.94,764.64 -1390.14,-187.19 -308.92,-1342.08 76.9,-854.59 758.5,-1136.37 -1269.2,-136.26 -2133.52,1089.41 -24.32,-2617.35 c 236.76,-242.89 471.77,-488.41 707.23,-732.88 z',
		geocodigo: '2500205',
		microrregiao: Microrregiao.pianco,
		name: 'Aguiar - PB',
	},
	{
		d: 'm 311649.68,137220.37 440.37,-494.11 -78.22,-429.27 345.07,-1038.48 388.45,137.59 913.61,-1259.4 857.75,121.71 383.2,-731.57 328.63,-257.3 -1206.76,385.62 -947.79,-291.03 -109.11,-595.31 664.51,-121.7 820.28,-633.67 1321.13,-1273.95 884.69,-1240.21 2112.48,-439.87 866.95,-656.16 477.19,-1048.39 49.29,-390.25 939.91,-461.03 489.66,2040.57 1070.05,598.61 154.46,203.06 2413.52,3700.8 607.3,-76.06 299.09,115.09 302.34,-463.02 495.59,84.01 650.7,2894.49 -1697.09,-313.52 205.73,605.88 548.17,482.2 -230.05,500.71 475.21,777.87 -1650.42,756.69 -241.88,687.91 -444.97,416.05 -1678.03,105.83 -4186.19,908.84 -752.58,-111.79 -784.13,31.74 -904.41,880.39 -383.19,-392.23 -527.8,-50.93 -860.37,588.68 -599.44,101.86 -1984.31,-3241.75 5.91,-988.86 -247.79,-126.34 z',
		geocodigo: '2500304',
		microrregiao: Microrregiao.brejo,
		name: 'Alagoa Grande - PB',
	},
	{
		d: 'm 301189.78,134434.35 1065.44,-694.52 696.72,-881.71 1607.04,225.55 955.68,-615.8 526.47,435.23 666.49,-142.21 1202.15,410.76 523.19,-463.68 155.78,308.89 713.8,-121.7 561.32,-552.31 1251.45,304.27 650.05,-190.5 1202.15,313.53 109.77,595.3 947.79,291.7 1206.76,-386.29 -329.29,257.97 -383.2,731.56 -857.09,-121.71 -914.27,1259.4 -387.79,-138.24 -345.07,1039.13 78.21,428.62 -440.37,494.1 -518.59,157.42 -588.26,719.66 -811.08,286.41 -507.42,-78.05 -597.46,336.01 -1636.62,-425.97 -575.12,263.92 -870.23,-15.88 -680.28,-306.91 -625.07,50.94 125.54,-507.34 -403.57,-1376.47 -981.32,-627.05 -267.51,-587.37 -1524.88,-676.66 z',
		geocodigo: '2500403',
		microrregiao: Microrregiao.brejo,
		name: 'Alagoa Nova - PB',
	},
	{
		d: 'm 320962.62,124883.04 1369.11,-848.64 2134.17,1525.3 4461.59,-398.19 49.3,885.02 387.79,1124.46 575.78,474.92 -66.39,1295.11 -284.6,466.99 -1169.29,622.42 -2798.57,-257.4 214.79,3203.56 -626.61,74.33 -2540.78,-3908.15 -1070.05,-598.61 -489.67,-2040.57 684.89,-490.8 -828.18,-1129.75 z',
		geocodigo: '2500502',
		microrregiao: Microrregiao.guarabira,
		name: 'Alagoinha - PB',
	},
	{
		d: 'm 281497.79,186804.49 610.6,-1270.65 134.09,-1246.82 721.69,-694.52 171.55,-556.94 -1895.59,-3595.64 338.5,-609.19 -153.8,-689.88 190.61,-431.93 -416.71,-405.47 583.66,-194.47 564.59,343.95 416.72,-330.06 -489.67,-438.54 405.54,-515.27 495.59,472.28 52.58,-723.63 507.42,262.6 59.15,-569.51 284.6,48.29 164.98,-252.67 147.88,252.67 1164.04,-227.54 -1.97,-657.48 264.22,-118.4 -39.43,273.84 345.07,138.9 32.86,-339.32 453.52,-70.77 -165.64,-372.4 273.44,-629.03 333.89,206.37 542.91,-212.99 225.44,208.36 471.93,-276.49 907.04,252.67 182.72,311.55 205.08,-545.7 1335.58,97.89 513.33,435.24 -52.58,438.54 1086.48,871.79 327.32,791.09 -76.24,634.99 712.48,502.7 -179.43,639.62 395.68,287.07 -869.58,1172.75 -47.98,564.87 -701.31,1134.39 63.76,185.87 473.89,9.25 -101.88,877.08 -222.81,-80.03 -147.89,881.71 -389.11,398.19 82.16,611.18 -502.16,-20.51 82.82,420.68 440.37,44.99 -558.02,435.23 -243.19,1376.47 -267.52,68.79 -413.43,-316.84 -163.66,170.66 -214.92,-246.06 -29.58,566.2 -350.33,-27.12 -395.68,468.97 36.15,304.26 -640.85,728.26 44.7,371.07 -422.63,348.58 -579.06,-174.62 -769.67,560.25 -990.51,-1344.07 -228.74,-144.19 -2303.09,-103.85 -1068.08,461.03 -692.11,-112.44 -61.78,-1078.17 z',
		geocodigo: '2500536',
		microrregiao: Microrregiao.caririOriental,
		name: 'Alcantil - PB',
	},
	{
		d: 'm 282572.44,125724.4 1954.73,-986.88 2197.28,-1736.3 2218.3,-1105.94 1641.22,-644.91 4583.84,-2973.21 817.66,-614.49 882.07,-1856.02 1281.68,-107.15 450.24,-13.23 1607.69,1717.11 1806.2,1414.85 359.53,738.83 757.18,746.12 1359.25,497.4 529.11,195.13 -308.27,472.28 -170.23,537.75 -2888.73,-1782.6 -1701.69,438.54 -1847.6,1356.63 -368.73,726.27 -3509.86,9.92 480.47,838.71 -472.59,549.01 -888.63,958.44 -1162.07,150.14 -318.12,521.89 -1925.82,894.28 1.32,285.08 -487.7,326.09 -170.23,683.28 -2107.23,1144.31 -543.57,-449.79 -1532.77,-20.5 z',
		geocodigo: '2500577',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Algodão de Jandaíra - PB',
	},
	{
		d: 'm 359982.48,151050.58 1464.41,-532.47 2347.13,-244.73 1232.39,447.8 375.31,601.92 744.04,-70.78 710.51,-575.46 362.82,-1800.46 664.5,260.61 -238.58,642.27 -118.97,879.72 956.99,-29.1 445.63,568.18 238.6,614.49 -194.56,963.72 1549.2,975.64 422.63,51.6 2355.02,1948.62 -3739.9,4898.68 753.89,463.68 1415.12,29.1 923.47,1003.42 364.79,206.37 69.67,-326.76 189.3,297.66 422.63,-43 350.32,433.26 -506.1,44.97 -299.06,1103.96 -1829.86,377.68 -2473.99,-1463.78 -310.23,-595.3 -1253.42,-664.1 -77.56,-419.36 -861.69,-830.77 159.06,-393.56 -33.52,-799.7 -1070.05,-1523.31 891.92,-1403.59 -6408.44,-5097.12 z',
		geocodigo: '2500601',
		microrregiao: Microrregiao.litoralSul,
		name: 'Alhandra - PB',
	},
	{
		d: 'm 212110.46,174167.52 -722.35,-1709.18 402.25,-244.73 -265.54,-672.04 262.91,-285.08 303.01,-2755.59 1299.43,-82.68 1049.67,-915.45 1996.8,-529.82 78.22,-31.74 141.31,388.93 801.23,159.4 2434.55,2823.06 -1729.95,1809.72 229.38,521.89 -214.92,341.97 257.65,394.22 -51.27,1542.5 1237.65,1009.37 -24.32,259.28 -446.28,502.71 -3860.84,-1488.26 -1998.78,-40.35 -450.24,-716.35 -731.54,-281.78 z',
		geocodigo: '2500734',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Amparo - PB',
	},
	{
		d: 'm 135000.79,123128.88 970.14,-1657.59 303.66,-72.1 240.56,-943.88 841.34,-365.53 -972.98,-3065.58 -493.5,-2956.17 -44.02,-1333.67 2729.96,-2278.65 694.46,-2356.2 327.37,-29.81 70.32,-281.78 1238.31,-861.2 1464.41,184.54 734.84,-486.83 1049.67,27.12 582.35,80.04 328.63,358.5 993.81,128.32 1085.82,1437.99 -217.56,1096.68 -3113.52,410.76 311.55,3389.26 601.41,582.74 44.69,1398.96 1138.4,1859.33 719.06,670.05 -364.79,187.19 -556.05,-316.84 -529.77,515.93 -120.93,1231.62 782.16,549 265.53,1249.47 -1240.28,857.9 -869.57,-211 -276.72,422.66 -634.27,9.27 -2123,-579.44 -1495.96,560.25 -1192.96,-23.81 -1262.62,765.96 -804.51,-348.59 -1206.76,198.44 z',
		geocodigo: '2500775',
		microrregiao: Microrregiao.sousa,
		name: 'Aparecida - PB',
	},
	{
		d: 'm 329495.38,117044.88 1308.63,-1425.42 805.83,-345.93 834.74,-763.31 682.25,-304.27 415.4,-527.84 2494.36,-689.22 151.18,-51.6 734.83,123.03 451.55,263.92 398.97,992.17 722.34,377.69 1114.74,3.31 292.49,658.14 350.98,-5.29 -511.36,736.19 -320.09,1891.73 1472.3,409.44 507.42,1537.87 -1329.01,672.03 195.21,798.37 757.18,894.28 -46.67,659.46 320.1,779.18 -337.19,793.74 910.99,624.41 467.32,1470.4 701.31,-31.75 256.34,363.13 845.92,544.37 -1112.77,1011.36 -2838.78,1175.39 -1725.35,-2119.94 -155.77,-1017.97 -1424.32,-161.39 -1342.16,-527.84 -561.31,-5556.16 -218.22,-172.64 161.03,-404.8 -260.93,-209.68 -366.11,86.65 -125.54,-176.61 -195.21,248.05 -140,-1062.29 -367.42,145.52 -130.13,-348.59 -539.63,161.4 105.17,-563.55 -354.28,-153.46 281.32,-684.6 -694.74,136.25 -132.12,-165.35 -2124.31,597.28 -385.17,-688.56 z',
		geocodigo: '2500809',
		microrregiao: Microrregiao.guarabira,
		name: 'Araçagi - PB',
	},
	{
		d: 'm 304489.31,120802.57 122.25,-1137.69 -253.05,-339.32 385.16,-158.75 92.02,-481.54 645.45,-17.86 299.06,-553.63 397.65,-185.2 2488.45,82.68 579.06,-825.49 1091.07,238.78 713.81,574.14 55.21,-211 812.39,-129.65 517.33,-429.19 -284.23,1745.73 1273.25,1541.64 2011.36,623.73 405.63,1006.69 529.04,46.24 -289.2,1992.39 -472.49,14.56 -1686.61,-1384.51 -3427.08,246.76 -1677.37,-840.04 -1058.87,37.7 -186.01,-615.81 -1154.18,-85.99 -1248.82,-691.87 -151.17,131.62 z',
		geocodigo: '2500908',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Arara - PB',
	},
	{
		d: 'm 300288,97642.632 1094.36,-4241.866 5296.34,-318.817 478.49,-28.443 3299.53,-829.456 711.17,72.098 554.74,-138.904 2049.39,-1643.698 769.67,277.147 448.92,-87.973 157.09,-166.685 332.58,-164.7 1563,612.5 -1205.44,2729.135 -785.45,652.188 -1676.05,2416.931 -333.9,1511.408 -1520.28,810.274 -584.98,9.26 899.82,1720.429 1342.15,115.09 -3242.34,1660.89 -411.45,-1203.17 -640.85,37.04 -448.92,-395.54 -1537.36,270.53 -3212.11,-1640.393 -718.4,-697.165 -2017.85,-1242.2 -659.9,-92.603 z',
		geocodigo: '2501005',
		microrregiao: Microrregiao.curimatauOriental,
		name: 'Araruna - PB',
	},
	{
		d: 'm 304708.84,121469.31 308.26,-471.62 151.17,-131.62 1248.17,691.87 1154.18,85.99 186,615.81 1059.53,-37.7 1677.38,840.04 3428.39,-246.73 1686.61,1384.42 472.49,-14.56 2635.03,481.53 2245.25,219.61 828.17,1129.75 -685.54,490.8 -939.24,461.03 -49.3,390.25 -477.18,1048.39 -866.95,656.16 -2112.48,439.87 -884.7,1240.21 -1321.12,1273.95 -820.94,633.66 -664.51,121.71 -1202.15,-314.18 -650.05,190.49 -1251.45,-303.61 -561.32,551.66 -713.8,121.7 -155.78,-308.89 -523.19,464.33 -1202.15,-411.42 46.01,-451.11 -254.37,-190.5 575.77,-2164.92 -141.97,-349.24 117.65,-1863.96 -1027.32,-1008.04 -177.47,-502.71 -124.22,-3030.75 -1014.83,-1729.69 z',
		geocodigo: '2501104',
		microrregiao: Microrregiao.brejo,
		name: 'Areia - PB',
	},
	{
		d: 'm 217853.73,134491.9 101.88,27.11 564.61,685.26 290.51,30.43 1283.66,-318.16 1179.81,-675.99 786.76,6.61 340.47,-233.49 473.89,153.46 1496.62,2445.37 -1062.82,5059.41 -952.38,123.03 -740.76,-207.69 -690.14,134.27 -901.78,707.75 -1156.15,1432.04 -673.71,-320.15 120.28,-103.84 386.48,-556.94 32.21,-875.76 -140,-459.04 -492.96,-209.68 233.34,-665.42 -349.68,-625.73 678.97,-70.11 127.51,-537.76 -891.92,-3232.5 -44.7,-1717.12 z',
		geocodigo: '2501153',
		microrregiao: Microrregiao.patos,
		name: 'Areia de Baraúnas - PB',
	},
	{
		d: 'm 293442.47,135769.15 -495.59,-56.22 289.2,-420.68 502.16,-197.11 -24.97,-1001.44 617.18,-1201.85 -388.46,-359.82 -512.01,13.89 413.42,-578.77 289.2,-128.32 439.07,355.19 1432.86,-163.37 1687.23,1570.27 61.78,1212.44 -672.39,1090.07 -709.2,694.52 z',
		geocodigo: '2501203',
		microrregiao: Microrregiao.esperanca,
		name: 'Areial - PB',
	},
	{
		d: 'm 303425.18,164280.2 276.71,298.98 502.82,-285.09 532.39,248.05 -190.61,166.68 118.97,408.11 834.74,-22.49 348.35,-550.32 332.59,187.19 6.57,662.11 546.85,-359.83 421.32,246.06 -31.55,432.58 217.55,127.67 458.13,-316.84 345.07,40.35 359.53,-590.67 496.9,351.88 767.04,-490.79 212.96,500.72 758.49,-703.78 264.23,676.66 580.37,-285.09 314.84,155.45 594.18,-308.9 443.65,652.85 347.05,17.19 217.56,-212.32 -66.39,-451.11 508.73,-31.08 95.31,-558.27 790.05,418.04 388.45,-445.16 2200.55,-729.57 553.43,161.39 470.62,-207.7 909,99.22 714.47,267.23 349.67,-498.08 446.94,220.93 106.48,836.73 -826.85,2381.88 -52.58,3334.35 -551.46,1035.17 141.32,2506.23 728.26,1001.43 -364.13,1019.29 -1564.98,-775.88 -2989.29,976.3 -888.64,204.39 -350.32,350.57 -126.86,676.65 -504.13,304.27 -1992.2,91.28 -955.69,-259.29 -642.81,-611.17 291.18,-263.92 -365.45,-328.08 31.55,-500.71 318.12,-449.79 -318.12,-801.68 -888.64,-390.25 80.84,-579.43 -3264.03,-3176.27 -1178.5,-699.81 -782.81,-634.99 -910.98,-2072.32 -131.46,-2029.32 503.47,-435.23 z',
		geocodigo: '2501302',
		microrregiao: Microrregiao.umbuzeiro,
		name: 'Aroeiras - PB',
	},
	{
		d: 'm 234376.34,138938.81 1963.95,-2375.92 68.35,-1549.11 -266.85,-1145.63 813.7,-726.93 47.98,-558.26 838.03,-445.16 362.82,-1116.52 2828.26,414.73 761.78,394.88 1330.98,2550.54 -314.83,437.22 297.09,447.8 -548.17,654.17 554.74,200.42 -844.6,376.37 195.87,1388.38 -370.7,200.41 733.52,1226.99 28.26,689.89 -985.92,-228.86 -1389.47,161.39 -332.59,654.84 -978.03,486.16 -1246.19,-1284.53 101.88,-699.15 -1766.76,545.03 -1886.39,-699.15 z',
		geocodigo: '2501351',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Assunção - PB',
	},
	{
		d: 'm 361144.54,109499.75 -280,-329.4 -410.14,-2499.61 1113.43,-3315.18 944.51,-1625.84 567.88,-387.61 513.33,-44.98 1106.86,339.32 432.48,-278.46 -7.89,306.24 526.48,154.78 233.34,2701.36 593.51,2078.27 497.57,896.26 1160.74,521.88 -257.65,763.31 200.48,1615.92 -891.93,-441.19 -2005.35,58.87 -2623.84,-787.12 -1415.12,270.53 z',
		geocodigo: '2501401',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Baía da Traição - PB',
	},
	{
		d: 'm 313657.65,105918.67 312.21,-593.32 632.3,-51.59 159.06,-400.18 -154.46,-531.14 698.68,-65.49 710.52,541.07 636.24,-54.9 1128.54,941.24 1171.93,-275.16 563.28,-1136.37 346.39,-225.55 748.64,-1211.12 782.81,-705.76 364.13,1.98 811.08,-554.29 899.81,802.34 817,-661.45 563.28,244.73 251.09,248.05 -31.56,2614.7 3.29,1318.93 -375.31,828.79 -658.58,988.87 -9.21,945.87 -329.29,654.17 -40.1,849.96 560,359.83 -4.6,814.9 401.6,574.14 763.75,486.82 5.92,476.25 -502.16,947.85 -562.63,-43.65 -2269.58,1523.97 -3306.75,-829.45 -2236.05,-399.52 -335.21,-1171.42 -1480.85,-1328.19 -523.19,-979.6 256.34,-1702.57 -324.7,-1109.25 -693.42,-670.05 -136.72,-672.03 386.48,-790.43 z',
		geocodigo: '2501500',
		microrregiao: Microrregiao.brejo,
		name: 'Bananeiras - PB',
	},
	{
		d: 'm 271454.61,108607.45 -378.59,-704.44 -422.64,-2139.12 329.3,-1486.94 3.29,-2278.68 -1894.27,-2856.135 -152.49,-1668.834 720.38,1078.16 1524.87,1046.411 1286.3,489.468 1310.6,1131.74 -636.24,3427.62 -577.08,3112.12 -1087.8,1068.9 -25.63,-216.96 z',
		geocodigo: '2501534',
		microrregiao: Microrregiao.seridoOriental,
		name: 'Baraúna - PB',
	},
	{
		d: 'm 271812.16,119483.64 11218.4,-12928 2983.37,-1179.36 1442.06,-976.3 560,11.25 2672.49,-1469.08 2237.37,-2107.37 -1810.15,3690.91 -889.91,741.99 15.11,1470.53 896.9,1635.77 1478.27,863.75 1765.08,-500.31 1957.28,1624.86 14.45,803.65 1119.35,709.08 441.69,41.67 337.18,543.71 -481.79,651.53 262.92,591.99 -57.84,687.91 -398.97,214.31 -90.71,446.47 664.51,651.53 -1281.68,107.15 -882.07,1856.69 -817.66,613.82 -4583.84,2973.21 -1641.22,644.91 -2218.3,1105.94 -2197.28,1736.3 -1954.73,986.88 -2523.29,931.98 -1631.36,-177.92 -6603.66,-6994.82 z',
		geocodigo: '2501609',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Barra de Santa Rosa - PB',
	},
	{
		d: 'm 284423.32,174521.4 -577.09,-473.6 -14.46,-925.36 -1457.84,-676.67 -165.63,-892.95 316.81,-312.2 -198.5,-281.78 410.14,-169.33 -54.55,-294.35 814.36,-54.24 529.77,-369.74 684.88,74.74 14.46,-454.41 778.87,-302.28 264.22,99.21 768.36,-950.5 648.73,-2613.38 2852.58,-2127.88 3338.6,2102.08 -39.07,438.54 723,517.26 189.3,-171.32 981.31,907.5 1224.51,598.62 441.69,-561.57 892.58,135.59 153.8,584.06 680.94,-417.37 1229.11,140.89 30.23,535.77 -658.59,779.19 -79.53,744.13 306.95,359.82 619.81,-19.18 976.71,873.77 769.67,-31.74 1397.37,468.96 -77.56,541.06 195.87,33.74 -62.44,196.45 -1733.24,230.19 -1246.19,1013.33 -296.44,984.24 -483.09,384.96 1033.89,2419.57 -301.03,435.24 199.15,214.97 9.21,838.05 -1723.38,-4.63 -445.64,466.99 -2178.21,982.91 -418.02,-121.71 -191.27,-916.77 -935.97,-611.18 -402.91,-782.48 -506.76,212.98 -395.67,-287.07 179.43,-639.62 -712.48,-502.7 76.24,-634.99 -327.33,-791.09 -1086.48,-871.79 52.59,-438.54 -513.34,-435.24 -1335.58,-97.89 -205.07,545.7 -183.38,-311.55 -906.38,-252.67 -471.93,276.49 -226.1,-208.36 -542.25,212.99 -333.9,-206.37 -273.43,629.03 165.63,372.4 -453.51,70.77 -32.87,339.32 -345.72,-138.9 40.09,-273.84 -264.23,118.4 -1131.82,-251.35 z',
		geocodigo: '2501575',
		microrregiao: Microrregiao.caririOriental,
		name: 'Barra de Santana - PB',
	},
	{
		d: 'm 258468.15,179886.4 6311.16,56.22 404.22,-521.88 -443.66,-406.13 -9.86,-211.66 -842.62,-1537.21 340.47,-164.69 314.83,-1152.25 -378.59,-9.92 -114.37,-496.08 510.71,-687.91 872.21,-336.02 1279.05,246.06 901.13,574.8 1277.08,-910.81 844.6,-719.66 -111.73,-296.33 -626.39,-65.48 34.84,-251.35 673.05,-420.68 435.12,-773.23 1612.3,-788.45 1057.56,-1426.08 407.51,-222.25 339.81,28.44 860.37,851.29 79.53,920.73 -200.47,1462.46 488.36,1680.08 1129.2,1313.64 554.74,1728.36 -61.78,422.67 2640.93,2995.03 122.25,547.68 -236.61,2691.44 332.58,1579.53 331.92,340.65 1334.27,340.64 562.63,537.76 62.44,1078.82 -1197.55,-195.12 -2816.43,164.69 -1352.68,441.85 -400.94,-1422.77 -737.46,-454.42 -2589.01,2172.86 -136.71,562.89 909.66,589.35 117.65,585.39 -750.6,697.82 -941.22,316.83 -550.14,-84.66 -1439.44,-1160.18 -985.91,-461.03 -4138.87,-145.52 -860.38,459.05 -1651.73,-291.04 -200.47,-3096.24 -3371.17,-6671.36 z',
		geocodigo: '2501708',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Barra de São Miguel - PB',
	},
	{
		d: 'm 365051.39,143531.91 2333.33,-3680.96 1116.05,-455.74 333.9,295.67 178.12,-529.16 303.67,-14.55 219.53,-600.6 -197.19,-302.28 410.14,-30.43 -588.26,-841.36 291.18,-830.77 1522.91,1148.93 -300.38,659.46 312.86,497.41 -281.31,830.78 -479.81,-134.27 -166.95,451.1 -621.79,115.09 -434.45,396.87 89.39,684.6 532.39,486.83 -389.11,1064.27 -623.75,449.78 -198.5,-248.04 -518.59,103.84 -939.91,-367.76 -425.91,388.27 -798.6,-91.28 z',
		geocodigo: '2501807',
		microrregiao: Microrregiao.joaoPessoa,
		name: 'Bayeux - PB',
	},
	{
		d: 'm 325384.78,113143.66 -6.58,-476.9 -763.09,-486.16 -402.26,-574.8 4.6,-814.24 -559.34,-359.83 39.44,-849.96 329.29,-654.84 9.86,-945.21 657.93,-988.86 375.31,-829.46 -3.29,-1318.26 1270.52,248.04 6194.82,1406.24 -1726.66,654.83 -1453.24,2096.13 -2292.58,1992.28 1678.69,601.92 2304.4,806.31 -1149.57,115.09 -1511.08,1484.95 -886.66,89.96 -453.53,-171.32 -836.7,-1024.58 -821.6,-2.65 z',
		geocodigo: '2501906',
		microrregiao: Microrregiao.guarabira,
		name: 'Belém - PB',
	},
	{
		d: 'm 173713.7,70734.929 1179.81,-179.253 1089.76,-830.116 815.68,-275.163 290.52,-248.043 648.73,662.11 1496.62,102.524 264.22,-953.146 1072.68,-23.151 1350.04,-28.442 -36.15,-564.877 1016.15,3.307 460.75,527.836 1741.12,-486.165 366.11,-1816.336 594.17,-572.814 1429.58,-234.152 1313.24,275.824 1027.32,-246.72 761.78,-1617.902 313.52,-148.826 523.2,-171.315 1240.28,270.532 1350.04,-1385.733 1923.85,-707.088 746.01,-550.325 866.29,566.199 1046.38,-166.684 644.13,-681.292 617.19,-1316.943 729.57,-96.571 1003.01,128.983 612.57,975.635 1375.03,-241.428 680.94,670.046 1474.27,-1.322 -119.62,504.023 362.81,2048.504 -151.18,1148.935 392.4,843.346 -570.52,2315.069 631.64,854.59 682.92,-107.155 -633.62,1305.699 -619.15,709.072 -1355.97,217.617 -101.87,1111.894 -671.08,569.506 -2154.55,590.673 -341.78,-391.577 -13.15,-248.043 -794.65,-259.949 154.47,-633.006 -339.16,-176.606 -1085.82,62.837 -281.32,-349.244 -1342.16,-63.499 17.75,356.52 -262.91,-262.595 -615.21,134.935 -1896.9,-864.512 -410.14,-476.904 -653.99,-150.148 -1597.84,422.665 -788.72,-17.86 -1904.79,1345.386 -769.67,-46.302 -806.49,467.644 -872.86,85.988 -190.61,710.395 -971.45,683.938 -584.97,203.064 -550.8,828.795 -1283.01,787.123 -2472.67,-138.905 -400.94,355.198 -684.88,-253.334 -552.11,67.467 -502.16,-295.667 -892.58,143.534 -1141.03,339.323 -824.22,824.164 -1392.78,429.28 -437.09,505.346 -186,-766.618 -610.62,-931.319 433.81,-772.571 z',
		geocodigo: '2502003',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'Belém do Brejo do Cruz - PB',
	},
	{
		d: 'm 101772.85,96700.069 -454.84,-340.646 276.71,-1090.066 1252.77,-929.334 1777.94,-2203.284 42.72,-1117.185 971.45,-526.513 106.48,-748.759 658.59,-189.174 778.87,742.806 1572.87,3528.163 -1717.47,-97.232 52.59,1399.624 -2529.86,197.111 -1505.82,385.624 -1084.51,747.436 z',
		geocodigo: '2502052',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Bernardino Batista - PB',
	},
	{
		d: 'm 127287.65,166009.89 49.3,-1112.56 -370.71,-535.77 738.12,-859.22 510.05,-2688.79 -1085.82,-1742.25 1025.35,-625.74 -42.72,-849.95 5161.59,21.16 2443.75,2057.77 1602.72,1182.46 1612,4614.59 -3506.67,222.52 -620.35,-1159.91 -4948.63,1690 -2566.67,-212.99 z',
		geocodigo: '2502102',
		microrregiao: Microrregiao.itaporanga,
		name: 'Boa Ventura - PB',
	},
	{
		d: 'm 266787.95,153147.37 -933.34,-1776.65 1275.12,-6877.07 7500.18,865.17 -147.89,-233.49 3898.96,321.46 2215.03,-2727.81 2202.53,-891.62 1077.93,134.27 178.12,3045.31 -136.71,1010.02 -604.04,764.64 -166.95,787.12 149.21,368.43 -243.2,187.19 -205.07,1028.55 -134.74,299.63 -277.37,-125.01 -335.21,3427.62 -666.48,4117.51 -1577.46,-967.03 -1833.15,147.5 -1433.8,1202.07 -1091.25,-368.6 -914.4,775.33 -1346.83,-1026.07 -728.26,540.41 -1502.53,-343.96 -623.1,854.6 -3069.48,168 -1597.84,-312.2 1076.62,-4395.32 z',
		geocodigo: '2502151',
		microrregiao: Microrregiao.campinaGrande,
		name: 'Boa Vista - PB',
	},
	{
		d: 'm 97252.1,119554.41 141.315,-227.53 -527.793,-686.59 72.958,-134.27 1110.139,-668.72 410.141,-636.98 462.722,-439.86 997.088,-256.64 -62.441,-460.37 -406.854,-515.27 59.154,-68.79 842.631,-29.1 872.86,296.33 235.31,-785.8 1213.33,321.46 2144.69,243.41 -444.98,798.37 -944.5,-81.35 53.89,267.22 -440.37,-17.86 -435.77,611.18 -542.26,7.93 -154.46,563.56 -764.41,849.96 216.24,673.35 -874.83,13.23 -76.24,443.17 -923.478,531.14 -544.225,1638.41 -578.403,-814.24 -1098.965,-826.15 -15.775,-611.18 z',
		geocodigo: '2502201',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Bom Jesus - PB',
	},
	{
		d: 'm 143983.78,92467.464 365.45,-457.723 658.59,-12.567 -524.51,-1622.532 850.51,-1006.724 -446.94,-130.967 50.61,-216.293 2506.19,-52.916 753.89,-578.767 47.33,-992.833 914.27,-503.362 14.46,718.332 849.2,1057.656 1118.03,-78.712 629.67,-713.703 357.55,140.889 143.29,488.148 968.17,75.405 2520.65,2100.759 -1687.22,3591.001 -1197.56,3074.41 -893.24,-624.407 -1162.72,-278.469 -3446.1,169.33 -366.76,-228.861 -2959.71,-31.749 628.35,-3516.919 -691.45,-363.797 z',
		geocodigo: '2502300',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'Bom Sucesso - PB',
	},
	{
		d: 'm 105784.2,149089.39 636.25,-228.86 113.05,-739.5 951.08,-552.31 504.13,46.96 1769.38,-1025.91 1898.88,-136.25 430.51,-570.83 -206.38,-250.03 168.26,-576.12 560,-258.63 386.48,1353.32 749.95,116.42 88.07,589.35 853.81,417.37 1802.9,-1403.59 40.1,1024.58 -431.17,1205.16 607.32,760.01 -694.74,394.88 -267.51,566.2 -216.25,616.47 153.15,582.07 -291.18,838.72 1.32,1094.69 571.17,92.61 -67.04,785.8 429.86,1076.83 -464.7,865.84 -1281.68,-27.12 -562.63,1107.27 -936.62,222.24 -696.71,699.81 -1001.69,291.04 -257.66,513.94 -1093.7,-517.25 -529.77,35.72 -147.88,-757.36 -809.11,158.75 -24.32,196.45 -255.68,-363.14 -305.63,-19.17 -290.52,-1075.52 -1539.34,-1102.63 -49.3,-1407.57 742.72,-674.01 398.97,-905.52 -582.34,-1121.82 35.49,-910.81 -720.38,-170.66 -170.23,-855.25 z',
		geocodigo: '2502409',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Bonito de Santa Fé - PB',
	},
	{
		d: 'm 274499.77,171163.89 540.93,-3947.52 -322.63,-4678.48 1214.11,602.18 934.3,-1297.01 448.74,-1972.86 3109.05,-535.31 107.95,-2573.26 -678.25,-856.02 1577.43,967.03 338.5,1043.1 907.7,900.9 872.86,489.47 155.78,1058.32 -478.5,1724.39 404.22,490.14 -492.96,220.92 55.87,396.87 661.22,348.58 19.07,385.63 3307.41,1993.6 -648.73,2613.38 -767.7,950.5 -264.23,-99.21 -779.53,302.28 -13.8,454.41 -685.54,-74.74 -529.76,369.74 -813.71,54.24 53.89,294.35 -410.14,169.33 198.5,281.78 -316.15,312.2 164.98,892.95 1458.5,676.67 14.45,925.36 577.09,473.6 -1186.38,84.66 -922.16,705.77 -321.41,-195.13 -466.01,178.59 -693.42,837.4 -422.63,-195.13 12.49,267.23 -271.46,47.62 -494.92,494.76 60.46,302.28 -408.82,224.23 -247.79,-139.56 -604.04,270.53 -322.73,-342.63 -389.76,341.31 -478.5,76.72 -265.54,-208.35 -257.65,549 -335.86,-50.93 62.43,-422.67 -555.4,-1728.36 -1128.54,-1313.63 -488.36,-1680.09 200.48,-1462.45 z',
		geocodigo: '2502508',
		microrregiao: Microrregiao.caririOriental,
		name: 'Boqueirão - PB',
	},
	{
		d: 'm 316507.6,114342.21 2235.4,400.17 3307.41,828.8 2268.92,-1523.32 563.29,43 -3342.91,2544.59 -1847.61,356.52 -2041.49,-312.21 -606.01,-463.67 -535.68,-1875.2 z',
		geocodigo: '2502706',
		microrregiao: Microrregiao.brejo,
		name: 'Borborema - PB',
	},
	{
		d: 'm 173947.68,77611.342 437.09,-505.346 1392.78,-429.28 824.22,-824.164 1141.03,-339.323 892.58,-143.534 502.16,295.667 552.11,-67.467 684.88,253.334 400.94,-355.198 2472.67,138.905 273.43,1121.815 713.8,795.061 1637.93,724.947 410.14,902.876 195.87,-305.589 464.04,-110.461 1204.13,-93.265 292.49,408.114 1079.25,-207.695 785.44,-774.556 232.68,306.912 933.33,67.468 1397.37,1147.612 356.24,57.547 191.27,481.533 707.23,175.945 -2189.39,1163.487 -917.55,1468.415 -15.78,1818.32 -796.62,758.681 -65.07,822.18 -5085.35,293.021 -1344.12,676.662 -1609.02,232.168 -543.57,313.526 -1197.55,-334.031 -1758.21,611.178 -947.8,-304.266 -744.03,248.043 -370.71,443.17 -32.2,-640.281 -1613.62,-1740.932 -429.2,-2198.652 266.85,-1084.775 -322.72,-582.075 55.87,-3719.321 -508.73,-331.386 -5.26,-634.99 z',
		geocodigo: '2502805',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'Brejo do Cruz - PB',
	},
	{
		d: 'm 151770.53,87873.045 1287.6,-875.095 815.68,33.734 291.18,-228.861 143.28,-386.947 -306.95,-324.771 20.38,-1664.864 2055.96,765.295 404.22,-624.407 920.85,-240.105 -187.99,-631.684 713.81,-871.788 -646.76,-677.323 2240,985.558 1229.76,1471.06 -1668.17,5939.803 -1265.91,-587.366 -1594.55,398.192 -464.7,324.771 -2520,-2100.759 -968.82,-75.405 -142.63,-487.487 -358.21,-141.551 z',
		geocodigo: '2502904',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'Brejo dos Santos - PB',
	},
	{
		d: 'm 364231.11,167171.39 556.71,-2214.53 -299.05,-936.61 216.24,-1108.58 165.63,-356.52 508.73,-182.57 1062.16,-2102.74 861.69,830.78 78.22,419.35 1252.77,663.44 310.23,595.3 2473.98,1464.45 1830.52,-377.69 299.06,-1103.95 505.45,-44.98 -394.37,1805.09 -33.52,3203.39 335.21,1897.69 -212.96,416.06 -312.86,-102.53 -922.16,812.92 -1270.51,-902.22 -506.11,648.22 h -400.94 l -46.01,-614.48 -642.15,62.18 -63.76,355.19 -281.32,-19.18 -676.99,-729.57 -596.8,-297.66 -1298.78,664.1 -736.15,81.36 -627.05,-1289.17 -1081.22,-1059.64 -53.89,-475.58 z',
		geocodigo: '2503001',
		microrregiao: Microrregiao.litoralSul,
		name: 'Caaporã - PB',
	},
	{
		d: 'm 259795.84,166349.21 6992.11,-13203.82 -1076.63,4395.98 1597.85,312.2 3068.82,-168.01 623.76,-854.58 1502.53,343.94 728.26,-541.06 1351.43,1028.05 914.4,-775.36 1091.25,368.63 1430.56,-1202.14 1833.79,-147.37 678.25,856.02 -107.95,2573.2 -3109.05,535.28 -448.74,1972.96 -934.3,1296.94 -1214.11,-602.24 320.66,4676.55 -540.28,3948.19 -79.53,-920.74 -860.37,-850.62 -340.47,-29.1 -406.85,222.24 -1057.56,1426.09 -1612.3,788.44 -435.77,773.23 -672.4,420.68 -35.49,251.35 627.04,65.49 111.08,296.33 -844.6,719.65 -987.23,-412.75 -1313.23,-1977.72 359.53,-1160.18 -1424.98,-44.98 -341.79,-479.55 -424.59,-127 -598.13,-1017.31 -281.31,-46.3 -86.1,-864.51 -726.29,-196.45 -219.54,-355.19 -119.62,243.4 -278.03,-289.71 -285.91,103.85 -30.24,-438.54 -443.66,56.22 -564.6,-552.31 -882.72,17.86 -449.57,-440.53 z',
		geocodigo: '2503100',
		microrregiao: Microrregiao.caririOriental,
		name: 'Cabaceiras - PB',
	},
	{
		d: 'm 372890.72,134974.09 431.18,-2260.17 324.04,29.11 471.26,-1172.75 976.06,-923.38 -25.64,-1418.15 -458.12,-926.02 841.31,4.63 320.1,2942.78 -318.12,1738.94 -772.96,861.21 -205.07,957.11 -459.44,452.44 333.9,2754.26 -1076.62,-384.3 -402.25,-812.91 588.26,-1328.19 -569.2,-514.61 z m 445.64,-3096.9 -103.85,-12.57 -36.15,-33.73 -28.92,-105.83 -190.61,-492.78 -106.48,-150.15 -90.7,-666.08 -47.98,-273.18 3.28,-299.63 132.11,-452.43 120.94,-48.29 190.61,39.69 v 50.27 l -98.59,50.93 -4.6,12.57 486.38,82.02 531.08,-158.75 44.7,-7.93 26.94,21.16 28.92,283.1 -167.6,1022.6 -176.15,455.74 -105.16,174.62 -160.38,220.92 -132.11,166.03 -42.72,43.65 -26.95,44.32 -46.01,35.71 z',
		geocodigo: '2503209',
		microrregiao: Microrregiao.joaoPessoa,
		name: 'Cabedelo - PB',
	},
	{
		d: 'm 90538.024,130294.35 293.146,-279.8 656.618,-107.15 652.019,-972.99 -375.305,-621.1 79.53,-445.82 -748.638,-174.62 -567.886,-739.5 717.088,-649.54 -518.591,-916.77 264.226,-93.26 -30.236,-634.99 -437.745,-411.42 647.417,-532.47 1881.124,-496.08 -143.286,-511.96 260.938,-187.2 1060.844,-77.38 -11.173,-681.29 1144.317,-132.95 843.286,-668.73 815.68,-288.39 -128.827,-545.7 356.244,-570.83 15.775,611.18 1098.965,825.49 578.403,814.9 950.712,2908.34 -925.46,4922.03 -1139.694,2354.36 125.899,97.49 -820.937,1504.79 53.896,535.77 -1208.074,-455.74 -119.624,-808.29 -1597.838,-804.98 -1235.679,-441.85 -1476.9,-1071.54 -556.713,82.68 -458.122,-332.71 z',
		geocodigo: '2503308',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Cachoeira dos Índios - PB',
	},
	{
		d: 'm 200435.52,145058.05 168.66,-6262.78 3995.58,-3736.52 911.64,543.72 514.65,-92.61 2048.73,870.47 809.11,646.24 2470.7,987.53 684.22,678.65 -780.84,596.63 645.44,3773.56 105.17,937.27 -232.02,379.67 -687.51,-42.99 -420.66,-552.31 -682.91,-7.94 -1357.93,736.19 -1036.53,399.52 -879.43,-189.84 -843.94,525.86 410.14,909.48 -806.58,1243.13 -2459.17,-1831.39 z',
		geocodigo: '2503407',
		microrregiao: Microrregiao.patos,
		name: 'Cacimba de Areia - PB',
	},
	{
		d: 'm 299736.55,99718.256 551.45,-2075.624 659.9,92.603 2017.84,1242.199 719.06,697.166 3212.11,1640.39 1537.37,-270.53 448.26,395.54 640.85,-37.04 412.09,1203.18 -1393.35,867.91 1051.83,3724.68 -692.36,659.3 -799.9,363.8 -1482.16,21.83 -1710.89,-707.09 -1783.85,-363.13 -337.18,-675.34 -483.1,220.92 -763.1,-164.7 103.2,1099.33 -1210.03,-334.63 z',
		geocodigo: '2503506',
		microrregiao: Microrregiao.curimatauOriental,
		name: 'Cacimba de Dentro - PB',
	},
	{
		d: 'm 205471.21,147400.98 805.26,-1243.13 -410.79,-909.48 844.6,-525.86 879.43,189.84 1036.52,-399.52 1357.94,-736.19 682.25,7.94 421.31,552.31 686.86,42.99 232.02,-379.67 -104.51,-937.27 1082.53,-347.26 268.83,-448.47 497.56,-234.81 1318.49,220.92 3246.94,1190.61 -120.93,103.85 -1246.85,1072.21 -1120.66,-40.35 1432.2,4433.02 -1087.13,-128.32 -332.58,441.85 -1289.58,108.47 -151.17,-257.3 -624.41,-134.94 -262.91,-478.22 -331.93,-28.45 -166.95,-638.95 -299.06,-107.16 -736.15,262.6 -4201.4,643.54 z',
		geocodigo: '2503555',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Cacimbas - PB',
	},
	{
		d: 'm 326337.17,105094.5 348.35,-228.86 19.06,-886.34 645.45,203.73 377.28,-1025.91 552.77,-350.56 627.04,-17.86 443.66,-386.95 917.55,83.34 448.27,1183.99 272.11,118.4 1098.31,-994.15 394.36,-3782.824 1232.4,-2495.643 -82.82,-2887.22 642.16,139.566 2487.13,1414.175 -2332.67,3700.14 512.02,2627.276 985.91,322.78 267.51,-349.9 761.79,30.42 552.77,-187.19 463.38,146.84 495.58,-639.62 416.72,191.82 310.23,-230.18 -23.66,558.26 331.92,428.62 -149.2,1358.61 -1381.59,-33.73 -668.45,-539.08 -186.02,1364.57 -1119.33,-285.75 -291.18,1447.91 -880.75,111.79 -133.42,939.25 -700,-204.39 -964.88,1073.53 -492.97,-511.96 -6194.82,-1406.9 z',
		geocodigo: '2503605',
		microrregiao: Microrregiao.guarabira,
		name: 'Caiçara - PB',
	},
	{
		d: 'm 97191.631,134126.78 -53.896,-535.12 820.28,-1504.79 -130.5,-96.16 1139.694,-2354.36 925.46,-4922.03 -945.453,-2910.32 543.567,-1638.41 923.477,-531.14 76.9,-443.17 874.18,-13.23 -216.25,-673.35 765.07,-849.96 154.46,-563.56 542.25,-7.93 435.12,-611.18 441.03,17.86 -54.55,-267.22 944.5,81.35 445.64,-798.37 379.9,433.92 2193.99,551.65 2690.89,95.9 4482.62,621.1 723.01,-339.32 245.16,1987.65 52.58,2222.46 -445.63,1589.46 -551.45,703.78 400.93,2778.09 308.27,2145.73 1308.63,119.73 1265.92,1395.65 -4322.25,15.87 -470.61,-201.74 -1342.16,310.88 -2360.93,95.92 115.68,406.12 -813.71,240.11 -525.17,731.56 -663.19,246.06 -963.56,1358.61 -309.58,92.61 -954.37,-504.03 -400.93,48.29 -670.43,574.8 -911.64,1515.37 -1908.07,-27.78 -1352.68,-663.43 -437.742,97.23 -1703.002,-782.49 -449.577,849.96 -237.277,-89.96 z',
		geocodigo: '2503704',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Cajazeiras - PB',
	},
	{
		d: 'm 155300.1,132119.28 -531.08,-769.92 -531.08,-173.3 -1096.99,-1228.31 -394.37,92.6 -569.2,-556.94 -418.03,-819.53 822.26,-554.96 103.19,-795.06 834.74,-363.8 496.25,-524.52 -136.72,-606.55 960.28,-489.47 38.12,-944.55 379.91,-738.83 1027.32,-234.16 944.51,-2097.45 688.82,-22.49 1004.98,361.81 1125.26,115.09 270.79,-169.99 409.48,373.06 755.87,29.1 2227.51,782.5 23.67,545.03 295.77,222.91 -85.45,577.44 610.61,227.54 -146.57,1236.91 343.75,488.15 3.29,1316.94 896.52,2750.3 2386.57,-2328.3 1261.32,760.67 717.09,2799.91 -671.08,454.41 -2106.57,457.72 -3120.09,224.23 -2176.9,-365.11 -3092.49,-11.25 -824.22,217.62 -2721.78,-239.45 z',
		geocodigo: '2503753',
		microrregiao: Microrregiao.sousa,
		name: 'Cajazeirinhas - PB',
	},
	{
		d: 'm 335917.63,133128.65 3193.04,4693.64 755.21,364.45 -140,541.07 709.2,134.27 -151.17,497.41 380.56,-193.14 316.15,241.43 489.67,-466.99 562.63,15.88 278.69,-280.46 -1747.7,3108.15 -995.12,356.52 -506.1,1350.68 -87.42,351.88 -247.79,523.21 -901.78,528.5 -354.27,-1293.13 132.11,-593.32 -366.11,-953.81 295.78,-789.11 -71.64,-1171.42 -168.26,-535.77 470.6,-365.12 -200.46,-889.65 -728.27,-1854.7 -877.46,21.17 -41.41,-3338.33 z',
		geocodigo: '2503803',
		microrregiao: Microrregiao.itabaiana,
		name: 'Caldas Brandão - PB',
	},
	{
		d: 'm 221110.54,191297.71 9616.61,-1481.65 -367.42,2326.31 1316.52,-855.91 879.44,-209.68 646.1,140.89 1815.4,-934.63 648.73,68.79 117.65,-1073.53 450.23,-232.17 442.35,182.57 333.9,-705.77 581.69,-316.83 584.97,-939.26 -22.61,2496.05 2526.04,1824.39 2824.65,3520.4 2319.33,1080.11 3462.53,2747.65 -17535.47,1510.75 -1722.06,1956.57 -630.99,371.72 -1531.45,1721.76 -811.08,374.37 -1003,-246.71 -859.06,380.99 -402.25,606.55 -556.72,119.72 -478.49,568.19 -675.68,-11895.49 -1969.86,-3104.84 z',
		geocodigo: '2503902',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Camalaú - PB',
	},
	{
		d: 'm 281431.4,156872.64 665.82,-4117.51 335.87,-3427.62 276.71,125.01 134.75,-299.63 205.07,-1028.55 243.19,-187.19 -149.21,-368.43 166.95,-787.12 604.04,-764.64 136.71,-1010.02 -178.11,-3045.31 2286.66,887 1185.07,-78.72 922.15,625.74 88.74,-516.6 233.98,-78.71 2386.57,-81.36 704.61,-257.96 583.66,1144.3 1073.33,1314.96 597.46,96.58 600.1,-201.75 676.99,95.91 -140,-1432.03 2542.35,516.59 1238.96,-1832.21 240.56,1264.03 818.31,959.09 33.52,495.43 497.56,595.3 -23.66,611.84 254.37,68.79 774.27,-648.21 606.01,-754.05 191.27,255.97 495.58,-70.11 799.91,447.8 606.01,562.23 -1871.93,2982.46 350.33,230.19 1664.22,331.39 892.59,718.33 936.61,214.97 339.81,410.75 682.26,51.6 444.97,364.46 1098.97,-657.48 569.2,400.18 868.26,-48.29 1197.56,-1030.54 843.94,-217.62 -2961.69,5962.3 599.85,-1203.43 -1341.03,148.65 -1752.01,-570.86 -1937.68,67.61 -2075.65,838.51 -513.84,-2243.77 -2192.68,-86.65 -1139.05,-609.19 -5.92,-361.81 -3727.41,-48.29 -394.37,-85.99 36.81,-353.87 -467.98,-38.36 -228.74,398.19 -594.83,251.35 23.66,699.15 -1513.7,-145.52 413.42,414.73 -131.45,1436.66 -295.78,56.22 233.34,1256.09 -249.77,65.49 86.1,278.47 -416.71,160.07 101.88,1602.02 -286.58,361.15 -497.55,86.65 -508.73,502.7 72.95,228.86 -390.42,-91.27 -375.96,222.24 -205.07,-473.59 -193.9,138.9 -728.26,-700.48 -863.66,-349.24 -6315.77,-641.6 z',
		geocodigo: '2504009',
		microrregiao: Microrregiao.campinaGrande,
		name: 'Campina Grande - PB',
	},
	{
		d: 'm 346692.35,121197.45 767.7,-419.35 -87.41,-386.95 767.04,-117.08 409.48,-482.86 552.11,353.22 857.09,-570.83 1212.01,361.81 -113.04,364.46 652.01,2043.21 -107.14,635.65 2140.75,377.69 387.8,-211.67 1710.89,3119.39 -398.97,-93.26 -1893.61,255.98 -1204.13,128.32 -419.34,-156.76 -1724.04,902.21 -13.8,-155.43 -161.03,-594.65 254.36,-990.85 -1167.32,-721.64 -1446.67,-1856.68 -677.65,-357.84 -325.35,-808.29 28.26,-615.81 z',
		geocodigo: '2504033',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Capim - PB',
	},
	{
		d: 'm 246044.97,182133.34 219.53,-214.31 454.17,471.62 876.81,104.5 1233.71,550.33 1008.26,-1977.73 285.92,-385.62 636.24,-228.87 359.53,-763.3 -558.64,-687.94 -2442.41,-1922.21 656.51,-629.8 4017.4,1207.85 2046.91,-696.67 1201.22,1385.98 1474.58,473.96 1203.25,-377.63 -247.84,1442.9 3371.17,6671.37 199.81,3096.24 -794.65,402.15 -1204.13,123.04 -320.75,622.42 -41.41,3112.11 -818.97,818.21 553.43,126.34 190.61,414.07 -341.78,771.25 -792.02,867.81 63.76,516.59 -1391.46,6.62 -1162.06,684.6 -577.74,-681.96 -815.69,358.51 -2674.45,-1356.63 -2291.27,-2912.36 833.43,-2412.96 -233.99,-785.8 287.89,-767.94 -1072.03,-445.16 -1381.59,-2150.36 -1729.95,-728.25 -554.74,-691.22 368.73,-636.97 -435.78,-386.95 -187.31,-685.26 534.36,-1701.25 z',
		geocodigo: '2504074',
		microrregiao: Microrregiao.caririOriental,
		name: 'Caraúbas - PB',
	},
	{
		d: 'm 119671.13,129917.32 1863.93,803.83 1108.57,1285.77 2317.15,-25.05 -586.18,1408.71 -1811.45,1917.54 -939.25,111.79 1871.27,1346.05 -607.33,120.38 -264.22,892.29 -703.94,731.56 -2583.1,-4587.8 337.84,-4007.06 z',
		geocodigo: '2504108',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Carrapateira - PB',
	},
	{
		d: 'm 296338.43,110359.63 834.76,-206.37 291.23,-572.91 599.41,-335.92 -205.2,-371.73 613.22,-646.24 1268.27,44.91 694.47,-952.42 1213.31,334.7 -103.84,-1099.33 763.74,164.7 483.1,-220.92 337.19,675.34 1783.84,363.13 1710.89,707.09 80.2,475.58 -210.33,243.41 166.94,277.81 -575.77,1714.47 -9.2,1402.93 -454.84,128.33 -237.27,255.98 268.82,251.35 -1156.15,957.11 418.69,1287.84 1265.91,2690.11 -397.65,185.2 -299.06,553.63 -645.45,17.86 -92.02,481.54 -385.16,158.75 252.4,339.32 -122.26,1137.69 -1359.25,-498.07 -757.18,-745.45 -359.53,-739.5 -1806.2,-1414.84 -1607.69,-1716.46 -449.58,12.57 -664.51,-651.52 90.71,-445.82 398.97,-214.97 57.18,-687.91 -262.25,-591.99 481.78,-651.53 -337.18,-543.71 -442.35,-41.67 -1119.34,-709.07 -14.46,-803 z',
		geocodigo: '2504157',
		microrregiao: Microrregiao.curimatauOriental,
		name: 'Casserengue - PB',
	},
	{
		d: 'm 167305.91,143507.43 2633.04,-1488.26 -596.14,-10183.65 670.42,-454.41 962.91,-622.43 1971.17,-504.02 1687.23,365.12 1093.71,-320.14 4985.44,480.21 524.5,8020.06 -2234.08,-70.78 3534.83,8576.99 -244.57,776.54 -1008.2,863.83 335.22,679.91 -1126,482.26 -11.05,814.26 -1785.16,1099.28 -847.91,1340.86 -801.24,490.07 -755.21,-664.09 -347.04,-1449.89 -596.15,-262.6 -168.26,-929.33 -1358.6,-115.1 -45.35,-402.15 -613.9,-177.27 -458.12,-963.73 -1300.75,-838.06 -55.86,-302.94 -619.82,-111.79 -232.67,174.63 -79.53,-613.17 -840.66,-890.96 321.41,-689.89 -527.79,-963.73 -74.93,-1359.94 -1992.86,216.3 z',
		geocodigo: '2504207',
		microrregiao: Microrregiao.pianco,
		name: 'Catingueira - PB',
	},
	{
		d: 'm 157283.1,82146.229 685.55,-824.164 1380.27,-558.262 -677.65,-588.688 985.92,-1048.395 1151.54,-191.821 2667.89,126.337 -22.35,-227.538 -974.74,-1063.609 68.36,-541.064 722.34,-842.023 -1152.86,-604.564 1068.07,-513.945 -216.24,-291.037 -738.78,-230.845 797.94,-922.72 731.54,-306.25 -59.15,-1057.655 388.45,-463.676 2905.16,-1206.481 788.73,679.969 1459.15,185.866 2266.29,17.199 596.15,-695.844 1607.7,-244.736 -128.83,4405.244 -434.46,772.571 610.62,931.319 186,766.618 5.26,634.99 508.73,331.386 -55.87,3718.66 323.39,582.736 -267.52,1084.774 429.2,2198.653 1614.28,1741.593 31.55,639.62 -108.45,952.485 -11228.91,-3136.586 34.83,7094.029 -200.47,-369.749 -709.2,-384.302 222.82,-905.522 -4259.81,-672.031 -703.28,-526.512 -498.88,-51.593 1668.17,-5939.803 -1229.76,-1470.399 -2240,-985.558 z',
		geocodigo: '2504306',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'Catolé do Rocha - PB',
	},
	{
		d: 'm 281431.4,156872.64 6315.77,641.61 863.66,348.58 727.6,701.14 193.9,-138.91 205.73,473.6 375.3,-222.91 391.08,91.28 285.92,416.05 -648.73,474.26 -61.79,433.25 686.86,392.24 79.53,330.72 -375.31,-123.03 -971.45,709.08 901.78,252.67 -228.08,1027.23 -627.69,4.63 72.95,262.59 -414.74,142.87 926.76,457.73 -88.73,249.36 -2852.58,2127.88 -3308.07,-1993.6 -19.06,-385.63 -661.22,-348.58 -55.87,-396.87 492.96,-220.92 -403.57,-490.14 478.5,-1724.39 -155.78,-1058.32 -872.86,-489.47 -908.35,-900.9 -338.5,-1043.1 z',
		geocodigo: '2504355',
		microrregiao: Microrregiao.caririOriental,
		name: 'Caturité - PB',
	},
	{
		d: 'm 96707.876,172620.4 327.981,-338.01 1444.036,-334.02 -275.4,-358.51 402.253,-441.85 -211.642,-609.85 273.427,-474.92 89.389,-864.51 -229.389,-406.13 593.52,-341.31 -138.685,-444.49 173.521,-693.2 1033.243,-1211.11 -721.694,-1481.64 -324.038,-118.41 90.048,-242.08 3011.644,-175.29 -179.44,-949.17 704.6,-433.92 76.24,-342.63 704.6,64.16 57.19,-1346.04 -799.91,-130.97 373.33,-974.31 491.65,-217.62 543.56,-1576.23 1079.91,-1334.8 833.42,-1710.5 1539.35,1102.63 290.51,1075.51 305.63,19.19 255.69,363.13 24.31,-196.45 809.11,-158.75 147.89,756.7 529.76,-35.06 1093.71,516.6 257.65,-513.29 1001.69,-291.04 696.71,-699.81 936.62,-222.25 562.63,-1107.26 1281.69,27.12 157.09,-75.41 32.21,379.67 438.4,-64.16 -502.16,1146.96 200.47,220.92 -245.16,465.66 446.94,-68.79 387.8,315.51 -591.55,1691.32 1033.89,728.26 -343.75,564.87 1.97,585.38 -256.34,117.08 -270.14,-321.47 -1317.84,116.42 -1079.9,466.98 -2520.66,254.66 -1103.56,396.87 1160.75,5991.4 -421.32,425.31 138.69,388.93 610.61,486.82 2140.09,287.73 1252.77,713.7 54.55,953.81 -960.28,1329.51 -208.36,147.51 -483.75,-286.41 46.01,491.45 -356.25,402.83 792.02,595.3 57.19,380.99 -535.68,827.48 53.89,451.1 500.85,630.36 -577.09,779.19 -276.72,1757.47 1162.73,3785.46 -1643.19,-169.33 526.47,-808.29 -34.83,-296.98 -351.65,-430.61 -816.99,-125.01 -648.73,-605.23 -171.55,-508.65 535.68,-1046.41 -253.05,-472.28 243.19,-511.95 -1910.7,-11.25 -272.11,265.9 -647.42,-488.15 -1178.5,-1056.33 174.84,-1361.92 -525.82,-1100.65 -960.94,-238.12 -1793.05,201.08 -1095.68,-254 -1162.72,524.53 -1656.99,-412.74 -521.22,-691.21 -480.472,-219.61 -2265.631,32.42 -864.976,-824.17 z',
		geocodigo: '2504405',
		microrregiao: Microrregiao.itaporanga,
		name: 'Conceição - PB',
	},
	{
		d: 'm 168036.8,127820.53 -130.14,-1134.38 1410.51,-286.41 -87.41,-921.4 -515.31,-723.62 359.53,-356.52 58.5,-1058.32 779.53,-1015.98 1123.94,-707.09 593.52,-814.24 514.65,-270.54 367.42,-531.13 -34.84,-826.15 435.77,-1065.6 -267.51,-1459.15 -133.42,-718.33 599.43,-674.02 138.69,-539.08 -768.36,-890.31 170.24,-788.44 -957,-951.83 -241.88,-1090.06 -712.49,-465.66 1345.45,-38.37 702.63,662.11 1736.52,-74.74 402.25,4929.77 510.71,494.76 1171.92,161.4 19.07,411.42 804.5,6007.27 615.21,761.32 264.23,1414.84 1028.63,1164.81 -480.46,2078.93 1852.86,2272.07 -4985.44,-480.2 -1093.71,320.13 -1687.23,-364.45 -1971.17,504.02 -962.91,622.42 -716.43,-2800.57 -1261.32,-760 z',
		geocodigo: '2504504',
		microrregiao: Microrregiao.sousa,
		name: 'Condado - PB',
	},
	{
		d: 'm 367220.4,148877.72 182.73,-527.83 439.06,-193.81 393.05,-957.11 1305.34,-926.03 531.08,-27.12 2301.13,601.26 208.36,491.45 301.69,-115.09 229.38,289.72 667.8,25.13 278.03,334.7 291.17,-206.38 -98.59,211 312.86,113.77 157.75,-171.31 106.48,240.1 33.52,-431.92 437.09,-235.48 683.56,142.88 -203.1,287.72 504.14,-17.86 36.15,345.95 803.19,-171.32 -113.05,496.08 224.13,-246.06 86.1,1646.35 396.99,1256.09 -141.31,1065.59 208.36,576.12 -265.54,1120.5 556.71,854.58 -439.06,2047.85 205.73,673.35 -372.68,1207.15 -310.23,-549 -176.15,56.22 -90.71,-683.28 -190.61,58.87 -28.92,-283.1 -335.21,47.62 166.95,-738.83 -327.32,-366.44 -1741.79,-425.98 -578.4,269.21 -379.9,-425.97 -173.53,113.77 -2355.01,-1949.29 -423.29,-50.93 -1548.54,-976.3 193.89,-963.07 -238.59,-614.48 -444.97,-568.19 -957.65,28.45 119.62,-879.73 238.59,-641.6 -664.51,-260.61 z',
		geocodigo: '2504603',
		microrregiao: Microrregiao.litoralSul,
		name: 'Conde - PB',
	},
	{
		d: 'm 235413.52,183840.54 809.11,-1045.09 555.4,1.99 1101.6,-665.42 95.3,-283.77 1154.18,-436.55 381.87,110.46 2775.03,-957.11 1559.71,-15.87 627.7,651.52 528.45,218.94 448.26,705.77 598.13,7.93 -534.37,1701.25 187.32,684.59 435.77,386.95 -368.73,636.98 554.74,691.21 1729.96,728.25 1382.25,2151.03 1071.36,444.5 -287.89,767.94 233.99,785.8 -833.42,2413.62 2291.26,2912.36 -1136.43,-214.97 -1488.73,525.18 -402.26,670.71 402.26,1516.7 -3463.19,-2747.65 -2317.36,-1129.82 -2824.62,-3470.69 -2526.07,-1824.44 21.3,-2496 z',
		geocodigo: '2504702',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Congo - PB',
	},
	{
		d: 'm 139410.45,131675.45 66.39,-286.41 1403.28,-436.55 -232.67,-892.96 803.19,-764.63 1855.49,-394.22 711.17,-1329.52 1446.67,-888.32 5559.24,3643.92 4275.58,1793.84 2722.44,240.11 823.56,-217.62 -146.57,5480.76 -1284.32,369.09 -951.08,-889.65 -1275.12,-118.4 -1584.03,273.84 -1626.76,-248.04 -2416.8,257.97 -253.06,746.77 -1429.57,534.45 -50.61,803.66 -337.18,196.45 -360.85,960.42 -95.3,718.33 211.64,670.71 -607.98,695.85 -39.43,803.65 -1911.36,107.16 -30.24,728.25 -521.22,-360.49 -326.01,-627.05 -424.6,-427.29 403.57,-179.26 389.77,-606.54 -448.27,-1060.97 -1464.41,-1235.58 -145.26,-441.19 165.64,-398.85 -594.18,-1783.93 -2277.46,-5435.78 z',
		geocodigo: '2504801',
		microrregiao: Microrregiao.pianco,
		name: 'Coremas - PB',
	},
	{
		d: 'm 243845.72,180549.18 -1557.07,12.53 -2003.08,-649.7 -1191.64,-5987.12 8180.14,-756.16 842.63,3906.52 2443.8,1926.77 556.67,687.94 -359.53,763.3 -635.59,228.87 -286.57,385.62 -1008.26,1977.73 -1233.71,-550.33 -876.15,-104.5 -454.83,-471.62 -218.88,214.31 -598.12,-7.93 -448.92,-705.77 -527.79,-218.94 -627.7,-651.52 z',
		geocodigo: '2504850',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Coxixola - PB',
	},
	{
		d: 'm 349585.03,143662.87 410.14,-95.91 655.3,-855.91 1354.65,-853.27 258.97,-554.96 531.08,-289.71 1798.3,-5120.93 -3119.43,-2805.2 7393.7,3561.9 1017.46,2915.66 -186.01,502.7 609.29,87.97 -306.94,545.69 74.93,529.82 -1811.45,6218.27 -1014.18,-46.3 -857.09,270.53 -1238.97,-163.37 -750.06,-105.74 -1331.53,-862.62 -870.89,-265.24 -303.66,-449.78 -262.91,193.8 -1681.97,-1898.36 -310.24,-15.87 -60.47,-439.86 z',
		geocodigo: '2504900',
		microrregiao: Microrregiao.sape,
		name: 'Cruz do Espírito Santo - PB',
	},
	{
		d: 'm 263920.91,120200.65 403.57,-332.71 176.8,-576.12 -335.87,-1602.03 113.06,-44.98 13.14,-374.37 964.88,-302.29 1308.64,905.53 459.44,-326.1 790.04,-1641.71 -12.49,-453.09 2210.43,1348.69 1969.85,54.9 -168.27,2627.27 -2194.64,4380.11 -535.68,1902.33 -728.26,555.61 -1222.54,-30.43 -410.14,-597.28 -2247.22,-1268.66 -1427.6,-1960.53 1213.33,-1403.59 107.79,-457.07 -446.95,-402.16 z',
		geocodigo: '2505006',
		microrregiao: Microrregiao.seridoOriental,
		name: 'Cubati - PB',
	},
	{
		d: 'm 273145.12,104643.4 636.24,-3427.63 662.54,-2584.273 3336.62,-6417.889 526.1,-2965.378 2051.45,-377.721 842.63,201.08 317.46,-214.309 1169.3,272.517 591.54,-537.757 340.47,-120.384 134.75,-47.624 710.51,-250.028 487.04,-775.878 529.11,380.994 -76.25,324.771 473.9,15.875 397.65,-353.213 759.82,1600.043 1052.95,878.402 1696.43,785.801 357.56,734.207 -328.64,556.939 400.29,127.659 145.25,475.581 2006.67,571.491 897.83,-331.385 1524.88,630.36 6597.74,-396.869 -1093.7,4241.866 -552.11,2075.624 -252.1,-836.551 -6277.8,1556.189 -277.52,396.22 -2236.7,2107.38 -2673.15,1468.41 -560,-11.24 -1442.06,976.29 -2983.38,1179.37 -2394.45,853.26 -698.03,-171.31 -582.35,223.57 -2092.11,-459.05 -1555.12,-627.05 -1971.82,-1740.93 -592.87,11.24 z',
		geocodigo: '2505105',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Cuité - PB',
	},
	{
		d: 'm 341399.97,119925.49 429.86,259.29 947.78,-6.62 669.11,-686.58 429.21,142.87 130.79,367.76 710.52,484.85 944.51,273.84 127.5,584.05 904.42,-147.5 -28.27,616.47 326.01,807.63 677,358.51 1447.32,1856.68 1167.32,720.98 -255.02,990.84 161.03,595.31 -376.62,307.57 -1671.45,-1022.59 -882.72,127.65 -208.36,345.94 -1206.76,500.72 -1270.51,-97.24 -431.17,181.9 -845.92,-543.71 -255.68,-363.79 -701.31,32.41 -467.33,-1471.06 -911.64,-623.75 337.84,-793.73 -320.09,-779.19 46,-659.47 -756.52,-894.27 -195.87,-798.37 1329.02,-672.69 z',
		geocodigo: '2505238',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Cuité de Mamanguape - PB',
	},
	{
		d: 'm 322212.76,122464.79 2497.65,-443.17 1933.71,-343.96 372.01,1035.17 1197.56,855.91 6.57,1173.41 572.49,-1.98 134.74,421.34 -4461.59,398.19 -2134.17,-1525.3 -117.66,-1570.93 z',
		geocodigo: '2505204',
		microrregiao: Microrregiao.guarabira,
		name: 'Cuitegi - PB',
	},
	{
		d: 'm 336189.74,112937.29 1613.61,-468.96 297.74,-486.17 -93.99,-1264.03 914.28,-1571.6 423.29,-101.2 257.64,564.88 371.37,-134.27 528.45,30.42 214.93,404.81 840.65,248.04 723.66,-332.71 219.54,-406.13 945.81,-16.53 632.96,193.8 1372.39,-694.52 116.34,-358.5 372.02,-54.24 349.67,-494.76 368.73,40.34 -65.07,345.94 446.95,422 543.57,1266.02 -531.08,1856.02 146.57,691.21 -799.91,758.68 -648.73,-707.09 -453.51,-66.14 -1221.23,491.46 -840.65,183.88 -999.06,-545.7 -4458.3,1587.48 -398.98,-992.17 -452.2,-263.92 -734.18,-123.03 z',
		geocodigo: '2505279',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Curral de Cima - PB',
	},
	{
		d: 'm 127287.65,166009.89 2566.01,212.32 4948.63,-1689.34 622.23,1161.24 3506.76,-222.52 1592.3,3777.73 -2506.15,1455.06 501.1,1521.8 -101.88,86.64 -122.25,73.43 -1986.29,1990.95 -2660,1043.11 -1006.95,11.24 -3250.23,-1507.44 155.78,-479.55 -1211.36,-339.98 -477.18,-511.96 136.71,-379.01 333.9,-1527.95 -180.76,-253.33 950.43,-594.64 -188.64,-291.7 226.76,-394.89 -153.8,-267.22 -696.72,-401.5 141.32,-318.82 -362.16,-198.43 -176.81,-1119.83 -602.06,-835.41 z',
		geocodigo: '2505303',
		microrregiao: Microrregiao.itaporanga,
		name: 'Curral Velho - PB',
	},
	{
		d: 'm 292927.16,100833.46 284.09,-397.55 6277.8,-1556.167 245.52,838.513 700.02,7600.764 -694.47,952.31 -1268.27,-44.9 -613.22,646.2 205.2,371.73 -599.41,335.99 -291.23,572.89 -836.73,209.04 -1955.31,-1624.88 -1765.08,500.34 -1478.27,-863.7 -896.9,-1635.83 -15.11,-1470.45 889.91,-742.07 1808.17,-3690.91 z',
		geocodigo: '2505352',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Damião - PB',
	},
	{
		d: 'm 205523.89,153296.19 1935.02,-2643.8 218.22,-700.47 98.59,-1258.08 4200.65,-642.93 736.81,-262.59 299.06,107.15 166.29,638.3 332.58,28.44 262.26,478.89 625.07,134.27 151.17,257.97 1289.57,-109.14 332.58,-441.19 1087.14,127.67 205.07,2382.53 -1111.45,254.65 -1850.89,6677.33 -1081.22,256.63 -367.42,-1006.72 -1386.2,-827.47 -2124.97,-435.24 -473.24,-505.34 -531.08,191.82 -698.02,-845.33 -878.13,228.86 -599.44,-118.4 -230.69,-1206.48 -610.62,-761.33 z',
		geocodigo: '2505402',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Desterro - PB',
	},
	{
		d: 'm 116019.97,155696.59 3213.42,-1725.06 1198.87,-125.01 246.48,182.56 461.41,-209.68 538.96,361.82 352.96,-154.13 523.19,763.31 41.41,723.63 912.96,180.57 818.96,1068.91 411.46,185.86 332.58,-189.17 2057.27,2307.79 1086.48,1742.91 -510.7,2688.13 -738.12,859.22 370.7,535.77 -49.3,1112.56 602.73,835.41 176.8,1119.83 362.16,198.44 -141.31,318.81 696.05,401.5 154.46,267.23 -227.41,395.54 189.29,290.38 -951.08,595.3 181.41,253.33 -333.89,1527.95 -394.37,413.41 -866.29,-339.99 -235.96,365.12 -339.81,-152.14 163.66,-505.34 -424.6,-168.01 -212.96,-470.29 -270.14,-1800.46 -779.53,58.87 -268.17,-845.99 -886.01,-216.3 -292.48,-1324.88 -1024.04,-998.78 -9.86,-648.22 487.04,-295.67 -157.75,-338 329.3,-862.53 314.84,-137.58 -1884.41,-1332.82 -321.41,37.04 -457.47,-361.8 -57.84,-558.27 -260.28,-321.46 -415.4,257.3 -253.71,-403.48 -594.83,574.8 -318.12,-550.32 -1466.39,513.28 -219.53,-136.26 -1.31,-585.38 343.1,-564.88 -1033.24,-728.25 591.55,-1691.33 -387.79,-314.85 -446.95,68.79 244.5,-465.66 -200.47,-220.92 502.82,-1147.61 -439.06,64.16 -31.55,-379.01 z',
		geocodigo: '2505600',
		microrregiao: Microrregiao.itaporanga,
		name: 'Diamante - PB',
	},
	{
		d: 'm 311950.05,101584.2 1231.08,-635.65 1083.84,-579.43 1073.34,-129.64 228.73,-409.439 1235.68,-606.548 1357.93,-92.603 354.93,-1340.755 263.57,-101.202 749.3,-148.825 1712.86,607.87 1481.49,-476.242 -205.06,497.409 -506.76,392.238 -3.29,436.556 201.78,468.967 659.91,103.847 337.18,304.266 1079.25,1863.961 -817,661.45 -899.81,-802.34 -811.08,554.29 -364.13,-1.98 -782.81,705.76 -748.64,1211.12 -346.39,225.55 -563.28,1136.37 -1171.93,275.16 -1128.54,-941.24 -636.24,54.9 -710.52,-541.07 -698.68,65.49 154.46,531.14 -159.06,400.18 -632.3,51.59 -312.21,593.32 -1829.8,-296.21 -697.42,1143.38 -1536.47,432.89 -1051.83,-3724.68 1393.35,-867.99 z',
		geocodigo: '2505709',
		microrregiao: Microrregiao.curimatauOriental,
		name: 'Dona Inês - PB',
	},
	{
		d: 'm 331727.49,109425 933.99,-1310.99 365.45,-1099.32 529.76,165.36 352.95,2002.86 271.46,402.16 2202.53,1022.6 1419.72,1862.64 -1613.61,468.97 -151.18,50.93 154.46,-604.56 -358.21,-350.57 -997.09,-496.09 -1553.15,-286.41 -626.38,-539.74 -326.01,-812.92 -604.69,-473.59 z',
		geocodigo: '2505808',
		microrregiao: Microrregiao.guarabira,
		name: 'Duas Estradas - PB',
	},
	{
		d: 'm 158698.22,137621.87 146.57,-5480.76 3093.14,11.25 2176.25,365.11 3120.09,-224.23 2106.56,-457.72 596.81,10183.65 -2633.7,1488.26 -2636.34,-71.44 -562.63,-936.6 -397.65,-1455.85 -1595.21,46.3 -375.3,-220.92 -726.29,-1187.31 -567.89,675.35 -460.75,-595.31 -1332.3,-395.55 45.36,-1742.25 z',
		geocodigo: '2505907',
		microrregiao: Microrregiao.pianco,
		name: 'Emas - PB',
	},
	{
		d: 'm 293843.4,131967.15 -1593.23,-2347.48 880.75,-726.93 147.89,-615.81 1850.89,-1328.19 -128.83,-729.58 741.41,-331.38 988.54,-48.29 844.6,395.55 1475.58,965.05 1429.58,228.86 981.31,902.22 2688.92,1297.76 -205.07,217.62 -30.24,899.57 510.05,756.69 356.25,255.98 114.36,472.28 744.7,-441.85 858.4,330.06 254.37,189.84 -46.01,451.77 -666.49,142.21 -526.47,-435.23 -955.68,615.8 -1607.04,-225.55 -696.72,881.71 -1065.44,694.52 -617.18,476.91 -551.46,70.77 -678.97,-373.06 -1105.53,2272.08 -974.74,11.24 -891.93,-293.02 709.2,-694.52 672.4,-1089.4 -61.78,-1213.1 -1686.57,-1569.62 -1432.87,163.38 -439.06,-355.86 -289.2,128.32 z',
		geocodigo: '2506004',
		microrregiao: Microrregiao.esperanca,
		name: 'Esperança - PB',
	},
	{
		d: 'm 304771.76,154106.09 1937.68,-67.55 1752,570.88 1341.04,-148.67 -597.22,1202.11 1577.47,-641.61 1490.04,353.21 -3765.53,9596.95 -359.53,590.02 -344.42,-39.69 -458.12,316.83 -217.56,-128.32 31.55,-431.93 -421.97,-246.71 -546.85,360.48 -5.92,-662.76 -332.58,-187.19 -348.35,550.32 -834.75,22.49 -118.97,-408.12 190.61,-166.02 -532.39,-248.04 -502.82,285.08 -276.71,-299.64 -208.36,-486.16 -701.31,158.09 141.97,-373.06 -192.58,-761.33 439.06,-872.44 759.82,-302.28 84.12,-295.67 -1190.98,-1800.46 -251.08,-934.63 386.99,-3665.61 z',
		geocodigo: '2506103',
		microrregiao: Microrregiao.campinaGrande,
		name: 'Fagundes - PB',
	},
	{
		d: 'm 252502.05,92797.526 -141.32,-1358.614 65.07,-984.235 1570.89,-3424.977 184.7,-1020.615 368.73,-2035.275 5036.05,297.651 2589.01,-4975.411 2312.96,1166.795 1353.33,-552.31 -270.14,492.779 281.31,375.041 369.39,28.442 196.53,476.904 -941.22,1196.56 -252.4,952.484 -1321.78,-152.132 -403.57,398.853 616.53,433.248 -661.22,1322.234 133.42,1057.656 -1116.05,1406.238 -338.5,-398.191 -944.5,-392.239 -590.24,-38.364 -308.26,217.616 -1321.78,2049.827 238.59,342.631 1110.14,-11.245 -1413.8,1518.684 1031.92,220.924 443.66,337.339 -85.44,847.976 -2827.61,3056.551 -693.42,2219.819 -671.08,-597.287 -308.26,-1540.512 -1289.58,-440.525 -1361.22,-809.612 -478.5,-89.295 -165.63,-1595.413 z',
		geocodigo: '2506202',
		microrregiao: Microrregiao.seridoOriental,
		name: 'Frei Martinho - PB',
	},
	{
		d: 'm 299850.91,168067.65 152.49,-164.7 51.27,132.29 163.66,-252.67 341.78,156.76 354.27,-459.04 405.54,136.26 481.78,-982.92 797.28,-183.88 -55.87,-1590.78 882.07,-578.77 -503.47,435.24 131.45,2028.65 911.64,2072.32 782.16,634.99 1177.84,699.81 3264.69,3176.28 -80.85,579.42 888.64,390.26 318.12,801.67 -318.12,449.79 -31.55,500.71 365.45,328.08 -290.52,263.92 642.16,611.18 955.68,259.29 -1836.43,313.52 -947.79,403.49 -851.17,-260.62 -322.73,-830.78 -675.68,-64.15 -214.27,544.37 -1419.06,892.95 -215.59,-49.61 -176.8,-617.79 -466.01,-246.72 -306.95,632.35 52.58,603.23 -284.6,208.36 -1277.09,-139.56 -1575.49,365.11 -667.79,-198.43 -9.86,-838.71 -198.49,-214.31 300.37,-435.24 -1033.24,-2419.58 483.1,-385.62 295.78,-984.23 1246.85,-1012.68 1733.23,-230.84 61.79,-196.46 -195.21,-33.73 77.56,-541.06 -1398.03,-468.97 -769.01,32.41 -976.71,-873.77 -619.82,19.18 -306.95,-360.49 79.53,-743.46 657.94,-779.86 -30.24,-535.77 z',
		geocodigo: '2506251',
		microrregiao: Microrregiao.umbuzeiro,
		name: 'Gado Bravo - PB',
	},
	{
		d: 'm 324709.1,122021.62 v -1555.73 l 1620.18,-1777.31 17.75,-819.54 368.73,-270.52 1297.46,-44.32 1482.16,-510.64 384.5,687.91 2124.98,-596.63 131.46,164.7 694.74,-136.26 -281.32,685.26 354.93,153.46 -105.17,563.55 538.97,-162.05 130.8,349.24 366.76,-146.18 140,1062.95 195.87,-248.05 125.54,175.95 365.44,-86.65 260.94,209.68 -160.37,404.8 217.56,173.3 561.31,5555.51 -721.69,-241.43 -273.42,63.5 -138.69,510.64 -485.07,-110.47 -141.32,176.61 362.82,572.82 -208.36,436.55 -807.79,-361.81 -879.44,566.86 146.58,623.74 -816.34,232.17 93.99,304.26 -2086.19,782.5 284.6,-467.64 67.04,-1294.45 -575.77,-475.58 -387.8,-1124.47 -49.29,-885.02 -135.4,-420.68 -572.49,1.33 -5.91,-1172.75 -1197.56,-855.91 -372.02,-1035.17 -1933.7,343.29 z',
		geocodigo: '2506301',
		microrregiao: Microrregiao.guarabira,
		name: 'Guarabira - PB',
	},
	{
		d: 'm 324553.32,139516.25 444.98,-416.05 241.88,-687.9 1650.41,-756.7 -475.21,-777.86 230.71,-500.72 -548.83,-481.53 -205.07,-606.55 1696.43,313.53 2817.74,521.88 705.92,-240.11 1450.6,-1027.22 541.6,-1027.23 1718.12,148.82 1093.71,-851.28 41.41,3337.66 877.46,-20.5 728.27,1854.7 200.46,889.65 -470.6,364.45 168.26,536.44 71.64,1171.42 -295.78,788.45 366.11,953.81 -132.11,593.98 354.27,1292.47 -2527.89,1069.56 638.87,2209.89 -1790.42,4.63 -330.61,265.24 -1016.15,-232.17 -895.2,746.12 -287.89,-236.8 -1043.1,599.94 -2323.52,-1559.77 -456.29,-2233.7 -1632.44,-293.62 -2666.61,-2975.86 1309.95,-475.58 538.97,-953.8 -801.22,-767.95 11.17,-542.38 z',
		geocodigo: '2506400',
		microrregiao: Microrregiao.itabaiana,
		name: 'Gurinhém - PB',
	},
	{
		d: 'm 247243.84,155494.85 1203.43,-255.99 1841.52,-1150.33 -139.79,-3727.18 -1019.44,-1461.14 157.09,-190.5 987.89,-91.28 674.37,-830.11 1677.36,-384.3 -1288.26,-810.94 -608.63,-2113.98 -1113.43,-679.97 3852.95,596.63 2063.85,183.88 4908.54,691.21 903.75,1373.16 5789.29,-2152.35 -1275.12,6877.73 933.34,1776 -630.99,402.15 -679.62,50.93 -1821.97,-900.89 -629.67,-193.8 -841.32,265.9 -2065.16,-52.91 -792.01,563.55 -602.73,44.32 -303.66,-286.41 -394.36,205.05 -1910.05,418.04 -1446.67,1153.56 -2849.29,1520.01 -4618.02,14.55 -210.03,-688.95 z',
		geocodigo: '2506509',
		microrregiao: Microrregiao.caririOriental,
		name: 'Gurjão - PB',
	},
	{
		d: 'm 113304.1,174026.64 356.24,-403.49 -46.01,-490.79 483.1,286.41 208.36,-147.51 960.28,-1329.51 -53.9,-953.81 -1252.77,-713.7 -2140.74,-288.39 -610.61,-486.17 -138.03,-388.92 421.31,-425.32 -1160.75,-5991.39 1103.57,-396.87 2520,-254.66 1079.9,-466.98 1318.5,-116.42 270.14,321.47 255.68,-117.08 219.53,135.6 1465.72,-513.29 318.13,550.33 594.83,-574.14 255.02,402.83 414.74,-257.31 260.94,321.47 57.19,558.92 458.12,361.15 320.75,-36.38 1884.41,1332.81 -314.84,137.59 -329.29,862.53 157.74,337.34 -487.04,296.32 9.86,648.22 1024.04,998.13 292.49,1324.88 -775.59,552.3 -1792.39,572.82 -1929.11,193.81 -446.29,-179.26 -434.46,78.05 -98.59,312.21 -524.5,553.63 475.21,1595.41 -285.92,812.92 111.08,1040.46 -4478.02,-54.24 z',
		geocodigo: '2506608',
		microrregiao: Microrregiao.itaporanga,
		name: 'Ibiara - PB',
	},
	{
		d: 'm 133466.7,142467.63 -4010.7,-691.21 -303.66,-347.92 -84.13,-1105.28 2051.36,-824.16 439.06,35.06 759.81,-451.11 58.5,278.47 420,41.66 404.22,-781.17 1076.62,19.19 1256.06,-1471.72 700,-32.42 276.71,201.75 441.69,-279.8 1860.09,9.27 478.5,289.71 2396.43,-246.06 28.92,558.26 -516.62,1477.02 125.54,267.22 -546.86,220.26 -491.64,1291.81 93.99,720.32 667.79,1297.76 1038.5,1001.43 138.03,1293.13 -365.45,277.15 209.67,366.44 -305.63,449.12 70.33,408.11 -571.17,150.82 -195.21,-190.5 -181.41,177.27 -1087.8,-201.75 -523.19,1211.77 -1875.86,-739.49 -2776.34,-271.86 -134.08,-2346.15 z',
		geocodigo: '2502607',
		microrregiao: Microrregiao.pianco,
		name: 'Igaracy - PB',
	},
	{
		d: 'm 166103.75,158597.7 -1151.55,-267.23 -171.55,-922.05 1116.06,-345.28 -38.12,-1162.16 191.26,-143.53 460.75,-1.99 750.61,669.38 553.43,-1242.19 1098.97,-796.39 1464.4,375.71 849.21,-80.04 737.46,-369.75 1106.85,619.78 1244.89,-302.95 739.43,342.63 973.43,-39.68 1022.06,-1078.83 411.81,-255.03 1514.37,504.91 4383.95,107.58 2050.24,-835.75 490.52,17.27 3641.31,-209.68 -57.19,521.88 389.11,324.77 -210.98,1027.23 744.03,1675.45 -1623.47,102.52 -533.05,263.92 -831.45,-577.45 -356.25,196.45 -705.91,233.49 -1772.68,-118.4 -2197.93,593.98 -60.47,188.52 747.32,1915.55 -720.37,609.86 -629.68,1144.31 -360.84,99.21 -358.87,513.28 99.91,404.81 -443.67,518.58 -500.84,586.04 -46.01,1234.92 -377.27,566.86 -1303.38,449.12 -1494.65,724.95 222.13,-2662.32 -2876.44,-3224.56 -2428.42,146.33 -2195.29,-2437.59 -1474.27,178.59 -828.17,-418.7 -199.15,865.84 -338.5,-286.41 -710.52,58.87 z',
		geocodigo: '2506707',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Imaculada - PB',
	},
	{
		d: 'm 309205.26,155662.86 597.22,-1202.14 2365.12,-4760.15 1854.18,652.84 524.51,-219.6 458.12,-551.65 1420.38,-555.61 690.14,-49.61 354.26,277.15 -74.92,-846.66 281.97,-739.5 454.84,-11.24 -284.61,-478.23 -415.39,163.38 -330.61,-371.07 -2582.44,-1094.69 707.88,-1233.6 2819.06,-875.76 1090.42,-740.82 -627.7,721.64 113.05,401.49 907.71,888.33 880.74,182.56 1045.07,-806.31 331.92,203.07 488.36,-144.2 1389.48,889.65 565.8,-347.74 -199.31,3045.14 -664.26,220.31 -639.94,3864.86 1583.82,6838.62 -634.27,-360.49 -525.17,-105.17 -433.8,-793.73 -3375.77,-1171.42 -908.36,-1338.12 -740.75,11.25 -1494.64,388.93 -2180.19,174.62 -1737.84,-417.37 -1490.04,-353.88 -1577.46,641.61 z',
		geocodigo: '2506806',
		microrregiao: Microrregiao.itabaiana,
		name: 'Ingá - PB',
	},
	{
		d: 'm 331870.78,153987.41 2267.6,-139.57 -758.5,-1276.59 -471.92,-1682.06 330.61,-526.51 2022.43,-679.97 273.44,-541.07 936.61,929.99 1084.51,-747.43 2259.71,2667.62 802.54,968.36 178.12,1305.7 1204.13,-119.72 1478.87,3079.7 -154.46,410.1 -917.56,695.84 456.15,652.85 -1923.84,1432.03 -678.97,-118.4 -578.4,408.12 -790.71,-328.08 153.14,619.78 -43.37,540.4 -642.16,-33.73 -722.35,9.92 272.11,913.46 -181.4,388.93 -853.81,183.88 -462.72,-116.41 -479.81,561.56 -340.47,25.8 -166.95,-1614.59 -461.4,-30.43 -201.79,-1336.13 -838.02,270.54 -1043.1,-3128.65 563.28,-2113.99 590.89,-329.4 -1131.83,-928.01 -1030.6,-277.15 z',
		geocodigo: '2506905',
		microrregiao: Microrregiao.itabaiana,
		name: 'Itabaiana - PB',
	},
	{
		d: 'm 122553.95,154789.09 1254.08,-1299.09 1655.68,-468.97 630.98,-1136.36 852.49,3.31 901.78,-1072.22 118.97,-308.89 -1515.02,-246.06 -391.74,-341.31 477.19,-2139.12 -391.08,58.87 -84.13,-2362.03 -1184.42,-286.4 -63.75,-1680.08 1390.14,187.19 2126.94,-764.64 907.7,-712.37 4224.97,248.04 1022.72,2063.05 133.43,2345.5 2776.33,272.51 1876.53,738.84 490.98,6461.68 2331.36,-478.22 -266.85,2367.98 -569.2,83.34 -434.46,457.72 -5115.58,2891.86 -2443.76,-2058.43 -5161.59,-20.51 43.38,849.3 -1026,625.74 -2057.28,-2307.14 -332.58,188.51 -411.45,-185.2 -819.63,-1068.9 -912.3,-181.24 -41.41,-722.96 z',
		geocodigo: '2507002',
		microrregiao: Microrregiao.itaporanga,
		name: 'Itaporanga - PB',
	},
	{
		d: 'm 337773.12,114318.4 4458.96,-1587.48 998.4,545.7 841.31,-183.89 1221.22,-491.45 452.86,65.48 649.39,707.75 331.93,1020.61 564.6,254 540.28,432.59 922.81,1756.8 2410.24,3094.92 -1211.36,-361.82 -857.09,571.5 -552.12,-353.88 -410.14,483.52 -766.38,117.07 87.42,386.95 -767.7,419.36 -905.07,146.84 -126.85,-584.06 -945.17,-273.18 -710.51,-484.83 -130.14,-368.43 -429.2,-142.21 -669.77,686.58 -947.79,5.95 -429.2,-258.63 -507.41,-1537.86 -1472.3,-410.1 319.43,-1891.08 512.02,-736.19 -350.99,4.63 -292.49,-657.48 -1115.39,-3.3 -721.69,-377.69 z',
		geocodigo: '2507101',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Itapororoca - PB',
	},
	{
		d: 'm 308507.24,164971.41 3765.53,-9596.95 1737.84,418.04 2180.18,-174.62 1494.65,-388.94 740.75,-10.58 908.36,1337.45 3375.77,1171.43 434.46,793.73 524.5,105.17 634.28,359.83 276.71,387.61 -488.36,384.3 -523.19,2719.87 -343.1,435.23 -407.51,213 -813.71,-428.62 -1135.12,871.78 -446.94,-220.26 -350.33,497.41 -713.8,-267.23 -909.01,-99.21 -471.27,207.69 -553.43,-161.39 -2200.56,729.58 -387.79,445.15 -790.71,-418.03 -95.3,558.26 -508.73,31.09 67.04,451.11 -218.21,212.98 -346.39,-17.86 -443.66,-652.85 -594.84,308.9 -314.83,-155.45 -580.38,285.09 -264.22,-676.66 -757.84,703.78 -213.62,-500.71 -766.38,490.79 -497.55,-351.89 z',
		geocodigo: '2507200',
		microrregiao: Microrregiao.itabaiana,
		name: 'Itatuba - PB',
	},
	{
		d: 'm 336758.94,95184.691 1683.94,1936.72 759.81,417.374 4071.17,-33.734 1545.26,-718.994 1510.42,-162.716 567.88,-740.822 111.08,-376.364 343.76,156.763 724.97,1678.755 632.96,603.241 395.68,14.552 -889.95,3494.424 4478.68,4386.73 -786.76,326.09 -200.47,-308.89 -135.4,254.65 -228.73,-281.77 -667.79,395.54 -701.31,-92.6 -506.77,504.02 -1037.18,474.92 -584.98,-222.24 -276.71,567.52 -910.98,581.41 -369.39,-40.35 -349.68,494.77 -372.01,54.23 -1189.67,-2232.38 1004.97,4.63 -2.63,-207.7 -1012.86,-951.82 -172.2,-499.39 -1869.96,-1170.11 -348.35,131.63 -1763.47,-227.54 -861.69,-252.67 149.86,-1358.61 -332.59,-428.62 23.66,-558.92 -309.57,230.84 -416.72,-192.48 -496.23,640.28 -462.73,-146.84 -553.43,187.19 -761.78,-30.43 -266.85,349.91 -985.92,-322.79 -512.01,-2627.93 z',
		geocodigo: '2507309',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Jacaraú - PB',
	},
	{
		d: 'm 152874.09,97343.658 1196.91,-3074.41 1687.22,-3591.001 464.7,-324.771 1594.55,-398.192 1265.91,587.366 498.88,50.931 703.28,526.513 4259.81,672.031 -222.82,906.183 709.2,383.641 200.47,369.749 -1028.64,181.237 -262.25,255.318 -280,2349.463 -758.5,2057.765 -1335.58,679.969 -812.4,30.427 -507.41,1488.253 120.94,750.75 -2232.77,-251.35 291.18,-598.61 46,-1789.219 -1704.31,-252.674 -949.77,-974.312 -2947.88,-38.365 z',
		geocodigo: '2507408',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'Jericó - PB',
	},
	{
		d: 'm 365051.39,143531.91 678.96,-554.3 798.6,91.28 425.91,-388.27 939.91,367.76 518.59,-103.84 198.5,248.04 623.75,-449.78 389.11,-1064.27 -532.39,-486.83 -89.39,-684.6 434.45,-396.87 621.79,-115.09 166.95,-451.1 479.81,134.27 281.31,-830.78 -312.86,-497.41 300.38,-659.46 1912.67,-2716.57 569.86,514.61 -588.27,1328.19 402.26,812.91 1075.96,384.3 -333.9,-2754.26 460.1,-452.44 387.79,916.77 570.52,365.12 -258.97,1818.98 849.2,914.12 -1.98,1449.24 887.33,1048.39 922.16,115.09 265.54,561.58 -82.16,3006.94 -594.84,1078.82 -186.01,2152.34 -224.13,246.07 112.4,-496.09 -802.54,171.31 -36.8,-345.27 -504.14,17.2 203.76,-287.73 -683.57,-142.88 -437.74,235.47 -32.87,431.93 -107.14,-240.1 -157.08,171.31 -313.52,-113.77 98.59,-211 -291.17,206.37 -278.03,-334.69 -667.8,-25.14 -228.73,-289.71 -302.35,115.09 -208.35,-490.79 -2300.47,-601.92 -531.74,27.12 -1304.69,926.69 -554.08,-926.69 -1912.67,91.28 -311.55,108.48 -404.23,-2940.79 z',
		geocodigo: '2507507',
		microrregiao: Microrregiao.joaoPessoa,
		name: 'João Pessoa - PB',
	},
	{
		d: 'm 107186.83,90297.914 1642.53,224.231 528.45,-104.509 272.77,-414.066 926.11,-94.588 541.59,-212.986 884.69,-1813.029 761.13,-35.057 292.49,1713.812 287.89,4948.954 -141.32,1037.15 -434.46,652.849 -691.45,400.176 -2283.38,-262.595 -92.02,-627.052 -218.21,-179.252 -705.92,-1705.875 -1572.2,-3528.163 z',
		geocodigo: '2513653',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Joca Claudino - PB',
	},
	{
		d: 'm 317936.52,140419.13 752.58,111.78 4186.19,-908.82 1678.03,-105.84 -11.17,543.05 801.22,767.94 -538.97,953.81 -1309.95,474.92 2666.61,2973.21 -1931.24,-214.79 -569.08,354.35 -1389.48,-889.64 -487.7,144.2 -332.59,-203.73 -1044.41,806.96 -881.4,-182.55 -907.7,-888.33 -113.06,-401.5 628.36,-721.64 -207.04,-556.94 -710.52,-528.5 -278.68,-1524.63 z',
		geocodigo: '2507606',
		microrregiao: Microrregiao.itabaiana,
		name: 'Juarez Távora - PB',
	},
	{
		d: 'm 241795.67,131829.57 1033.9,-72.1 593.53,-310.22 1321.12,748.76 343.1,-849.96 871.55,-476.91 -274.75,-377.68 534.37,-427.3 1112.77,-259.28 228.73,-580.75 1773.33,-152.14 118.97,-1118.51 -335.21,-1705.87 258.97,-275.16 -1.32,-1054.35 717.08,-357.19 505.45,-694.51 -258.96,-1204.5 6225.06,4488.58 -337.18,3168.34 28.92,452.43 -882.73,1947.97 1337.56,7701.9 -987.88,860.54 -318.12,1049.71 127.5,2250.25 -2063.85,-183.88 -3852.95,-597.29 -1709.57,-484.84 -1570.89,-3174.95 -3547.32,118.4 70.98,-259.28 -28.26,-689.23 -732.86,-1227.65 370.05,-199.76 -195.21,-1389.04 844.59,-376.37 -555.39,-199.76 548.82,-654.83 -297.75,-447.79 314.84,-436.57 -1330.99,-2551.2 z',
		geocodigo: '2507705',
		microrregiao: Microrregiao.seridoOriental,
		name: 'Juazeirinho - PB',
	},
	{
		d: 'm 227988.93,135186.42 7.89,-3514.28 6647.03,-7131.73 1071.37,-29.1 -470.62,1443.94 47.98,181.89 1574.19,2253.56 1848.91,1089.4 508.73,-348.58 1695.78,-3712.7 2383.28,120.38 -345.07,436.55 -128.83,1961.86 -394.36,585.38 -332.58,168.67 -1041.13,-190.5 -728.26,533.79 -405.54,683.94 480.47,1116.52 627.7,597.29 -2828.92,-414.73 -362.16,1117.19 -838.03,444.49 -2035.58,912.14 -489.67,-270.54 -748.64,172.64 -1240.94,-412.74 -1181.13,1304.38 -2723.75,1155.55 -599.43,-255.99 z',
		geocodigo: '2507804',
		microrregiao: Microrregiao.seridoOcidental,
		name: 'Junco do Seridó - PB',
	},
	{
		d: 'm 342866.35,158984.65 -456.15,-652.85 917.56,-695.84 154.45,-410.1 255.69,-772.57 788.73,-478.89 222.82,-258.63 -134.09,-208.36 289.2,-17.2 134.09,-377.68 69.67,156.76 280,-181.23 -116.34,-271.85 475.86,-259.3 418.04,-1268.65 410.14,-257.97 13.14,-259.28 331.93,97.89 278.68,-262.6 224.13,334.7 402.25,-43.66 1319.81,513.94 -241.88,521.89 879.45,1447.91 224.12,995.48 765.08,1.32 1446.66,1475.69 -410.14,396.87 211.64,1086.1 -1248.16,-302.28 -707.9,560.24 -721.68,137.59 -676.34,-752.07 -3164.13,-958.44 -2634.36,966.38 z',
		geocodigo: '2507903',
		microrregiao: Microrregiao.sape,
		name: 'Juripiranga - PB',
	},
	{
		d: 'm 151622.64,165396.72 2546.95,-2265.46 2226.19,-771.91 418.69,-211 718.4,-972.98 1392.76,-372.4 1173.9,-1011.36 864.98,-96.57 604.03,-516.59 1281.69,745.45 671.08,-584.06 834.74,209.68 1814.09,-767.94 -490.99,678.65 246.48,1428.73 -278.03,1344.06 1185.72,326.75 755.22,1849.41 -1915.97,5815.45 -1630.04,1080.15 7.89,721.64 1072.01,2227.09 -470.6,23.15 -306.95,859.22 -736.15,742.15 -31.55,225.55 -120.94,838.72 -1159.44,-597.29 -496.24,-756.7 -1242.91,-42.99 28.26,-358.51 -360.84,64.16 -712.48,-341.3 -333.9,-656.16 -1745.73,-844.66 -268.83,27.77 -181.4,-463.01 -1309.95,12.57 271.46,-999.45 -171.56,-333.37 -2429.94,24.47 -163.67,-942.56 359.53,-750.74 -249.76,-148.83 -1662.91,-4442.28 z',
		geocodigo: '2508000',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Juru - PB',
	},
	{
		d: 'm 147373.35,96608.789 3446.1,-169.331 1162.72,278.47 893.24,623.745 2947.89,38.365 949.76,974.312 1704.32,253.335 -46.01,1788.555 -291.18,598.61 2232.77,251.35 898.5,97.23 144.6,709.07 -246.48,820.86 508.73,1440.64 -166.95,410.75 -5598.68,-231.5 -1113.42,-949.18 -985.92,374.38 -1102.91,-1103.96 -2475.95,684.6 -930.05,-253.99 -517.28,385.62 -429.2,-1014.66 292.49,-321.46 -61.78,-315.52 -749.3,-870.46 332.58,-1083.45 486.38,-627.055 z',
		geocodigo: '2508109',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'Lagoa - PB',
	},
	{
		d: 'm 333026.93,107014.69 964.88,-1073.53 700,205.05 133.42,-939.26 880.75,-112.45 291.18,-1447.91 1119.33,286.41 186.02,-1365.23 668.45,539.74 1381.59,33.08 -868.26,2189.39 510.04,87.97 1027.33,1367.88 -49.3,2693.41 -372.02,134.28 -257.65,-564.88 -422.63,101.2 -914.93,1571.6 93.99,1264.03 -297.08,486.16 -1419.72,-1862.63 -2202.53,-1022.6 -272.12,-401.5 -352.95,-2003.53 -529.11,-164.7 z',
		geocodigo: '2508208',
		microrregiao: Microrregiao.guarabira,
		name: 'Lagoa de Dentro - PB',
	},
	{
		d: 'm 295807.35,138588.24 2431.26,-1707.19 517.93,310.87 1854.18,2292.58 824.22,-215.63 479.82,250.69 265.54,-321.46 183.38,579.43 694.08,148.82 237.27,601.26 548.83,269.21 570.52,-862.53 331.26,412.74 390.43,-204.38 539.62,281.11 705.91,162.05 -385.16,593.32 235.96,566.87 -1188.35,356.52 -1588.64,1285.19 122.91,996.8 -836.71,556.94 -495.58,70.11 -191.27,-255.97 -606.01,754.05 -774.27,648.21 -254.37,-68.79 23.66,-611.84 -497.56,-595.3 -33.52,-495.43 -818.31,-959.09 -240.56,-1264.03 -1238.96,1832.21 -403.57,-1163.49 -736.15,-715.02 -95.31,-435.23 -824.22,-1017.98 264.22,-1150.25 -12.48,-926.69 z',
		geocodigo: '2508307',
		microrregiao: Microrregiao.campinaGrande,
		name: 'Lagoa Seca - PB',
	},
	{
		d: 'm 128095.44,101433.39 1575.49,-1267.33 893.9,-1806.418 1213.33,-68.791 -2303.09,-5171.861 1932.39,334.692 599.43,732.223 348.36,62.837 1903.47,-4.629 961.6,-555.617 895.86,1966.485 1121.97,255.98 -410.14,803.66 -1066.75,548.34 -859.07,943.225 -201.78,1561.679 912.96,474.915 1092.39,1150.92 -1330.98,592 -1299.44,1233.6 -1227.13,-1756.81 -4754.74,-29.1 z',
		geocodigo: '2508406',
		microrregiao: Microrregiao.sousa,
		name: 'Lastro - PB',
	},
	{
		d: 'm 214503.6,158323.86 1850.89,-6677.98 1111.45,-253.99 -205.73,-2382.54 2357.98,2211.92 10617.56,3371.93 2328.93,282.94 351.65,-1423.01 1778.14,-785.97 1231.09,359.9 -1360.38,1139.14 2651.12,2006.39 -2536.3,1645.93 -2239.99,-110.47 -2291.27,116.42 -1708.26,-284.42 -339.81,489.47 -828.82,147.5 -559.35,554.95 -916.24,-84.66 -1103.56,306.91 -1440.76,-420.68 -1495.96,91.28 -1973.8,462.35 -2430.61,1065.6 -918.21,-1150.26 -1705.63,-748.76 -224.13,66.8 z',
		geocodigo: '2508505',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Livramento - PB',
	},
	{
		d: 'm 330266.36,102487.74 416.72,-612.5 313.52,-1854.7 7.89,-251.352 -402.26,-285.085 1004.98,-775.878 20.37,-875.096 310.24,-756.697 -291.18,-357.181 410.14,-337.338 251.74,-1056.333 -251.74,-527.836 -810.41,-449.784 -71.65,-934.626 2452.96,217.616 82.81,2886.559 -1232.39,2496.304 -393.71,3782.817 -1099.62,994.16 -271.46,-119.06 -448.26,-1183.99 z',
		geocodigo: '2508554',
		microrregiao: Microrregiao.guarabira,
		name: 'Logradouro - PB',
	},
	{
		d: 'm 363939.94,124047.63 987.88,-456.4 858.41,-108.47 990.51,-582.74 852.49,-65.48 739.44,-560.25 775.58,-846.65 1052.96,-506.67 1633.32,1905.63 831.46,465.66 1093.71,183.88 -698.03,1224.34 -271.45,1434.02 406.85,1145.63 769.67,875.09 -397.65,629.04 -910.99,447.8 -141.31,-964.39 -813.05,-109.14 86.1,-568.18 -728.26,222.24 -571.17,-189.83 -55.22,-219.6 -205.06,92.6 -247.14,-363.14 -133.43,263.93 -241.22,-324.78 -711.17,498.08 -4951.26,-3526.85 z',
		geocodigo: '2508604',
		microrregiao: Microrregiao.joaoPessoa,
		name: 'Lucena - PB',
	},
	{
		d: 'm 182537.63,147306.12 1012.86,-1476.34 572.49,12.56 46.01,-728.25 960.28,-142.21 877.46,-985.56 669.77,283.1 475.21,-312.2 300.37,-402.83 116.34,-960.42 2692.2,89.3 1558.4,118.4 1392.97,1601.89 505.25,750.87 349.67,1241.54 54.56,971.01 -834.75,138.24 -806.47,822.18 -1012.87,35.06 -437.75,484.84 -1624.78,662.77 -427.23,451.11 -184.69,418.03 968.17,575.46 993.8,1281.88 -1206.76,944.55 -3641.31,209.68 -495.12,-15.94 -2050.23,835.75 -4383.96,-107.58 -1514.38,-504.91 388.13,-235.04 847.91,-1340.86 1785.16,-1099.35 11.05,-814.19 1126.06,-482.26 -335.25,-679.91 1008.17,-863.75 251.14,-777.94 z',
		geocodigo: '2508703',
		microrregiao: Microrregiao.patos,
		name: 'Mãe D`Água - PB',
	},
	{
		d: 'm 176630.03,117077.29 1157.46,-871.12 421.98,-5.96 -146.58,-922.05 266.86,-940.58 803.19,253.99 1623.47,-593.31 901.78,758.68 15.78,2171.53 -226.1,313.53 -1252.77,455.73 36.8,611.18 640.85,554.3 597.46,1170.76 460.1,423.98 228.72,408.12 -591.54,128.32 -111.08,225.55 397,168.01 682.25,1014.66 -184.7,1057.65 866.95,928.02 772.95,1161.5 1071.37,129.64 -451.55,1430.72 957.65,481.53 22.35,219.6 -538.97,465.66 -1517,306.91 -854.45,-958.44 -1122.63,165.37 -217.56,636.31 -801.88,322.12 -149.2,1079.49 596.81,277.14 -172.21,657.49 -101.88,7.93 -1852.2,-2272.07 479.81,-2078.27 -1028.63,-1165.47 -263.57,-1414.18 -615.22,-761.99 -805.15,-6007.27 z',
		geocodigo: '2508802',
		microrregiao: Microrregiao.sousa,
		name: 'Malta - PB',
	},
	{
		d: 'm 346397.89,113375.83 799.91,-758.68 -145.92,-691.21 531.08,-1856.02 -544.22,-1266.02 -446.3,-422 65.08,-345.94 910.98,-580.75 276.71,-568.18 584.98,222.25 1037.18,-474.92 506.77,-504.69 701.31,93.26 667.79,-395.54 228.73,281.78 135.4,-254 200.47,308.23 786.76,-326.09 -4478.68,-4386.06 889.95,-3495.091 1716.15,601.917 926.76,-486.825 849.2,70.775 947.79,-1230.955 667.79,-433.249 792.02,-1801.784 -513.99,2913.678 -1029.95,922.059 600.09,2324.605 1777.61,19.71 -15.3,1166.95 429.71,599.63 516.62,844.67 -903.1,449.79 -406.86,668.72 -1146.94,553.63 627.04,1949.29 -548.82,3744.46 -540.94,660.78 -437.09,908.83 171.55,2098.12 1356.62,3229.18 258.96,238.12 1566.29,49.61 -4.6,430.61 -274.74,160.07 228.73,366.44 -868.26,1950.61 118.96,1504.13 -170.23,233.49 -1666.19,478.89 -387.8,211 -2140.75,-377.69 107.14,-634.99 -652.01,-2043.87 113.04,-364.46 -2410.89,-3094.91 -922.15,-1756.81 -540.94,-432.58 -563.94,-254 -332.59,-1021.27 z',
		geocodigo: '2508901',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Mamanguape - PB',
	},
	{
		d: 'm 123434.7,184570.11 1925.16,-2596.84 884.69,-416.05 1736.53,-1740.93 247.79,-412.74 -173.52,-459.05 372.02,-478.89 871.55,-588.69 3630.13,-453.09 -284.6,-1992.28 1006.29,-11.24 2660.66,-1043.11 1986.28,-1990.95 -1235.68,1909.6 -768.35,2017.41 1622.16,2648.44 -1082.54,899.57 -162.35,447.8 448.27,396.87 550.14,107.15 -475.87,1524.64 -632.3,1280.56 252.39,337.34 -200.47,388.93 928.74,136.26 -293.81,1367.88 190.61,470.94 -565.91,611.18 -456.81,1793.85 -756.52,-765.3 -1609.02,-388.26 -765.06,726.27 -200.47,-95.92 -1213.34,914.79 -872.86,456.4 -1152.86,923.38 -960.28,428.62 -225.45,349.24 -1337.55,23.82 -572.49,-224.23 -820.28,433.9 -761.79,-271.85 -793.33,-1185.98 -349.67,-1861.31 577.09,-760 -480.47,-673.36 103.19,-402.16 -1223.85,104.51 1053.62,-1414.84 -615.21,-466.98 z',
		geocodigo: '2509008',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Manaíra - PB',
	},
	{
		d: 'm 361144.54,109499.75 1415.78,-269.87 2623.18,787.12 2005.36,-59.53 891.92,441.85 -198.5,792.41 398.97,592 -718.41,985.55 427.23,244.74 -31.54,867.82 -264.23,151.47 -634.28,-412.74 -335.2,14.55 -213.62,300.96 -61.78,764.63 -1289.58,982.91 -770.98,-662.77 -889.3,142.22 -189.29,744.12 -1018.78,-87.97 -249.77,-433.25 -458.12,143.54 -241.88,-382.32 -1449.95,-827.47 -1173.9,-94.59 -2247.88,-2504.24 411.46,-998.12 523.19,-259.29 2321.5,-312.21 1420.37,-652.84 z',
		geocodigo: '2509057',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Marcação - PB',
	},
	{
		d: 'm 335917.63,133128.65 32.2,-670.7 343.1,-116.42 12.49,-1064.27 454.83,-667.4 3430.33,-931.32 2838.12,-1176.06 1112.77,-1011.35 -77.57,649.54 777.57,1784.59 -212.96,391.58 -11.18,2369.96 -230.7,649.54 790.05,1135.05 -378.6,507.33 -101.21,745.45 -1743.11,2672.26 -638.87,279.78 -278.68,280.46 -562.63,-15.88 -489.67,466.99 -316.81,-241.43 -379.9,193.14 151.17,-497.41 -709.2,-134.27 140,-541.07 -755.21,-364.45 -3193.05,-4693.64 z',
		geocodigo: '2509107',
		microrregiao: Microrregiao.sape,
		name: 'Mari - PB',
	},
	{
		d: 'm 120316.58,114121.28 4582.53,-329.4 -1998.78,4423.1 72.96,1054.35 -561.31,2395.1 -1739.16,406.79 -674.36,-137.57 -890.61,-1081.47 450.23,-574.8 57.19,-2320.36 871.54,-2203.28 -166.94,-1632.46 z',
		geocodigo: '2509156',
		microrregiao: Microrregiao.sousa,
		name: 'Marizópolis - PB',
	},
	{
		d: 'm 302743.58,144941.45 836.06,-556.94 -122.26,-996.8 1588.64,-1285.19 1187.7,-356.52 1114.74,-838.72 437.09,19.18 871.55,-427.29 740.74,-670.71 920.85,-206.37 -65.73,-182.56 1265.91,-650.87 374,-454.41 1984.31,3241.76 -551.45,545.69 -515.31,3.3 -136.71,293.03 -1017.46,509.98 109.11,1259.39 1062.16,1397.64 -251.09,1096.01 -408.83,3017.53 -843.94,217.61 -1197.55,1030.54 -868.26,48.29 -569.21,-400.18 -1098.96,657.48 -444.98,-364.46 -682.25,-51.59 -340.47,-410.76 -935.96,-214.31 -892.58,-718.99 -1664.88,-330.73 -349.67,-230.84 1871.92,-2982.46 -606.01,-562.24 -799.9,-447.8 z',
		geocodigo: '2509206',
		microrregiao: Microrregiao.campinaGrande,
		name: 'Massaranduba - PB',
	},
	{
		d: 'm 355005.58,94680.668 640.85,52.916 145.91,-414.728 443.66,-180.575 2055.97,1966.485 682.25,228.861 1504.5,-777.863 609.3,-844.669 705.25,-179.252 556.72,222.246 1380.27,-337.338 897.19,-814.904 626.38,-357.844 104.51,207.695 151.17,300.959 144.6,8069.663 -526.48,-154.78 8.55,-306.25 -432.49,278.47 -1106.85,-339.32 -513.99,44.98 -1821.97,-286.41 -1025.35,365.12 -1283.66,860.54 -2179.53,1187.3 -517.28,-844.67 -431.02,-604.26 15.3,-1166.94 -1777.61,-19.7 -598.78,-2319.995 1030.61,-921.397 513.33,-2914.34 z',
		geocodigo: '2509305',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Mataraca - PB',
	},
	{
		d: 'm 303056.44,139926.35 330.61,-302.28 851.18,-1414.83 625.06,-50.94 680.29,306.91 869.58,15.88 575.77,-263.92 1635.96,425.97 598.12,-336.01 507.41,78.05 811.09,-286.41 588.26,-719.66 517.93,-157.42 248.45,127 -6.57,988.86 -373.99,453.76 -1265.26,651.53 65.07,182.55 -920.84,206.37 -740.75,670.72 -871.55,427.29 -437.09,-19.18 -1114.74,838.72 -235.31,-566.87 384.51,-593.98 -705.91,-161.39 -538.97,-281.78 -391.08,205.05 -330.6,-412.74 -571.18,862.52 -548.17,-269.21 -237.28,-601.25 z',
		geocodigo: '2509339',
		microrregiao: Microrregiao.brejo,
		name: 'Matinhas - PB',
	},
	{
		d: 'm 160370.98,101248.18 -120.28,-750.74 506.76,-1488.256 812.4,-30.427 1336.24,-679.969 758.5,-2057.764 279.34,-2349.463 262.91,-255.981 1028.64,-180.575 222.81,615.808 1447.98,286.407 606.01,1004.739 717.09,357.183 84.79,433.909 427.23,-43.655 40.09,222.908 395.68,57.546 912.96,629.037 33.52,796.383 -2297.84,-125.014 523.19,590.674 -155.77,363.135 -834.74,304.265 -841.32,-115.091 140.01,660.786 -305.64,545.695 -629.67,148.82 -853.8,-196.445 -869.57,255.985 -316.81,992.17 -2412.21,105.17 z',
		geocodigo: '2509370',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'Mato Grosso - PB',
	},
	{
		d: 'm 189541.57,153182.43 1207.41,-943.89 -993.8,-1281.89 -968.83,-576.12 184.7,-417.37 427.89,-451.77 1624.78,-662.11 437.09,-484.84 1012.86,-35.06 806.48,-822.84 834.74,-137.58 696.71,451.11 133.43,549 507.42,-14.55 826.85,737.51 -352.96,134.94 246.49,619.11 -707.9,1275.27 130.15,697.83 -1277.74,30.43 -1798.97,2362.03 502.81,1081.46 -2619.24,1437.33 -744.04,-1675.45 211.64,-1027.89 -389.76,-324.76 57.18,-521.23 z',
		geocodigo: '2509396',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Maturéia - PB',
	},
	{
		d: 'm 324304.87,158985.97 -1578.56,-6841.34 639.94,-3864.8 664.26,-220.27 199.31,-3045.25 1929.23,212.22 1634.45,296.36 456.29,2233.61 2322.21,1559.77 2335.96,1569.61 471.92,1682.06 758.5,1277.26 -2267.6,138.9 -439.07,19.18 -1276.43,707.09 532.4,1375.15 383.19,377.03 -2341.88,832.1 -571.17,37.04 -732.86,238.78 -702.63,478.23 -741.41,78.71 -632.3,558.27 -339.15,580.75 -429.2,105.83 -276.71,-387.61 z',
		geocodigo: '2509404',
		microrregiao: Microrregiao.itabaiana,
		name: 'Mogeiro - PB',
	},
	{
		d: 'm 292229.13,138089.51 380.57,-1564.98 832.77,-755.38 2929.48,828.8 891.92,293.02 974.74,-11.25 -2431.26,1707.2 -2809.86,120.38 -769.67,-617.79 z',
		geocodigo: '2509503',
		microrregiao: Microrregiao.esperanca,
		name: 'Montadas - PB',
	},
	{
		d: 'm 99325.149,144141.76 5336.431,37.7 370.7,-691.21 677.65,-177.27 2397.74,-126.34 310.24,204.39 -36.81,660.79 4552.95,40.35 333.24,870.46 256.34,129.64 1091.08,119.73 1462.44,-537.76 502.81,662.77 -86.1,533.13 -1803.56,1402.93 -853.81,-417.38 -87.41,-588.69 -749.96,-117.07 -387.13,-1353.32 -559.34,258.62 -168.27,576.13 206.39,250.02 -431.18,570.83 -1898.21,136.25 -1770.04,1025.91 -504.13,-46.3 -950.43,551.65 -113.05,739.5 -636.24,228.86 180.09,-607.87 -415.4,-298.32 -1839.71,654.84 -871.55,-134.28 -400.94,-856.57 -346.38,154.12 -720.38,-488.15 -236.62,-697.83 -319.43,-942.56 235.3,-774.55 -453.52,-398.2 -758.496,158.75 -512.018,-715.68 z',
		geocodigo: '2509602',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Monte Horebe - PB',
	},
	{
		d: 'm 193034.99,199837.66 527.79,-190.5 1764.13,-212.33 222.16,-499.39 415.4,-126.33 66.38,-518.58 -241.87,-393.56 130.79,-566.86 -901.78,-175.94 1016.15,-257.31 314.83,-275.16 1177.19,-21.17 3093.79,-2158.3 -19.06,-920.08 -392.39,-580.75 232.02,-499.39 1816.05,-333.37 -429.86,-1514.72 -901.12,119.73 -230.71,-297.66 976.06,-428.62 -28.26,-462.35 546.85,-195.79 404.22,190.5 1409.86,-564.88 464.7,-564.87 617.18,-31.75 527.13,408.11 366.1,283.1 -50.61,-368.42 766.39,-124.36 637.56,-992.17 1179.81,173.96 569.2,-596.63 672.39,163.38 396.34,-499.39 626.38,345.94 452.87,-72.1 709.86,-872.45 688.16,25.8 976.06,-662.77 446.95,-54.24 345.07,-562.9 761.78,-451.77 1353.33,-1454.52 888.64,2418.26 3711.64,5835.95 1969.86,3104.18 675.68,11896.14 -613.9,539.08 -918.88,260.61 -1652.38,920.07 -623.1,-509.97 -782.16,12.56 -2650.8,-890.97 -958.96,183.88 -2183.48,-754.05 -786.75,897.59 -982.63,360.49 -887.32,-200.42 -480.47,-537.76 -850.52,13.23 -476.52,-1198.54 -1401.32,-60.86 -163.66,-1046.41 -513.99,-736.19 -206.38,-155.43 -1039.81,-47.63 -278.69,-480.21 -345.07,14.55 -255.68,-518.58 -370.71,-7.93 51.27,-1623.2 -1065.44,-613.82 -830.14,473.6 -136.71,198.43 82.81,818.87 -665.16,-4.63 -142.63,-232.17 -477.18,285.09 -696.72,-288.39 -1198.87,-84.67 -1003,275.16 -1315.21,-513.28 -563.28,383.64 -987.23,-156.77 -1650.42,854.6 -125.55,-382.32 -1039.81,27.12 592.87,-1499.5 -127.51,-439.87 z',
		geocodigo: '2509701',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Monteiro - PB',
	},
	{
		d: 'm 325621.51,129773.05 2793.96,259.37 1168.64,-622.43 2086.19,-782.5 -93.33,-304.26 815.68,-232.17 -146.57,-623.74 879.44,-566.86 807.79,361.81 208.36,-436.55 -362.82,-572.82 141.97,-176.61 485.07,110.47 138.03,-510.64 273.43,-63.5 721.69,241.43 1342.15,528.49 1424.98,161.4 155.77,1017.97 1725.35,2119.94 -3430.33,931.32 -454.17,667.39 -13.15,1064.27 -343.09,116.42 -32.21,670.71 -1093.71,851.28 -1717.46,-148.82 -542.26,1027.22 -1449.95,1027.24 -705.91,240.1 -2817.74,-521.88 -650.71,-2894.5 -496.24,-83.34 -301.69,462.35 -299.06,-115.02 z',
		geocodigo: '2509800',
		microrregiao: Microrregiao.guarabira,
		name: 'Mulungu - PB',
	},
	{
		d: 'm 317941.12,175073.05 544.22,-163.38 1564.98,776.54 364.13,-1019.3 -728.26,-1002.09 -141.98,-2505.56 552.12,-1035.83 51.92,-3334.36 826.86,-2381.21 -105.83,-836.73 1135.12,-872.45 813.71,429.28 407.51,-212.99 265.35,2034.21 2051.33,958.77 1953.89,234.89 -482.99,1985.2 -1520.64,2728.87 -510.04,1399.69 -690.14,750.75 23.66,531.14 467.99,169.33 1052.29,-334.03 -473.89,1010.03 278.68,545.69 -558.03,82.69 -426.57,1029.21 1202.16,1572.92 -860.37,188.51 -424.6,652.85 -2029.01,367.11 -831.46,-352.55 -1212.02,-306.92 -626.38,257.3 -926.76,13.23 -637.56,703.78 -941.12,-1936.81 156.92,-793.19 -2027.57,-519.65 2445.67,-811.63 z',
		geocodigo: '2509909',
		microrregiao: Microrregiao.umbuzeiro,
		name: 'Natuba - PB',
	},
	{
		d: 'm 114989.36,126150.38 46.66,-379.68 1083.85,-407.45 722.35,215.64 1334.27,-41.68 322.72,-691.21 820.28,139.56 -208.36,-350.56 497.56,-435.23 1062.16,-2127.22 1739.81,-406.13 884.04,1672.14 1505.82,812.92 755.21,-232.17 375.3,316.83 1242.25,-449.86 454.18,45.06 529.77,2464.56 -621.79,4057.98 -621.78,-107.15 -1952.82,1735.14 -2317.18,25.05 -1108.6,-1285.72 -1862.61,-803.88 -1796.99,-105.83 -1265.26,-1395.65 -1309.3,-119.73 -308.26,-2145.73 z',
		geocodigo: '2510006',
		microrregiao: Microrregiao.sousa,
		name: 'Nazarezinho - PB',
	},
	{
		d: 'm 274443.9,98631.497 235.3,-7713.145 197.19,-1428.727 3430.32,-241.429 -526.19,2965.504 -3337.94,6416.474 z',
		geocodigo: '2510105',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Nova Floresta - PB',
	},
	{
		d: 'm 140175.52,159106.36 1892.3,359.82 519.9,667.4 -294.45,570.17 1235.67,1268.66 978.03,564.88 1102.91,345.27 581.03,741.48 745.35,1812.37 2313.61,1830.89 -1863.37,1773.34 411.45,502.7 -5.91,416.05 -594.84,103.85 356.25,-472.27 -1214.65,353.87 -506.1,-526.52 -1082.54,-250.02 -2205.82,-928.01 646.11,-564.88 176.15,-534.44 -321.41,-990.2 -783.47,-887 197.18,-734.21 -264.23,-672.03 400.94,-302.28 -545.54,-1062.95 82.82,-488.14 -193.9,-329.4 -431.17,-100.55 -613.9,-988.86 248.45,-422.66 -970.14,-1057.66 z',
		geocodigo: '2510204',
		microrregiao: Microrregiao.pianco,
		name: 'Nova Olinda - PB',
	},
	{
		d: 'm 252104.4,111329.31 -766.39,-175.94 -982.63,-1488.26 22.35,-246.72 726.95,-890.97 751.92,-3029.43 1104.88,-3153.79 -201.78,-774.55 777.56,-382.32 423.29,76.73 429.2,439.86 2067.13,941.24 937.93,1455.85 809.77,-742.14 804.5,369.08 2330.7,2224.45 483.76,-1059.64 1043.09,-1084.77 -33.51,-2006.17 370.7,-685.26 5245.72,3827.8 1892.29,2345.49 -680.93,474.26 -1367.14,116.41 -375.3,842.03 -1793.71,1078.16 -359.53,2213.2 -1734.55,-291.7 -626.38,629.7 -467.98,-35.72 -4526.67,-1566.31 -257.64,-288.39 -655.31,-140.22 -966.86,364.45 -678.96,-140.88 -569.2,331.38 -499.53,-177.93 -1968.55,246.72 -235.3,-164.7 -478.49,549 z',
		geocodigo: '2510303',
		microrregiao: Microrregiao.seridoOriental,
		name: 'Nova Palmeira - PB',
	},
	{
		d: 'm 153144.24,153247.91 -109.77,-1763.42 -440.37,-836.73 5814.92,-2933.53 1123.94,-5208.23 2581.13,-1418.15 1595.21,-46.3 396.99,1456.51 563.29,935.95 2636.33,72.1 1992.2,-216.3 74.93,1360.61 527.8,963.06 -321.42,689.89 841.32,890.97 79.53,613.16 232.02,-174.63 620.47,112.45 55.21,302.28 1300.75,838.06 458.12,963.73 613.9,177.27 46,402.16 1357.94,114.43 168.92,930 596.15,262.59 346.38,1449.9 755.21,664.08 -1022.06,1078.83 -973.43,39.69 -739.43,-342.63 -1244.89,302.94 -1106.85,-619.78 -737.46,369.75 -849.21,80.04 -1464.4,-375.71 -1098.97,796.39 -553.43,1242.19 -750.61,-669.38 -460.75,1.99 -191.26,144.19 38.12,1161.5 -1116.06,345.28 171.55,922.06 1151.55,267.22 65.07,182.56 -1814.08,767.95 -835.4,-209.69 -670.42,584.06 -1281.69,-745.45 -604.69,516.59 -864.98,95.92 -1173.24,1011.35 -1392.77,373.05 -719.05,973 -418.04,211 -262.25,-226.88 357.56,-730.24 -502.16,-673.35 -33.52,-1930.11 -285.26,-2916.99 -699.34,-726.27 -489.67,187.19 -796.62,-598.61 -467.32,489.47 -826.86,351.9 -33.51,-425.31 629.67,-697.84 -327.33,-535.77 487.7,-681.95 z',
		geocodigo: '2510402',
		microrregiao: Microrregiao.pianco,
		name: 'Olho D`Água - PB',
	},
	{
		d: 'm 265290.02,127529.5 1229.1,-621.11 610.61,-619.11 1222.54,30.43 728.25,-554.96 535.68,-1902.99 2194.65,-4379.44 6603.65,6994.15 1631.36,177.93 -400.28,1886.45 -1039.81,651.52 -600.09,55.57 -357.56,384.3 -971.46,2435.45 -402.25,515.27 -139.99,2731.12 -238.6,362.47 -1790.42,127.66 -504.13,376.36 -281.31,-104.5 -316.81,529.82 323.38,606.54 483.1,54.24 15.77,1125.12 -3472.39,-971.66 -5067.6,-9890.63 z',
		geocodigo: '2510501',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Olivedos - PB',
	},
	{
		d: 'm 206196.28,181091.56 -586.29,-702.46 -442.34,-1168.11 -961.6,-972.99 -118.31,-1465.77 90.71,-259.29 629.67,-694.52 466.66,-1206.48 899.82,-1483.63 882.06,-1017.3 1596.52,-1299.75 884.04,-1015.99 1116.05,-361.81 1437.47,-937.27 -303.01,2755.6 -262.91,284.42 265.54,672.03 -402.25,244.73 722.35,1709.18 -583.66,761.99 481.78,717.01 -324.7,577.45 388.45,435.23 -38.77,960.42 -1979.06,-187.85 -911.64,547.68 -422.64,969.68 -1354.64,717.01 46.01,828.79 -299.06,764.64 -434.46,336.01 -478.5,-505.34 z',
		geocodigo: '2510600',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Ouro Velho - PB',
	},
	{
		d: 'm 235926.86,153024.34 531.08,-64.16 1488.19,-3021.09 1493,609.82 3201,-200.42 936.31,923.8 2964.65,78.29 1703.37,668.7 2044.33,2069.19 -1841.52,1150.37 -1203.43,256.05 -246.92,165.6 203.54,695.56 -99.91,1972.44 -2215.02,1059.64 -2240.62,-268.58 -751.94,-594.62 -2443.15,-273.37 -2233.52,-2078.97 -2651.12,-2006.39 1358.39,-1140.54 z',
		geocodigo: '2510659',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Parari - PB',
	},
	{
		d: 'm 211903.41,143060.96 -645.44,-3772.91 780.84,-597.28 -466.01,-666.75 310.24,-534.44 42.72,-832.1 391.08,-624.41 1329.67,-68.79 326.01,-308.9 1085.82,-267.22 398.97,-483.52 2399.71,-414.07 44.04,1717.12 892.58,3231.84 -127.51,537.75 -678.97,70.78 349.67,625.73 -233.33,665.41 492.96,209.69 140,459.04 -32.21,875.76 -385.82,556.94 -3247.6,-1190.61 -1317.84,-220.93 -497.56,235.48 -268.83,447.8 -1083.19,347.26 z',
		geocodigo: '2510709',
		microrregiao: Microrregiao.patos,
		name: 'Passagem - PB',
	},
	{
		d: 'm 180813.59,130771.25 172.21,-657.48 -596.81,-276.49 149.2,-1080.14 801.88,-322.12 217.56,-636.98 1122.63,-164.7 854.45,958.44 1517,-306.91 538.97,-465.66 -22.35,-218.94 -957.65,-482.19 451.55,-1430.05 -1071.37,-130.31 -772.95,-1161.5 3662.34,297.64 2746.1,1422.78 709.2,-3.31 653.34,-373.05 2203.84,-255.98 3458.59,-878.41 3140.47,-155.44 751.92,-321.46 1885.72,32.41 515.31,-238.78 1031.92,-9.26 565.92,825.49 846.57,64.15 -165.64,585.39 -909.67,630.35 738.13,76.74 732.86,605.22 -553.43,1475.69 481.78,627.05 95.31,647.56 -3851.04,2648.4 231.36,1333.11 -1041.14,1347.48 1807.63,1825.86 h 0.1 l -2351.16,2198.69 -3108.92,-2488.37 -2440.47,400.17 515.31,214.31 -145.26,451.77 165.64,266.57 -696.72,1224.33 -368.73,199.76 33.53,658.15 -422.64,480.2 -194.55,730.9 -1171.27,315.51 -253.05,-150.8 -1768.07,837.39 -519.91,-201.74 -124.22,-1483.63 2170.33,-308.9 704.59,-889.65 228.74,-2115.3 -133.43,-2318.38 -258.97,18.52 -1219.9,-3889.97 -1343.47,-82.69 -1658.31,498.74 -488.36,-79.38 -506.1,-463.01 -1761.5,-49.61 -655.3,-435.23 -944.51,-7.94 -1642.54,572.82 -1785.82,70.77 z',
		geocodigo: '2510808',
		microrregiao: Microrregiao.patos,
		name: 'Patos - PB',
	},
	{
		d: 'm 161271.46,101343.43 2412.2,-105.17 316.15,-992.17 870.23,-255.979 853.8,197.109 629.67,-148.82 304.98,-545.698 -140,-661.448 841.31,115.092 834.75,-303.604 155.77,-363.135 -522.53,-590.674 2297.18,125.014 1213.33,-82.019 352.96,-1044.427 1095.67,-285.084 613.9,-30.426 166.95,-926.689 295.77,-42.994 739.44,1582.845 2276.8,1019.291 1189.02,-95.91 1181.78,-397.53 2253.14,-1264.027 987.89,-189.835 158.41,-1019.954 2132.85,1731.672 -423.28,103.847 -1004.98,1110.571 184.7,685.26 418.03,337.339 -383.2,2164.923 -19.06,806.96 318.12,211 106.48,713.71 -193.89,1748.86 797.93,1315.62 -652.02,937.28 305.64,547.67 -993.81,2163.6 -2455.58,173.96 -4536.52,595.31 -4162.54,112.44 -1345.44,38.37 -9230.12,-5807.52 166.29,-410.76 -508.74,-1440.63 246.48,-820.86 -144.6,-709.07 z',
		geocodigo: '2510907',
		microrregiao: Microrregiao.sousa,
		name: 'Paulista - PB',
	},
	{
		d: 'm 135715.25,159671.23 5114.92,-2891.85 -12.49,667.4 -887.32,906.19 245.16,753.39 969.48,1057.65 -247.79,422.66 613.9,988.87 430.51,100.54 194.56,330.06 -82.82,488.15 545.54,1062.29 -400.94,302.28 264.22,672.03 -197.18,734.87 783.48,886.34 321.4,990.85 -176.8,533.79 -645.45,564.87 -95.3,168.01 72.95,1794.51 -322.72,674.67 -2529.85,670.71 -1154.18,675.34 -501.03,-1520.47 2506.08,-1455.06 -1592.3,-3777.78 -1612,-4614.54 z',
		geocodigo: '2511004',
		microrregiao: Microrregiao.itaporanga,
		name: 'Pedra Branca - PB',
	},
	{
		d: 'm 251378.11,119234.27 942.53,-1113.88 1352.02,-528.49 364.13,-476.24 408.82,-2582.95 -226.1,-1560.36 -1288.26,-994.82 -339.81,-470.95 -487.04,-177.27 479.15,-549 235.3,164.7 1968.55,-246.72 498.87,177.93 569.2,-331.38 678.97,140.88 966.85,-364.45 655.31,140.22 257.65,288.39 4526.66,1566.31 467.98,35.72 626.39,-629.7 1734.55,291.7 359.53,-2213.2 1793.7,-1078.16 375.31,-842.03 1367.13,-116.41 680.94,-474.26 1108.17,1318.93 25.63,217.61 1087.8,-1068.9 -585.64,9100.21 -1969.85,-54.24 -2210.43,-1348.7 12.49,452.43 -790.04,1641.72 -459.44,326.75 -1308.64,-905.52 -964.88,302.28 -13.14,374.38 -113.06,44.98 335.87,1601.36 -176.8,576.13 -403.57,332.7 -329.3,-566.2 -1222.53,-54.24 -645.45,263.92 -380.56,-305.59 36.81,431.93 -217.56,198.44 -536.34,-412.75 -314.84,35.06 -327.31,-505.35 -154.47,423.99 -466.01,11.25 -398.96,-384.31 -653.34,381 -1219.9,-156.76 -143.28,164.69 -163.67,-209.68 -518.59,205.06 -4.6,-261.28 -395.68,51.59 -76.24,-457.72 -472.59,-220.92 -1114.08,972.99 -521.88,-368.43 -70.33,189.18 -365.44,-120.39 -95.31,-394.88 -577.09,-191.82 -201.78,268.55 -342.44,-308.9 -268.82,481.54 -381.22,-124.36 z',
		geocodigo: '2511103',
		microrregiao: Microrregiao.seridoOriental,
		name: 'Pedra Lavrada - PB',
	},
	{
		d: 'm 349143.99,153409.97 106.48,-926.69 724.98,-189.18 747.98,-1686.03 30.23,-621.1 1449.95,-3714.03 871.55,265.24 1329.56,865.91 751.38,102.45 1238.96,163.38 857.09,-270.53 1014.84,46.3 855.11,2758.89 861.69,846 6408.45,5097.12 -891.93,1403.59 1070.05,1523.31 33.52,799.7 -159.06,393.56 -1062.16,2102.74 -508.73,182.56 -165.64,357.18 -216.24,1108.59 299.06,935.94 -556.72,2215.19 -488.35,-849.96 388.45,-1275.26 -51.27,-569.51 -1485.44,-1068.9 -1578.78,-1642.38 -1343.47,-583.4 -1280.37,-1037.14 -691.46,-382.32 -330.61,-94.59 -621.78,526.51 -683.57,295.67 -1591.93,-330.73 -453.51,-278.47 -2001.41,-547.67 -211.64,-1086.76 410.14,-396.21 -1446.66,-1475.69 -765.08,-1.32 -224.12,-995.48 -879.45,-1448.57 241.88,-521.22 z',
		geocodigo: '2511202',
		microrregiao: Microrregiao.litoralSul,
		name: 'Pedras de Fogo - PB',
	},
	{
		d: 'm 339352.55,103139.26 861.69,252.68 1763.47,227.54 348.35,-131.63 1869.96,1170.1 171.55,499.39 1012.86,951.83 3.28,207.69 -1004.97,-4.63 1189.67,2232.39 -116.34,358.5 -1372.39,694.52 -632.96,-193.8 -945.81,16.53 -219.54,406.13 -723.66,332.71 -840.65,-248.04 -214.93,-404.81 -528.45,-30.42 49.29,-2692.76 -1026.67,-1368.54 -510.7,-87.97 868.26,-2188.73 z',
		geocodigo: '2512721',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Pedro Régis - PB',
	},
	{
		d: 'm 139278.34,147887.53 523.19,-1211.77 1087.79,201.75 181.41,-177.27 195.21,190.5 571.18,-150.82 -70.33,-408.11 305.63,-449.12 -209.67,-366.44 365.44,-277.15 -138.68,-1293.13 -1037.84,-1001.43 -667.79,-1297.76 -93.99,-720.32 490.98,-1291.81 547.51,-220.26 -126.19,-267.22 517.27,-1477.02 -28.26,-558.26 594.18,1783.93 -165.64,398.19 145.26,441.84 1464.41,1235.59 448.27,1060.96 -389.77,606.55 -403.57,179.25 424.6,427.3 326.01,627.05 521.22,359.83 30.24,-728.26 1911.36,-107.15 39.43,-803 607.98,-696.5 -211.64,-670.05 95.3,-718.99 360.85,-959.77 337.18,-196.45 50.61,-803.65 1429.57,-534.46 253.06,-747.43 2416.8,-257.3 1626.76,248.04 1584.03,-273.84 1275.12,118.4 951.08,889.65 1284.32,-369.75 -46.01,1742.91 1332.95,394.88 460.75,595.31 567.89,-675.34 726.29,1187.96 375.31,220.26 -2580.47,1418.15 -1123.94,5208.23 -5815.58,2933.53 440.38,836.73 109.76,1763.42 -4119.8,550.98 -2049.39,-953.8 -1754.27,-1336.13 -1097,522.55 -2022.43,1838.82 -2331.36,478.23 -490.99,-6461.03 z',
		geocodigo: '2511301',
		microrregiao: Microrregiao.pianco,
		name: 'Piancó - PB',
	},
	{
		d: 'm 256775,97868.186 693.42,-2219.158 2826.95,-3056.551 85.45,-847.976 -443,-338 -1031.93,-220.924 1413.14,-1518.023 -1109.48,11.245 -238.59,-342.631 1321.12,-2049.827 308.27,-217.616 590.23,38.364 944.51,391.577 339.15,398.853 1115.4,-1406.899 -133.42,-1057.656 661.87,-1321.573 -617.18,-433.909 404.22,-398.192 1321.13,152.133 253.05,-952.485 941.22,-1196.56 -197.18,-476.904 -368.74,-29.103 -281.97,-374.38 270.8,-492.779 2629.76,-1523.314 886.01,880.387 1120.66,1113.217 61.78,625.73 33.52,2065.701 -1179.81,1826.258 -257,577.444 203.11,1060.963 3218.67,806.305 99.91,2400.395 2218.31,-251.35 -197.19,1429.389 -235.3,7713.144 -663.19,2584.279 -1309.95,-1131.74 -1286.95,-489.47 -1524.88,-1046.411 -720.38,-1078.822 153.15,1668.833 1893.61,2856.79 -3.28,2278.69 -329.3,1486.28 423.29,2139.78 377.93,703.78 -1108.16,-1318.27 -1891.65,-2346.15 -5246.38,-3827.8 -370.04,685.26 32.86,2006.17 -1042.44,1085.44 -483.76,1058.98 -2331.35,-2223.79 172.21,-248.05 -383.2,-1885.12 -162.35,-2866.056 -418.02,-492.779 -1444.04,-368.426 z',
		geocodigo: '2511400',
		microrregiao: Microrregiao.seridoOriental,
		name: 'Picuí - PB',
	},
	{
		d: 'm 339816.58,151991.82 367.42,-491.45 368.74,-44.32 817.65,471.61 427.88,-175.94 556.06,-1622.54 -287.23,-1326.2 -372.68,-971.67 -471.92,-577.44 -222.82,68.79 90.71,-886.34 1004.98,166.02 268.82,-260.61 312.86,209.68 588.92,-398.85 410.14,312.2 960.28,-233.49 193.9,166.02 1060.84,-156.76 397,-361.15 28.92,676.66 -439.06,542.39 -287.23,1110.57 1308.63,3895.27 297.09,498.73 -278.68,262.59 -331.93,-97.23 -12.48,258.62 -410.8,257.97 -418.03,1268.66 -475.21,259.29 115.68,271.85 -279.34,181.23 -70.33,-156.76 -133.43,377.69 -289.86,17.2 134.09,208.36 -222.82,259.28 -788.73,478.23 -255.68,773.23 -1478.87,-3080.36 -1204.13,119.72 -178.12,-1305.7 -802.54,-968.36 z',
		geocodigo: '2511509',
		microrregiao: Microrregiao.sape,
		name: 'Pilar - PB',
	},
	{
		d: 'm 316081.03,124183.23 289.19,-1990.3 -529.04,-46.3 -408.88,-1008.04 1337.55,-1014.67 1635.96,-160.06 2180.19,-934.63 1621.5,3437.54 118.31,1570.94 -1369.76,848.64 -2245.26,-219.61 -2634.36,-481.53 z',
		geocodigo: '2511608',
		microrregiao: Microrregiao.brejo,
		name: 'Pilões - PB',
	},
	{
		d: 'm 320589.29,119027.9 540.28,-1235.58 5586.19,-193.8 -368.73,270.52 -17.75,819.54 -1620.18,1777.31 v 1555.73 l -2498.31,443.17 z',
		geocodigo: '2511707',
		microrregiao: Microrregiao.guarabira,
		name: 'Pilõezinhos - PB',
	},
	{
		d: 'm 321129.57,117792.32 410.14,-1156.87 3342.91,-2544.59 502.16,-947.2 822.25,3.31 836.05,1023.92 453.52,171.32 887.32,-89.3 1510.43,-1484.95 1149.57,-115.75 733.52,93.26 548.17,356.52 726.95,67.47 216.24,383.64 276.06,121.71 -414.74,528.49 -682.25,303.61 -834.75,763.31 -805.82,345.94 -1309.29,1425.42 -1481.5,510.63 -1297.47,44.98 -5586.19,193.8 z',
		geocodigo: '2511806',
		microrregiao: Microrregiao.guarabira,
		name: 'Pirpirituba - PB',
	},
	{
		d: 'm 373747.81,170038.77 212.96,-416.06 -335.21,-1897.69 33.52,-3203.39 394.37,-1805.09 -350.33,-433.91 -422.63,42.99 -189.3,-296.99 -69.67,326.1 -364.13,-206.38 -924.13,-1003.41 -1415.11,-28.45 -753.25,-464.33 3739.25,-4898.02 173.52,-113.77 379.9,425.97 579.07,-269.21 1741.12,425.97 327.32,365.78 -166.95,739.5 335.87,-47.62 28.27,283.1 191.26,-59.53 90.05,683.27 176.81,-55.56 310.23,549 -663.18,5536.32 565.91,920.08 -535.68,449.78 -281.32,615.81 373.34,1837.5 -764.42,1212.44 -423.28,1398.96 -558.03,125.01 -470.61,-419.35 z',
		geocodigo: '2511905',
		microrregiao: Microrregiao.litoralSul,
		name: 'Pitimbu - PB',
	},
	{
		d: 'm 273830,138391.79 -15.77,-1125.12 -483.1,-54.24 -322.72,-606.54 316.15,-529.82 281.97,104.5 503.48,-376.36 1790.41,-127.66 238.6,-362.47 140,-2731.12 402.24,-515.27 972.12,-2435.45 357.56,-384.3 599.43,-55.57 1039.81,-651.52 400.94,-1886.45 2523.29,-931.32 2523.28,2911.03 1532.77,20.5 544.22,449.79 2106.57,-1144.3 170.24,-683.28 488.35,-326.76 -1.97,-284.42 1925.82,-894.94 318.12,-521.21 1162.07,-150.82 889.29,-958.44 88.73,797.05 680.94,1279.9 128.83,730.24 -1850.89,1327.52 -147.89,616.48 -880.75,726.27 1593.23,2347.48 -413.42,579.42 511.36,-14.55 388.45,360.49 -617.18,1201.19 25.63,1002.09 -502.81,196.45 -289.21,421.35 496.25,55.56 -833.43,755.37 -379.91,1564.99 -3636.7,4709.5 -233.99,78.05 -88.74,517.26 -922.15,-625.73 -1184.41,78.71 -2286.67,-887 -1077.93,-134.27 -2202.53,891.63 -2215.02,2728.47 -3898.97,-322.12 304.98,-666.75 114.37,-763.97 -193.9,-756.69 207.04,-390.25 -259.63,-32.42 157.75,-481.53 -477.18,-1286.52 253.05,-169.33 -168.92,-968.36 -444.98,-462.35 z',
		geocodigo: '2512002',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Pocinhos - PB',
	},
	{
		d: 'm 105135.47,83800.511 1327.7,-148.164 -346.39,-222.909 349.68,-444.493 796.61,-156.763 1187.7,255.98 882.72,769.265 814.37,-852.607 1344.79,-812.919 1302.72,-286.407 -1334.27,1166.132 -1004.98,1421.452 496.25,1156.872 -227.42,1285.194 1478.87,-171.315 -364.13,275.162 144.6,847.976 -884.04,1813.029 -542.25,212.986 -925.44,94.588 -273.43,414.066 -527.79,104.509 -1643.2,-224.231 -778.87,-742.806 -658.59,189.174 47.98,-334.693 2154.55,-1870.574 478.5,119.721 -888.64,-1115.201 -906.38,622.423 -637.56,-278.47 -1575.49,408.114 -564.6,-199.758 -33.52,-312.203 -565.92,-89.295 -345.72,97.233 -573.8,-527.836 515.3,-222.908 180.09,-605.887 304.98,-163.377 1095.68,-80.035 -193.9,-804.983 610.61,-171.314 142.63,-361.812 143.29,-55.562 z',
		geocodigo: '2512036',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Poço Dantas - PB',
	},
	{
		d: 'm 109465.61,95531.952 217.56,179.252 92.67,627.052 467.32,3247.049 2518.69,-265.902 1569.57,432.586 -955.68,1135.711 -440.37,1332.81 -1474.27,2020.06 23.66,971.01 -422.63,629.04 -887.32,406.13 -49.3,-564.88 -504.13,-85.99 -879.43,-1368.53 -935.31,27.12 -97.93,-1281.89 -809.77,-1460.48 195.87,-302.94 -658.59,-289.71 157.75,-846 327.32,-357.178 -166.95,-1748.869 642.16,-64.16 -301.69,-1924.814 268.83,-52.916 -54.56,-488.149 489.67,492.78 1672.77,-394.885 z',
		geocodigo: '2512077',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Poço de José de Moura - PB',
	},
	{
		d: 'm 144129.69,113550.46 -311.54,-3389.26 3113.51,-411.42 217.56,-1096.02 -1085.82,-1438.65 -993.8,-128.32 -402.25,-2080.26 3690.6,-2390.47 429.2,1014.66 517.28,-385.62 930.05,253.99 2475.95,-684.6 1102.91,1103.96 985.92,-374.38 1113.42,949.18 5598.68,231.5 9230.79,5807.52 711.83,465.66 241.88,1090.06 957.65,951.83 -170.23,788.44 767.69,890.31 -138.03,539.08 -599.43,674.02 133.42,718.33 -7418.01,-592 -1309.96,1107.27 24.98,1897.69 -2397.74,702.46 -63.76,2235.69 -755.21,-29.1 -410.14,-373.06 -270.14,169.99 -1125.92,-115.09 -1004.97,-361.81 -688.17,22.49 -945.16,2097.45 -1026.67,234.16 -380.56,738.83 -38.12,944.55 -960.28,489.47 136.71,606.55 -496.24,524.52 -834.74,363.8 -103.2,795.06 -822.25,554.96 418.68,819.53 569.21,556.94 394.36,-92.6 1097,1228.31 531.08,173.3 531.08,769.92 -4275.58,-1793.84 -5559.24,-3643.92 -594.84,-4344.39 1240.28,-857.9 3841.78,-121.71 176.8,-527.83 613.25,-232.17 353.61,-297.65 782.16,-1065.6 2021.12,-96.56 1169.95,-1254.11 -232.01,-60.85 192.58,-465.66 -140,-279.8 -1173.24,-350.56 144.6,-1048.4 -502.82,-329.4 62.45,-320.14 -249.77,-629.04 276.06,-513.28 -109.11,-472.27 -657.28,-347.26 892.58,-951.83 -243.19,-717.01 -1426.95,118.4 -4644.31,928.01 -2049.38,932.65 -1078.6,174.62 z',
		geocodigo: '2512101',
		microrregiao: Microrregiao.sousa,
		name: 'Pombal - PB',
	},
	{
		d: 'm 206674.78,181595.59 434.46,-336.02 299.06,-764.63 -46.01,-828.8 1354.65,-717.01 423.29,-969.68 910.98,-547.68 1979.06,187.19 38.78,-959.76 -388.45,-435.23 324.69,-578.11 -481.78,-716.35 583.66,-761.99 700.66,1228.98 372.67,-49.62 1281.69,947.2 497.56,-22.49 782.15,792.42 746.01,-120.39 209.68,6037.7 -190.62,65.48 -1353.33,1454.52 -761.78,451.77 -345.07,562.9 -446.29,54.24 -976.71,662.77 -688.17,-25.8 -709.2,872.45 -453.52,72.1 -626.39,-345.94 -396.33,499.39 -672.4,-163.38 -569.2,596.63 -1179.81,-173.96 -348.35,-216.3 -19.07,-281.11 1308.64,-2004.19 -734.18,-1046.41 -189.29,-1307.02 -676.34,-1110.57 z',
		geocodigo: '2512200',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Prata - PB',
	},
	{
		d: 'm 138415.34,172311.5 101.22,-86.65 1154.83,-675.34 2529.86,-670.71 322.72,-674.67 -73.62,-1794.51 95.97,-168.01 2205.16,928.67 1083.19,249.37 505.44,526.51 1214.65,-353.87 -356.24,472.27 -120.94,-164.7 71.64,634.99 456.81,65.48 572.48,518.58 745.35,-124.35 343.76,466.98 987.23,-208.36 763.09,373.06 -252.39,739.5 -1690.51,986.88 122.25,2555.84 128.83,283.09 -251.08,345.95 -1.31,737.51 681.59,544.37 246.48,379.01 -118.97,500.71 558.03,382.32 264.22,683.28 1267.23,739.5 351.64,806.3 607.33,291.7 293.8,582.07 500.85,357.18 -145.92,196.45 -1356.62,-302.28 -573.8,313.53 -423.29,1173.41 -1179.81,812.26 -320.74,1107.92 -784.14,715.03 -922.16,825.48 -860.37,-345.27 -527.8,454.41 -758.49,4.63 -270.14,-68.78 -265.54,-810.94 -1060.84,-888.33 -1210.05,-478.22 -2170.98,1788.55 43.38,-945.21 -847.89,-1507.44 63.76,-979.6 954.36,-704.44 354.28,-1164.81 46.66,-928.02 -262.91,-617.79 548.83,-1110.57 383.19,-1526.62 -2377.37,-1.32 450.24,-2254.88 372.01,360.49 494.28,-7.94 127.51,-371.73 -286.58,-399.51 235.31,-207.04 -255.68,-697.16 -979.34,-320.15 -224.13,-326.75 -297.75,54.23 95.31,-261.93 -348.36,-550.32 -767.7,-255.98 z',
		geocodigo: '2512309',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Princesa Isabel - PB',
	},
	{
		d: 'm 288591.11,142800.34 3636.71,-4709.5 769.67,617.79 2809.86,-120.39 12.48,926.69 -264.22,1150.92 824.22,1017.97 95.31,435.23 736.15,715.02 403.57,1163.5 -2542.35,-517.26 140,1432.04 -676.99,-95.91 -600.1,201.74 -597.46,-95.91 -1073.33,-1315.62 -583.66,-1143.64 -704.61,257.3 -2386.57,81.36 z',
		geocodigo: '2512408',
		microrregiao: Microrregiao.campinaGrande,
		name: 'Puxinanã - PB',
	},
	{
		d: 'm 290037.12,163798.67 88.73,-250.03 -926.76,-457.72 414.74,-142.21 -72.95,-262.6 627.7,-5.29 227.41,-1026.57 -901.78,-252.67 972.11,-709.07 375.31,123.03 -79.54,-331.39 -687.51,-392.24 62.44,-433.25 648.73,-473.59 -286.57,-416.06 -72.95,-228.86 508.73,-502.7 497.55,-86.65 286.58,-361.15 -101.88,-1602.02 416.71,-160.07 -86.1,-278.47 249.77,-65.49 -233.34,-1256.09 295.78,-56.22 131.45,-1436.66 -413.42,-414.73 1513.7,145.52 -23.66,-699.15 594.83,-251.35 228.74,-398.19 467.98,38.36 -36.81,353.87 394.37,85.99 3727.41,48.29 5.92,361.81 1139.05,609.19 2192.68,86.65 513.84,2243.83 -394.88,3670.18 251.08,934.62 1190.99,1800.46 -84.13,295.67 -759.81,302.94 -439.06,871.8 192.58,761.98 -141.97,372.4 701.31,-158.09 208.36,486.17 -882.07,579.42 55.21,1590.78 -796.62,183.89 -481.78,982.91 -405.54,-136.26 -354.27,459.05 -341.78,-156.76 -164.32,252.67 -50.61,-132.95 -152.49,165.36 -1229.11,-140.89 -680.94,417.37 -154.45,-584.06 -891.93,-135.59 -441.69,561.57 -1224.51,-598.62 -981.31,-907.5 -189.29,171.32 -723.01,-517.26 46.96,-438.57 -3346.48,-2102.05 z',
		geocodigo: '2512507',
		microrregiao: Microrregiao.campinaGrande,
		name: 'Queimadas - PB',
	},
	{
		d: 'm 204599.76,135060.08 -1644.46,1545.77 -1807.63,-1825.86 1041.14,-1347.52 -231.36,-1333.07 3851.04,-2656.34 1478.88,654.84 857.08,790.43 770.98,30.43 246.48,568.18 834.74,166.68 1205.45,815.57 820.94,-23.82 1060.18,-709.07 341.78,459.71 863.67,329.4 -246.48,957.11 3812.86,1011.36 -2399.06,414.07 -398.97,483.51 -1086.47,267.23 -326.01,308.9 -1329.02,68.78 -391.73,623.75 -42.73,832.11 -309.57,535.1 465.35,666.74 -683.57,-678.64 -2471.35,-986.88 -809.11,-646.9 -2048.08,-870.46 -514.64,93.26 -911.65,-544.37 z',
		geocodigo: '2512606',
		microrregiao: Microrregiao.patos,
		name: 'Quixaba - PB',
	},
	{
		d: 'm 294231.19,124142.22 472.59,-549.01 -480.47,-838.71 3509.86,-9.92 368.73,-726.27 1847.6,-1356.63 1701.69,-438.54 2888.73,1782.6 170.23,-537.75 1014.84,1729.68 123.56,3031.41 178.13,502.05 1027.32,1008.04 -117.66,1863.96 141.98,349.25 -575.78,2164.92 -859.05,-329.4 -744.04,441.84 -114.37,-472.27 -356.25,-255.98 -510.7,-757.36 30.24,-898.91 205.73,-217.61 -2688.92,-1297.76 -981.31,-902.88 -1429.58,-228.86 -1475.59,-964.39 -844.6,-395.55 -989.2,47.62 -740.75,331.39 -680.28,-1279.9 -89.39,-797.05 z',
		geocodigo: '2512705',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Remígio - PB',
	},
	{
		d: 'm 313181.13,100947.23 -1342.81,-115.1 -899.82,-1720.422 585.64,-9.922 1519.62,-809.612 334.55,-1512.07 1675.4,-2416.27 785.44,-652.849 1733.24,630.361 3595.3,1826.258 1555.11,1500.825 -1481.49,476.904 -1712.86,-607.871 -749.3,148.164 -263.57,101.202 -354.93,1340.755 -1357.93,92.603 -1235.68,606.547 -228.73,410.097 -1073.34,129.64 -1083.84,578.77 z',
		geocodigo: '2512747',
		microrregiao: Microrregiao.curimatauOriental,
		name: 'Riachão - PB',
	},
	{
		d: 'm 312166.29,149700.57 408.83,-3018.19 1941.59,-804.98 2581.78,1094.69 331.27,371.07 414.74,-163.37 284.6,478.89 -454.83,11.24 -281.32,739.5 74.93,845.99 -354.93,-276.49 -690.14,48.95 -1419.71,555.62 -458.12,552.31 -524.51,218.94 -1854.18,-652.86 z',
		geocodigo: '2512754',
		microrregiao: Microrregiao.itabaiana,
		name: 'Riachão do Bacamarte - PB',
	},
	{
		d: 'm 339062.69,143491.56 506.1,-1350.68 995.12,-356.52 1747.7,-3108.15 260.94,152.14 -86.11,423.99 672.4,219.6 249.76,-173.3 -140,483.52 289.86,723.62 691.45,409.43 278.04,130.98 -151.18,440.52 17.74,1129.75 -1628.06,1850.07 -3703.76,-972.99 z',
		geocodigo: '2512762',
		microrregiao: Microrregiao.sape,
		name: 'Riachão do Poço - PB',
	},
	{
		d: 'm 276409.81,177769.77 335.21,50.93 257.66,-549 265.54,208.35 478.49,-76.72 389.77,-341.31 322.72,342.63 604.03,-270.53 248.45,139.56 408.17,-224.23 -60.46,-302.28 494.92,-494.76 272.12,-47.62 -13.15,-267.23 423.29,195.13 693.42,-837.4 465.36,-178.59 321.4,195.13 922.16,-705.77 1186.39,-84.66 1131.82,250.68 1.98,658.15 -1164.04,227.54 -147.89,-253.34 -164.98,253.34 -284.59,-48.29 -59.16,569.51 -507.42,-262.6 -52.58,722.97 -496.24,-471.62 -405.54,515.27 490.33,438.54 -416.72,329.4 -564.6,-343.95 -583.66,195.13 416.71,404.8 -190.61,431.92 153.81,689.9 -338.5,609.86 1895.58,3595.62 -172.2,556.94 -721.69,694.53 -133.43,1246.82 -610.61,1269.98 -562.62,-537.76 -1334.28,-340.64 -332.58,-340.64 -331.92,-1579.54 236.62,-2691.44 -122.25,-547.68 -2641.6,-2995.03 z',
		geocodigo: '2512788',
		microrregiao: Microrregiao.caririOriental,
		name: 'Riacho de Santo Antônio - PB',
	},
	{
		d: 'm 165233.52,93451.698 -34.84,-7093.368 11229.57,3135.925 -486.39,478.888 65.07,321.464 645.45,339.322 1027.32,1665.526 394.37,80.036 1445.35,1768.05 1362.53,740.822 550.14,51.593 73.61,1302.391 -2253.14,1264.027 -1181.78,396.869 -1189.67,95.909 -2276.8,-1019.29 -739.44,-1582.184 -295.77,42.994 -166.95,926.689 -613.9,30.426 -1095.68,284.423 -352.96,1045.087 -1213.33,81.359 -32.86,-796.384 -912.96,-629.037 -395.68,-57.546 -40.09,-222.246 -427.89,42.994 -84.13,-433.91 -717.09,-356.52 -606.01,-1004.74 -1448.64,-286.407 -222.16,-616.47 z',
		geocodigo: '2512804',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'Riacho dos Cavalos - PB',
	},
	{
		d: 'm 354130.75,123144.75 1666.19,-478.22 170.23,-233.49 -118.96,-1504.13 868.26,-1950.61 -228.73,-366.44 274.74,-160.08 4.6,-430.6 -1566.29,-49.61 -258.96,-238.12 -1356.62,-3229.85 -171.55,-2097.45 437.09,-908.83 540.94,-660.79 548.82,-3744.45 -627.04,-1949.29 1146.94,-553.63 406.86,-668.73 903.1,-449.78 2180.18,-1187.3 1283,-861.2 1026.02,-364.46 1821.96,285.74 -567.88,387.61 -944.51,1625.84 -1112.77,3315.84 410.14,2499.61 280,329.4 -1420.37,652.85 -2321.5,312.2 -523.19,259.29 -411.46,998.13 2247.88,2504.24 1173.9,94.59 1449.95,827.47 241.88,382.31 458.12,-143.53 249.77,433.25 1018.78,87.97 189.29,-744.13 889.3,-142.21 770.98,662.11 1289.58,-982.25 61.78,-765.29 213.62,-300.3 335.2,-14.55 634.28,412.74 1332.3,-152.13 378.59,3743.13 792.02,2547.24 -3.29,747.43 -1052.3,507.33 -776.24,846.66 -739.44,559.58 -852.49,66.15 -990.51,582.07 -858.4,109.13 -987.23,455.74 -3496.71,811.6 -1577.47,1129.76 -1197.55,25.79 -1054.27,346.6 -770.99,-98.56 -1710.89,-3119.39 z',
		geocodigo: '2512903',
		microrregiao: Microrregiao.litoralNorte,
		name: 'Rio Tinto - PB',
	},
	{
		d: 'm 223311.1,141673.9 c 745.59,-2145.62 1062.16,-5060.08 1062.16,-5060.08 l 1321.12,-353.87 1739.81,-1014 554.74,-59.53 599.44,255.97 2723.75,-1154.88 1181.78,-1304.38 1240.29,412.75 748.63,-172.64 490.33,270.53 2034.93,-912.14 -47.32,558.27 -814.37,726.26 266.85,1145.63 -68.36,1549.11 -1963.28,2376.59 -2291.92,2763.53 -1686.57,-381 -803.2,95.91 -410.14,296.33 -613.89,-97.89 -1553.8,736.19 -1058.87,60.85 -744.04,-593.98 -1047.7,235.47 -835.4,-130.96 -19.05,-246.72 z',
		geocodigo: '2513000',
		microrregiao: Microrregiao.seridoOcidental,
		name: 'Salgadinho - PB',
	},
	{
		d: 'm 323225.62,162912.98 343.1,-435.23 523.19,-2719.87 488.36,-384.3 429.2,-105.17 339.15,-581.42 632.3,-558.25 741.41,-78.72 702.63,-478.22 732.86,-238.12 571.17,-37.05 2341.88,-832.1 -383.19,-377.68 -532.4,-1374.5 1276.43,-707.74 439.07,-18.53 1030.6,276.49 1131.83,928.01 -590.89,330.06 -563.28,2113.33 1043.1,3128.65 838.02,-270.53 201.79,1336.12 461.4,30.43 166.95,1614.59 -2002.06,873.77 -507.42,750.75 -431.17,183.88 -952.4,-240.1 -947.02,-1161.5 -1842.82,1660.27 -1411.22,602.5 -1953.89,-234.83 -2051.33,-958.73 z',
		geocodigo: '2513109',
		microrregiao: Microrregiao.itabaiana,
		name: 'Salgado de São Félix - PB',
	},
	{
		d: 'm 288192.15,188741.87 423.28,-348.58 -44.69,-371.08 640.84,-728.25 -36.81,-304.27 396.34,-468.96 349.67,27.78 30.24,-566.86 214.93,246.72 163.66,-171.32 412.77,316.83 267.51,-68.79 243.19,-1376.47 558.03,-435.23 -440.38,-44.98 -82.82,-420.68 502.82,21.17 -82.81,-611.18 389.76,-398.85 147.89,-881.72 222.15,80.04 101.88,-877.08 -473.89,-9.26 -63.76,-185.87 701.31,-1134.38 47.98,-564.87 869.58,-1172.75 507.42,-212.99 402.25,782.49 936.62,611.18 191.27,917.43 418.03,121.05 2178.21,-982.26 445.63,-466.98 1723.38,4.63 667.79,198.43 1575.49,-365.11 -258.96,275.16 140,219.6 244.51,-74.08 -272.78,693.19 537,359.83 111.73,646.24 -414.08,1461.8 396.34,758.02 -63.75,1285.19 283.28,275.16 651.36,-59.53 32.21,464.34 -918.87,298.97 -947.8,-336.01 -1499.9,291.03 -710.52,-110.46 -694.74,480.21 -79.53,696.5 -74.92,664.1 -1491.36,1273.29 -2061.23,-569.5 -758.49,406.78 -1135.12,-200.42 -792.01,158.75 -824.88,1001.43 -826.86,-195.12 -381.88,931.32 -376.62,-22.5 -1327.69,-201.73 -990.52,-1033.19 z',
		geocodigo: '2513158',
		microrregiao: Microrregiao.umbuzeiro,
		name: 'Santa Cecília - PB',
	},
	{
		d: 'm 136705.76,101395.03 -1092.39,-1150.26 -912.96,-475.582 201.78,-1561.679 859.07,-942.564 1066.75,-549.001 410.14,-802.999 316.81,-22.488 1278.4,-888.325 187.33,-1158.195 897.18,-560.247 884.04,-1600.043 642.15,-326.755 195.87,-461.029 400.94,-41.671 163.66,-738.837 414.74,-70.776 130.14,766.618 555.4,584.059 -318.12,950.501 997.09,123.691 691.45,363.135 -628.35,3516.918 2959.71,32.412 366.76,228.86 1284.97,2788.665 -486.38,627.712 -332.58,1082.79 749.3,870.47 61.78,315.51 -292.49,321.46 -826.85,-359.83 -746.01,19.19 -341.78,-314.85 -1352.68,506.67 -1782.53,195.12 -507.42,-349.9 -410.8,-784.48 -51.92,-1113.22 -1787.13,-107.81 -1102.26,261.27 -1319.81,1346.05 -863.66,-683.94 -554.74,162.05 z',
		geocodigo: '2513208',
		microrregiao: Microrregiao.sousa,
		name: 'Santa Cruz - PB',
	},
	{
		d: 'm 97227.781,109386.64 186.009,-320.14 -224.131,-388.93 629.671,-1240.22 281.314,-100.54 -128.826,-326.75 752.581,-83.34 343.756,-668.73 17.089,-713.7 -732.863,-868.48 132.112,-861.21 443.662,-211 2707.325,2305.81 205.72,764.63 451.55,68.79 1227.14,1454.53 460.09,992.17 1580.09,168.01 818.97,-532.47 1718.77,374.38 1413.81,281.78 -12.49,695.84 657.93,182.56 341.78,436.56 -50.61,728.25 283.29,83.34 -314.83,902.22 70.98,556.94 -469.95,878.4 65.07,1197.22 -1032.58,331.39 -589.58,507.33 -1531.45,-148.83 -623.1,-288.39 -1110.14,392.24 -379.9,-433.91 -2145.35,-243.41 -1212.68,-321.47 -1076.62,-398.19 -814.363,-1000.11 -792.018,-272.52 -33.521,-241.42 122.911,-343.96 -546.854,-836.73 112.395,-766.62 -645.446,-447.8 -559.342,-1248.81 z',
		geocodigo: '2513307',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Santa Helena - PB',
	},
	{
		d: 'm 94151.072,176055.96 -232.019,-915.44 381.877,-73.43 144.601,-1582.84 607.323,-411.42 1200.187,16.54 453.52,-468.97 864.976,824.16 2265.631,-32.41 480.472,219.6 521.22,691.22 1656.99,412.74 1162.72,-525.19 1095.68,254.66 1793.05,-201.75 960.94,238.13 525.82,1101.31 -174.84,1361.92 1178.5,1055.67 647.42,488.15 272.11,-265.24 1910.7,11.24 -243.19,511.96 253.05,471.62 -535.68,1047.07 171.55,508.65 648.73,605.23 816.99,124.35 351.65,430.6 34.83,297.65 -526.47,808.29 -503.48,931.32 -1480.84,211 -818.97,361.81 -61.78,820.86 -1448.64,-52.91 -949.1,420.68 -985.92,-439.87 -1372.39,510.64 -710.52,-336.02 -199.15,207.7 -230.05,-561.57 -582.35,605.22 -548.16,-42.33 -299.06,-895.6 -712.49,-187.19 -28.92,-985.56 -591.55,-847.97 -664.51,-437.22 -80.84,-990.85 -1078.592,-599.93 -53.897,-644.91 -673.708,-41.68 48.639,-620.43 -931.361,-803.66 -459.436,214.31 -205.07,-123.03 -1011.548,-1729.69 -669.765,-163.37 -682.252,-782.5 -700.656,-9.26 z',
		geocodigo: '2513356',
		microrregiao: Microrregiao.itaporanga,
		name: 'Santa Inês - PB',
	},
	{
		d: 'm 217559.28,121589.69 19.06,-358.5 3992.95,-3751.07 -625.07,-772.57 82.82,-521.88 -254.37,-693.2 523.19,-542.39 141.32,523.87 1436.14,-1303.06 1973.15,-1127.77 386.47,488.15 335.87,1892.41 223.48,173.3 -188.64,820.85 197.19,775.88 2043.47,1214.42 562.62,483.51 42.73,736.2 -368.74,654.17 1640.56,1211.77 473.9,-285.08 78.21,985.55 469.3,78.72 718.41,-633.67 367.41,499.39 1287.6,328.08 285.26,593.32 535.68,128.32 -26.95,463.68 663.2,446.47 68.35,443.17 -6647.03,7132.4 -7.89,3514.27 -554.74,58.87 -1739.81,1014.66 -1321.12,353.88 -1496.62,-2445.38 -473.89,-153.45 -339.82,233.49 -787.42,-6.62 -184.69,-1113.88 1910.05,-1716.46 133.42,-1182.66 -698.03,-755.37 161.04,-1555.73 -547.52,-196.45 -1563,511.96 -58.49,-795.06 567.22,-1493.55 -861.68,-85.99 -962.25,230.18 -3370.52,-2304.48 532.4,-905.52 1125.91,-881.71 99.91,-408.11 z',
		geocodigo: '2513406',
		microrregiao: Microrregiao.seridoOcidental,
		name: 'Santa Luzia - PB',
	},
	{
		d: 'm 351472.07,133085.66 2434.55,-1630.47 -524.51,-203.73 -256.34,-1700.58 -564.6,-761.99 466.01,-739.5 180.1,-1329.51 343.09,-293.02 1893.62,-255.98 399.62,93.26 770.98,98.56 1054.28,-346.6 1197.55,-25.79 1577.47,-1129.76 3496.05,-811.6 4951.91,3526.85 710.52,-497.42 241.88,324.78 133.42,-263.92 246.49,363.14 205.06,-92.61 55.87,218.94 571.17,190.5 728.27,-222.24 -86.1,567.52 812.39,109.13 141.31,965.05 -147.23,441.19 233.34,1674.13 584.97,1361.92 -430.52,2259.51 -1913.33,2717.22 -1522.9,-1148.93 -291.17,830.12 588.25,842.02 -410.14,30.43 197.18,302.28 -219.52,599.93 -303.66,14.55 -178.13,529.82 -333.9,-296.33 -1116.05,456.4 -2332.67,3680.3 403.57,2941.45 311.55,-109.14 1913.32,-91.28 553.43,926.69 -393.05,957.12 -439.06,193.14 -182.72,528.49 -362.16,1799.81 -711.17,576.12 -744.04,70.77 -375.31,-601.92 -1232.39,-447.8 -2347.13,244.74 -1464.41,532.46 -861.69,-845.99 -855.12,-2758.9 1810.8,-6218.27 -74.93,-529.82 306.95,-545.69 -608.64,-87.97 186.01,-502.7 -1018.12,-2915.66 -7393.7,-3561.9 z',
		geocodigo: '2513703',
		microrregiao: Microrregiao.joaoPessoa,
		name: 'Santa Rita - PB',
	},
	{
		d: 'm 180711.71,130779.85 101.88,-8.6 1785.82,-70.11 1642.54,-572.82 944.5,7.94 655.3,435.23 1761.5,49.61 505.45,462.35 489.01,80.04 1657.65,-499.39 1344.14,83.34 1219.9,3889.97 258.96,-19.18 133.44,2319.04 -229.4,2115.31 -703.94,889.65 -2170.33,308.9 123.57,1483.62 27.61,950.5 -2692.2,-89.95 -116.34,960.42 -300.38,403.48 -475.86,311.54 -669.11,-283.1 -878.12,986.22 -960.28,142.21 -46.01,728.25 -572.49,-13.22 -1012.86,1477.01 -3534.84,-8577 2234.09,70.12 -524.51,-8020.06 z',
		geocodigo: '2513802',
		microrregiao: Microrregiao.patos,
		name: 'Santa Teresinha - PB',
	},
	{
		d: 'm 113304.1,174026.64 4477.36,54.24 -111.07,-1039.8 286.57,-812.92 -475.87,-1595.41 525.16,-553.63 97.94,-312.21 434.46,-78.05 446.95,179.25 1928.44,-193.8 1792.4,-572.81 776.24,-552.32 885.35,216.3 268.83,845.99 778.87,-58.87 270.8,1800.46 212.95,470.29 424.6,168.01 -163.66,505.34 339.81,152.14 235.31,-365.12 866.95,339.99 394.36,-413.41 -136.71,379.01 476.52,512.62 1212.02,339.32 -155.77,479.55 3250.23,1507.44 284.6,1992.29 -3630.14,453.08 -871.55,588.7 -372.02,478.88 172.87,459.05 -247.8,412.74 -1736.52,1740.93 -884.04,416.05 -1925.81,2597.51 -376.62,-750.74 278.02,-885.02 -376.62,-57.55 76.25,-464.33 -330.61,-814.24 -427.89,-308.9 -266.85,177.93 -497.56,-201.74 -538.97,116.41 -927.41,-1297.1 -384.51,770.59 -464.69,253.34 -125.54,521.21 -818.97,661.46 -1569.58,506.66 224.79,408.77 -638.21,759.35 -1302.07,-33.08 -486.38,-699.81 -1162.06,-3785.46 276.05,-1757.47 577.09,-779.19 -500.84,-630.36 -53.9,-451.1 535.68,-827.48 -57.18,-380.99 -792.02,-595.3 z',
		geocodigo: '2513505',
		microrregiao: Microrregiao.itaporanga,
		name: 'Santana de Mangueira - PB',
	},
	{
		d: 'm 140175.52,159106.36 -245.16,-753.39 887.32,-906.19 12.49,-667.4 434.46,-457.06 569.2,-83.34 266.85,-2368.65 2023.1,-1838.82 1096.99,-522.55 1753.62,1336.13 2050.04,953.8 4119.81,-550.98 -488.36,681.96 327.98,535.77 -629.67,697.82 32.86,425.97 826.85,-352.55 467.99,-489.47 796.62,598.61 489.67,-187.19 699.34,726.27 284.6,2916.99 33.52,1930.1 502.81,674.02 -358.21,729.57 262.25,226.88 -2226.19,771.91 -2546.95,2265.46 -1332.95,1309.01 -1037.84,564.87 -2313.61,-1830.88 -746.01,-1813.04 -580.37,-740.82 -1103.57,-345.27 -978.03,-564.88 -1235.02,-1269.31 293.8,-569.51 -519.9,-667.4 -1892.3,-359.83 z',
		geocodigo: '2513604',
		microrregiao: Microrregiao.pianco,
		name: 'Santana dos Garrotes - PB',
	},
	{
		d: 'm 241511.73,148002.63 753.24,-1606.66 23.67,-375.7 -403.57,4.63 1094.36,-1173.41 220.84,-521.22 -350.32,-857.9 181.4,-2048.5 -246.47,-1166.13 3547.31,-118.4 1571.56,3174.95 1708.91,484.84 1113.43,679.97 608.64,2113.98 1288.25,810.94 -1677.36,384.3 -674.36,830.11 -987.23,91.28 -157.76,190.5 1019.44,1461.14 143.08,3727.12 -2044.33,-2069.22 -1703.37,-668.67 -2964.65,-78.29 -936.32,-923.8 -3200.99,200.42 2070.63,-2547.6 z',
		geocodigo: '2513851',
		microrregiao: Microrregiao.caririOriental,
		name: 'Santo André - PB',
	},
	{
		d: 'm 161481.12,122001.11 63.76,-2235.69 2397.74,-702.46 -25.63,-1897.69 1310.61,-1107.27 7417.36,592 267.51,1459.15 -435.77,1065.6 34.84,826.15 -367.42,531.13 -514.65,270.54 -593.52,814.24 -1123.94,707.09 -779.53,1015.98 -58.5,1058.32 -359.53,356.52 515.31,723.62 87.41,921.4 -1410.51,286.41 130.14,1134.38 -2386.57,2328.3 -896.53,-2750.3 -3.28,-1316.94 -343.75,-488.15 146.57,-1236.91 -610.62,-227.54 86.11,-577.44 -295.77,-222.91 -23.66,-545.03 z',
		geocodigo: '2513927',
		microrregiao: Microrregiao.sousa,
		name: 'São Bentinho - PB',
	},
	{
		d: 'm 176428.25,89495.578 107.79,-951.824 370.7,-443.17 744.04,-248.043 947.8,304.266 1758.87,-611.178 1196.89,334.031 544.23,-313.526 1609.01,-232.168 1344.13,-676.662 5084.69,-293.021 -483.1,739.499 -92.02,1119.831 782.16,693.198 324.03,789.107 -638.86,1162.826 -431.18,4472.711 -1437.46,1293.13 -3379.06,129.644 -2132.2,-1731.67 -159.06,1019.291 -987.23,190.497 -73.62,-1302.391 -550.14,-51.593 -1362.53,-740.822 -1445.35,-1768.05 -394.37,-80.036 -1027.32,-1665.526 -645.44,-339.322 -65.08,-321.464 486.39,-478.888 z',
		geocodigo: '2513901',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'São Bento - PB',
	},
	{
		d: 'm 144129.69,113550.46 1078.6,-174.63 2049.38,-932.64 4644.31,-928.67 1426.95,-118.4 243.19,717.01 -892.58,952.48 657.28,347.26 109.11,471.62 -276.06,513.94 249.77,629.04 -62.45,319.48 502.82,330.06 -144.6,1047.73 1173.24,350.57 140,280.45 -192.58,465.66 232.01,60.2 -1169.95,1254.76 -2021.12,95.91 -782.16,1066.25 -353.61,297.66 -613.25,231.5 -176.8,527.84 -3841.78,122.37 -265.54,-1250.14 -782.16,-549 120.94,-1231.62 529.11,-515.26 556.71,316.17 364.13,-187.19 -718.4,-670.05 -1139.06,-1859.33 -44.04,-1398.96 z',
		geocodigo: '2513968',
		microrregiao: Microrregiao.sousa,
		name: 'São Domingos - PB',
	},
	{
		d: 'm 258468.15,179886.4 249.81,-1442.94 -1203.25,377.66 -1474.58,-473.95 3755.71,-11997.96 450.24,439.86 882.72,-17.86 563.94,552.31 443.66,-56.22 30.23,438.54 286.58,-103.85 278.03,289.72 119.62,-243.42 219.53,355.2 726.29,196.45 86.1,864.51 281.32,46.31 598.12,1017.96 423.94,126.34 342.45,480.21 1424.31,44.32 -358.87,1160.84 1313.23,1977.73 987.23,412.74 -1276.43,910.15 -901.78,-574.14 -1278.4,-246.71 -872.86,336.01 -510.71,687.91 114.37,496.08 378.59,9.93 -314.83,1152.24 -340.47,164.7 843.28,1537.86 9.2,211.01 443.67,406.79 -404.23,521.22 -6311.16,-55.57 z',
		geocodigo: '2513943',
		microrregiao: Microrregiao.caririOriental,
		name: 'São Domingos do Cariri - PB',
	},
	{
		d: 'm 138125.48,101915.59 1319.81,-1346.05 1102.25,-261.27 1787.13,107.81 52.59,1113.22 410.14,784.48 507.41,349.9 1782.54,-195.12 1352.67,-506.67 342.44,314.85 745.35,-19.19 826.86,359.83 -3690.61,2391.14 402.25,2080.25 -328.63,-358.5 -582.35,-80.04 -1049.67,-27.12 -734.84,486.17 -1464.41,-183.89 -1238.31,861.21 -70.32,281.11 -324.04,25.8 -227.42,-1260.72 155.78,-401.5 -682.26,-1544.48 211.65,-1032.52 -284.6,-39.69 -319.44,-1896.37 z',
		geocodigo: '2513984',
		microrregiao: Microrregiao.sousa,
		name: 'São Francisco - PB',
	},
	{
		d: 'm 244888.82,159386.15 2215.02,-1058.98 99.91,-1973.1 4618.02,-14.56 2849.29,-1520 1447.32,-1153.57 1909.39,-418.03 394.37,-205.05 303.66,287.07 602.72,-44.98 792.01,-563.56 2065.82,52.92 840.66,-265.9 629.67,193.8 1822.63,900.9 678.31,-50.93 631.64,-401.5 -6991.45,13203.16 -3755.67,11998.01 -1203.31,-1390.66 -2046.83,696.73 -4017.32,-1207.91 -656.66,629.8 -842.63,-3906.44 5036.05,-3952.16 -7421.31,-9829.77 z',
		geocodigo: '2514008',
		microrregiao: Microrregiao.caririOriental,
		name: 'São João do Cariri - PB',
	},
	{
		d: 'm 105197.91,115939.61 1109.49,-392.24 623.75,287.73 1530.8,148.82 590.23,-507.33 1031.92,-330.72 -65.07,-1197.22 470.61,-878.41 -71.64,-556.94 314.84,-902.21 -283.29,-83.34 51.27,-728.26 -341.79,-437.21 -658.59,-181.9 12.49,-696.51 -1413.14,-281.77 206.38,-676.67 -358.87,-1543.81 1198.87,-125.02 795.96,-705.76 228.08,97.89 887.32,-406.79 422.63,-629.04 -23.66,-971 1474.27,-2019.4 440.37,-1332.82 955.02,-1136.367 1777.94,1957.227 1906.75,-1438.65 1788.45,-943.889 853.8,382.317 62.45,500.712 440.37,83.35 540.94,-277.15 -591.55,947.19 -3.29,878.41 -747.32,830.77 13.8,406.8 828.83,622.42 -340.47,588.69 -666.48,3254.98 -32.86,5458.27 -1202.16,1176.05 1357.93,-56.22 166.95,1632.46 -871.55,2203.28 -57.18,2320.35 -450.23,574.14 890.6,1082.13 674.37,137.58 -1062.16,2126.56 -497.56,435.23 208.36,350.57 -820.94,-138.9 -322.72,691.21 -1333.61,41.67 -722.35,-216.29 -1084.51,408.11 -46.01,379.01 -400.93,-2778.08 552.11,-703.78 444.97,-1588.8 -52.58,-2222.47 -244.5,-1988.31 -723.67,339.32 -4482.62,-620.43 -2690.23,-95.91 -2193.99,-552.31 z',
		geocodigo: '2500700',
		microrregiao: Microrregiao.cajazeiras,
		name: 'São João do Rio do Peixe - PB',
	},
	{
		d: 'm 221811.19,225197.58 -785.44,-673.36 -553.42,-966.38 126.19,-1046.41 758.49,-912.13 -498.21,-2299.86 98.6,-236.8 238.59,29.11 203.75,-995.48 -387.79,-428.62 -81.5,-776.54 259.62,-454.41 h 670.42 l 851.17,-976.3 -95.96,-647.56 314.84,-302.28 -21.69,-654.84 999.71,-1983.02 432.49,-356.52 21.03,-502.03 1490.05,-1103.3 -36.81,-1057.66 432.49,-1427.4 615.21,-183.89 427.89,-492.78 55.87,-1155.54 -292.49,-722.97 811.07,-374.37 1530.8,-1721.76 631.64,-371.72 1722.07,-1956.57 17535.47,-1510.75 -534.36,782.5 453.51,623.74 -222.82,320.14 -974.73,-179.25 -1523.57,-658.8 -823.57,1557.05 -32.86,836.73 -342.44,393.56 v 5053.46 l -1518.31,860.55 -577.09,625.72 -47.98,283.77 513.99,93.92 227.42,812.92 742.06,56.22 607.99,687.91 -1.97,607.87 -739.44,565.54 -1996.81,379.01 -1963.94,1275.27 469.29,1028.55 -1543.94,49.61 -1047.03,-388.27 -214.28,399.52 723.66,792.41 -478.5,73.42 -115.02,302.95 -1348.08,38.36 -547.5,196.45 -243.2,1148.93 -584.97,350.57 -1014.18,-406.13 -951.07,-9.92 -1106.86,277.15 -615.21,795.06 -3561.78,1248.15 -1269.2,963.73 -1210.04,377.03 -891.93,963.72 -1065.45,1150.26 -1589.95,393.57 -200.47,281.77 23.67,878.4 -331.93,377.69 z',
		geocodigo: '2514107',
		microrregiao: Microrregiao.caririOcidental,
		name: 'São João do Tigre - PB',
	},
	{
		d: 'm 127534.13,130353.88 621.78,-4057.98 -529.76,-2464.56 -452.85,-45.06 -937.25,-1295.9 5496.21,-1277.3 2073.99,168.18 1196.51,1747.62 1206.76,-198.44 804.5,348.59 1262.63,-765.96 1192.3,23.81 1496.62,-560.24 2123,579.43 634.27,-9.27 276.71,-422.66 869.58,211.01 594.84,4344.39 -1446.67,888.32 -711.17,1330.17 -1855.49,393.56 -803.19,764.64 232.67,892.95 -1403.28,437.22 -66.39,285.74 -3081.31,-341.96 -3827.98,269.86 -1443.37,-119.72 -3523.66,-1129.75 z',
		geocodigo: '2514206',
		microrregiao: Microrregiao.sousa,
		name: 'São José da Lagoa Tapada - PB',
	},
	{
		d: 'm 115864.19,155771.33 464.04,-865.17 -429.2,-1077.5 67.04,-785.8 1781.87,-1631.79 238.59,-1432.03 650.71,-235.48 1164.03,-2049.83 606.01,150.15 141.32,-840.04 866.29,-1572.92 67.04,-646.23 606.01,-616.47 -79.53,-494.1 961.59,-603.24 -96.62,-678.65 260.94,-22.49 201.79,-409.43 1173.23,208.35 308.92,1342.08 63.1,1680.08 1185.07,286.4 84.13,2362.03 391.08,-58.87 -477.18,2139.12 391.74,341.31 1515.02,246.06 -118.97,308.89 -901.78,1072.22 -852.49,-3.31 -630.98,1136.36 -1655.68,468.97 -1254.74,1299.09 -523.2,-763.32 -352.3,153.46 -539.62,-361.81 -460.75,209.67 -246.48,-181.89 -1199.53,124.35 -3213.42,1725.05 -157.09,75.41 z',
		geocodigo: '2514305',
		microrregiao: Microrregiao.itaporanga,
		name: 'São José de Caiana - PB',
	},
	{
		d: 'm 178329.75,114337.58 574.45,-659.47 368.74,-963.06 -310.23,-675.34 -343.1,-377.69 732.86,-1193.91 1432.2,-681.96 2455.58,-173.96 5601.97,-935.95 698.03,191.82 3981.78,103.85 2057.27,-894.28 2215.02,359.83 -467.32,1393.67 742.72,814.91 1158.78,577.44 -356.24,1963.18 -311.55,608.53 1141.69,940.58 -233.34,510.64 375.31,809.61 19.06,916.76 1486.76,989.53 1074.64,1812.37 297.09,1074.19 224.79,3070.44 -515.31,238.78 -1886.38,-31.75 -751.92,321.47 -3140.46,154.78 -3458.59,879.06 -2203.85,255.32 -653.33,373.06 -709.2,3.3 -2746.11,-1422.77 -3662.34,-297.65 -866.29,-928.01 184.7,-1057.66 -682.25,-1014.66 -397.66,-168.01 111.08,-225.55 591.55,-128.32 -228.73,-407.45 -459.43,-423.99 -598.13,-1171.43 -640.84,-553.63 -36.81,-611.84 1253.43,-455.74 225.44,-313.52 -15.77,-2171.53 -901.13,-758.68 -1623.47,593.98 -803.19,-254.66 z',
		geocodigo: '2514404',
		microrregiao: Microrregiao.patos,
		name: 'São José de Espinharas - PB',
	},
	{
		d: 'm 97428.25,134215.41 450.235,-849.96 1703.002,782.49 437.093,-97.23 1353.33,663.43 1908.07,27.78 910.99,-1515.37 671.07,-574.8 400.94,-48.29 953.71,504.03 310.24,-92.61 963.56,-1358.61 663.19,-246.06 524.51,-731.56 814.37,-240.11 -116.34,-406.12 2361.59,-95.92 1342.16,-310.88 470.61,201.74 4322.25,-15.87 1796.33,105.83 -337.18,4006.39 2582.44,4587.81 24.32,2618 2133.52,-1089.4 1269.2,135.6 -758.5,1136.36 -76.9,854.6 -1173.24,-208.36 -201.78,410.1 -260.94,22.49 -1092.4,30.43 -128.82,-330.07 -665.82,-49.61 -2913.05,837.39 122.25,695.85 -157.74,251.35 -621.79,-35.06 -832.77,1543.82 -502.81,-662.77 -1462.44,537.76 -1091.08,-119.73 -256.34,-129.64 -333.24,-870.46 -4552.95,-40.35 36.81,-660.79 -310.24,-204.39 -2397.74,126.34 -677.65,177.27 -370.7,691.21 -5336.431,-37.7 -1306.665,54.24 -1892.298,399.51 348.357,-1033.18 443.661,-513.95 -601.408,-552.3 717.089,-409.44 -147.887,-1744.24 1100.28,-1574.25 416.713,-1293.12 -246.478,-501.38 -545.54,-390.26 -298.403,-702.46 -420,-249.36 519.906,-944.55 15.774,-468.3 z',
		geocodigo: '2514503',
		microrregiao: Microrregiao.cajazeiras,
		name: 'São José de Piranhas - PB',
	},
	{
		d: 'm 136411.3,188721.36 456.81,-1793.85 565.91,-611.17 -190.61,-470.29 293.8,-1368.54 -928.73,-136.26 200.47,-388.26 -253.05,-338.01 632.96,-1279.9 475.87,-1525.3 -550.14,-107.15 -448.92,-396.87 162.34,-447.8 1083.2,-899.57 -1622.16,-2648.43 768.35,-2017.42 1235.03,-1908.94 122.91,-74.09 767.69,255.99 348.36,550.32 -95.3,263.26 297.08,-54.9 224.14,326.09 979.34,320.8 256.34,697.17 -235.31,206.37 285.92,400.18 -126.86,371.07 -494.93,8.6 -372.02,-360.49 -449.57,2254.88 2377.36,1.32 -383.84,1526.62 -548.17,1110.57 262.25,617.79 -46.01,928.01 -354.93,1164.81 -953.71,704.45 -63.75,979.6 847.23,1506.78 -42.73,945.87 -427.23,1197.22 -24.31,1185.31 669.1,931.32 -103.19,285.09 -667.79,615.81 -2935.4,-683.28 -171.55,-160.07 -295.77,-436.55 122.25,-403.49 -629.67,-260.61 -20.37,-590.67 z',
		geocodigo: '2514552',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'São José de Princesa - PB',
	},
	{
		d: 'm 190257.33,142683.27 -26.94,-950.5 519.91,201.74 1768.07,-836.73 253.05,150.14 1171.92,-314.84 193.9,-731.57 422.63,-480.2 -33.53,-657.49 369.4,-199.75 696.71,-1224.35 -165.63,-267.22 144.6,-451.1 -515.31,-214.98 2441.13,-399.51 3108.25,2488.36 -169.98,6262.78 -4203.71,-480.78 -2162.87,1819.96 28.87,-494.03 -378.59,-747.44 -507.3,-752.2 -1391.57,-1599.91 -1558.4,-118.4 z',
		geocodigo: '2514602',
		microrregiao: Microrregiao.patos,
		name: 'São José do Bonfim - PB',
	},
	{
		d: 'm 183249.46,75636.259 1283.66,-787.123 550.14,-828.795 584.98,-203.064 971.45,-683.276 191.27,-710.395 872.86,-86.65 805.82,-466.982 769.67,46.301 1905.45,-1346.047 788.07,17.86 1598.5,-422.666 653.33,150.81 410.14,476.243 1896.9,864.512 615.21,-134.273 262.91,262.595 -17.75,-357.182 1342.16,64.16 281.31,348.583 1085.82,-62.176 339.16,175.945 -154.47,633.667 795.31,259.288 12.49,248.043 341.78,391.577 -1314.55,887.002 -1377,1840.148 -302.34,2164.919 -878.12,60.854 -1478.87,-291.038 -834.75,152.134 -678.96,1663.541 -448.26,367.103 -707.23,-175.945 -191.27,-481.534 -356.24,-58.207 -1397.37,-1146.951 -933.33,-67.467 -232.68,-307.574 -785.44,774.556 -1079.25,208.356 -292.49,-408.114 -1204.13,92.603 -464.04,110.462 -195.87,305.589 -410.14,-902.215 -1637.93,-724.947 -713.8,-795.061 -273.43,-1121.815 z',
		geocodigo: '2514651',
		microrregiao: Microrregiao.catoleDoRocha,
		name: 'São José do Brejo do Cruz - PB',
	},
	{
		d: 'm 224848.47,113043.12 5431.73,-1899.67 4511.54,4080.47 236.62,3765.62 2842.72,-171.31 -1071.36,3141.21 -898.49,1446.59 -187.33,1105.28 -1072.02,29.1 -68.35,-443.17 -663.2,-446.48 26.95,-464.33 -535.68,-127.66 -284.6,-593.98 -1287.6,-328.08 -367.42,-499.39 -719.06,634.32 -468.64,-78.71 -78.21,-985.55 -473.9,284.42 -1640.56,-1211.12 368.73,-654.83 -43.38,-735.52 -562.63,-483.53 -2043.47,-1214.41 -197.18,-776.54 189.29,-820.86 -224.13,-172.64 -335.21,-1893.06 -386.48,-488.15 z',
		geocodigo: '2514701',
		microrregiao: Microrregiao.seridoOcidental,
		name: 'São José do Sabugi - PB',
	},
	{
		d: 'm 217351.57,160158.05 2430.61,-1066.25 1973.8,-462.35 1495.96,-91.28 1440.76,420.68 1103.56,-306.91 916.24,84.67 559.35,-554.96 828.82,-147.5 339.81,-489.47 1708.26,285.08 2291.27,-117.08 2239.99,110.47 2536.3,-1650.56 2233.52,2078.88 2438.53,290.04 -450.23,480.18 -833.43,-86.65 -1157.46,427.29 -591.55,662.77 -851.83,385.63 79.53,289.72 -588.92,806.3 -179.44,2225.77 -6996.7,2399.07 -1434.17,1051.04 -2143.38,-506.01 -1273.8,352.56 -39.44,240.1 -1006.95,431.93 -583.01,-260.61 -957.64,154.78 -947.8,-348.58 -759.8,-1136.37 -1700.38,-235.48 -188.64,-505.35 -621.78,-188.51 99.91,-574.79 -233.34,-386.95 -971.45,-420.68 -178.13,-631.03 -755.86,-810.93 659.9,-253.33 -220.84,-422.01 -608.64,-155.44 895.21,-1365.23 z',
		geocodigo: '2514800',
		microrregiao: Microrregiao.caririOcidental,
		name: 'São José dos Cordeiros - PB',
	},
	{
		d: 'm 330573.31,149319.57 1043.1,-599.94 287.89,236.81 895.2,-745.46 1016.15,231.51 330.61,-265.24 1790.42,-5.29 -638.87,-2209.89 2527.89,-1068.91 901.78,-527.83 247.79,-523.2 736.81,404.8 1085.82,1420.79 293.8,766.61 -90.05,886.34 222.17,-68.79 471.92,578.11 372.68,971 287.23,1326.87 -556.06,1622.53 -427.89,175.94 -816.99,-472.27 -369.39,44.98 -366.76,491.46 -2259.71,-2667.62 -1084.51,747.44 -936.62,-930 -273.43,541.06 -2022.44,679.97 -330.61,526.51 -2335.96,-1570.28 z',
		geocodigo: '2514453',
		microrregiao: Microrregiao.sape,
		name: 'São José dos Ramos - PB',
	},
	{
		d: 'm 201348.21,117962.31 3180.56,52.91 2784.23,-1967.14 976.05,-890.97 2137.46,1025.25 111.08,558.92 960.28,-3.31 1004.98,-366.44 -306.95,756.69 1656.99,2123.25 441.69,74.09 671.74,-388.94 626.38,431.93 345.08,1057.66 818.96,129.64 -73.61,979.6 874.83,52.26 -100.56,408.11 -1125.26,882.37 -533.05,905.52 3371.17,2303.83 962.25,-230.18 861.69,86.64 -567.88,1492.89 59.15,795.06 1563,-511.96 546.86,197.11 -160.38,1555.07 698.03,755.37 -134.09,1182.67 -1909.39,1717.12 184.7,1113.22 -1179.81,675.99 -1283.66,318.16 -290.51,-30.43 -564.61,-685.26 -101.88,-27.11 -3812.85,-1011.36 246.47,-956.46 -863.66,-330.06 -341.78,-459.04 -1060.84,708.41 -820.29,24.48 -1205.44,-816.23 -834.74,-166.69 -246.48,-567.52 -770.99,-31.09 -857.08,-789.77 -1478.88,-654.83 -95.96,-648.22 -481.12,-627.05 553.42,-1475.69 -733.51,-604.57 -737.47,-76.72 909.67,-630.36 164.98,-586.05 -845.91,-63.49 -565.92,-826.15 -1031.92,9.92 -224.14,-3071.11 -297.74,-1073.53 -1074.65,-1813.02 z',
		geocodigo: '2514909',
		microrregiao: Microrregiao.seridoOcidental,
		name: 'São Mamede - PB',
	},
	{
		d: 'm 346286.82,145880.71 186,-1155.55 769.67,-58.87 1536.06,-423.99 804.5,-579.43 60.47,439.87 310.24,15.87 1681.97,1898.35 262.91,-193.8 303.66,449.78 -1450.61,3714.03 -30.23,621.1 -747.32,1686.03 -724.98,189.18 -106.48,926.69 -1319.81,-513.95 -402.25,42.99 -224.14,-334.03 -297.09,-499.39 -1308.63,-3894.61 287.23,-1111.23 439.06,-542.39 -28.92,-676.65 z',
		geocodigo: '2515005',
		microrregiao: Microrregiao.sape,
		name: 'São Miguel de Taipu - PB',
	},
	{
		d: 'm 298238.61,136879.72 1104.88,-2272.73 678.97,373.05 552.11,-70.11 616.52,-476.91 1524.88,676.67 267.51,587.36 981.32,627.06 403.57,1376.47 -125.54,507.33 -850.52,1414.18 -331.26,302.28 -694.74,-148.17 -182.73,-579.43 -265.54,321.47 -480.47,-251.35 -823.56,216.29 -1854.18,-2293.24 -517.93,-310.22 z',
		geocodigo: '2515104',
		microrregiao: Microrregiao.esperanca,
		name: 'São Sebastião de Lagoa de Roça - PB',
	},
	{
		d: 'm 207920.32,212615.51 2696.81,270.53 1031.92,-519.89 599.43,-694.53 1093.71,-278.47 540.94,-438.54 898.5,-97.89 419.34,267.22 1216.62,-223.57 372.67,-380.99 -529.76,-969.68 -267.51,-859.88 121.59,-615.82 -561.97,-1260.71 958.96,-183.88 2650.8,890.96 782.16,-12.56 623.76,510.64 1651.73,-920.08 918.87,-261.27 613.9,-539.08 479.15,-568.19 556.06,-119.71 402.25,-606.55 859.06,-381 1003.01,246.06 292.49,723.63 -55.22,1155.55 -427.88,492.78 -615.22,183.88 -432.49,1427.4 36.16,1057.66 -1490.05,1102.63 -20.37,502.7 -432.49,356.52 -1000.37,1983.02 22.34,654.17 -314.83,302.28 95.3,648.22 -850.52,976.3 h -671.08 l -258.96,454.41 80.85,775.89 388.44,429.27 -203.75,994.82 -238.59,-28.44 -98.59,236.8 497.55,2299.19 -758.49,912.14 -125.54,1046.41 553.43,966.37 785.44,674.02 -393.05,216.29 -1478.22,-137.58 -963.56,401.5 -2134.18,-968.36 -1817.36,-2038.58 -3081.97,-452.43 -561.32,-1090.07 -1947.5,-2291.91 -165.64,-740.17 168.92,-1784.58 -258.31,-52.92 -1066.76,259.29 -1378.31,-497.41 -245.16,-338 14.46,-515.26 898.49,-630.37 -550.13,-814.24 389.1,-830.78 657.28,-180.57 21.69,-95.91 z',
		geocodigo: '2515203',
		microrregiao: Microrregiao.caririOcidental,
		name: 'São Sebastião do Umbuzeiro - PB',
	},
	{
		d: 'm 249619.24,121616.81 82.81,-598.61 -486.38,-614.49 268.16,-740.82 698.03,-526.51 1194.28,97.89 381.87,125.02 268.83,-482.2 341.78,308.9 201.78,-268.54 577.75,191.81 95.31,394.89 365.44,120.38 70.33,-188.51 521.22,367.76 1114.74,-972.99 471.93,220.92 76.9,457.73 395.67,-50.94 4.6,260.62 518.6,-205.05 163.66,209.67 143.29,-164.69 1219.9,156.76 653.33,-380.99 398.97,384.3 466.01,-11.25 153.8,-423.98 327.98,505.34 314.83,-35.06 535.69,412.75 217.56,-198.44 -36.15,-431.92 379.9,305.59 645.45,-263.92 1222.53,54.24 329.3,566.85 446.94,401.51 -107.79,457.72 -1213.33,1402.93 1427.6,1960.53 2247.23,1269.32 410.14,596.63 -610.61,619.11 -1229.1,621.1 -3075.4,1995.59 -5988.44,791.75 337.18,-3168.33 -6225.06,-4488.59 -719.06,-1044.42 z',
		geocodigo: '2515401',
		microrregiao: Microrregiao.seridoOriental,
		name: 'São Vicente do Seridó - PB',
	},
	{
		d: 'm 342312.92,138678.2 639.53,-279.79 1742.44,-2672.91 101.88,-745.46 378.59,-507.32 -790.04,-1134.39 230.04,-649.54 11.18,-2369.97 212.95,-392.24 -776.9,-1783.92 77.56,-649.55 431.17,-182.55 1270.52,97.23 1206.75,-500.72 208.36,-345.28 882.73,-128.32 1670.79,1022.6 377.28,-307.57 13.8,155.44 1724.04,-902.21 419.34,156.76 1204.13,-128.32 -343.75,293.02 -179.45,1329.51 -466,739.5 564.59,761.33 255.69,1701.24 524.51,203.06 -2433.9,1631.13 3119.43,2804.54 -1798.3,5120.93 -531.08,289.72 -258.97,555.61 -1354.65,852.61 -655.3,855.91 -410.14,95.91 -345.07,-508.65 -351.64,281.78 -299.07,-183.88 -640.84,209.67 -134.74,-711.05 -348.36,57.54 -65.06,-400.83 -779.54,123.02 46.67,-763.97 -402.91,-203.06 43.38,-497.41 -394.37,-269.21 -122.91,-414.06 259.63,-579.44 -155.78,-263.91 -881.4,193.8 -691.46,500.72 -68.36,686.58 -691.45,-410.1 -289.86,-722.96 140,-483.52 -249.76,173.3 -672.4,-219.6 86.11,-423.99 -260.94,-152.13 z',
		geocodigo: '2515302',
		microrregiao: Microrregiao.sape,
		name: 'Sapé - PB',
	},
	{
		d: 'm 228888.75,167176.02 1434.83,-1051.04 6996.05,-2398.41 180.09,-2226.44 588.26,-806.3 -79.53,-289.71 852.49,-385.63 591.55,-662.77 1156.8,-427.3 833.43,86.66 450.25,-480.22 751.94,584.69 2247.2,263.95 7420.64,9829.78 -5035.39,3952.15 -8183.43,759.54 1191.64,5983.34 2003.08,653.4 -2774.38,957.14 -381.87,-110.45 -1154.18,437.21 -95.31,283.1 -1102.25,665.42 -554.74,-1.33 -809.77,1044.43 -2370.79,-4020.94 79.53,-843.34 924.13,-377.69 -33.52,-595.31 -1942.91,-183.88 -337.18,-1963.84 -610.61,-761.32 -1178.5,-1101.31 1081.22,-996.81 -1027.32,-1531.25 730.23,-1571.6 -5214.17,-244.73 3374.46,-2473.82 z',
		geocodigo: '2515500',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Serra Branca - PB',
	},
	{
		d: 'm 328738.2,111845.9 -1678.69,-601.25 2292.58,-1992.95 1453.24,-2096.12 1726.66,-654.18 493.62,511.96 -366.11,1099.33 -933.32,1310.33 -395.69,352.55 -183.37,810.94 -891.93,288.39 -1516.99,969.68 z',
		geocodigo: '2515609',
		microrregiao: Microrregiao.guarabira,
		name: 'Serra da Raiz - PB',
	},
	{
		d: 'm 115964.1,153042.86 -570.52,-92.6 -1.97,-1094.7 291.17,-838.05 -152.49,-582.74 216.25,-615.8 266.85,-566.21 695.4,-395.54 -607.98,-760 431.17,-1205.17 -40.09,-1023.91 86.1,-533.13 833.43,-1543.82 621.78,35.06 157.09,-251.35 -122.25,-695.85 2913.05,-837.39 666.47,49.61 128.83,330.07 1092.39,-30.43 96.62,677.98 -962.25,603.9 79.53,494.1 -605.35,615.82 -67.04,646.89 -866.29,1572.92 -141.98,840.04 -605.35,-150.15 -1164.03,2049.82 -650.71,234.82 -238.59,1432.7 -1782.53,1631.79 z',
		geocodigo: '2515708',
		microrregiao: Microrregiao.itaporanga,
		name: 'Serra Grande - PB',
	},
	{
		d: 'm 312576.43,146682.38 251.09,-1096.02 -1062.16,-1396.98 -109.11,-1259.39 1017.46,-510.64 136.71,-293.02 515.31,-2.65 551.45,-546.36 599.44,-101.86 860.37,-588.68 527.8,50.93 383.19,392.23 905.07,-880.39 783.47,-31.74 278.68,1524.64 710.52,528.49 207.04,556.28 -1091.08,741.48 -2819.05,875.1 -707.89,1233.6 -1941.6,804.98 z',
		geocodigo: '2515807',
		microrregiao: Microrregiao.campinaGrande,
		name: 'Serra Redonda - PB',
	},
	{
		d: 'm 313424.19,120516.19 -1273.25,-1541.61 284.23,-1745.73 1514.97,937.85 795.3,-1180.69 2299.16,-766.61 606.01,463.67 2041.49,312.2 1847.61,-356.52 -410.14,1156.88 -540.28,1234.92 -2180.19,934.62 -1635.96,160.07 -1337.59,1014.67 z',
		geocodigo: '2515906',
		microrregiao: Microrregiao.brejo,
		name: 'Serraria - PB',
	},
	{
		d: 'm 328738.2,111845.9 1516.99,-969.68 891.93,-287.73 183.37,-811.6 395.69,-351.89 604.03,473.6 326.66,812.92 625.73,539.08 1553.81,286.41 997.08,496.08 357.56,350.57 -154.46,605.23 -2494.36,689.22 -276.71,-121.7 -216.25,-383.64 -726.95,-67.47 -548.16,-356.52 -733.53,-93.26 -2303.74,-806.31 z',
		geocodigo: '2515930',
		microrregiao: Microrregiao.guarabira,
		name: 'Sertãozinho - PB',
	},
	{
		d: 'm 338975.27,143843.44 87.42,-351.88 3703.76,972.98 1628.06,-1850.06 -17.74,-1129.76 151.18,-439.86 -278.04,-130.96 68.36,-686.59 691.46,-500.72 881.4,-193.8 155.78,263.92 -259.63,579.43 122.91,414.06 394.37,269.21 -43.38,497.41 402.91,203.72 -46.67,762.66 779.54,-123.03 65.06,401.5 348.36,-57.55 134.74,710.4 640.84,-209.02 299.07,183.88 351.64,-281.78 345.07,508.65 -805.16,579.44 -1535.4,423.98 -769.67,59.53 -186.67,1155.55 -396.99,361.15 -1060.85,156.76 -193.89,-166.02 -960.29,233.49 -410.14,-312.2 -588.25,398.19 -313.53,-209.68 -268.82,261.27 -1004.98,-166.68 -293.8,-766.62 -1086.48,-1420.79 -736.15,-404.8 z',
		geocodigo: '2515971',
		microrregiao: Microrregiao.sape,
		name: 'Sobrado - PB',
	},
	{
		d: 'm 306177.85,117928.58 -1265.92,-2690.11 -418.02,-1287.84 1156.15,-957.11 -268.83,-251.35 236.62,-255.98 454.84,-128.33 9.86,-1402.93 575.77,-1714.47 -166.95,-277.81 209.67,-243.41 -79.53,-475.58 1482.16,-21.83 799.9,-363.8 690.39,-659.39 1536.47,-432.87 697.42,-1143.21 1828.49,293.46 -386.48,789.77 136.72,672.69 693.42,670.05 324.04,1109.25 -255.69,1702.57 523.2,978.94 1480.18,1328.19 335.87,1171.42 535.68,1875.87 -2299.15,765.95 -795.31,1181.35 -1513.65,-933.22 -519.96,423.9 -813.05,130.31 -55.21,211 -713.8,-574.79 -1091.08,-238.13 -579.06,825.49 -2488.44,-83.35 z',
		geocodigo: '2516003',
		microrregiao: Microrregiao.curimatauOriental,
		name: 'Solânea - PB',
	},
	{
		d: 'm 255529.46,144581.62 -126.85,-2250.25 318.12,-1049.71 987.23,-860.54 -1337.56,-7701.9 882.72,-1947.97 -28.26,-452.43 5987.79,-792.41 3075.39,-1995.59 5068.26,9891.29 3472.39,971.01 146.57,752.06 444.98,462.35 168.92,968.36 -253.05,170 477.18,1286.51 -157.75,481.54 259.63,31.74 -207.05,390.92 193.9,756.7 -114.36,763.31 -305.64,667.4 147.89,233.49 -7500.18,-865.84 -5789.95,2152.35 -903.09,-1373.16 -4908.54,-691.21 z',
		geocodigo: '2516102',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Soledade - PB',
	},
	{
		d: 'm 271812.16,119483.64 168.92,-2627.27 584.98,-9100.86 577.09,-3112.11 593.52,-11.25 1971.83,1740.93 1555.11,627.72 2092.11,459.04 582.35,-224.23 698.02,171.32 2394.47,-852.61 -11218.4,12928 z',
		geocodigo: '2516151',
		microrregiao: Microrregiao.curimatauOcidental,
		name: 'Sossêgo - PB',
	},
	{
		d: 'm 120316.58,114121.28 -1357.93,56.23 1202.15,-1176.06 32.87,-5458.27 666.48,-3254.98 340.47,-588.69 -828.83,-623.08 -13.8,-406.13 1060.18,224.23 1760.19,-334.69 812.39,1004.73 753.9,221.59 639.53,1169.44 876.15,-1310.33 556.71,-550.98 57.18,-527.84 966.2,-656.16 253.05,-478.22 4754.74,28.44 1227.13,1757.47 1299.44,-1234.27 1330.98,-591.99 554.74,-161.39 863.66,683.27 319.44,1896.37 284.6,39.69 -211.64,1031.86 682.25,1544.48 -155.78,401.5 227.38,1261.32 324.74,-25.74 -324.74,25.74 -694.46,2366.89 -2729.96,2278.65 44.02,1333.67 493.5,2956.17 972.98,3065.58 -844.63,358.91 -240.56,943.89 -303,72.1 -970.14,1658.25 -1191.91,-1741.67 -2073.99,-168.18 -5496.21,1277.3 937.25,1295.9 -1248.18,443.91 -375.97,-316.83 -755.21,231.51 -1505.82,-812.93 -884.03,-1672.14 561.31,-2395.1 -72.96,-1054.34 1998.78,-4423.11 -4582.53,329.4 z',
		geocodigo: '2516201',
		microrregiao: Microrregiao.sousa,
		name: 'Sousa - PB',
	},
	{
		d: 'm 212110.46,174167.52 730.89,281.78 450.23,716.35 1998.78,40.35 3860.84,1488.26 446.94,-502.71 23.67,-259.28 -1237,-1009.37 50.61,-1542.5 -257.65,-394.22 214.93,-341.97 -228.73,-521.89 1729.95,-1809.72 -2434.55,-2823.06 -801.22,-159.4 -141.97,-388.93 1056.24,-531.81 527.79,496.75 1376.99,-1039.13 1699.72,235.47 760.47,1136.37 947.14,348.58 957.65,-155.44 583.66,261.27 1006.29,-431.93 39.44,-240.1 1273.8,-351.89 2143.38,505.35 -3373.8,2473.81 5214.17,244.74 -730.23,1571.6 1027.32,1531.25 -1081.22,996.8 1178.49,1101.31 609.96,761.33 337.18,1963.84 1943.56,183.88 32.87,595.3 -923.48,377.69 -79.53,843.35 2370.8,4020.94 2761.87,3426.3 -584.97,939.26 -581.69,316.83 -333.9,705.77 -442.35,-182.57 -450.23,232.17 -117.65,1073.53 -648.73,-68.79 -1815.4,934.63 -646.1,-140.89 -879.44,209.68 -1316.52,855.91 367.42,-2326.31 -9616.61,1481.65 -3711.64,-5835.96 -888.64,-2417.6 190.61,-66.14 -209.67,-6037.03 -746.01,119.72 -782.16,-792.42 -497.55,22.49 -1281.7,-947.19 -372.67,49.61 -700.65,-1228.98 z',
		geocodigo: '2516300',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Sumé - PB',
	},
	{
		d: 'm 315837.84,93712.97 1205.44,-2729.135 1195.59,226.216 244.5,374.379 1210.71,-145.518 580.37,802.998 32.87,403.483 747.98,630.36 61.78,95.91 1183.1,25.796 -43.38,313.526 418.68,73.421 519.91,-174.622 372.01,-620.438 429.21,80.035 203.75,-222.908 312.87,227.538 471.26,-271.855 133.43,-233.491 546.85,236.798 391.08,-183.882 792.02,71.436 406.85,398.854 274.75,-232.169 569.85,433.249 505.45,59.53 291.17,-269.21 644.13,-33.072 290.52,-416.051 978.03,718.333 291.17,-542.388 76.25,606.548 71.64,934.626 810.42,449.123 251.74,528.497 -251.74,1055.671 -409.48,338 290.51,356.521 -310.23,757.357 -20.38,875.096 -1004.97,775.878 402.25,285.085 -7.89,251.345 -313.52,1854.7 -416.06,612.51 -917.55,-83.35 -443.66,387.61 -627.04,17.86 -552.77,350.57 -377.28,1025.24 -645.45,-203.06 -19.06,886.34 -348.35,228.86 -1270.52,-248.04 32.21,-2614.71 -251.74,-248.04 -562.63,-244.74 -1079.9,-1863.956 -337.19,-304.267 -659.9,-103.847 -201.78,-468.966 3.29,-437.217 507.41,-392.239 205.07,-497.409 -1555.11,-1500.826 -3595.31,-1825.596 -1733.23,-631.021 z',
		geocodigo: '2516409',
		microrregiao: Microrregiao.curimatauOriental,
		name: 'Tacima - PB',
	},
	{
		d: 'm 217260.21,149009.35 -1432.2,-4433.02 1120.66,40.35 1246.85,-1072.21 674.36,320.14 1156.15,-1432.03 901.13,-707.75 690.14,-134.28 741.41,208.36 952.39,-123.69 19.06,246.72 834.74,130.96 1047.7,-235.47 744.03,593.98 1059.53,-60.85 1553.15,-736.19 613.89,97.89 410.14,-296.33 803.19,-95.91 1687.23,381 2291.27,-2763.53 1885.73,699.15 1766.75,-545.7 -101.87,699.15 1246.84,1285.2 978.03,-486.83 331.93,-654.17 1390.14,-161.39 985.25,228.86 -70.98,259.29 246.48,1166.13 -181.41,2048.5 349.67,857.9 -220.85,521.88 -1094.36,1172.75 404.23,-5.29 -23.66,376.36 -753.9,1606.65 -2073.92,2542.98 -1493,-609.91 -1484.91,3022.51 -531.07,64.15 -1231.08,-355.2 -1782.74,782.69 -350.99,1422.99 -2329.68,-282.94 -10617.47,-3371.85 -2352.72,-2208.69 z',
		geocodigo: '2516508',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Taperoá - PB',
	},
	{
		d: 'm 147198.52,170064.56 594.83,-103.84 6.58,-416.06 -412.12,-502.7 1864.04,-1772.68 1037.83,-564.88 1332.96,-1309 1662.91,4442.29 249.77,148.16 -358.88,750.74 163.67,942.57 2429.28,-23.81 171.56,332.7 -272.12,1000.11 1310.61,-12.57 181.41,462.36 268.82,-27.12 1745.74,844.66 333.89,656.16 712.48,340.65 360.85,-64.16 -28.26,359.16 1242.91,43 496.24,756.7 1159.43,596.62 845.92,173.3 561.31,561.57 -14.46,1164.81 -963.56,297.65 -190.61,416.05 -917.56,-902.21 -93.99,-93.27 43.38,-558.26 -581.03,-150.81 -17.09,-867.16 -442.35,-571.49 -740.75,77.39 -1105.54,1166.13 -2606.1,227.54 -1052.3,476.91 -1599.81,2188.72 -1213.33,521.89 -429.2,526.51 -607.32,-291.04 -351.65,-806.96 -1267.23,-738.84 -263.56,-683.27 -558.69,-382.99 119.62,-500.71 -246.47,-379.01 -682.25,-544.37 1.31,-737.51 251.73,-345.28 -128.82,-283.76 -122.91,-2555.18 1690.51,-987.53 253.06,-739.51 -763.1,-372.39 -987.89,207.69 -343.09,-466.98 -746.01,124.36 -572.49,-517.92 -456.15,-66.14 -71.65,-634.99 120.94,164.69 z',
		geocodigo: '2516607',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Tavares - PB',
	},
	{
		d: 'm 194119.49,147366.98 -50.56,-969.75 2162.78,-1819.96 4203.81,480.78 2576.52,511.54 2459.17,1831.39 2305.73,1294.8 -101.78,1254.15 -217.56,701.14 -1935.02,2643.14 -1570.9,-1111.89 -1124.59,-219.6 -1359.25,-1177.38 -2011.92,-70.11 -1108.17,66.8 -879.44,947.19 -1869.94,86.66 -130.15,-697.84 707.89,-1275.26 -246.48,-619.12 352.96,-134.27 -827.51,-738.18 -506.76,14.55 -133.43,-549 -696.71,-451.11 z',
		geocodigo: '2516706',
		microrregiao: Microrregiao.serraDoTeixeira,
		name: 'Teixeira - PB',
	},
	{
		d: 'm 241034.55,131434.02 -628.35,-596.62 -479.81,-1117.19 405.53,-683.27 727.61,-534.46 1041.78,190.5 332.58,-168.01 394.37,-586.04 128.82,-1961.85 345.08,-436.56 1284.31,377.69 1514.37,14.55 487.69,204.38 3030.71,-4520.33 719.06,1045.09 258.96,1204.5 -505.45,694.51 -717.08,357.19 1.32,1054.35 -258.97,275.16 335.21,1705.87 -118.97,1118.51 -1773.33,152.14 -228.73,580.75 -1112.77,259.28 -534.37,427.3 274.75,377.68 -871.55,476.91 -343.1,849.96 -1321.12,-748.76 -593.53,310.22 -1033.9,72.1 z',
		geocodigo: '2516755',
		microrregiao: Microrregiao.seridoOriental,
		name: 'Tenório - PB',
	},
	{
		d: 'm 98928.155,103604.92 177.464,-846.65 1267.891,-830.12 -273.43,-1067.57 -719.062,-636.98 707.232,-871.787 34.18,-492.778 504.13,-325.432 -69.02,-366.442 1213.33,-1467.092 198.5,-241.429 1085.16,-747.436 1505.82,-385.624 2529.2,-197.111 -51.92,-1399.624 1717.46,97.232 705.92,1705.875 -1672.77,395.546 -490.33,-492.779 54.55,487.487 -268.82,52.916 302.35,1925.475 -642.82,63.499 166.95,1749.529 -327.33,356.525 -157.74,846.65 658.59,289.05 -195.87,302.95 809.76,1461.13 97.94,1281.89 935.3,-27.78 879.44,1368.53 504.13,86.65 49.29,564.22 -227.41,-97.23 -796.62,705.76 -1198.87,124.35 359.53,1544.49 -207.05,676.66 -1718.77,-374.38 -818.97,533.12 -1580.75,-168 -459.43,-992.18 -1227.14,-1454.52 -452.2,-68.79 -205.07,-765.3 -2707.984,-2305.8 z',
		geocodigo: '2516805',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Triunfo - PB',
	},
	{
		d: 'm 109773.87,96336.934 2283.38,261.933 691.45,-399.515 434.46,-652.848 141.32,-1037.151 -287.89,-4948.954 -292.49,-1714.473 -761.13,35.719 -145.25,-848.638 364.13,-275.163 1043.09,-253.995 249.77,760.003 1587.32,783.816 1806.2,-302.282 987.23,713.703 232.01,260.61 166.95,635.652 493.62,386.947 104.5,1341.416 709.2,-155.44 210.33,606.548 715.12,-361.813 582.35,97.895 270.14,128.982 707.23,-7.275 817.65,1001.432 352.95,1073.53 1400.66,65.483 385.16,1181.346 381.22,1259.397 -1593.23,349.906 427.88,2331.604 -190.61,789.108 -1196.24,535.772 -1150.89,32.411 -540.94,277.15 -440.37,-83.35 -61.79,-501.373 -853.8,-382.317 -1789.1,944.55 -1906.76,1438.65 -1777.28,-1957.228 -1569.57,-432.587 -2518.69,265.903 -467.32,-3246.387 z',
		geocodigo: '2516904',
		microrregiao: Microrregiao.cajazeiras,
		name: 'Uiraúna - PB',
	},
	{
		d: 'm 302695.6,178904.15 1277.09,138.91 284.6,-207.7 -52.58,-603.24 306.95,-632.34 466,246.71 176.15,617.8 216.24,49.61 1418.41,-892.95 214.93,-544.38 675.68,64.16 322.72,830.12 850.52,261.27 947.79,-403.48 1836.42,-313.53 1992.87,-91.28 503.47,-304.27 127.51,-676.65 349.68,-350.57 888.69,-204.39 2027.64,519.68 -97.78,746.81 881.91,1981.88 -685.54,958.44 -588.26,542.39 -1181.79,782.49 -311.54,134.27 -1696.43,197.12 -922.16,-21.17 -119.62,-422.01 -341.79,-154.11 -588.26,400.17 -293.8,550.33 -424.61,212.98 -1319.8,129.65 -488.36,304.27 -225.45,516.58 -404.22,205.05 -739.43,70.12 -1342.16,998.78 -677,153.46 -1873.24,516.59 -32.2,-463.67 -651.36,58.86 -283.29,-275.16 63.76,-1284.53 -396.34,-758.68 413.42,-1461.14 -111.07,-646.24 -537.66,-359.82 273.43,-693.2 -244.5,73.42 -140,-218.94 258.96,-275.16 z',
		geocodigo: '2517001',
		microrregiao: Microrregiao.umbuzeiro,
		name: 'Umbuzeiro - PB',
	},
	{
		d: 'm 207313,116048.08 1668.16,-2334.25 661.22,70.12 3232.49,-958.44 1158.12,-343.95 1313.24,-681.96 508.73,-400.17 2319.52,-1822.29 889.3,-30.43 891.92,926.69 421.32,1369.2 -107.8,1242.2 266.86,849.3 364.13,342.62 862.34,80.04 -466,593.32 -523.2,542.39 254.37,693.2 -82.82,521.88 625.07,772.57 -3992.95,3751.07 -19.06,358.5 -874.83,-52.91 73.61,-979.61 -818.96,-129.64 -345.08,-1057.66 -626.38,-431.92 -671.74,388.93 -441.69,-73.42 -1656.99,-2123.91 306.95,-756.69 -1004.98,366.44 -960.28,3.31 -111.08,-558.27 -2137.46,-1025.9 -976.05,891.63 z',
		geocodigo: '2517100',
		microrregiao: Microrregiao.seridoOcidental,
		name: 'Várzea - PB',
	},
	{
		d: 'm 120357.99,102670.3 747.32,-830.12 3.29,-879.06 591.55,-947.2 1150.89,-31.746 1195.58,-535.773 190.61,-789.107 -427.23,-2331.605 1593.24,-350.567 -381.88,-1259.397 2331.36,-1632.454 406.85,182.56 845.92,-394.884 739.43,224.23 128.83,21.829 2302.44,5171.861 -1213.33,68.791 -893.24,1807.072 -1576.15,1267.34 -253.05,478.22 -966.2,656.16 -57.18,527.84 -556.71,550.98 -876.15,1310.33 -639.53,-1169.44 -753.9,-221.59 -812.39,-1004.73 -1760.19,334.69 z',
		geocodigo: '2517209',
		microrregiao: Microrregiao.sousa,
		name: 'Vieirópolis - PB',
	},
	{
		d: 'm 172085.62,110493.9 4162.53,-111.78 4536.52,-595.97 -1432.2,681.96 -732.86,1193.91 343.1,377.69 310.23,675.34 -368.74,963.06 -574.45,659.47 -266.86,940.58 146.58,922.05 -421.98,5.96 -1157.46,871.12 -19.06,-411.42 -1171.93,-162.05 -510.04,-494.1 -402.91,-4929.77 -1735.87,74.74 -703.29,-662.11 z',
		geocodigo: '2505501',
		microrregiao: Microrregiao.sousa,
		name: 'Vista Serrana - PB',
	},
	{
		d: 'm 207506.89,205332.97 1401.32,60.85 476.52,1198.54 850.52,-12.56 480.47,537.09 887.32,200.42 982.63,-359.83 786.75,-898.24 2183.48,754.05 561.31,1260.71 -120.94,615.82 267.51,859.88 529.11,969.68 -372.02,380.99 -1215.96,223.57 -420,-267.22 -898.5,97.89 -540.93,438.54 -1093.71,278.47 -599.43,694.53 -1031.93,519.89 -2696.8,-270.53 -286.58,-1718.44 297.75,-883.7 -951.08,-1707.19 -88.73,-969.69 701.31,-1174.73 -84.79,-828.79 z',
		geocodigo: '2517407',
		microrregiao: Microrregiao.caririOcidental,
		name: 'Zabelê - PB',
	},
];

const defaultRanges = [800, 1000, 1100, 1200, 9600];

export const microrregioesComRanges = [
	{ microrregiao: Microrregiao.serraDoTeixeira, ranges: defaultRanges },
	{ microrregiao: Microrregiao.pianco, ranges: defaultRanges },
	{ microrregiao: Microrregiao.brejo, ranges: defaultRanges },
	{ microrregiao: Microrregiao.guarabira, ranges: defaultRanges },
	{ microrregiao: Microrregiao.caririOriental, ranges: defaultRanges },
	{ microrregiao: Microrregiao.curimatauOcidental, ranges: defaultRanges },
	{ microrregiao: Microrregiao.litoralSul, ranges: defaultRanges },
	{ microrregiao: Microrregiao.caririOcidental, ranges: defaultRanges },
	{ microrregiao: Microrregiao.sousa, ranges: defaultRanges },
	{ microrregiao: Microrregiao.curimatauOriental, ranges: defaultRanges },
	{ microrregiao: Microrregiao.patos, ranges: defaultRanges },
	{ microrregiao: Microrregiao.esperanca, ranges: defaultRanges },
	{ microrregiao: Microrregiao.umbuzeiro, ranges: defaultRanges },
	{ microrregiao: Microrregiao.litoralNorte, ranges: defaultRanges },
	{ microrregiao: Microrregiao.seridoOriental, ranges: defaultRanges },
	{ microrregiao: Microrregiao.joaoPessoa, ranges: defaultRanges },
	{ microrregiao: Microrregiao.catoleDoRocha, ranges: defaultRanges },
	{ microrregiao: Microrregiao.cajazeiras, ranges: defaultRanges },
	{ microrregiao: Microrregiao.itaporanga, ranges: defaultRanges },
	{ microrregiao: Microrregiao.campinaGrande, ranges: defaultRanges },
	{ microrregiao: Microrregiao.itabaiana, ranges: defaultRanges },
	{ microrregiao: Microrregiao.sape, ranges: defaultRanges },
	{ microrregiao: Microrregiao.seridoOcidental, ranges: defaultRanges },
];

Microrregiao.seridoOcidental;
