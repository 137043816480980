<div
	*ngIf="loading"
	style="height: 400px; width: 100%"
	class="d-flex align-items-center justify-content-center">
	<div
		class="spinner-border text-primary"
		style="width: 4rem; height: 4rem"
		role="status">
		<span class="visually-hidden">Carregando tabela...</span>
	</div>
</div>

<table
	#tabela
	datatable
	id="tabela{{ scrollX ? '_scrollX' : '' }}"
	[dtOptions]="dtOptions"
	[dtTrigger]="dtTrigger"
	[class]="class"
	[class.invisible]="loading"
	style="width: 100%">
	<thead>
		<tr *ngIf="header && !hasComplexHeader">
			<th colspan="29" class="p-0" style="border-radius: 10px 10px 0 0">
				<div class="grid-container">
					<div
						*ngFor="let coluna of header; let i = index"
						class="grid-item bg-tertiary text-white fw-normal">
						<div>{{ coluna.title }}</div>
					</div>
				</div>
			</th>
		</tr>
		<tr
			[class.dataTables_wrapper]="spaceReduceTh"
			*ngIf="header && !hasComplexHeader">
			<th
				[class]="i === 0 && header ? 'adjust-header' : ''"
				*ngFor="let column of columns; let i = index"
				class="bg-tertiary text-white fw-normal text-center"
				class="bg-tertiary text-white fw-normal text-center rounded-0"></th>
		</tr>
		<ng-container *ngIf="hasComplexHeader" class="">
			<tr>
				<ng-container class="grid-container">
					<th
						[class]="i === 0 && header ? 'adjust-header' : ''"
						class="bg-tertiary text-white fw-normal text-center"
						*ngFor="let item of header; let i = index"
						[style]="borderRadius(i)"
						[attr.colspan]="i === 0 ? 1 : 3"
						[attr.rowspan]="i === 0 ? 2 : 1">
						{{ item.title }}
					</th>
				</ng-container>
			</tr>
			<tr>
				<th
					*ngFor="let column of columns.slice(1); let i = index"
					class="bg-tertiary text-white fw-normal text-center">
					{{ column.title }}
				</th>
			</tr></ng-container
		>
	</thead>
</table>
