import { Component, inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Meses } from '@home/submodulos/dados-meteorologicos/enum';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces';
import { SPI } from '@home/submodulos/dados-meteorologicos/interfaces/SPI';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { format, formatISO } from 'date-fns';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { PERIODO_MINIMO_SPI_EM_ANOS } from '../pluviometro-grafico-spi/pluviometro-grafico-spi.component';
import { formataValorPrecipitacao } from '@utils';
import { formatarDataPeriodo } from '../../../utils';

@Component({
	selector: 'seira-tabela-spi',
	templateUrl: './tabela-spi.component.html',
	styleUrls: ['./tabela-spi.component.scss'],
})
export class TabelaSPIComponent implements OnInit {
	inputs = inject(INPUTS_RELATORIOS);

	tituloTabela = 'ÍNDICE DE PRECIPITAÇÃO PADRONIZADA';

	carregando = false;
	descricaoRelatorio =
		'Define-se como SPI o Índice de Precipitação Padronizado, que mede a anomalia da precipitação em diferentes escalas temporais, ajudando a identificar secas ou excessos de chuva em uma região.';

	colunasNDC: ADTColumns[] = [
		{ data: 'nomeRegiao', title: 'Região Pluviométrica' },
		{
			data: null,
			title: 'Mês/Ano',
			className: 'text-center',
			render: (spi: SPI) => {
				return Meses[spi.mes - 1] + '/' + spi.ano;
			},
		},
		{
			data: 'spi',
			title: 'Valor SPI ',
			type: 'string',
			className: 'text-center',
			render: (spi: number) => {
				return formataValorPrecipitacao(spi);
			},
		},

		{
			data: 'spi',
			title: 'SPI',
			type: 'string',
			className: 'text-center',
			render: (spi: number) => {
				const valorSPIformatado = spi.toFixed(1);
				const valorSPI = this.getIndiceDeSeca(valorSPIformatado);

				return valorSPI;
			},
		},
	];
	resultados: SPI[] = [];

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit() {
		setTimeout(() => {
			this.definirPeriodoPadrao();
		});
		setTimeout(() => {
			this.gerarRelatorio();
		}, 1);
	}

	private definirPeriodoPadrao() {
		const anoAtual = moment().startOf('year');
		const anoInicial = moment(anoAtual).subtract(
			PERIODO_MINIMO_SPI_EM_ANOS,
			'year'
		);

		this.inputs.form.get('dataInicio')?.setValue(anoInicial);
		this.inputs.form.get('dataFim')?.setValue(anoAtual);
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	gerarRelatorio() {
		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const regiaoId = this.getDataFromForm(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		)?.value;

		if (this.inputs.form.invalid) {
			return;
		}

		this.tituloTabela = `ÍNDICE DE PRECIPITAÇÃO PADRONIZADA - ${formatarDataPeriodo(
			diaInicio,
			diaFim,
			'anual'
		)}`;
		this.carregando = true;
		this.inputs.setLoading(true);

		this.relatoriosService
			.buscarRelatorioSPIPorRegiao(
				formatISO(new Date(diaInicio)),
				formatISO(new Date(diaFim)),
				regiaoId
			)
			.subscribe({
				next: SPIResult => {
					this.resultados = SPIResult;
				},
				error: err => {
					this.toastrService.error(
						'Erro ao gerar tabela de SPI, tente novamente'
					);
					this.carregando = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);
		const dataInicioFomatada = format(dataInicio?.value.toDate(), 'MM/yyyy');
		const dataFimFomatada = format(dataFim?.value.toDate(), 'MM/yyyy');
		const tituloTabela = `RELATÓRIO SPI POR REGIÃO PLUVIOMÉTRICA - ${dataInicioFomatada} à ${dataFimFomatada}`;
		documentDefinition.content.push({
			text: tituloTabela,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.resultados) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}
		const tableData: (string | number)[][] = [];

		tableData.push(['Região pluviométrica', 'Mês/Ano', 'SPI (mm)']);

		this.resultados.forEach(dadoRelatorio => {
			tableData.push([
				dadoRelatorio.nomeRegiao,
				Meses[dadoRelatorio.mes - 1] + '/' + dadoRelatorio.ano,
				!isNaN(dadoRelatorio.spi)
					? formataValorPrecipitacao(dadoRelatorio.spi)
					: '-',
			]);
		});
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData: (string | number)[][] = [];

		tableData.push(['Região pluviométrica', 'Mês/Ano', 'SPI (mm)']);

		this.resultados.forEach(dadoRelatorio => {
			tableData.push([
				dadoRelatorio.nomeRegiao,
				Meses[dadoRelatorio.mes - 1] + '/' + dadoRelatorio.ano,
				!isNaN(dadoRelatorio.spi)
					? formataValorPrecipitacao(dadoRelatorio.spi)
					: '-',
			]);
		});

		DocumentExporter.gerarCSV(tableData, this.tituloTabela);
	}

	getIndiceDeSeca(valor: string): string {
		for (const key of Object.keys(spiInfo)
			.map(Number)
			.sort((a, b) => b - a)) {
			if (Number(valor) >= Number(key)) {
				return spiInfo[key];
			}
		}
		return '';
	}
	exportarTXT() {
		let txtData = '';

		this.resultados.forEach(dadoRelatorio => {
			txtData +=
				`Região pluviométrica: ${dadoRelatorio.nomeRegiao}\n` +
				`Mês/Ano: ${dadoRelatorio.mes}/${dadoRelatorio.ano}\n` +
				`SPI (mm): ${
					!isNaN(dadoRelatorio.spi)
						? formataValorPrecipitacao(dadoRelatorio.spi)
						: '-'
				}\n`;
		});

		DocumentExporter.gerarTXT(txtData, this.tituloTabela);
	}
}

export enum IndiceSeca {
	EXTREMAMENTE_UMIDO = 'Extremamente úmido',
	SEVERAMENTE_UMIDO = 'Severamente úmido',
	MODERAMENTE_UMIDO = 'Moderamente úmido',
	NORMAL_SPI = 'Normal SPI',
	MODERADAMENTE_SECO = 'Moderadamente seco',
	SEVERAMENTE_SECO = 'Severamente Seco',
	EXTREMAMENTE_SECO = 'Extremamente Seco',
}
export const spiInfo: { [key: string]: string } = {
	'-2': IndiceSeca.EXTREMAMENTE_SECO,
	'-1.5': IndiceSeca.SEVERAMENTE_SECO,
	'-1': IndiceSeca.MODERADAMENTE_SECO,
	'1': IndiceSeca.NORMAL_SPI,
	'1.5': IndiceSeca.MODERAMENTE_UMIDO,
	'2': IndiceSeca.SEVERAMENTE_UMIDO,
	'2.5': IndiceSeca.EXTREMAMENTE_UMIDO,
};
