import { HttpClient } from '@angular/common/http';
import {
	Component,
	inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	ChuvaEstado,
	DadosTabela,
} from '@home/submodulos/dados-meteorologicos/interfaces/chuva-estado';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { MapaChuvaEstadoService } from '@home/submodulos/dados-meteorologicos/services/mapa-chuva-estado.service';
import { Select } from '@layout/interfaces/select';
import { TipoPrecipitacao } from '@shared/enum';
import { GeoJSON } from '@shared/interfaces/geometry';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { capitalizeFirstLetter, numberToBrNumber } from '@utils';
import { DocumentExporter } from '@utils/document-exporter';
import { VariavelMeteorologica } from '@utils/interpolacao/intepolacao-parametros';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
	ValorPorMesoregiao,
} from '@utils/interpolacao/interpolacao';
import * as pdfseira from '@utils/pdf-seira';
import { format, formatISO } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { FeatureCollection, Polygon } from 'geojson';
import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { formatarDataPeriodo } from '../../../utils';
import Position = GeoJSON.Position;

@Component({
	selector: 'seira-mapa-chuva-estado',
	templateUrl: './mapa-chuva-estado.component.html',
	styleUrls: ['./mapa-chuva-estado.component.scss'],
})
export class MapaChuvaEstadoComponent implements OnInit, OnDestroy {
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;
	interpolacao?: InterpolacaoCanvasBounds;
	carregando = false;
	valores: ChuvaEstado[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];
	medicoes: ChuvaEstado[] = [];
	dados: DadosTabela[] = [];
	loading = false;
	regiao?: Position[][];
	descricaoRelatorio =
		'Define-se como chuva no estado o volume total de precipitação registrado em diferentes regiões de um estado específico durante um determinado período.';

	periodoTitulo = '';
	precipitacaoTitle = '';
	OpcoesTipoVisualizacao: OptionRadio<'mapa' | 'tabela'>[] = [
		{ label: 'Mapa', value: 'mapa' },
		{ label: 'Tabela', value: 'tabela' },
	];

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF(this.dados);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarCSV(this.dados);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT(this.dados);
			},
		},
	];
	constructor(
		private mapaChuvaEstadoService: MapaChuvaEstadoService,
		private httpClient: HttpClient,
		private fb: FormBuilder
	) {}

	ngOnInit() {
		this.form = this.inputs.form;
		if (!this.form.contains('tipoVisualizacao')) {
			this.form.addControl('tipoVisualizacao', this.fb.control('mapa'));
		}
		setTimeout(() => {
			this.setValidators();
		}, 0);
		this.initialValues();
		this.gerarRelatorio();
	}

	calcularDataInicio(): Date {
		const dataInicio = new Date();
		dataInicio.setMonth(dataInicio.getMonth() - 6);
		return dataInicio;
	}

	calcularMedia(valores: number[]): number {
		const soma = valores.reduce((total, valor) => total + valor, 0);
		return soma / valores.length;
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	setValidators() {
		this.form.get('tipoVisualizacao')?.setValidators(Validators.required);
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.MICRORREGIAO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PRECIPITACAO)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		if (this.form.invalid) {
			return;
		}
		const dataInicio: Date | null = this.form.get(
			FormularioRelatorio.DATA_INICIO
		)?.value;
		const dataFim: Date | null = this.form.get(FormularioRelatorio.DATA_FIM)
			?.value;

		if (!dataInicio || !dataFim || !this.postos.length) {
			return;
		}

		this.inputs.setLoading(true);
		this.carregando = true;
		this.periodoTitulo = formatarDataPeriodo(dataInicio, dataFim, 'mensal');
		this.precipitacaoTitle = this.form.get('precipitacao')?.value.toUpperCase();

		this.mapaChuvaEstadoService
			.buscarMapaChuvaEstado(
				formatISO(new Date(dataInicio)),
				formatISO(new Date(dataFim))
			)
			.subscribe({
				next: (resp: ChuvaEstado[]) => {
					if (this.dados.length === 0) {
						const valoresAgrupados: { [mesorregiao: string]: ChuvaEstado[] } =
							{};
						resp.forEach(valor => {
							if (!valoresAgrupados[valor.mesorregiao]) {
								valoresAgrupados[valor.mesorregiao] = [];
							}
							valoresAgrupados[valor.mesorregiao].push(valor);
						});

						for (const mesorregiao in valoresAgrupados) {
							if (valoresAgrupados.hasOwnProperty(mesorregiao)) {
								const valores = valoresAgrupados[mesorregiao];
								const mediaAcumulado = this.calcularMedia(
									valores.map(v => v.acumulado)
								);
								const mediaMedia = this.calcularMedia(
									valores.map(v => v.media)
								);
								const mediaDesvio =
									((mediaAcumulado - mediaMedia) / mediaMedia) * 100;

								this.dados.push({
									mesorregiao: mesorregiao,
									acumulado: mediaAcumulado,
									media: mediaMedia,
									desvio: mediaDesvio,
								});
							}
						}
					}
					this.medicoes = resp;

					this.interpolarValores();
					this.inputs.setLoading(false);
				},
				error: erro => {
					console.error('erro', erro);
				},
			});
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.form.get(FormularioRelatorio.PRECIPITACAO)?.clearValidators();
	}

	interpolarValores() {
		this.carregando = true;
		if (this.medicoes.length > 0) {
			this.criarImagemInterpolacao().then(interpolacao => {
				this.interpolacao = interpolacao as InterpolacaoCanvasBounds;
				this.carregando = false;
			});
		} else {
			this.interpolacao = undefined;
			this.carregando = false;
		}
	}

	criarImagemInterpolacao() {
		const tipoPrecipitacao = this.form.get('precipitacao')?.value;
		let variavel: VariavelMeteorologica = 'precipitacao';

		const valores: ValorPorMesoregiao[] = this.medicoes.map(value => {
			let valor: number;
			if (tipoPrecipitacao === TipoPrecipitacao.ACUMULADA) {
				valor = value.acumulado;
			} else if (tipoPrecipitacao === TipoPrecipitacao.MEDIA) {
				valor = value.media;
			} else {
				valor = value.desvio;
				variavel = 'desvioAcumulado';
			}

			return {
				lat: value.latitude,
				lng: value.longitude,
				value: valor,
				mesoregiao: value.mesorregiao,
				nomePosto: value.nomePosto,
				isPrecipitacao: variavel === 'precipitacao',
			};
		});
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				valores,
				variavel,
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject();
			}
			resolve(interpolacao);
		});
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	colunasTabelaPrecipitacoes: Array<DataTables.ColumnSettings> = [
		{
			title: 'Mesorregião',
			data: null,
			className: 'text-tertiary  text-center',
			render: function (row) {
				return capitalizeFirstLetter(row.mesorregiao.toLowerCase());
			},
		},
		{
			title: 'Chuva observada (mm)',
			data: 'acumulado',
			className: 'text-tertiary  text-center',
			render: function (acumulado: number) {
				return numberToBrNumber(acumulado.toFixed(2));
			},
		},
		{
			title: 'Chuva esperada (mm)',
			data: 'media',
			render: function (media: number) {
				return numberToBrNumber(media.toFixed(2));
			},
			className: 'text-tertiary  text-center',
		},
		{
			title: 'Desvio observado (%)',
			data: 'desvio',
			render: function (desvio: number) {
				return numberToBrNumber(desvio.toFixed(2));
			},
			className: 'text-tertiary  text-center',
		},
	];

	getDadosTabelaChuvaEstado(dados: DadosTabela[]) {
		const tableData: any[][] = [];
		const colunas = [
			'Mesorregião',
			'Chuva Observada (mm)',
			'Chuva Esperada (mm)',
			'Desvio Observado (%)',
		];
		tableData.push(colunas);

		dados.forEach((item: DadosTabela) => {
			const rowData = [
				capitalizeFirstLetter(item.mesorregiao.toLowerCase()),
				numberToBrNumber(item.acumulado.toFixed(2)),
				numberToBrNumber(item.media.toFixed(2)),
				numberToBrNumber(item.desvio.toFixed(2)),
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	getPeriodo() {
		const dataInicio = this.form.get('dataInicio')?.value;
		const dataFim = this.form.get('dataFim')?.value;

		if (!dataInicio || !dataFim) return '';
		return `${format(dataInicio.toDate(), 'MM/yyyy')} a ${format(
			dataFim.toDate(),
			'MM/yyyy'
		)}`;
	}

	async exportarPDF(medicoes?: DadosTabela[]) {
		const elementos = ['chuvaEstado', 'iapm', 'precipitacao'];
		const imagens = [];

		for (const elemento of elementos) {
			const elementoHtml = document.getElementById(elemento);
			if (elementoHtml) {
				const img = await html2canvas(elementoHtml).then(canvas =>
					canvas.toDataURL('image/png')
				);
				imagens.push({ imagem: img, titulo: 'Gráfico de chuva no Estado' });
			}
		}

		const dataFormatada = format(new Date(), "dd 'de' MMMM 'de' yyyy HH'h'mm", {
			locale: ptBrLocale,
		});
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.content.push([
			{
				text: `Relatório - Chuva no Estado - ${this.getPeriodo()}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				text: `Relatório exportado em: ${dataFormatada}`,
				alignment: 'center',
				margin: [0, 10, 5, 10],
				fontSize: 12,
			},
		]);

		for (const imagem of imagens) {
			if (imagem.imagem && imagem.titulo) {
				documentDefinition.content.push(
					{
						text: imagem.titulo,
						alignment: 'center',
						margin: [0, 15, 0, 5],
						fontSize: 12,
					},
					{
						image: imagem.imagem,
						width: 515,
						heigth: 250,
						alignment: 'center',
					}
				);
			}
		}

		if (medicoes) {
			documentDefinition.content.push({
				text: `Tabela de precipitação`,
				alignment: 'center',
				margin: [0, 15, 0, 5],
				fontSize: 12,
			});
			documentDefinition.content.push({
				table: {
					widths: ['*', 'auto', 'auto', '*'],
					body: this.getDadosTabelaChuvaEstado(medicoes).map(row =>
						row.map(cell => ({
							text: cell,
							alignment: 'center',
						}))
					),
					alignment: 'center',
					layout: 'fullWidth',
					fontSize: 12,
				},
			});
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarTXT(medicoes: DadosTabela[]) {
		const tableData = this.getDadosTabelaChuvaEstado(medicoes);
		const txtContent = tableData.map(row => row.join('\t')).join('\n');
		DocumentExporter.gerarTXT(
			txtContent,
			`relatorio-chuva-no-estado-${this.getPeriodo()}`
		);
	}

	exportarCSV(medicoes: DadosTabela[]) {
		const tableData = this.getDadosTabelaChuvaEstado(medicoes);
		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-chuva-no-estado-${this.getPeriodo()}`
		);
	}
}
