import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';

import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { HighStockComponent } from '@componentes/high-stock/high-stock.component';
import { optionsChartObservadoDesvioClimatologia } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-pluviometria-observada-desvio-climatologia/chartOptions';
import {
	AgrupamentoResponse,
	DadosAgrupamento,
} from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import { format } from 'date-fns-tz';
import * as Highcharts from 'highcharts/highstock';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { Subject, takeUntil } from 'rxjs';

import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { GraficosObservadoDesvioClimatologiaService } from '@home/submodulos/dados-meteorologicos/services/graficos-observado-desvio-climatologia.service';
import { capitalizeFirstLetter, criarImagemBase64FromChart } from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import { formatISO } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { EPeriodosBusca } from '../../../enum';
import { formatarDataPeriodo, gerarFilenameGrafico } from '../../../utils';

@Component({
	selector: 'seira-grafico-pluviometria-observado-desvio-climatologia',
	templateUrl:
		'./grafico-pluviometria-observado-desvio-climatologia.component.html',
	styleUrls: [
		'./grafico-pluviometria-observado-desvio-climatologia.component.scss',
	],
})
export class GraficoPluviometriaObservadoDesvioClimatologiaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild(HighStockComponent, { static: false })
	highStockComponent: HighStockComponent;
	inputs = inject(INPUTS_RELATORIOS);
	form: FormGroup;
	chart: Highcharts.Chart;
	loading = false;
	gerou = false;
	_destroyed = new Subject();
	tipoDesvio = '';
	resultado = false;
	chartOptions: Highcharts.Options = optionsChartObservadoDesvioClimatologia(
		this.inputs.form.get(FormularioRelatorio.TIPO)?.value ===
			'PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA'
	);

	descricaoRelatorio = '';
	descricaoRelatorioMilimetro =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado. ' +
		'O desvio em milímetros refere-se à diferença entre a quantidade de chuva registrada e a sua climatologia para o mesmo período, expressa em milímetros em uma determinada localidade. ' +
		'A climatologia é o estudo das condições médias do clima em uma região ao longo de um período de 30 anos, utilizada como referência para comparar variações climáticas, como a precipitação.';
	descricaoRelatorioPorcentagem =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado. ' +
		'O desvio em porcentagem refere-se à variação da precipitação em relação à sua climatologia, expressa como uma porcentagem para indicar se o volume de chuva foi superior ou inferior ao esperado. ' +
		'A climatologia é o estudo das condições médias do clima em uma região ao longo de um período de 30 anos, utilizada como referência para comparar variações climáticas, como a precipitação.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
	];
	periodoTitulo = '';

	constructor(
		private toastr: ToastrService,
		private graficosObservadoDesvioClimatologiaService: GraficosObservadoDesvioClimatologiaService
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.form = this.inputs.form;
		this.tipoDesvio =
			this.inputs.form.get(FormularioRelatorio.TIPO)?.value ===
			'PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA'
				? 'milimetro'
				: 'porcentagem';
		this.descricaoRelatorio =
			this.tipoDesvio === 'milimetro'
				? this.descricaoRelatorioMilimetro
				: this.descricaoRelatorioPorcentagem;
		this.gerarRelatorio();
		this.observarMudancasPeriodoBusca();
	}

	setChart(chart: Highcharts.Chart) {
		this.chart = chart;
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this._destroyed.next(undefined);
	}

	observarMudancasPeriodoBusca() {
		const periodoBusca = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		);
		this.lidarComPeriodo(periodoBusca?.value);
		periodoBusca?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: (periodo: PeriodosLabel | null) => {
				this.lidarComPeriodo(periodo);
			},
		});
	}

	lidarComPeriodo(periodo: PeriodosLabel | null) {
		const dataInicio: AbstractControl<Moment | null> | null =
			this.inputs.form.get(FormularioRelatorio.DATA_INICIO);
		const dataFim: AbstractControl<Moment | null> | null = this.inputs.form.get(
			FormularioRelatorio.DATA_FIM
		);
		switch (periodo) {
			case 'anual':
				if (dataFim?.value) {
					const hoje = new Date();
					if (dataFim.value.year() == hoje.getFullYear()) {
						dataFim?.setValue(moment(hoje));
					} else {
						dataFim?.setValue(dataFim.value.endOf('year'));
					}
				}
				if (dataInicio?.value && dataFim?.value) {
					const fim = moment(dataFim.value).subtract(
						EPeriodosBusca.ANUAL,
						'year'
					);
					dataInicio?.setValue(fim);
				}
				break;
			case 'mensal':
				if (dataFim?.value) {
					const hoje = new Date();
					if (
						dataFim.value.year() === hoje.getFullYear() &&
						dataFim.value.month() >= hoje.getMonth()
					) {
						dataFim.setValue(moment(hoje));
					} else {
						dataFim.setValue(dataFim.value.endOf('month'));
					}
				}
				if (dataInicio?.value && dataFim?.value) {
					const fim = moment(dataFim.value).subtract(
						EPeriodosBusca.MENSAL,
						'month'
					);
					dataInicio?.setValue(fim);
				}
				break;
			case 'diario':
				if (dataFim?.value) {
					const hoje = new Date();
					if (
						dataFim.value.year() === hoje.getFullYear() &&
						dataFim.value.month() >= hoje.getMonth()
					) {
						dataFim?.setValue(moment(hoje));
					} else {
						dataFim?.setValue(dataFim.value.endOf('month'));
					}
				}
				if (dataInicio?.value && dataFim?.value) {
					const fim = moment(dataFim.value).subtract(
						EPeriodosBusca.DIARIO,
						'day'
					);
					dataInicio?.setValue(fim);
				}
				break;
		}
	}

	lidarComAgrupamento() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO);
		const municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO);
		const microrregiao = this.inputs.form.get(FormularioRelatorio.MICRORREGIAO);
		const mesorregiao = this.inputs.form.get(FormularioRelatorio.MESORREGIAO);
		const municipioPosto = this.inputs.form.get(FormularioRelatorio.POSTO);
		const bacia = this.inputs.form.get(FormularioRelatorio.BACIA);
		const regiao = this.inputs.form.get(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		);
		municipio?.clearValidators();
		microrregiao?.clearValidators();
		mesorregiao?.clearValidators();
		regiao?.clearValidators();
		municipioPosto?.clearValidators();
		bacia?.clearValidators();

		switch (agrupamento?.value) {
			case 'MUNICIPIO_POSTO':
				municipioPosto?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				microrregiao?.setValidators(Validators.required);
				break;
			case 'MESORREGIAO':
				mesorregiao?.setValidators(Validators.required);
				break;
			case 'REGIAO_PLUVIOMETRICA':
				regiao?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				municipio?.setValidators(Validators.required);
				break;
			case 'BACIA':
				bacia?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.lidarComAgrupamento();
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: () => {
					this.lidarComAgrupamento();
				},
			});
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		this.loading = true;
		this.inputs.setLoading(true);
		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;

		const diaInicio =
			periodoAgrupamento !== 'anual'
				? dataInicio
				: new Date(`${new Date(dataInicio).getFullYear()}-01-01T00:00:00`);
		const diaFim =
			periodoAgrupamento !== 'anual'
				? dataFim
				: new Date(`${new Date(dataFim).getFullYear()}-12-31T00:00:00`);

		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		let id = null;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
		};
		this.periodoTitulo = formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoAgrupamento
		);

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = this.inputs.form.get(FormularioRelatorio.ESTACAO)?.value;
				id = this.inputs.form.get('estacao')?.value;
				break;
			case 'MICRORREGIAO':
				dados.microrregiao = this.inputs.form.get(
					FormularioRelatorio.MICRORREGIAO
				)?.value;
				id = this.inputs.form.get('microrregiao')?.value;
				break;
			case 'MESORREGIAO':
				dados.mesorregiao = this.inputs.form.get(
					FormularioRelatorio.MESORREGIAO
				)?.value;
				id = this.inputs.form.get('mesorregiao')?.value;
				break;
			case 'REGIAO_PLUVIOMETRICA':
				dados.regiao = this.inputs.form.get(
					FormularioRelatorio.REGIAO_PLUVIOMETRICA
				)?.value;
				id = this.inputs.form.get('regiao')?.value;
				break;
			case 'BACIA':
				dados.bacia = this.inputs.form.get(FormularioRelatorio.BACIA)?.value;
				id = this.inputs.form.get('bacia')?.value;
				break;
			case 'MUNICIPIO':
				dados.municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO)
					?.value;
				id = this.inputs.form.get('municipio')?.value;
				break;
		}

		this.graficosObservadoDesvioClimatologiaService
			.buscarObservadoDesvioClimatologia(
				dados,
				agrupamento,
				formatISO(new Date(diaInicio)),
				formatISO(new Date(diaFim)),
				id
			)
			.subscribe({
				next: preciptacoesAgrupadas => {
					this.configurarChart(preciptacoesAgrupadas, periodoAgrupamento);
					this.chartOptions = {
						...this.chartOptions,
						exporting: {
							enabled: true,
							buttons: {
								contextButton: {
									menuItems: [
										'viewFullscreen',
										'separator',
										'downloadPNG',
										'downloadJPEG',
									],
								},
							},
							filename: gerarFilenameGrafico(
								`grafico_pluviometria_observada_desvio_${
									this.tipoDesvio === 'porcentagem' ? '%' : 'mm'
								}_${this.inputs.form
									.get(FormularioRelatorio.AGRUPAMENTO)
									?.value.toLocaleLowerCase()}_${periodoAgrupamento.toLocaleLowerCase()}`
							),
						},
					};

					this.resultado = preciptacoesAgrupadas[0].length > 0;
				},
				error: () => {
					this.inputs.setLoading(false);
					this.resultado = false;
					this.loading = false;
					this.gerou = false;
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	extrairDatasPrecipitacao(
		preciptacaoAgrupada: AgrupamentoResponse[],
		periodoAgrupamento: PeriodosLabel
	) {
		return [
			...new Set(
				preciptacaoAgrupada
					.filter((el: any) => el.data)
					.map((el: any) => {
						const [ano, mes, dia] = el.data!.split('-');
						return new Date(+ano, (+mes || 1) - 1, +dia || 1);
					})
					.sort((a, b) => +a - +b)
					.map((data: any) => {
						let formato = 'dd/MM/yyyy';
						if (periodoAgrupamento === 'mensal') {
							formato = 'MM/yyyy';
						} else if (periodoAgrupamento === 'anual') {
							formato = 'yyyy';
						}
						return format(data, formato, {
							timeZone: 'America/Sao_Paulo',
						});
					})
			),
		];
	}

	configurarChart(
		preciptacoesAgrupadas: any[],
		periodoAgrupamento: PeriodosLabel
	) {
		const sufixoTipo = this.tipoDesvio === 'milimetro' ? 'mm' : '%';
		const categorias = ['Observado', `Desvio ${sufixoTipo}`, 'Climatologia'];
		const dataSeries = this.extrairDatasPrecipitacao(
			preciptacoesAgrupadas[0],
			periodoAgrupamento
		);

		this.chartOptions.title = {
			text: `Pluviometria observada/Climatologia(mm)/Desvio(${sufixoTipo}) - ${
				periodoAgrupamento === 'diario'
					? 'Diário'
					: capitalizeFirstLetter(periodoAgrupamento)
			}`,
			align: 'center',
			style: {
				fontSize: '18px',
			},
		};

		this.chartOptions.series = categorias.map(serie => {
			let resultado: any[] = [];
			const y = serie !== `Desvio ${sufixoTipo}` ? 0 : 1;
			const sufixo = serie === 'Desvio %' ? '%' : 'mm';
			const tipo = serie == `Desvio ${sufixoTipo}` ? 'spline' : 'column';

			if (serie === 'Observado') {
				let i = 0;
				resultado = preciptacoesAgrupadas[0].map((dados: any) => {
					const retorno = [dataSeries[i], dados?.precipitacaoAcumulada];
					i += 1;
					return retorno;
				});
			} else if (
				serie === 'Climatologia' &&
				(periodoAgrupamento === 'diario' || periodoAgrupamento === 'anual')
			) {
				let i = 0;
				const dataClimatologia = this.convertePorPeriodo(
					preciptacoesAgrupadas[0],
					preciptacoesAgrupadas[1],
					periodoAgrupamento
				);

				resultado = dataClimatologia.map((dados: any) => {
					const retorno = [dataSeries[i], dados?.climatologia];
					i += 1;
					return retorno;
				});
			} else if (
				serie === 'Climatologia' &&
				periodoAgrupamento === 'mensal' &&
				dataSeries.length > 0
			) {
				resultado = dataSeries.map(serie => {
					const [mesSerie] = serie.split('/');
					const meses = preciptacoesAgrupadas[1];

					const dadosEncontrados = meses
						.map((mes: any) =>
							mes.desvios.find((dados: any) => {
								const [, mesDados, ,] = dados.data.split('-');
								return mesDados === mesSerie;
							})
						)
						.filter((dado: any) => dado !== undefined);

					const dadoCombinado = dadosEncontrados.reduce(
						(acc: any, curr: any) => {
							return {
								desvioMilimetro:
									(acc.desvioMilimetro || 0) + curr.desvioMilimetro,
								desvioPorcentagem:
									(acc.desvioPorcentagem || 0) + curr.desvioPorcentagem,
								climatologia: (acc.climatologia || 0) + curr.climatologia,
								chuva: (acc.chuva || 0) + curr.chuva,
								data: curr.data,
							};
						},
						{}
					);

					return dadoCombinado
						? [serie, dadoCombinado?.climatologia]
						: [serie, 0];
				});
			} else {
				if (periodoAgrupamento === 'mensal') {
					resultado = dataSeries.map(serie => {
						const [mesSerie, anoSerie] = serie.split('/');
						const meses = preciptacoesAgrupadas[1];

						const dadoEncontrado = meses[0]?.desvios.find((dados: any) => {
							const [, mesDados, ,] = dados.data.split('-');
							return mesDados === mesSerie;
						});

						let desvio = 0;
						if (dadoEncontrado) {
							desvio =
								this.tipoDesvio === 'milimetro'
									? dadoEncontrado?.desvioMilimetro
									: dadoEncontrado?.desvioPorcentagem;
						}

						return [serie, desvio];
					});
				} else {
					let i = 0;
					const dataDesvio = this.convertePorPeriodo(
						preciptacoesAgrupadas[0],
						preciptacoesAgrupadas[1],
						periodoAgrupamento
					);
					resultado = dataDesvio.map((dados: any) => {
						let desvio = 0;
						if (dados) {
							desvio =
								this.tipoDesvio === 'milimetro'
									? dados?.desvioMilimetro
									: dados?.desvioPorcentagem;
						}
						const retorno = [dataSeries[i], desvio];
						i += 1;
						return retorno;
					});
				}
			}

			return {
				name: serie,
				type: tipo,
				tooltip: {
					valueDecimals: 1,
					valueSuffix: sufixo,
				},
				yAxis: y,
				marker: {
					enabled: y,
					radius: 5,
				},
				zIndex: y * 10,
				data: resultado,
			} as Highcharts.SeriesColumnOptions;
		});

		this.chartOptions.xAxis = {
			categories: dataSeries,
		};
	}

	convertePorPeriodo(
		objetoChuva: AgrupamentoResponse[],
		objetoDesvioClimatologia: any[],
		periodo: string
	): any[] {
		const dataSeries = objetoChuva.map(
			(item: AgrupamentoResponse) => item.data
		);

		return dataSeries.map(date => {
			if (date) {
				if (periodo === 'anual') {
					const desviosEncontrados =
						objetoDesvioClimatologia[0]?.desvios.filter((desvio: any) => {
							return new Date(desvio.data).getFullYear() === parseInt(date, 10);
						});

					if (desviosEncontrados && desviosEncontrados.length > 0) {
						const somatorioDesvioClimatologia = desviosEncontrados.reduce(
							(acc: any, curr: any) => {
								acc.desvioMilimetro += curr.desvioMilimetro;
								acc.climatologia += curr.climatologia;
								acc.chuva += curr.chuva;
								return acc;
							},
							{
								desvioMilimetro: 0,
								climatologia: 0,
								chuva: 0,
								desvioPorcentagem: 0,
								data: date,
							}
						);

						somatorioDesvioClimatologia.desvioPorcentagem =
							(somatorioDesvioClimatologia.desvioMilimetro /
								somatorioDesvioClimatologia.climatologia) *
							100;

						return somatorioDesvioClimatologia;
					} else {
						return null;
					}
				} else {
					const resultado = objetoDesvioClimatologia[0]?.desvios.find(
						(desvio: any) => {
							const dataSerie = new Date(date);
							dataSerie.setHours(dataSerie.getHours() + 3);
							return new Date(desvio.data).getMonth() === dataSerie.getMonth();
						}
					);

					return resultado
						? {
								...resultado,
								data: date,
						  }
						: null;
				}
			}
			return null;
		});
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];

		if (!this.chart || !this.resultado) {
			this.toastr.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}

		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chart),
			fontSize: 10,
			alignment: 'center',
			height: 400,
			width: 750,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		const content = [
			{
				text: `Relatório`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
		];

		documentDefinition.content.push(content);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}
}
