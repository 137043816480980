<label class="text-center text-tertiary fw-semibold w-100">
	CLIMATOLOGIA/DESVIO ({{
		tipoDesvio === 'porcentagem' ? '%' : 'mm'
	}})/OBSERVADO - {{ periodoTitulo }}
</label>
<seira-card-info aditionalClasses="mt-3 mb-3" [texto]="descricaoRelatorio" />

<div
	*ngIf="loading"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>

<div *ngIf="gerou && !loading">
	<seira-high-stock
		*ngIf="chartOptions"
		(chartInstance)="setChart($event)"
		classChart="highcharts-light h-auto"
		styleChart="width: 100%; height: 550px; display: block;"
		[options]="chartOptions" />
</div>

<ng-container *ngIf="!gerou && !loading">
	<seira-sem-dados />
</ng-container>
