import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import { criarImagemBase64FromChart, filterSelectOptions } from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import { formatISO, subMonths } from 'date-fns';
import { Chart } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Subject, takeUntil } from 'rxjs';
import {
	FormularioRelatorio,
	IapmDTO,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '../../../interfaces';
import { GraficosPrecipitacaoService } from '../../../services';
import { Agrupamento } from '../../../submodulos/monitoramento/interfaces/estacao-monitorada';
import { formatarDataPeriodo, gerarFilenameGrafico } from '../../../utils';

@Component({
	selector: 'seira-pluviometria-grafico-iapm',
	templateUrl: './pluviometria-grafico-iapm.component.html',
	styleUrls: ['./pluviometria-grafico-iapm.component.scss'],
})
export class PluviometriaGraficoIapmComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup<any>;
	isLoading = false;
	chart!: Chart;
	dados?: IapmDTO[];
	idValue?: number;
	descricaoRelatorio =
		'Define-se como IAPM o índice que mede a anomalia da precipitação mensal em comparação com a média histórica, utilizado para identificar padrões fora do comum em determinada região.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
	];
	fileName = '';
	periodoTitulo = '';
	agrupamento: Select<string>[];
	private _destroyed = new Subject();

	constructor(
		private readonly service: GraficosPrecipitacaoService,
		private toastr: ToastrService
	) {
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.MUNICIPIO_POSTO,
			Agrupamento.MUNICIPIO,
			Agrupamento.SUBBACIA
		);
	}

	ngOnInit(): void {
		this.form = this.inputs.form;
		this.form.get(FormularioRelatorio.AGRUPAMENTO)?.setValue('MICRORREGIAO');
		this.handleSetValidatorsByAgrupamento(
			this.inputs.form?.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.handleObserveAgrupamento();
		this.gerarRelatorio();
	}

	ngOnDestroy(): void {
		this._destroyed.next(undefined);
	}

	onChartCreated(chart: Chart) {
		this.chart = chart;
	}

	gerarRelatorio() {
		if (this.form?.invalid) {
			return;
		}

		const agrupamento = this.form?.get('agrupamento')?.value;
		const periodoBusca = this.form?.get('periodoBusca')?.value;
		const dataInicio = this.form?.get('dataInicio')?.value;
		const dataFim = this.form?.get('dataFim')?.value;

		if (
			!this.idValue ||
			!agrupamento ||
			!periodoBusca ||
			!dataInicio ||
			!dataFim
		) {
			return;
		}

		let dataFinalFixed = '';
		const dateFinal = new Date(dataFim);
		if (dateFinal.getMonth() === new Date().getMonth()) {
			dataFinalFixed = formatISO(subMonths(dateFinal, 1));
		} else {
			dataFinalFixed = formatISO(dateFinal);
		}
		this.periodoTitulo = formatarDataPeriodo(dataInicio, dataFim, periodoBusca);
		this.handleFetchIAPM(
			this.idValue!,
			agrupamento,
			this.handleFormatPeriodoBusca(periodoBusca),
			formatISO(new Date(dataInicio)),
			dataFinalFixed
		);
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];

		if (!this.chart || !this.dados) {
			this.toastr.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}

		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chart),
			fontSize: 10,
			alignment: 'center',
			height: 400,
			width: 750,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		const content = [
			{
				text: `Relatório`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
		];

		documentDefinition.content.push(content);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	private handleObserveAgrupamento() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.handleSetValidatorsByAgrupamento(value);
				},
			});
	}

	private handleSetValidatorsByAgrupamento(agrupamentoValue: string) {
		switch (agrupamentoValue) {
			case 'MICRORREGIAO':
				this.inputs.form
					.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				this.idValue = this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)
					?.value;

				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MESORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.REGIAO_PLUVIOMETRICA
				);

				break;
			case 'MESORREGIAO':
				this.inputs.form
					.get(FormularioRelatorio.MESORREGIAO)
					?.setValidators(Validators.required);
				this.idValue = this.inputs.form.get(FormularioRelatorio.MESORREGIAO)
					?.value;

				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MICRORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.REGIAO_PLUVIOMETRICA
				);
				break;
			case 'BACIA':
				this.inputs.form
					.get(FormularioRelatorio.BACIA)
					?.setValidators(Validators.required);
				this.idValue = this.inputs.form.get(FormularioRelatorio.BACIA)?.value;

				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MICRORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MESORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.REGIAO_PLUVIOMETRICA
				);
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.inputs.form
					.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.setValidators(Validators.required);
				this.idValue = this.inputs.form.get(
					FormularioRelatorio.REGIAO_PLUVIOMETRICA
				)?.value;

				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MICRORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(
					FormularioRelatorio.MESORREGIAO
				);
				this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
				break;
		}
	}

	private handleFetchIAPM(
		id: number,
		agrupamento: string,
		periodo: string,
		dataInicial: string,
		dataFinal: string
	) {
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.service
			.gerarGraficoIAPM(id, agrupamento, periodo, dataInicial, dataFinal)
			.subscribe({
				next: values => {
					this.dados = values;
					this.fileName = gerarFilenameGrafico(
						`grafico_iapm_${this.inputs.form
							.get(FormularioRelatorio.AGRUPAMENTO)
							?.value.toLocaleLowerCase()}_${periodo.toLocaleLowerCase()}`
					);
				},
				error: err => {
					this.toastr.error('Erro ao buscar as informações');
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	private handleFormatPeriodoBusca(periodo: string) {
		if (periodo === 'anual') return 'ANUAL';
		if (periodo === 'mensal') return 'MENSAL';
		return 'DIARIO';
	}

	private handleRemoveValidatorsFromFormControl(
		formControl: FormularioRelatorio
	) {
		this.inputs.form.get(formControl)?.setValidators(null);
		this.inputs.form.get(formControl)?.reset();
	}
}
