import { Component } from '@angular/core';
import { legendaInfo } from '@componentes/mapa-paraiba-svg/legenda';
import { enumAsSelectOptions } from '@utils';

@Component({
	selector: 'seira-legenda-pluviometria-observada',
	templateUrl: './legenda-pluviometria-observada.component.html',
	styleUrls: ['./legenda-pluviometria-observada.component.scss'],
})
export class LegendaPluviometriaObservadaComponent {
	legendaInfo = enumAsSelectOptions(legendaInfo);

	constructor() {}

	getKeys() {
		return Object.keys(this.legendaInfo).map(Number);
	}
	getLegendas = () => {
		const legendas = [];
		for (const key in legendaInfo) {
			if (legendaInfo.hasOwnProperty(key)) {
				legendas.push({ valor: key, cor: legendaInfo[key] });
			}
		}
		return legendas;
	};
	borderRadius(index: number) {
		const isFirst = index === 0;
		const isLast = index === this.getLegendas().length - 1;

		const topLeft = isFirst ? '10px' : '0';
		const topRight = isFirst ? '10px' : '0';
		const bottomRight = isLast ? '10px' : '0';
		const bottomLeft = isLast ? '10px' : '0';

		return `border-radius: ${topRight} ${bottomRight} ${bottomLeft} ${topLeft} ;`;
	}
}
