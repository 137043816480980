import { format } from 'date-fns';
import { PeriodosLabel } from '../interfaces';

export function formatarDataPeriodo(
	dataInicial: string | number | Date,
	dataFinal: string | number | Date,
	periodo?: string | PeriodosLabel,
	isPeriodoUnico = false
) {
	const dataInicialFormatada = new Date(dataInicial);
	const dataFinalFormatada = new Date(dataFinal);
	if (periodo == 'MENSAL' || periodo == 'mensal') {
		if (isPeriodoUnico) {
			return `${format(dataInicialFormatada, 'MM/yyyy')}`;
		}
		return `${format(dataInicialFormatada, 'MM/yyyy')} à ${format(
			dataFinalFormatada,
			'MM/yyyy'
		)}`;
	}

	if (periodo == 'DIARIO' || periodo == 'diario') {
		if (isPeriodoUnico) {
			return `${format(dataInicialFormatada, 'dd/MM/yyyy')}`;
		}
		return `${format(dataInicialFormatada, 'dd/MM/yyyy')} à ${format(
			dataFinalFormatada,
			'dd/MM/yyyy'
		)}`;
	}

	if (isPeriodoUnico) {
		return `${format(dataInicialFormatada, 'yyyy')}`;
	}
	return `${format(dataInicialFormatada, 'yyyy')} à ${format(
		dataFinalFormatada,
		'yyyy'
	)}`;
}
