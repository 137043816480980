<div class="container">
	<form [formGroup]="form" class="form-group">
		<div class="mt-4">
			<label class="text-center text-tertiary fw-semibold w-100">
				CLIMATOLOGIA (mm) - {{ periodoTitulo }}
			</label>
			<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />
			<div
				*ngIf="carregando; else mapa"
				class="d-flex justify-content-center align-items-center w-100 h-50"
				style="min-height: 500px">
				<seira-loading [pageCentered]="false"></seira-loading>
			</div>
			<ng-template #mapa>
				<div class="row" *ngIf="interpolacao; else nenhumResultado">
					<div class="col-12">
						<div class="mb-3" style="position: relative">
							<seira-mapa-interpolacao
								[mapaId]="'climatologia'"
								[mapaNome]="'Climatologia'"
								[imageOpacity]="0.8"
								[interpolacao]="interpolacao"
								[valores]="climatologias"
								[zoomOnlyWithCtrl]="true">
							</seira-mapa-interpolacao>
						</div>
					</div>
				</div>
				<ng-template #nenhumResultado>
					<div class="row">
						<div class="col-12">
							<seira-sem-dados></seira-sem-dados>
						</div>
					</div>
				</ng-template>
			</ng-template>
		</div>
	</form>
</div>
