import {
	Component,
	inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ValorPorGeocodigo } from '@componentes/mapa-paraiba-svg/mapa.interfaces';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import { numberToBrNumber, obter_erro_request } from '@utils';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Estacao } from '../../../interfaces/filtros-opcoes';
import { LegendaItem } from '../../../interfaces/legenda-de-cores';
import { DadosMapaQuantis } from '../../../interfaces/mapa-quantis-mensal';
import {
	ChuvasPorMicrorregiao,
	PrecipitacaoPorMunicipio,
} from '../../../interfaces/quantis-anual';
import { PostosRelatorios } from '../../../interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '../../../interfaces/tipos-relatorios';
import { GraficosPrecipitacaoService } from '../../../services/graficos-precipitacao.service';
import { MapasQuantisAnualService } from '../../../services/mapas-quantis-anual.service';
import { format } from 'date-fns';

@Component({
	selector: 'seira-mapa-quantis',
	templateUrl: './mapa-quantis.component.html',
	styleUrls: ['./mapa-quantis.component.scss'],
})
export class MapaQuantisComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];
	chuvaPorMunicipio: ValorPorGeocodigo[] = [];
	legendaItems: LegendaItem[] = [];
	fraseAnaliseChuvas = '';
	mapa = false;
	microrregiaoNome = '';
	loading = false;
	isDesktop = window.innerWidth > 1200;
	months = [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	];
	descricaoRelatorio = '';
	descricaoRelatorioMensal =
		'Define-se como quantis mensal os valores que dividem a distribuição da precipitação mensal em partes iguais, permitindo a análise de diferentes percentis da chuva observada em uma microrregião.';
	descricaoRelatorioAnual =
		'Define-se como quantis anual os valores que segmentam a precipitação acumulada ao longo de um ano, dividindo-a em percentis para analisar a distribuição da chuva em uma microrregião.';

	private subscription = new Subscription();
	constructor(
		private mapasQuantisAnualService: MapasQuantisAnualService,
		private graficosPrecipitacaoService: GraficosPrecipitacaoService,
		private toastr: ToastrService
	) {}
	botoesDeExportacao: GroupButton[] = [];
	ngOnInit() {
		this.form = this.inputs.form;

		setTimeout(() => {
			this.setValidators();
			this.submitFirstSearch();
		}, 0);

		setTimeout(() => {
			this.gerarRelatorio();
		}, 1);
	}
	submitFirstSearch() {
		const tipo = this.form.get('tipo')?.value!;
		this.form.patchValue({
			periodo:
				tipo === 'QUANTIS_MENSAL' ? moment() : moment().subtract(1, 'year'),
			microrregiao: this.microrregioes[0].value,
		});
		this.descricaoRelatorio =
			tipo === 'QUANTIS_MENSAL'
				? this.descricaoRelatorioMensal
				: this.descricaoRelatorioAnual;
	}
	setValidators() {
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.MICRORREGIAO)
			?.setValidators(Validators.required);
	}
	gerarRelatorio() {
		if (this.form.invalid) {
			return;
		}
		this.loading = true;
		this.inputs.setLoading(true);
		const periodo = this.form.get('periodo')?.value!;
		const microrregiao = Number(this.form.get('microrregiao')?.value!);
		const tipo = this.form.get('tipo')?.value!;
		const microrregiaoSelect = this.microrregioes.find(
			value => Number(value.value) === microrregiao
		);
		this.microrregiaoNome = microrregiaoSelect ? microrregiaoSelect.name : '';
		const anoSelecionado = moment(periodo).toDate().getFullYear();
		let regioesIds: number[] = [];
		if (microrregiao === 0) {
			regioesIds = this.microrregioes
				.filter(micro => micro.value !== 'Estado completo')
				.map(micro => Number(micro.value));
		} else {
			regioesIds.push(Number(microrregiao));
		}
		if (tipo === 'QUANTIS_MENSAL') {
			this.fraseAnaliseChuvas = ` QUANTIS MENSAL - ${this.microrregiaoNome.toUpperCase()} - ${format(
				new Date(periodo),
				'MM/yyyy'
			)}`;
			this.graficosPrecipitacaoService
				.buscarMapaQuantisMensal(regioesIds, null, periodo)
				.subscribe({
					next: resp => {
						this.chuvaPorMunicipio = [];
						if (Array.isArray(resp) && resp.length > 0) {
							resp.forEach((resp: DadosMapaQuantis) => {
								if (resp && resp.precipitacoesMunicipios) {
									resp.precipitacoesMunicipios.forEach(
										(micro: PrecipitacaoPorMunicipio) => {
											this.chuvaPorMunicipio.push({
												valor: micro.precipitacaoAcumulada,
												geocodigo: micro.geocodigo,
											});
										}
									);
								}
							});
						}
						this.mapa = true;
						this.loading = false;
						this.inputs.setLoading(false);
					},
					error: erro => {
						this.loading = false;
						this.inputs.setLoading(false);
						const msg_erro = obter_erro_request(erro);
						this.toastr.error(
							msg_erro,
							'Erro ao obter informações do posto selecionado'
						);
					},
				});
		} else {
			const primeiroDiaDoAno = new Date(anoSelecionado, 0, 1);
			const ultimoDiaDoAno = new Date(anoSelecionado, 11, 31, 23, 59, 59, 999);
			this.fraseAnaliseChuvas = ` QUANTIS ANUAL - ${this.microrregiaoNome.toUpperCase()} - ${format(
				new Date(primeiroDiaDoAno),
				'yyyy'
			)}`;
			this.mapasQuantisAnualService
				.mapaQuantisAnual({
					regioesIds: regioesIds,
					diaInicio: primeiroDiaDoAno,
					diaFim: ultimoDiaDoAno,
				})
				.subscribe({
					next: resp => {
						this.chuvaPorMunicipio = [];
						if (Array.isArray(resp) && resp.length > 0) {
							resp.forEach((resp: ChuvasPorMicrorregiao) => {
								if (resp && resp.precipitacoesMunicipios) {
									resp.precipitacoesMunicipios.forEach(
										(micro: PrecipitacaoPorMunicipio) => {
											this.chuvaPorMunicipio.push({
												valor: micro.precipitacaoAcumulada,
												geocodigo: micro.geocodigo,
											});
										}
									);
								}
							});
						}
						this.mapa = true;
						this.loading = false;
						this.inputs.setLoading(false);
					},
					error: erro => {
						this.loading = false;
						this.inputs.setLoading(false);
						const msg_erro = obter_erro_request(erro);
						this.toastr.error(
							msg_erro,
							'Erro ao obter informações do posto selecionado'
						);
					},
				});
		}
	}

	getTipo() {
		return this.form.get('tipo')?.value === 'QUANTIS_MENSAL'
			? 'Quantis mensal'
			: 'Quantis anual';
	}

	getPeriodo() {
		const periodo = this.form.get('periodo')?.value;
		const tipo = this.form.get('tipo')?.value;

		if (!periodo) return '';

		return tipo === 'QUANTIS_MENSAL'
			? periodo.format('MM/yyyy')
			: periodo.format('yyyy');
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.subscription.unsubscribe();
	}
	handleLegendaItems(legendaItems: LegendaItem[]) {
		this.legendaItems = legendaItems;
	}

	precipitacaoAcumuladaPorGeocodigo(geocodigo: string) {
		const precipitacao = this.chuvaPorMunicipio.find(
			municipio => municipio.geocodigo === +geocodigo
		)?.valor;

		return numberToBrNumber(precipitacao)?.toString();
	}

	getTituloLegenda() {
		const tipo = this.form.get('tipo')?.value!;
		const unidadeMap = new Map([
			['QUANTIS_MENSAL', 'Quantis mensal'],
			['QUANTIS_ANUAL', 'Quantis anual'],
		]);
		return unidadeMap.get(tipo);
	}
}
