import * as Highcharts from 'highcharts/highcharts';
import Variwide from 'highcharts/modules/variwide';

Variwide(Highcharts);

export const options: Highcharts.Options = {
	chart: {
		type: 'variwide',
	},
	title: {
		text: 'Número de dias com chuva',
	},
	credits: {
		enabled: false,
	},
	legend: {
		enabled: false,
	},
	xAxis: {
		type: 'category',
	},
	yAxis: {
		min: 0,
		title: {
			text: 'Número de dias com chuva',
		},
	},
	plotOptions: {
		variwide: {
			pointPadding: 0.1,
			groupPadding: 0.1,
			borderWidth: 0,
		},
	},
	series: [],
};
