import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { FormGroup, Validators } from '@angular/forms';
import { formatISO } from 'date-fns';
import { FeatureCollection, Polygon } from 'geojson';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Position } from 'pdfmake/interfaces';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
	TipoValor,
	ValoresBusca,
} from '@utils/interpolacao/interpolacao';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { formatarDataPeriodo } from '../../../utils';

@Component({
	selector: 'seira-mapa-climatologia',
	templateUrl: './mapa-climatologia.component.html',
	styleUrls: ['./mapa-climatologia.component.scss'],
})
export class MapaClimatologiaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	climatologias = [];
	regiao?: Position[][];
	carregando = false;
	interpolacao?: InterpolacaoCanvasBounds;
	descricaoRelatorio =
		'Define-se como climatologia o estudo das condições médias do clima em uma região ao longo de um período de 30 anos, utilizado como referência para comparar variações climáticas, como a precipitação.';
	periodoTitulo = '';

	constructor(
		private httpClient: HttpClient,
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService
	) {}
	ngOnInit() {
		this.form = this.inputs.form;
		setTimeout(() => {
			this.setValidators();
		}, 0);
		this.initialValues();
		this.gerarRelatorio();
	}
	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}
	setValidators() {
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		if (this.form.invalid) {
			return;
		}
		const dataInicio = this.form.get(FormularioRelatorio.DATA_INICIO)?.value;
		const dataFim = this.form.get(FormularioRelatorio.DATA_FIM)?.value;

		if (!dataInicio || !dataFim) {
			return;
		}

		this.inputs.setLoading(true);
		this.carregando = true;
		this.periodoTitulo = formatarDataPeriodo(dataInicio, dataFim, 'mensal');
		this.relatoriosService
			.buscarClimatologia(
				formatISO(new Date(dataInicio)),
				formatISO(new Date(dataFim))
			)
			.subscribe({
				next: (resp: []) => {
					this.climatologias = resp;
				},
				error: (erro: HttpErrorResponse) => {
					this.carregando = false;
					this.inputs.setLoading(false);
					this.toastr.error('Nenhum resultado encontrado', erro.message);
				},
				complete: () => {
					this.interpolarValores();
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	interpolarValores() {
		this.carregando = true;
		if (this.climatologias.length > 0) {
			this.criarImagemInterpolacao().then(interpolacao => {
				this.interpolacao = interpolacao as InterpolacaoCanvasBounds;
				this.carregando = false;
			});
		} else {
			this.interpolacao = undefined;
			this.carregando = false;
		}
	}

	criarImagemInterpolacao() {
		const valores: ValoresBusca[] = this.climatologias.map((value: any) => {
			return {
				lat: value.latitude,
				lng: value.longitude,
				value: value.total,
				municipio: value.nome,
				unidade: 'mm',
				tipoValor: TipoValor.CLIMATOLOGIA,
			};
		});
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				valores,
				'climatologia',
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject();
			}
			resolve(interpolacao);
		});
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.TIPO_VISUALIZACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}
}
