import {
	AfterViewInit,
	ApplicationRef,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	inject,
	Injector,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import * as Leaflet from 'leaflet';
import { PublicAComponent } from '@componentes/public-a/public-a.component';
import { StatusEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/status-estacao';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { FormGroup } from '@angular/forms';
import { Select } from '@layout/interfaces/select';
import { HttpClient } from '@angular/common/http';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import {
	CORES,
	LegendaCores,
	legendaInfo,
} from '@componentes/mapa-paraiba-svg/legenda';
import { AgrupamentoResponse } from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import { PopupAgrupamentoComponent } from '@home/submodulos/dados-meteorologicos/componentes/popup-agrupamento/popup-agrupamento.component';
import { enumAsSelectOptions, numberToBrNumber } from '@utils';
import {
	AgrupamentoGeojson,
	agrupamentoGeojsonMap,
} from '@home/submodulos/dados-meteorologicos/enum/Agrupamento';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import * as pdfseira from '@utils/pdf-seira';
import pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { formatarDataPeriodo } from '../../../utils';

@Component({
	selector: 'seira-mapa-pluviometria-observada',
	templateUrl: './mapa-pluviometria-observada.component.html',
	styleUrls: ['./mapa-pluviometria-observada.component.scss'],
})
export class MapaPluviometriaObservadaComponent
	implements OnInit, AfterViewInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild('linkRef', { static: false })
	linkRef!: TemplateRef<PublicAComponent>;
	map!: Leaflet.Map;

	loadingFiltrando = false;

	status: { label: StatusEstacao; value: keyof typeof StatusEstacao }[] = [];
	show = 'block';

	centerDefault: Leaflet.LatLngExpression = [-7.2605416, -36.7290255];
	zoomDefault = 8;
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	agrupamento: Select[] = [];
	geoJsonLayer?: Leaflet.GeoJSON;
	legenda = enumAsSelectOptions(legendaInfo);
	agrupamentoResult: AgrupamentoResponse[] = [];
	periodoTitulo = '';

	descricaoRelatorio =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPdf();
			},
		},
	];
	constructor(
		private resolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
		private cdr: ChangeDetectorRef,
		private httpClient: HttpClient,
		private relatorioService: RelatoriosService
	) {}

	ngOnInit() {
		this.form = this.inputs.form;
		this.gerarRelatorio();
	}

	ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	setupMap(mapa: Leaflet.Map) {
		this.map = mapa;
		this.cdr.detectChanges();
	}

	compilePopupAgrupamento(
		onAttach: (el: ComponentRef<PopupAgrupamentoComponent>) => void
	) {
		const compFactory = this.resolver.resolveComponentFactory(
			PopupAgrupamentoComponent
		);
		const compRef = compFactory.create(this.injector);

		// onAttach allows you to assign
		if (onAttach) onAttach(compRef);

		this.appRef.attachView(compRef.hostView);
		compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

		const div = document.createElement('div');
		div.appendChild(compRef.location.nativeElement);
		return div;
	}
	ngOnDestroy(): void {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.form.get(FormularioRelatorio.PRECIPITACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
	}
	gerarRelatorio() {
		const diaInicio = this.getFormItemValue('dataInicio');
		const diaFim = this.getFormItemValue('dataFim');
		const agrupamento = this.getFormItemValue('agrupamento');
		const periodo = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		) as PeriodosLabel;

		this.inputs.setLoading(true);
		this.loadingFiltrando = true;
		this.periodoTitulo = formatarDataPeriodo(diaInicio, diaFim, periodo);
		this.relatorioService
			.buscarRelatorioPorAgrupamento({
				diaInicio,
				diaFim,
				agrupamento,
				periodoAgrupamento: periodo.toUpperCase(),
			})
			.subscribe({
				next: (val: AgrupamentoResponse[]) => {
					this.agrupamentoResult = this.maioresPreciptacoesPorMunicipio(
						val,
						agrupamento
					);
				},
				error: () => {
					this.loadingFiltrando = false;
				},
				complete: () => {
					this.loadMunicipiosMap(this.map);
					this.loadingFiltrando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	maioresPreciptacoesPorMunicipio(
		val: AgrupamentoResponse[],
		agrupamento: string
	) {
		const copiaVal = JSON.parse(JSON.stringify(val));
		const arrayPorMunicipio = copiaVal.reduce((anterior: any, atual: any) => {
			const municipio = atual.nome.split('/')[0];
			if (!anterior[municipio]) {
				anterior[municipio] = [];
			}
			anterior[municipio].push(atual);
			return anterior;
		}, {});

		const resultado = Object.keys(arrayPorMunicipio).map(key => {
			const maior = arrayPorMunicipio[key].reduce(
				(max: AgrupamentoResponse, item: AgrupamentoResponse) => {
					return item.precipitacaoAcumulada > max.precipitacaoAcumulada
						? item
						: max;
				}
			);

			if (maior.nome.split('/')[0] === maior.nome.split('/')[1]) {
				maior.nomeOriginal = maior.nome.split('/')[0];
			} else {
				maior.nomeOriginal = maior.nome;
			}
			maior.nome = maior.nome.split('/')[0];
			return maior;
		});

		return resultado;
	}

	getGeojsonPorAgrupamento() {
		const agrupamentoStr = this.form?.get('agrupamento')?.value;
		const agrupamento = agrupamentoGeojsonMap[agrupamentoStr];

		return agrupamento ? agrupamento : AgrupamentoGeojson.MUNICIPIO;
	}

	loadMunicipiosMap(map: Leaflet.Map) {
		if (this.geoJsonLayer) {
			this.geoJsonLayer.remove();
			this.geoJsonLayer.clearLayers();
		}

		const geojson = this.getGeojsonPorAgrupamento();

		this.httpClient.get<GeoJSON.GeoJsonObject>(geojson).subscribe({
			next: value => {
				this.geoJsonLayer = Leaflet.geoJSON(value, {
					style: feature => this.estiloRegiao(feature),
					onEachFeature: (feature, layer) => {
						layer.on({
							click: e => {
								const nome = this.getPropertyNomeGeoson(feature);

								const dadosRegiao = this.agrupamentoResult.find(
									(d: AgrupamentoResponse) => d.nome === nome
								);

								if (dadosRegiao) {
									const markerPopup = this.compilePopupAgrupamento(c => {
										const headerColor = this.getColor(
											dadosRegiao.precipitacaoAcumulada
										);
										c.instance.agrupamento = dadosRegiao;
										c.instance.headerColor = headerColor;
										c.instance.titleColor =
											headerColor === LegendaCores.LARANJA ||
											headerColor === LegendaCores.BRANCO_NEVE ||
											headerColor === LegendaCores.AMARELO
												? 'gray'
												: 'white';
									});

									layer.bindPopup(markerPopup).openPopup(e.latlng);
								}
							},
						});
					},
				}).addTo(map);
			},
		});
	}

	getColor(valor: number): string {
		for (const key of Object.keys(legendaInfo)
			.map(Number)
			.sort((a, b) => b - a)) {
			if (valor >= key) {
				return legendaInfo[key];
			}
		}
		return CORES.CINZA;
	}
	getPropertyNomeGeoson(feature: {
		properties: {
			name: string;
			NM_MESO: string;
			Nome: string;
			nome: string;
			Regiao: string;
		};
	}) {
		return (
			feature.properties.name ||
			feature.properties.NM_MESO ||
			feature.properties.Nome ||
			feature.properties.nome ||
			feature.properties.Regiao
		);
	}
	estiloRegiao(feature: any): any {
		const nome = this.getPropertyNomeGeoson(feature);
		const dadosRegiao = this.agrupamentoResult.find(
			(d: AgrupamentoResponse) => d.nome === nome
		);
		const valorPrecipitacao = dadosRegiao
			? dadosRegiao.precipitacaoAcumulada
			: 0;
		return {
			fillColor: this.getColor(valorPrecipitacao),
			weight: 2,
			opacity: 1,
			color: '#607d8b',
			fillOpacity: 0.7,
		};
	}

	getFormItemValue(formValue: string) {
		return this.form.get(formValue)!.value;
	}

	getDataFormatada(data: Date): string {
		return moment(data).format('DD/MM/YYYY');
	}

	async exportarPdf() {
		const diaInicio = this.getFormItemValue('dataInicio');
		const diaFim = this.getFormItemValue('dataFim');

		const documentDefinition: any = await pdfseira.documentDefinitions();
		const img = await this.getMapImage();

		const dataInicioFormatada = this.getDataFormatada(diaInicio);
		const dataFimFormatada = this.getDataFormatada(diaFim);

		documentDefinition.content.push([
			{
				text: `Relatório - Estações Pluviometricas - ${dataInicioFormatada} - ${dataFimFormatada}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: 500,
				height: 300,
				margin: [0, 15, 5, 15],
				alignment: 'center',
			},
		]);

		const tableData = this.getDadosTabelaParaExportacao(this.agrupamentoResult);
		documentDefinition.content.push({
			text: `Tabela de Municípios`,
			alignment: 'center',
			margin: [0, 15, 0, 15],
			fontSize: 12,
		});
		documentDefinition.content.push(
			{
				text: '',
				alignment: 'center',
				fontSize: 10,
			},
			{
				table: {
					body: tableData,
				},
				marginLeft: 80,
				layout: 'fullWidth',
			}
		);
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}

	getDadosTabelaParaExportacao(dados: AgrupamentoResponse[]) {
		const tableData: any[][] = [];

		const colunas = ['Município/Posto', 'Precipitação'];
		tableData.push(colunas);

		dados.forEach((item: AgrupamentoResponse) => {
			const rowData = [
				item.nomeOriginal ?? item.nome,
				numberToBrNumber(item.precipitacaoAcumulada.toFixed(1)),
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('mapa-legenda');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}
}
