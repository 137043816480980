import { Component, Input } from '@angular/core';
import { AgrupamentoResponse } from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import {
	capitalizeFirstLetter,
	isNotNuloOuUndefined,
	numberToBrNumber,
} from '@utils';

@Component({
	selector: 'seira-popup-agrupamento',
	templateUrl: './popup-agrupamento.component.html',
	styleUrls: ['./popup-agrupamento.component.scss'],
})
export class PopupAgrupamentoComponent {
	@Input() agrupamento?: AgrupamentoResponse;
	@Input() headerColor = 'bg-primary';
	@Input() titleColor = 'white';
	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly capitalizeFirstLetter = capitalizeFirstLetter;
}
