import { Component, Input } from '@angular/core';
import {
	capitalizeFirstLetter,
	isNotNuloOuUndefined,
	numberToBrNumber,
} from '@utils';
import { RelatorioNDCResponse } from '../../interfaces/relatorio-ndc';

@Component({
	selector: 'seira-popup-ndc',
	templateUrl: './popup-ndc.component.html',
	styleUrls: ['./popup-ndc.component.scss'],
})
export class PopupNdcComponent {
	@Input() ndc?: RelatorioNDCResponse;
	@Input() headerColor = 'bg-primary';
	@Input() titleColor = 'white';
	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly capitalizeFirstLetter = capitalizeFirstLetter;
}
