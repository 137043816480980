<div class="d-flex flex-column">
	<label class="text-tertiary text-center w-100 fw-semibold mt-3">
		IAPM - {{ titlePeriodo }}
	</label>

	<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio" />
</div>
<div
	*ngIf="isLoading; else tabela"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>
<ng-template #tabela>
	<div style="max-width: 1300px" *ngIf="tableData; else semDados">
		<seira-tabela-iapm [relatorios]="tableData" />
	</div>
</ng-template>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
