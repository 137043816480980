import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { formataValorPrecipitacao } from '@utils';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
	AgrupamentoResponse,
	DadosAgrupamento,
	Estacao,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
	PostosRelatorios,
} from '../../../interfaces';
import { RelatoriosService } from '../../../services';

import * as pdfMake from 'pdfmake/build/pdfmake';

import { HttpErrorResponse } from '@angular/common/http';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { format } from 'date-fns';
import { corrigeDuplicacaoNome, formatarDataPeriodo } from '../../../utils';

@Component({
	selector: 'seira-tabela-pluviometria-observada',
	templateUrl: './tabela-pluviometria-observada.component.html',
	styleUrls: ['./tabela-pluviometria-observada.component.scss'],
})
export class TabelaPluviometriaObservadaComponent
	implements OnDestroy, InstanciaRelatorio, OnInit
{
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaPluviometriaObservada?: AgrupamentoResponse[];
	carregandoRelatorio = false;
	private subscription = new Subscription();
	loading = false;
	periodo = '';

	descricaoRelatorio =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado.';

	tituloTabela = 'Tabela de Pluviometria Observada';
	colunas: ADTColumns[] = [];

	colunasSelecionadas: string[] = [];
	@ViewChild('tabelaPluviometriaObservada', { static: false })
	tabela?: PublicTableComponent;
	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Município', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportarPDF();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				this.exportarCSV();
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				this.exportarTXT();
			},
		},
	];

	disabledSelectList = false;

	tituloColunaAgrupamento = 'Nome';

	constructor(
		private toastr: ToastrService,
		private relatorioService: RelatoriosService
	) {
		this.form = this.inputs.form;
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
		this.setValidators();
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
	}

	ngOnInit(): void {
		this.gerarRelatorio();
	}

	setColunasSelecionadas(colunas: string[]) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	atualizarTituloColuna() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);
		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				this.tituloColunaAgrupamento = 'Município/Posto';
				break;
			case 'MICRORREGIAO':
				this.tituloColunaAgrupamento = 'Microrregião';
				break;
			case 'MUNICIPIO':
				this.tituloColunaAgrupamento = 'Município';
				break;
			case 'MESORREGIAO':
				this.tituloColunaAgrupamento = 'Mesorregião';
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.tituloColunaAgrupamento = 'Região Pluviométrica';
				break;
			case 'BACIA':
				this.tituloColunaAgrupamento = 'Bacia';
				break;
			case 'SUB_BACIA':
				this.tituloColunaAgrupamento = 'Sub bacia';
				break;
		}
	}

	atualizarColunasTabela() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: null,
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-left',
					render: (data: AgrupamentoResponse) =>
						corrigeDuplicacaoNome(data.nome),
				},
				{
					data: 'dadosPosto.latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'dadosPosto.regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
				{
					data: 'dadosPosto.bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunas = [...colunasParaExibir];
		} else {
			this.disabledSelectList = true;
			this.colunas = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-center',
				},
			];
		}

		this.colunas.push(
			...[
				{
					data: 'data',
					title: 'Data',
					className: 'text-center',
					render: (data: string) => format(new Date(data), 'MM/yyyy'),
				},
				{
					data: 'precipitacaoAcumulada',
					render: (data: number) => {
						return formataValorPrecipitacao(data);
					},
					title: 'Pluviometria Observada (mm)',
					className: 'text-center',
				},
			]
		);
	}

	setValidators() {
		this.lidarComAgrupamento();
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}
	lidarComAgrupamento() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO);
		const municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO);
		const microrregiao = this.inputs.form.get(FormularioRelatorio.MICRORREGIAO);
		const municipioPosto = this.inputs.form.get(FormularioRelatorio.ESTACAO);
		municipio?.clearValidators();
		microrregiao?.clearValidators();
		municipioPosto?.clearValidators();
		switch (agrupamento?.value) {
			case 'MUNICIPIO_POSTO':
				municipioPosto?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				microrregiao?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				municipio?.setValidators(Validators.required);
				break;
		}
	}
	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.subscription.unsubscribe();
	}

	gerarRelatorio(): void {
		if (this.form.invalid) {
			return;
		}
		this.loading = true;
		this.carregandoRelatorio = true;
		this.inputs.setLoading(true);
		const diaInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;

		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		this.periodo = formatarDataPeriodo(diaInicio, diaFim, periodoAgrupamento);
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		this.inputs.setLoading(true);
		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			municipio: this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.value,
			posto: this.inputs.form.get(FormularioRelatorio.ESTACAO)?.value,
			microrregiao: this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)
				?.value,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
		};
		this.relatorioService.buscarRelatorioPorAgrupamento(dados).subscribe({
			next: preciptacoesAgrupadas => {
				this.dadosTabelaPluviometriaObservada = preciptacoesAgrupadas;
				this.atualizarTituloColuna();
				this.atualizarColunasTabela();
			},
			error: (err: HttpErrorResponse) => {
				this.inputs.setLoading(false);
				this.loading = false;
				this.carregandoRelatorio = false;
				this.dadosTabelaPluviometriaObservada = undefined;
				this.toastr.error(
					'Erro ao buscar dados de Pluviometria observada',
					err.message
				);
			},
			complete: () => {
				this.inputs.setLoading(false);
				this.loading = false;
				this.carregandoRelatorio = false;
			},
		});
	}
	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	async exportarTXT() {
		let txtData = '';

		const tableData = this.prepararDadosParaTabela();

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}
		DocumentExporter.gerarTXT(
			txtData,
			`Relatório de Pluviometria Observada -(${this.periodo})`
		);
	}

	exportarCSV() {
		if (this.temDadosParaTabela()) {
			const tableData = this.prepararDadosParaTabela();
			DocumentExporter.gerarCSV(
				tableData,
				`Relatório de Pluviometria Observada -(${this.periodo})`
			);
		}
	}
	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';
		this.adicionarTitulo(documentDefinition);

		if (this.temDadosParaTabela()) {
			const tableData = this.prepararDadosParaTabela();
			this.adicionarTabelaAoDocumento(documentDefinition, tableData);
		} else {
			this.adicionarMensagemSemDados(documentDefinition);
		}

		this.gerarDocumentoPDF(documentDefinition);
	}

	private adicionarTitulo(documentDefinition: any): void {
		documentDefinition.content.push({
			text: `${this.tituloTabela}(${this.periodo})`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});
	}

	private temDadosParaTabela(): boolean {
		return !!this.dadosTabelaPluviometriaObservada && !!this.tabela;
	}

	private prepararDadosParaTabela(): string[][] {
		const visibleColumns = this.getVisibleColumns();
		const tableData = [visibleColumns];

		this.tabela?.data.forEach((row: any) => {
			const rowData = this.prepararDadosDaLinha(row, visibleColumns);
			tableData.push(rowData);
		});

		return tableData;
	}

	private getVisibleColumns(): string[] {
		return this.tabela!.columns.map(col => col.title as string);
	}

	private prepararDadosDaLinha(row: any, visibleColumns: string[]): string[] {
		return visibleColumns.map(columnTitle => {
			if (columnTitle === 'Município/Posto') {
				return this.formatarMunicipioPosto(row);
			} else {
				return this.getCellValue(row, columnTitle);
			}
		});
	}

	private formatarMunicipioPosto(row: any): string {
		return row.nome;
	}

	private getCellValue(row: any, columnTitle: string): string {
		const column = this.tabela!.columns.find(col => col.title === columnTitle);
		if (column && column.data) {
			return this.extractNestedValue(row, column.data as string) ?? '-';
		}
		return '-';
	}

	private extractNestedValue(obj: any, path: string): any {
		return path.split('.').reduce((value, key) => value?.[key], obj);
	}

	private adicionarTabelaAoDocumento(
		documentDefinition: any,
		tableData: string[][]
	): void {
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: 'lightHorizontalLines',
			},
		});
	}

	private adicionarMensagemSemDados(documentDefinition: any): void {
		documentDefinition.content.push({
			text: 'Nenhum dado encontrado na tabela',
			alignment: 'center',
			fontSize: 10,
			margin: [0, 10],
		});
	}

	private gerarDocumentoPDF(documentDefinition: any): void {
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}
}
