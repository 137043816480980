import { Component, inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { DadosAgrupamento } from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { format } from 'date-fns';
import * as Highcharts from 'highcharts/highstock';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { EPeriodosBusca } from '../../../enum';
import { RelatorioNDCResponse } from './../../../interfaces/relatorio-ndc';
import { options } from './chartOptions';
import { formatarDataPeriodo, gerarFilenameGrafico } from '../../../utils';

@Component({
	selector: 'seira-grafico-ndc',
	templateUrl: './grafico-ndc.component.html',
	styleUrls: ['./grafico-ndc.component.scss'],
})
export class GraficoNdcComponent implements OnInit {
	chart!: Highcharts.Chart;
	chartOptions: Highcharts.Options = options;

	inputs = inject(INPUTS_RELATORIOS);
	_destroyed = new Subject();

	tituloEixoX = '';
	tituloChart = 'Gráfico de NDC';
	resultados: RelatorioNDCResponse[] = [];
	descricaoRelatorio =
		'Define-se como NDC o número de dias em que houve registro de precipitação em uma localidade, utilizado para monitorar a frequência de eventos de chuva em determinado período.';

	periodoTitulo = '';
	loading: boolean;

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.gerarRelatorio();
		this.observarMudancasPeriodoBusca();
	}

	observarMudancasPeriodoBusca() {
		const periodoBusca = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		);

		this.lidarComPeriodo(periodoBusca?.value);
		periodoBusca?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: (periodo: PeriodosLabel | null) => {
				this.lidarComPeriodo(periodo);
			},
		});
	}

	lidarComPeriodo(periodo: PeriodosLabel | null) {
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);

		if (dataInicio && dataFim) {
			const hoje = new Date();
			dataFim.setValue(moment(hoje));

			switch (periodo) {
				case 'anual':
					if (dataFim?.value) {
						const hoje = new Date();
						if (dataFim.value.year() == hoje.getFullYear()) {
							dataFim?.setValue(moment(hoje));
						} else {
							dataFim?.setValue(dataFim.value.endOf('year'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.ANUAL,
							'year'
						);
						dataInicio?.setValue(fim);
					}
					break;
				case 'mensal':
					if (dataFim?.value) {
						const hoje = new Date();
						if (
							dataFim.value.year() === hoje.getFullYear() &&
							dataFim.value.month() >= hoje.getMonth()
						) {
							dataFim.setValue(moment(hoje));
						} else {
							dataFim.setValue(dataFim.value.endOf('month'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.MENSAL,
							'month'
						);
						dataInicio?.setValue(fim);
					}
					break;
				case 'diario':
					if (dataFim?.value) {
						const hoje = new Date();
						if (
							dataFim.value.year() === hoje.getFullYear() &&
							dataFim.value.month() >= hoje.getMonth()
						) {
							dataFim?.setValue(moment(hoje));
						} else {
							dataFim?.setValue(dataFim.value.endOf('month'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.DIARIO,
							'day'
						);
						dataInicio?.setValue(fim);
					}
					break;
			}
		}
	}

	setValidatorsAgrupamento() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO);
		const municipio = this.getDataFromForm(FormularioRelatorio.MUNICIPIO);
		const microrregiao = this.getDataFromForm(FormularioRelatorio.MUNICIPIO);
		const municipioPosto = this.getDataFromForm(FormularioRelatorio.MUNICIPIO);

		municipio?.clearValidators();
		microrregiao?.clearValidators();
		municipioPosto?.clearValidators();

		switch (agrupamento?.value) {
			case 'MUNICIPIO_POSTO':
				municipioPosto?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				microrregiao?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				municipio?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.setValidatorsAgrupamento();
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: () => {
					this.setValidatorsAgrupamento();
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setChart(chart: Highcharts.Chart) {
		this.chart = chart;
		this.atualizarTitulosChart();
		this.configurarChart(this.resultados);
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	gerarRelatorio() {
		this.limparChart();

		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.loading = true;
		this.inputs.setLoading(true);
		this.periodoTitulo = formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodoAgrupamento
		);

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
		};

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = this.getDataFromForm(FormularioRelatorio.ESTACAO)?.value;
				break;
			case 'MICRORREGIAO':
				dados.microrregiao = this.getDataFromForm(
					FormularioRelatorio.MICRORREGIAO
				)?.value;
				break;
			case 'MUNICIPIO':
				dados.municipio = this.getDataFromForm(FormularioRelatorio.MUNICIPIO)
					?.value;
				break;
		}

		this.relatoriosService.buscarRelatorioNDCPorAgrupamento(dados).subscribe({
			next: ndcAgrupados => {
				this.resultados = ndcAgrupados;

				this.chartOptions = {
					...this.chartOptions,
					exporting: {
						enabled: true,
						buttons: {
							contextButton: {
								menuItems: [
									'viewFullscreen',
									'separator',
									'downloadPNG',
									'downloadJPEG',
								],
							},
						},
						filename: gerarFilenameGrafico(
							`grafico_numero_dias_com_chuva_${this.inputs.form
								.get(FormularioRelatorio.AGRUPAMENTO)
								?.value.toLocaleLowerCase()}_${periodoAgrupamento.toLocaleLowerCase()}`
						),
					},
				};
			},
			error: err => {
				this.toastrService.error(
					'Erro ao gerar gráfico de NDC, tente novamente'
				);
				this.inputs.setLoading(false);
				this.configurarChart([]);
			},
			complete: () => {
				this.loading = false;
				this.inputs.setLoading(false);
			},
		});
	}

	limparChart() {
		if (this.chart) {
			for (let i = this.chart.series.length - 1; i >= 0; i--) {
				this.chart?.series[i].remove(false);
			}
		}
	}

	configurarChart(ndcAgrupados: RelatorioNDCResponse[]) {
		const series = [
			{
				name: 'Dias com chuva',
				data: ndcAgrupados.map(ndc => {
					return {
						name: ndc.nome,
						y: ndc.diasComChuva,
						z: 1,
						precipitacao: ndc.somaPrecipitacao
							? ndc.somaPrecipitacao.toFixed(1).replace('.', ',')
							: 0,
					};
				}),
				tooltip: {
					pointFormat:
						'Dias com chuva: <b>{point.y} dias</b><br>' +
						'Precipitação total: <b>{point.precipitacao} mm</b><br>',
				},
				colorByPoint: true,
			},
		];

		series.forEach(item => {
			this.chart?.addSeries(item as any);
		});

		this.chart.xAxis[0].setTitle({ text: this.tituloEixoX });
		this.chart.setTitle({ text: this.tituloChart });
	}

	atualizarTitulosChart() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoBusca = this.getDataFromForm(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);

		let dataInicioFomatada = '';
		let dataFimFomatada = '';

		if (dataInicio && dataFim && periodoBusca) {
			switch (periodoBusca) {
				case 'anual':
					dataInicioFomatada = format(dataInicio.value.toDate(), 'yyyy');
					dataFimFomatada = format(dataFim.value.toDate(), 'yyyy');
					break;
				case 'mensal':
					dataInicioFomatada = format(dataInicio.value.toDate(), 'MM/yyyy');
					dataFimFomatada = format(dataFim.value.toDate(), 'MM/yyyy');

					break;
				case 'diario':
					dataInicioFomatada = format(dataInicio.value.toDate(), 'dd/MM/yyyy');
					dataFimFomatada = format(dataFim.value.toDate(), 'dd/MM/yyyy');
					break;
			}
			this.tituloChart = `Gráfico de Número de Dias com Chuva - (${dataInicioFomatada} à ${dataFimFomatada})`;
		} else {
			this.tituloChart = `Gráfico de Número de Dias com Chuva`;
		}

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				this.tituloEixoX = 'Município/Posto';
				break;
			case 'MICRORREGIAO':
				this.tituloEixoX = 'Microrregião';
				break;
			case 'MUNICIPIO':
				this.tituloEixoX = 'Município';
				break;
			case 'MESORREGIAO':
				this.tituloEixoX = 'Mesorregiões';
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.tituloEixoX = 'Regiões Pluviométricas';
				break;
			case 'BACIA':
				this.tituloEixoX = 'Bacias';
				break;
			case 'SUB_BACIA':
				this.tituloEixoX = 'Sub bacias';
				break;
		}
	}
}
