import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { DadosMeteorologicosRoutingModule } from './dados-meteorologicos-routing.module';
import { BoletimDiarioComponent } from './pages/boletim-diario/boletim-diario.component';

import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardValuesComponent } from './componentes/dashboard-values/dashboard-values.component';
import { DataTablesModule } from 'angular-datatables';
import { MonitoramentoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/monitoramento/monitoramento.component';
import { TabelaRelatoriosComponent } from './componentes/tabela-relatorios/tabela-relatorios.component';
import { ModalRelatorioPostosComponent } from './componentes/modal-relatorio-postos/modal-relatorio-postos.component';
import { ExportAsModule } from 'ngx-export-as';
import { CustomRadioGroupComponent } from './componentes/custom-radio-group/custom-radio-group.component';
import { CycleSelectComponent } from '@home/submodulos/dados-meteorologicos/componentes/cycle-select/cycle-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExportButtonBoletimComponent } from '@home/submodulos/dados-meteorologicos/componentes/export-button-boletim/export-button-boletim.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopupBoletimDiarioMapaComponent } from './componentes/popup-boletim-diario-mapa/popup-boletim-diario-mapa.component';
import { NgxLoadingModule } from 'ngx-loading';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MonitoramentoModule } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/monitoramento.module';
import { LegendaMapaGraficoRelatorioComponentHtmlComponent } from './componentes/legenda-mapa-grafico-relatorio.component.html/legenda-mapa-grafico-relatorio.component.html.component';
import { GraficoQuantisComponent } from './componentes/grafico-quantis/grafico-quantis.component';
import { TabelaRelatorioEvapotranspiracaoComponent } from './componentes/tabela-relatorio-evapotranspiracao/tabela-relatorio-evapotranspiracao.component';
import { ImagemHistoricoRelatorioComponent } from './componentes/imagem-historico-relatorio/imagem-historico-relatorio.component';
import { TabelaRelatoriosDiariosComponent } from '@home/submodulos/dados-meteorologicos/componentes/tabela-relatorios-diarios/tabela-relatorios-diarios.component';
import { SelectPostosRelatorioComponent } from './componentes/select-postos-relatorio/select-postos-relatorio.component';
import { ModalRelatorioHistoricoImagensComponent } from './componentes/modal-relatorio-historico-imagens/modal-relatorio-historico-imagens.component';
import { LegendaRelatorioHistoricoVariaveisComponent } from './componentes/legenda-relatorio-historico-variaveis/legenda-relatorio-historico-variaveis.component';
import { PluviometroTabelaPrecipitacaoDiariaComponent } from './componentes/relatorios/pluviometro-tabela-precipitacao-diaria/pluviometro-tabela-precipitacao-diaria.component';
import { PluviometroGraficoQuantisComponent } from './componentes/relatorios/pluviometro-grafico-quantis/pluviometro-grafico-quantis.component';
import { EvapotranspiracaoTabelaComponent } from './componentes/relatorios/evapotranspiracao-tabela/evapotranspiracao-tabela.component';
import { SemDadosComponent } from './componentes/relatorios/sem-dados/sem-dados.component';
import { VariaveisMetorologicasMapaComponent } from './componentes/relatorios/variaveis-metorologicas-mapa/variaveis-metorologicas-mapa.component';
import { PluviometricoTabelaMediaHistoricaComponent } from './componentes/relatorios/pluviometrico-tabela-media-historica/pluviometrico-tabela-media-historica.component';
import { TabelaRelatorioMediaHistoricaComponent } from './componentes/tabela-relatorio-media-historica/tabela-relatorio-media-historica.component';
import { ModalLegendaMapaRelatoriosComponent } from './componentes/modal-legenda-mapa-relatorios/modal-legenda-mapa-relatorios.component';
import { MapaQuantisComponent } from './componentes/relatorios/mapa-quantis/mapa-quantis.component';
import { LegendaRelatorioComponent } from './componentes/legenda-relatorio/legenda-relatorio/legenda-relatorio.component';
import { TabelaRelatoriosPluviometricosPorPostoComponent } from './componentes/tabela-relatorios-pluviometricos-por-posto/tabela-relatorios-pluviometricos-por-posto.component';
import { PluviometroTabelaPluviometricoPorPostoComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-pluviometrico-por-posto/pluviometro-tabela-pluviometrico-por-posto.component';
import { PluviometroGraficoSpiComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-grafico-spi/pluviometro-grafico-spi.component';
import { MapaChuvaEstadoComponent } from './componentes/relatorios/mapa-chuva-estado/mapa-chuva-estado.component';
import { PluviometroTabelaPrecipitacaoAnualComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-precipitacao-anual/pluviometro-tabela-precipitacao-anual.component';
import { TabelaRelatorioPrecipitacaoAnualComponent } from '@home/submodulos/dados-meteorologicos/componentes/tabela-relatorio-precipitacao-anual/tabela-relatorio-precipitacao-anual.component';
import { TabelaRelatorioVariaveisMeteorologicasComponent } from '@home/submodulos/dados-meteorologicos/componentes/tabela-relatorio-variaveis-meteorologicas/tabela-relatorio-variaveis-meteorologicas.component';
import { VariaveisMeteorologicasTabelaComponent } from './componentes/relatorios/variaveis-meteorologicas-tabela/variaveis-meteorologicas-tabela.component';
import { PluviometroTabelaPrecipitacaoAcumuladaComponent } from './componentes/relatorios/pluviometro-tabela-precipitacao-acumulada/pluviometro-tabela-precipitacao-acumulada.component';
import { TabelaRelatoriosPrecipitacaoAcumuladaComponent } from './componentes/tabela-relatorios-precipitacao-acumulada/tabela-relatorios-precipitacao-acumulada.component';
import { ModalQuantisComponent } from './componentes/modal-quantis/modal-quantis.component';
import { MapaIapmComponent } from './componentes/relatorios/mapa-iapm/mapa-iapm.component';
import { GraficoVariaveisMeteorologicasComponent } from './componentes/relatorios/grafico-variaveis-meteorologicas/grafico-variaveis-meteorologicas.component';
import { PluviometroTabelaPluviometricoDoEstadoComponent } from './componentes/relatorios/pluviometro-tabela-pluviometrico-do-estado/pluviometro-tabela-pluviometrico-do-estado.component';
import { TabelaRelatoriosPluviometricosDoEstadoComponent } from '@home/submodulos/dados-meteorologicos/componentes/tabela-relatorios-pluviometricos-do-estado/tabela-relatorios-pluviometricos-do-estado.component';
import { ModalPdfOptionsComponent } from './componentes/modal-pdf-options/modal-pdf-options.component';
import { MapaPluviometriaObservadaComponent } from './componentes/relatorios/mapa-pluviometria-observada/mapa-pluviometria-observada.component';
import { PopupAgrupamentoComponent } from './componentes/popup-agrupamento/popup-agrupamento.component';
import { LegendaPluviometriaObservadaComponent } from './componentes/legenda-pluviometria-observada/legenda-pluviometria-observada.component';
import { MapaDesvioComponent } from './componentes/relatorios/mapa-desvio/mapa-desvio.component';
import { MapaInterpolacaoEstacoesPCDSComponent } from './componentes/relatorios/mapa-interpolacao-estacoes-pcds/mapa-interpolacao-estacoes-pcds.component';
import { GraficoPluviometriaObservadaComponent } from './componentes/relatorios/grafico-pluviometria-observada/grafico-pluviometria-observada.component';
import { TabelaRelatorioSudeneComponent } from './componentes/tabela-relatorio-sudene/tabela-relatorio-sudene.component';
import { MapaClimatologiaComponent } from './componentes/relatorios/mapa-climatologia/mapa-climatologia.component';
import { MapaNdcComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-ndc/mapa-ndc.component';
import { QuantisComponent } from './pages/quantis/quantis.component';
import { GraficoDesvioComponent } from './componentes/relatorios/grafico-desvio/grafico-desvio.component';
import { GraficoClimatologiaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-climatologia/grafico-climatologia.component';
import { GraficoNdcComponent } from './componentes/relatorios/grafico-ndc/grafico-ndc.component';
import { TabelaNdcComponent } from './componentes/relatorios/tabela-ndc/tabela-ndc.component';
import { TabelaRelatorioDesvioComponent } from './componentes/tabela-relatorio-desvio/tabela-relatorio-desvio.component';
import { PluviometroTabelaQuantisComponent } from './componentes/relatorios/pluviometro-tabela-quantis/pluviometro-tabela-quantis.component';
import { TabelaRelatoriosQuantisComponent } from './componentes/tabela-relatorios-quantis/tabela-relatorios-quantis.component';
import { TabelaVecComponent } from './componentes/relatorios/tabela-vec/tabela-vec.component';
import { TabelaClimatologiaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/tabela-climatologia/tabela-climatologia.component';
import { GraficoPluviometriaObservadoDesvioClimatologiaComponent } from './componentes/relatorios/grafico-pluviometria-observada-desvio-climatologia/grafico-pluviometria-observado-desvio-climatologia.component';
import { TabelaSPIComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/tabela-spi/tabela-spi.component';
import { TabelaPluviometriaObservadaComponent } from './componentes/relatorios/tabela-pluviometria-observada/tabela-pluviometria-observada.component';
import { TabelaRelatorioPluviometriaObservadaComponent } from './componentes/relatorios/tabela-relatorio-pluviometria-observada/tabela-relatorio-pluviometria-observada.component';
import { PluviometroMapaNdcComponent } from './componentes/relatorios/pluviometro-mapa-ndc/pluviometro-mapa-ndc.component';
import { PopupNdcComponent } from './componentes/popup-ndc/popup-ndc.component';
import { LegendaNdcComponent } from './componentes/legenda-ndc/legenda-ndc.component';
import { PluviometriaGraficoIapmComponent } from './componentes/relatorios/pluviometria-grafico-iapm/pluviometria-grafico-iapm.component';
import { GraficoIapmComponent } from './componentes/relatorios/grafico-iapm/grafico-iapm.component';
import { PluviometriaTabelaIapmComponent } from './componentes/relatorios/pluviometria-tabela-iapm/pluviometria-tabela-iapm.component';
import { TabelaIapmComponent } from './componentes/relatorios/tabela-iapm/tabela-iapm.component';

@NgModule({
	declarations: [
		BoletimDiarioComponent,
		MonitoramentoComponent,
		MapaQuantisComponent,

		DashboardValuesComponent,
		TabelaRelatoriosComponent,
		ModalLegendaMapaRelatoriosComponent,
		ModalRelatorioPostosComponent,
		GraficoVariaveisMeteorologicasComponent,
		CustomRadioGroupComponent,
		LegendaMapaGraficoRelatorioComponentHtmlComponent,
		CycleSelectComponent,
		ExportButtonBoletimComponent,
		GraficoQuantisComponent,
		PopupBoletimDiarioMapaComponent,
		CycleSelectComponent,
		ExportButtonBoletimComponent,
		TabelaRelatorioEvapotranspiracaoComponent,
		ImagemHistoricoRelatorioComponent,
		SelectPostosRelatorioComponent,
		TabelaRelatoriosDiariosComponent,
		LegendaRelatorioComponent,
		TabelaRelatorioPrecipitacaoAnualComponent,
		PluviometroTabelaPrecipitacaoDiariaComponent,
		PluviometroTabelaPluviometricoPorPostoComponent,
		PluviometroTabelaPrecipitacaoAnualComponent,
		PluviometroGraficoQuantisComponent,
		PluviometricoTabelaMediaHistoricaComponent,
		EvapotranspiracaoTabelaComponent,
		TabelaRelatorioMediaHistoricaComponent,
		SemDadosComponent,
		TabelaRelatoriosPluviometricosPorPostoComponent,
		PluviometroGraficoSpiComponent,
		MapaChuvaEstadoComponent,
		SelectPostosRelatorioComponent,
		TabelaRelatorioVariaveisMeteorologicasComponent,
		VariaveisMeteorologicasTabelaComponent,
		PluviometroTabelaPrecipitacaoAcumuladaComponent,
		TabelaRelatoriosPrecipitacaoAcumuladaComponent,
		ModalQuantisComponent,
		MapaIapmComponent,
		ModalRelatorioHistoricoImagensComponent,
		LegendaRelatorioHistoricoVariaveisComponent,
		VariaveisMetorologicasMapaComponent,
		PluviometroTabelaPluviometricoDoEstadoComponent,
		TabelaRelatoriosPluviometricosDoEstadoComponent,
		ModalPdfOptionsComponent,
		MapaPluviometriaObservadaComponent,
		PopupAgrupamentoComponent,
		LegendaPluviometriaObservadaComponent,
		MapaInterpolacaoEstacoesPCDSComponent,
		MapaDesvioComponent,
		GraficoPluviometriaObservadaComponent,
		MapaClimatologiaComponent,
		QuantisComponent,
		GraficoClimatologiaComponent,
		TabelaRelatorioSudeneComponent,
		GraficoNdcComponent,
		MapaNdcComponent,
		TabelaNdcComponent,
		PluviometroTabelaQuantisComponent,
		TabelaRelatoriosQuantisComponent,
		GraficoDesvioComponent,
		TabelaVecComponent,
		GraficoPluviometriaObservadoDesvioClimatologiaComponent,
		TabelaSPIComponent,
		TabelaPluviometriaObservadaComponent,
		TabelaRelatorioPluviometriaObservadaComponent,
		TabelaRelatorioDesvioComponent,
		TabelaClimatologiaComponent,
		PluviometroMapaNdcComponent,
		PopupNdcComponent,
		LegendaNdcComponent,
		PluviometriaGraficoIapmComponent,
		GraficoIapmComponent,
		PluviometriaTabelaIapmComponent,
		TabelaIapmComponent,
	],
	imports: [
		MonitoramentoModule,
		FormsModule,
		CommonModule,
		DataTablesModule,
		DadosMeteorologicosRoutingModule,
		SharedModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		ExportAsModule,
		NgxLoadingModule,
		NgSelectModule,
		ModalModule,
		PopoverModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [MonitoramentoModule],
})
export class DadosMeteorologicosModule {}
