import { Component, inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subject, takeUntil } from 'rxjs';
import { EPeriodosBusca } from '../../../enum';
import {
	DadosAgrupamento,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	PeriodosLabel,
} from '../../../interfaces';
import { RelatorioNDCResponse } from '../../../interfaces/relatorio-ndc';
import { RelatoriosService } from '../../../services';
import { corrigeDuplicacaoNome, formatarDataPeriodo } from '../../../utils';

@Component({
	selector: 'seira-tabela-ndc',
	templateUrl: './tabela-ndc.component.html',
	styleUrls: ['./tabela-ndc.component.scss'],
})
export class TabelaNdcComponent implements OnInit {
	inputs = inject(INPUTS_RELATORIOS);
	_destroyed = new Subject();

	tituloTabela = 'Tabela de NDC';
	tituloColunaAgrupamento = 'Agrupamento';
	descricaoRelatorio =
		'Define-se como NDC o número de dias em que houve registro de precipitação em uma localidade, utilizado para monitorar a frequência de eventos de chuva em determinado período.';

	carregando: boolean;
	disabledSelectList = false;

	colunasNDC: ADTColumns[] = [];
	resultados: RelatorioNDCResponse[] = [];
	colunasSelecionadas: string[] = [];

	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Município', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
	}

	ngOnInit() {
		this.setValidators();
		this.gerarRelatorio();
		this.observarMudancasPeriodoBusca();
	}

	observarMudancasPeriodoBusca() {
		const periodoBusca = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		);

		this.lidarComPeriodo(periodoBusca?.value);
		periodoBusca?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: (periodo: PeriodosLabel | null) => {
				this.lidarComPeriodo(periodo);
			},
		});
	}

	lidarComPeriodo(periodo: PeriodosLabel | null) {
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);

		if (dataInicio && dataFim) {
			const hoje = new Date();
			dataFim.setValue(moment(hoje));

			switch (periodo) {
				case 'anual':
					if (dataFim?.value) {
						const hoje = new Date();
						if (dataFim.value.year() == hoje.getFullYear()) {
							dataFim?.setValue(moment(hoje));
						} else {
							dataFim?.setValue(dataFim.value.endOf('year'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.ANUAL,
							'year'
						);
						dataInicio?.setValue(fim);
					}
					break;
				case 'mensal':
					if (dataFim?.value) {
						const hoje = new Date();
						if (
							dataFim.value.year() === hoje.getFullYear() &&
							dataFim.value.month() >= hoje.getMonth()
						) {
							dataFim.setValue(moment(hoje));
						} else {
							dataFim.setValue(dataFim.value.endOf('month'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.MENSAL,
							'month'
						);
						dataInicio?.setValue(fim);
					}
					break;
				case 'diario':
					if (dataFim?.value) {
						const hoje = new Date();
						if (
							dataFim.value.year() === hoje.getFullYear() &&
							dataFim.value.month() >= hoje.getMonth()
						) {
							dataFim?.setValue(moment(hoje));
						} else {
							dataFim?.setValue(dataFim.value.endOf('month'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.DIARIO,
							'day'
						);
						dataInicio?.setValue(fim);
					}
					break;
			}
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	setColunasSelecionadas(colunas: any) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	gerarRelatorio() {
		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.tituloTabela = `  NÚMERO DE DIAS COM CHUVA - ${formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodoAgrupamento
		)}`;
		this.carregando = true;
		this.inputs.setLoading(true);

		this.colunasNDC = [];
		this.resultados = [];

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
		};

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = 0;
				break;
			case 'MICRORREGIAO':
				dados.microrregiao = 0;
				break;
			case 'MUNICIPIO':
				dados.municipio = 0;
				break;
		}

		this.relatoriosService.buscarRelatorioNDCPorAgrupamento(dados).subscribe({
			next: ndcAgrupados => {
				this.resultados = ndcAgrupados;

				this.atualizarTitulosTabela();
				this.altualizarColunasTabelaNDC();
			},
			error: err => {
				this.toastrService.error(
					'Erro ao gerar tabela de NDC, tente novamente'
				);
				this.carregando = false;
				this.inputs.setLoading(false);
			},
			complete: () => {
				this.inputs.setLoading(false);
				this.carregando = false;
			},
		});
	}

	altualizarColunasTabelaNDC() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		this.colunasNDC = [
			{
				data: 'nome',
				title: this.tituloColunaAgrupamento,
				type: 'text',
				className: 'text-left',
				render: (nome: string) => corrigeDuplicacaoNome(nome),
			},
		];

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: 'id',
					title: 'Id',
					type: 'number',
					className: 'text-center',
				},

				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
					render: (latitude: number) => latitude ?? '-',
				},

				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
					render: (longitude: number) => longitude ?? '-',
				},

				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
					render: (altitude: number) => altitude ?? '-',
				},

				{
					data: 'municipio',
					title: 'Município',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
					render: (regiao: string) => (regiao !== '' ? regiao : '-'),
				},

				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
					render: (bacia: string) => (bacia !== '' ? bacia : '-'),
				},

				{
					data: 'subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
					render: (subbacia: string) => (subbacia !== '' ? subbacia : '-'),
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunasNDC.push(...colunasParaExibir);
		} else {
			this.disabledSelectList = true;
		}

		this.colunasNDC.push(
			...[
				{
					data: 'diasComChuva',
					title: 'Dias com chuva',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'somaPrecipitacao',
					title: 'Precipitação total (mm)',
					type: 'number',
					className: 'text-center',
					render: (precipitacao: number) => {
						return precipitacao
							? precipitacao.toFixed(1).replace('.', ',')
							: '-';
					},
				},
			]
		);
	}

	atualizarTitulosTabela() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoBusca = this.getDataFromForm(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				this.tituloColunaAgrupamento = 'Município/Posto';
				break;
			case 'MICRORREGIAO':
				this.tituloColunaAgrupamento = 'Microrregião';
				break;
			case 'MUNICIPIO':
				this.tituloColunaAgrupamento = 'Município';
				break;
			case 'MESORREGIAO':
				this.tituloColunaAgrupamento = 'Mesorregião';
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.tituloColunaAgrupamento = 'Região Pluviométrica';
				break;
			case 'BACIA':
				this.tituloColunaAgrupamento = 'Bacia';
				break;
			case 'SUB_BACIA':
				this.tituloColunaAgrupamento = 'Sub bacia';
				break;
		}
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: this.tituloTabela,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.resultados) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}
		const tableData: (string | number)[][] = [];

		tableData.push([
			this.tituloColunaAgrupamento,
			'Dias com chuva',
			'Precipitação total (mm)',
		]);

		this.resultados.forEach(dadoRelatorio => {
			tableData.push([
				dadoRelatorio.nome,
				dadoRelatorio.diasComChuva,
				dadoRelatorio.somaPrecipitacao.toFixed(1).replace('.', ','),
			]);
		});
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData: (string | number)[][] = [];

		tableData.push([
			this.tituloColunaAgrupamento,
			'Dias com chuva',
			'Precipitação total (mm)',
		]);

		this.resultados.forEach(dadoRelatorio => {
			tableData.push([
				dadoRelatorio.nome,
				dadoRelatorio.diasComChuva,
				dadoRelatorio.somaPrecipitacao.toFixed(1).replace('.', ','),
			]);
		});

		DocumentExporter.gerarCSV(tableData, this.tituloTabela);
	}

	exportarTXT() {
		let txtData = '';

		this.resultados.forEach(dadoRelatorio => {
			txtData +=
				`${this.tituloColunaAgrupamento}: ${dadoRelatorio.nome}\n` +
				`Dias com chuva: ${dadoRelatorio.diasComChuva}\n` +
				`Precipitação total (mm): ${dadoRelatorio.somaPrecipitacao
					.toFixed(1)
					.replace('.', ',')}\n`;
		});

		DocumentExporter.gerarTXT(txtData, this.tituloTabela);
	}
}
