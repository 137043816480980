import { HttpClient } from '@angular/common/http';
import {
	AfterViewInit,
	ApplicationRef,
	ChangeDetectorRef,
	Component,
	ComponentRef,
	createComponent,
	EnvironmentInjector,
	Input,
	OnDestroy,
} from '@angular/core';
import {
	CORES,
	LegendaCores,
	legendaInfo,
} from '@componentes/mapa-paraiba-svg/legenda';
import Leaflet from 'leaflet';
import { AgrupamentoGeojson } from '../../../enum/Agrupamento';
import { RelatorioNDCResponse } from '../../../interfaces/relatorio-ndc';
import { PopupNdcComponent } from '../../popup-ndc/popup-ndc.component';

@Component({
	selector: 'seira-mapa-ndc-chuva',
	templateUrl: './mapa-ndc.component.html',
	styleUrls: ['./mapa-ndc.component.scss'],
})
export class MapaNdcComponent implements OnDestroy, AfterViewInit {
	@Input() dados?: RelatorioNDCResponse[];

	map?: Leaflet.Map;
	geoJsonLayer?: Leaflet.GeoJSON;
	centerDefault: Leaflet.LatLngExpression = [-7.2605416, -36.7290255];
	zoomDefault = 8;

	constructor(
		private httpClient: HttpClient,
		private cdr: ChangeDetectorRef,
		private appRef: ApplicationRef,
		private envInjector: EnvironmentInjector
	) {}

	ngOnDestroy() {
		this.map = undefined;
	}

	ngAfterViewInit() {
		this.cdr.detectChanges();
		this.loadMunicipiosMap(this.map!);
	}

	setupMap(mapa: Leaflet.Map) {
		this.cdr.detectChanges();
		this.map = mapa;
	}

	loadMunicipiosMap(map: Leaflet.Map) {
		this.httpClient
			.get<GeoJSON.GeoJsonObject>(AgrupamentoGeojson.MUNICIPIO)
			.subscribe({
				next: value => {
					this.geoJsonLayer = Leaflet.geoJSON(value, {
						style: feature => this.handleGetStyleByFeature(feature),
						onEachFeature: (feature, layer) => {
							layer.on({
								click: e => {
									const ndc = this.handleFindNDCByPropertyName(
										feature!.properties!['name']
									);
									if (ndc) {
										const markerPopup = this.handleAttachPopup(
											this.handleCreatePopup(ndc)
										);
										layer.bindPopup(markerPopup).openPopup(e.latlng);
									}
								},
							});
						},
					}).addTo(map);
				},
			});
	}

	private handleCreatePopup(
		ndc: RelatorioNDCResponse
	): ComponentRef<PopupNdcComponent> {
		const componentRef = createComponent(PopupNdcComponent, {
			environmentInjector: this.envInjector,
		});
		componentRef.instance.ndc = ndc;
		const color = this.getColorByDiasChuva(ndc.diasComChuva);
		componentRef.instance.headerColor = color;

		componentRef.instance.titleColor =
			color === LegendaCores.LARANJA ||
			color === LegendaCores.BRANCO_NEVE ||
			color === LegendaCores.AMARELO
				? 'gray'
				: 'white';
		return componentRef;
	}

	private handleAttachPopup(compRef: ComponentRef<PopupNdcComponent>) {
		this.appRef.attachView(compRef.hostView);
		compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

		const div = document.createElement('div');
		div.appendChild(compRef.location.nativeElement);
		return div;
	}

	private handleGetStyleByFeature(feature?: GeoJSON.Feature<GeoJSON.Geometry>) {
		const ndc = this.handleFindNDCByPropertyName(feature!.properties!['name']);
		const diasComChuva = ndc ? ndc.diasComChuva : 0;
		return {
			fillColor: this.getColorByDiasChuva(diasComChuva),
			weight: 2,
			opacity: 1,
			color: '#607d8b',
			fillOpacity: 0.7,
		};
	}

	private handleFindNDCByPropertyName(name: string) {
		return this.dados?.find(dado => dado.municipio === name);
	}

	private getColorByDiasChuva(valor: number): string {
		for (const key of Object.keys(legendaInfo)
			.map(Number)
			.sort((a, b) => b - a)) {
			if (valor >= key) {
				return legendaInfo[key];
			}
		}
		return CORES.CINZA;
	}
}
