import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import {
	DadosRelatorioPluviometricoPorPosto,
	PostosRelatorios,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { formatISO } from 'date-fns';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subscription } from 'rxjs';
import { formatarDataPeriodo } from '../../../utils';

@Component({
	selector: 'seira-pluviometro-tabela-precipitacao-diaria',
	templateUrl: './pluviometro-tabela-pluviometrico-por-posto.component.html',
	styleUrls: ['./pluviometro-tabela-pluviometrico-por-posto.component.scss'],
})
export class PluviometroTabelaPluviometricoPorPostoComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio =
		'Define-se como pluviometria por posto os dados de chuva coletados em uma estação meteorológica específica, utilizados para monitorar o clima em locais pontuais.';
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes!: Select<string>[];
	carregandoRelatorio = false;
	dadosTabelaRelatorio: Relatorios<DadosRelatorioPluviometricoPorPosto>[] = [];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarCSV(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT(this.dadosTabelaRelatorio);
			},
		},
	];
	periodoTitulo = '';

	private subscription = new Subscription();
	constructor(
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.form = this.inputs.form;
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
		setTimeout(() => {
			this.setValidators();
		}, 0);
		this.gerarRelatorio();
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	formataData(data: Date) {
		const formatoData = new Date(data);
		return formatoData.getFullYear();
	}

	gerarRelatorio() {
		const periodo = this.form.get('periodo')?.value;
		if (this.form.invalid || !periodo || !this.postos.length) {
			return;
		}

		this.inputs.setLoading(true);
		this.carregandoRelatorio = true;
		this.periodoTitulo = formatarDataPeriodo(periodo, '', '', true);
		const postosIds = this.postos.map(posto => posto.id);
		this.relatoriosService
			.buscarRelatorioPluviometricosPorPosto(
				postosIds,
				formatISO(new Date(periodo)),
				formatISO(new Date(periodo))
			)
			.subscribe({
				next: pluviometricos => {
					this.dadosTabelaRelatorio =
						this.converteFormatoRelatorioPluviometrico(pluviometricos);
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
				},
			});
	}

	converteFormatoRelatorioPluviometrico(dadosOriginais: any) {
		const dadosTransformados: Relatorios<DadosRelatorioPluviometricoPorPosto>[] =
			[];

		dadosOriginais?.resumosPluviometricos.forEach((relatorio: any) => {
			const detalhesPosto = relatorio.detalhesPosto;
			const ano = relatorio.ano;
			const { municipio, posto, tipo } = detalhesPosto;
			const data: Relatorios<DadosRelatorioPluviometricoPorPosto> = {
				municipio,
				posto,
				tipo:
					tipo === 'PLUVIOMETRO_CONVENCIONAL'
						? 'Pluviômetro Convencional'
						: tipo,
				ano,
				data: [],
			};

			for (let i = 0; i < relatorio.resumosMensais.length; i++) {
				const resumosMensais = relatorio.resumosMensais[i];
				if (resumosMensais) {
					const dadosPosto = {
						id: resumosMensais.mes,
						mes: this.transformarFormatoMes(resumosMensais.mes),
						chuvaAcumulada: this.defineCasasDecimais(
							resumosMensais.chuvaAcumulada
						),
						mediaAcumulada: this.defineCasasDecimais(
							resumosMensais.mediaAcumulada
						),
						desvioAcumulado: this.defineCasasDecimais(
							resumosMensais?.desvioAcumulado
						),
						numeroVeranicos: resumosMensais.numeroVeranicos,
						diasConsecultivosSemChuva: resumosMensais.diasConsecultivosSemChuva,
						valorMaiorChuva: resumosMensais.valorMaiorChuva,
						diaMaiorChuva: this.formataDataISO(resumosMensais.diaMaiorChuva),
						valido: resumosMensais.valido,
						dadosCompletos: resumosMensais.dadosCompletos,
					};

					data.data.push(dadosPosto);
				}
			}

			const dadosTotal = {
				id: 13,
				mes: this.transformarFormatoMes(relatorio.total.mes),
				chuvaAcumulada: this.defineCasasDecimais(
					relatorio.total.chuvaAcumulada
				),
				mediaAcumulada: this.defineCasasDecimais(
					relatorio.total.mediaAcumulada
				),
				desvioAcumulado: this.defineCasasDecimais(
					relatorio.total?.desvioAcumulado
				),
				numeroVeranicos: relatorio.total.numeroVeranicos,
				diasConsecultivosSemChuva: relatorio.total.diasConsecultivosSemChuva,
				valorMaiorChuva: relatorio.total.valorMaiorChuva,
				diaMaiorChuva: this.formataDataISO(relatorio.total.diaMaiorChuva),
				valido: relatorio.total.valido,
				dadosCompletos: relatorio.total.dadosCompletos,
			};

			data.data.push(dadosTotal);
			dadosTransformados.push(data);
		});

		return dadosTransformados;
	}

	defineCasasDecimais(valor: any) {
		try {
			const resultado = valor?.toFixed(2);
			if (isNaN(resultado)) {
				return null;
			}
			return resultado;
		} catch (e) {
			return null;
		}
	}

	transformarFormatoMes(mes: string) {
		const mesesAbreviados: any = {
			1: 'Jan',
			2: 'Fev',
			3: 'Mar',
			4: 'Abr',
			5: 'Mai',
			6: 'Jun',
			7: 'Jul',
			8: 'Ago',
			9: 'Set',
			10: 'Out',
			11: 'Nov',
			12: 'Dez',
		};

		if (!mes) {
			return 'Total';
		}
		return mesesAbreviados[mes];
	}

	formataDataISO(data: any) {
		if (data) {
			const dataFormatada = moment(data).format('DD/MM/YYYY');
			return dataFormatada;
		}
		return data;
	}

	setValidators() {
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
	}

	getPeriodo() {
		return this.dadosTabelaRelatorio[0].ano;
	}

	async exportarPDF(
		dadosTabelaPluviometricoDiario: Relatorios<DadosRelatorioPluviometricoPorPosto>[]
	) {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		documentDefinition.content.push({
			text: `Relatório - Pluviométrico por posto (mm) - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dadosTabelaPluviometricoDiario.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		dadosTabelaPluviometricoDiario.forEach(dadoRelatorio => {
			if (dadoRelatorio.data.length) {
				const tableData: (string | number)[][] = [];

				tableData.push([
					'Mês',
					'Chuva acumulada (mm)',
					'Média histórica (mm)',
					'Desvio percentual (%)',
					'Nº de veranicos',
					'Nº de dias do maior veranico',
					'Dia da maior chuva',
					'Valor da maior chuva (mm)',
				]);

				dadoRelatorio?.data.forEach((item: any) => {
					return tableData.push([
						item.mes,
						item.chuvaAcumulada || '-',
						item.mediaAcumulada || '-',
						item.desvioAcumulado || '-',
						item.numeroVeranicos || '-',
						item.diasConsecultivosSemChuva || '-',
						item.diaMaiorChuva,
						item.valorMaiorChuva || '-',
					]);
				});

				documentDefinition.content.push(
					{
						text: `${dadoRelatorio.municipio} - ${dadoRelatorio.posto}`,
						fontSize: 10,
						margin: [0, 10],
					},
					{
						table: {
							body: tableData.map(row =>
								row.map(cell => ({ text: cell, fontSize: 10 }))
							),
						},
					}
				);
			}
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(
		dadosTabelaPluviometricoPorPosto: Relatorios<DadosRelatorioPluviometricoPorPosto>[]
	) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município',
			'Posto',
			'Mês',
			'Chuva acumulada (mm)',
			'Média histórica (mm)',
			'Desvio percentual (%)',
			'Nº de veranicos',
			'Nº de dias do maior veranico',
			'Dia da maior chuva',
			'Valor da maior chuva (mm)',
		]);

		dadosTabelaPluviometricoPorPosto.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				tableData.push([
					dadoRelatorio.municipio,
					dadoRelatorio.posto || '',
					dado.mes,
					dado.chuvaAcumulada || '-',
					dado.mediaAcumulada || '-',
					dado.desvioAcumulado || '-',
					dado.numeroVeranicos || '-',
					dado.diasConsecultivosSemChuva || '-',
					dado.diaMaiorChuva,
					dado.valorMaiorChuva || '-',
				]);
			});
		});

		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-pluviometrico-por-posto-${this.getPeriodo()}`
		);
	}

	exportarTXT(
		dadosTabelaPluviometricoPorPosto: Relatorios<DadosRelatorioPluviometricoPorPosto>[]
	) {
		let txtData = '';

		dadosTabelaPluviometricoPorPosto.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				txtData +=
					`Município: ${dadoRelatorio.municipio} \n` +
					`Posto: ${dadoRelatorio.posto} \n` +
					`Mês: ${dado.mes} \n` +
					`Chuva acumulada (mm): ${dado.chuvaAcumulada || '-'} \n` +
					`Média histórica (mm): ${dado.mediaAcumulada || '-'} \n` +
					`Desvio percentual (%): ${dado.desvioAcumulado || '-'} \n` +
					`Nº de veranicos: ${dado.numeroVeranicos || '-'} \n` +
					`Nº de dias do maior veranico: ${
						dado.diasConsecultivosSemChuva || '-'
					} \n` +
					`Dia da maior chuva: ${dado.diaMaiorChuva || '-'} \n` +
					`Valor da maior chuva (mm): ${dado.valorMaiorChuva || '-'} \n\n`;
			});
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-pluviometrico-por-posto-${this.getPeriodo()}`
		);
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.POSTO)?.clearValidators();
		this.subscription.unsubscribe();
	}
}
