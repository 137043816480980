import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	Estacao,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	Municipio,
	PeriodosLabel,
	PostosRelatorios,
} from '../../../interfaces';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import { RelatoriosService } from '../../../services';
import { ToastrService } from 'ngx-toastr';
import { RelatorioNDCResponse } from '../../../interfaces/relatorio-ndc';
import { HttpErrorResponse } from '@angular/common/http';
import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfseira from '@utils/pdf-seira';
import { numberToBrNumber } from '@utils';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { EPeriodosBusca } from '../../../enum';
import { Subject, takeUntil } from 'rxjs';
import { formatarDataPeriodo } from '../../../utils';

@Component({
	selector: 'seira-pluviometro-mapa-ndc',
	templateUrl: './pluviometro-mapa-ndc.component.html',
	styleUrls: ['./pluviometro-mapa-ndc.component.scss'],
})
export class PluviometroMapaNdcComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	form!: FormGroup<any>;
	postos?: PostosRelatorios[] | undefined;
	estacoes?: Estacao[] | undefined;
	regioes?: Select<string>[] | undefined;
	microrregioes?: Select<string>[] | undefined;
	mesorregioes?: Select<string>[] | undefined;
	municipios?: Municipio[] | undefined;
	precipitacao?: Select<string>[] | undefined;
	agrupamento?: Select<string>[] | undefined;
	inputs = inject(INPUTS_RELATORIOS);
	isLoading?: boolean;
	response?: RelatorioNDCResponse[];

	periodoTitulo = '';
	descricaoRelatorio =
		'Define-se como NDC o número de dias em que houve registro de precipitação em uma localidade, utilizado para monitorar a frequência de eventos de chuva em determinado período.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportPdf().then();
			},
		},
	];

	private _destroyed = new Subject();

	constructor(
		private relatorioService: RelatoriosService,
		private toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.form = this.inputs.form;
		this.setValidators();
		this.observarMudancasPeriodoBusca();
		this.gerarRelatorio();
	}

	ngOnDestroy(): void {
		this.form.get(FormularioRelatorio.VARIAVEL_MAPA)?.clearValidators();
		this.form.get(FormularioRelatorio.TIPO_PERIODO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this._destroyed.next(undefined);
	}

	observarMudancasPeriodoBusca() {
		const periodoBusca = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		);

		this.lidarComPeriodo(periodoBusca?.value);
		periodoBusca?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: (periodo: PeriodosLabel | null) => {
				this.lidarComPeriodo(periodo);
			},
		});
	}

	lidarComPeriodo(periodo: PeriodosLabel | null) {
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);

		if (dataInicio && dataFim) {
			const hoje = new Date();
			dataFim.setValue(moment(hoje));

			switch (periodo) {
				case 'anual':
					if (dataFim?.value) {
						const hoje = new Date();
						if (dataFim.value.year() == hoje.getFullYear()) {
							dataFim?.setValue(moment(hoje));
						} else {
							dataFim?.setValue(dataFim.value.endOf('year'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.ANUAL,
							'year'
						);
						dataInicio?.setValue(fim);
					}
					break;
				case 'mensal':
					if (dataFim?.value) {
						const hoje = new Date();
						if (
							dataFim.value.year() === hoje.getFullYear() &&
							dataFim.value.month() >= hoje.getMonth()
						) {
							dataFim.setValue(moment(hoje));
						} else {
							dataFim.setValue(dataFim.value.endOf('month'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.MENSAL,
							'month'
						);
						dataInicio?.setValue(fim);
					}
					break;
				case 'diario':
					if (dataFim?.value) {
						const hoje = new Date();
						if (
							dataFim.value.year() === hoje.getFullYear() &&
							dataFim.value.month() >= hoje.getMonth()
						) {
							dataFim?.setValue(moment(hoje));
						} else {
							dataFim?.setValue(dataFim.value.endOf('month'));
						}
					}
					if (dataInicio?.value && dataFim?.value) {
						const fim = moment(dataFim.value).subtract(
							EPeriodosBusca.DIARIO,
							'day'
						);
						dataInicio?.setValue(fim);
					}
					break;
			}
		}
	}

	setValidators() {
		this.form
			.get(FormularioRelatorio.VARIAVEL_MAPA)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.TIPO_PERIODO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		const diaInicio = this.form.get(FormularioRelatorio.DATA_INICIO)?.value;
		const diaFim = this.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const periodo = this.form.get(FormularioRelatorio.TIPO_PERIODO)?.value;

		if (!diaInicio || !diaFim || !periodo || this.inputs.form.invalid) {
			return;
		}

		this.periodoTitulo = formatarDataPeriodo(diaInicio, diaFim, periodo);
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.relatorioService
			.buscarRelatorioNDCPorAgrupamento({
				diaInicio,
				diaFim,
				agrupamento: 'MUNICIPIO_POSTO',
				periodoAgrupamento: periodo,
				posto: 0,
			})
			.subscribe({
				next: resp => {
					this.response = resp;
				},
				error: (erro: HttpErrorResponse) => {
					this.toastr.error('Nenhum resultado encontrado', erro.message);
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.isLoading = false;
				},
			});
	}

	async exportPdf() {
		const elementos = ['ndc'];
		const imagens = [];

		for (const elemento of elementos) {
			const elementoHtml = document.getElementById(elemento);
			if (elementoHtml) {
				const img = await html2canvas(elementoHtml).then(canvas =>
					canvas.toDataURL('image/png')
				);
				imagens.push({ imagem: img, titulo: 'Mapa de quantidade de chuva' });
			}
		}

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.content.push([
			{
				text: `Relatório de número de dias com chuva - ${this.getPeriodo()}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				text: `Relatório exportado em: ${this.getPeriodoAtualExportacao()}`,
				alignment: 'center',
				margin: [0, 10, 5, 10],
				fontSize: 12,
			},
		]);

		for (const imagem of imagens) {
			if (imagem.imagem && imagem.titulo) {
				documentDefinition.content.push(
					{
						text: imagem.titulo,
						alignment: 'center',
						margin: [0, 15, 0, 5],
						fontSize: 12,
					},
					{
						image: imagem.imagem,
						width: 515,
						heigth: 250,
						alignment: 'center',
					}
				);
			}
		}

		documentDefinition.content.push({
			text: `Tabela de precipitação`,
			alignment: 'center',
			margin: [0, 15, 0, 5],
			fontSize: 12,
		});

		documentDefinition.content.push({
			table: {
				widths: ['30%', '35%', '35%'],
				body: this.tableData(),
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	getPeriodo() {
		const periodo = this.form.get(FormularioRelatorio.TIPO_PERIODO)?.value;
		const dataInicio = this.form.get(FormularioRelatorio.DATA_INICIO)?.value;
		const dataFim = this.form.get(FormularioRelatorio.DATA_FIM)?.value;

		if (periodo == 'MENSAL') {
			return `${dataInicio.format('MM/yyyy')} à ${dataFim.format('MM/yyyy')}`;
		}

		if (periodo == 'DIARIO') {
			return `${dataInicio.format('DD/MM/YYYY')} à ${dataFim.format(
				'DD/MM/YYYY'
			)}`;
		}

		return `${dataInicio.format('yyyy')} à ${dataFim.format('yyyy')}`;
	}

	private getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	private getPeriodoAtualExportacao() {
		return format(new Date(), "dd 'de' MMMM 'de' yyyy HH'h'mm", {
			locale: ptBrLocale,
		});
	}

	private tableData() {
		const dadosTabela: Array<any[]> = [];

		const cabecalho = [
			{ text: 'Posto', fillColor: '#DCDCDC' },
			{ text: 'Precipitação', fillColor: '#DCDCDC' },
			{ text: 'Número de Dias com Chuva', fillColor: '#DCDCDC' },
		];
		dadosTabela.push(cabecalho);

		const valores = this.response!.map(value => [
			value.nome,
			numberToBrNumber(value.somaPrecipitacao.toFixed(1)),
			value.diasComChuva,
		]);
		dadosTabela.push(...valores);

		return dadosTabela;
	}
}
