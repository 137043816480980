<div class="container">
	<form [formGroup]="form" class="form-group">
		<label class="text-center text-tertiary fw-semibold w-100">
			NÚMERO DE DIAS COM CHUVA - {{ periodoTitulo }}
		</label>
		<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />

		<div
			*ngIf="isLoading; else mapa"
			class="d-flex justify-content-center align-items-center w-100 h-50"
			style="min-height: 500px">
			<seira-loading [pageCentered]="false"></seira-loading>
		</div>
		<ng-template #mapa>
			<div class="row" *ngIf="response; else nenhumResultado">
				<seira-mapa-ndc-chuva [dados]="response" />
			</div>
		</ng-template>
		<ng-template #nenhumResultado>
			<div class="row">
				<div class="col-12">
					<seira-sem-dados></seira-sem-dados>
				</div>
			</div>
		</ng-template>
	</form>
</div>
