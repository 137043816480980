import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { DadosPluviometriaRelatorioSudene } from '@home/submodulos/dados-meteorologicos/interfaces/response';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { format } from 'date-fns';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PostosRelatorios } from '../../interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '../../interfaces/tipos-relatorios';
import { RelatoriosService } from '../../services/relatorios.service';

type Localizacao = {
	latitude: number;
	longitude: number;
	altitude: number;
};

type RelatorioSudenePosto = {
	posto: string;
	dados: Omit<DadosPluviometriaRelatorioSudene, 'posto'>[];
	total: () => number;
	localizacao: Localizacao;
};

@Component({
	selector: 'seira-tabela-relatorio-variaveis-meteorologicas',
	templateUrl: './tabela-relatorio-sudene.component.html',
	styleUrls: ['./tabela-relatorio-sudene.component.scss'],
})
export class TabelaRelatorioSudeneComponent
	implements InstanciaRelatorio, OnInit, AfterViewChecked, OnDestroy
{
	inputs = inject(INPUTS_RELATORIOS);
	@Input() dadosSudene?: DadosPluviometriaRelatorioSudene[];
	@Input() dadosSudeneSeparadosPorPosto?: RelatorioSudenePosto[];
	postos: PostosRelatorios[] = [];
	@ViewChild('tabelaRelatorioSudene', { static: false })
	tabela?: PublicTableComponent;
	carregandoRelatorio = false;
	private subscription = new Subscription();
	colunas: ADTColumns[] = [
		{
			data: 'data',
			title: 'Data',
			type: 'date',
			className: 'text-start',
		},
		{
			data: 'valor',
			title: 'Precipitação',
			type: 'number',
			className: 'text-start',
			render: texto => texto + ' mm',
		},
	];

	descricaoRelatorio =
		'Define-se como histórico da SUDENE o conjunto de dados climáticos e de precipitação registrados ao longo dos anos, utilizados como base para estudos e análises do comportamento climático no Nordeste brasileiro.';

	constructor(
		private readonly relatorioService: RelatoriosService,
		private readonly toastr: ToastrService,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.postos = this.inputs.postos;
		setTimeout(() => {
			this.setValidators();
		}, 0);
	}

	ngAfterViewChecked() {
		this.changeDetectorRef.detectChanges();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.TIPO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}
	gerarRelatorio() {
		const periodo = this.inputs.form.get('periodo')?.value;
		if (this.inputs.form.invalid || !periodo || !this.postos.length) {
			return;
		}
		this.inputs.setLoading(true);
		this.carregandoRelatorio = true;
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		this.relatorioService
			.buscarRelatorioSudene(
				this.postos.map(p => p.nomePosto),
				dataInicio,
				dataFim
			)
			.subscribe({
				next: resp => {
					this.dadosSudene = (resp as DadosPluviometriaRelatorioSudene[]).map(
						pluv => ({
							...pluv,
							data: format(new Date(pluv.data), 'dd/MM/yyyy'),
						})
					);
					const postos = new Set(this.dadosSudene.map(ds => ds.posto));
					const result: RelatorioSudenePosto[] = [];
					postos.forEach(e => {
						const dados = this.dadosSudene?.filter(ds => ds.posto === e)!;
						dados.sort((a, b) => {
							const dataA = moment(a.data, 'dd/MM/yyyy');
							const dataB = moment(b.data, 'dd/MM/yyyy');
							return dataA.isBefore(dataB) ? -1 : dataA.isAfter(dataB) ? 1 : 0;
						});
						result.push({
							posto: e,
							dados: dados,
							total: () => dados.reduce((sum, item) => sum + item.valor, 0),
							localizacao: {
								latitude: dados[0].latitude,
								longitude: dados[0].longitude,
								altitude: dados[0].altitude,
							},
						});
					});
					this.dadosSudeneSeparadosPorPosto = result;
					this.dadosSudeneSeparadosPorPosto.sort((a, b) =>
						a.posto > b.posto ? 1 : -1
					);
					this.inputs.setLoading(false);
					this.carregandoRelatorio = false;
				},
				error: error => {
					this.toastr.error('Ocorreu um erro ao gerar o relatório', error);
					this.inputs.setLoading(false);
					this.carregandoRelatorio = false;
				},
			});
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.POSTO)?.clearValidators();
		this.subscription.unsubscribe();
	}
}
