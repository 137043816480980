import kriging from './kriging';
import L from 'leaflet';
import {
	VariavelMeteorologica,
	variavelParametros,
} from './intepolacao-parametros';
import { DesvioDTO } from '@modulos/home/submodulos/dados-meteorologicos/interfaces/desvioDTO';

export interface LegendaCor {
	cor: string;
	valorMin: number;
	valorMax: number;
	unidade: string;
	variavel: string;
	municipio: string;
}

export interface LatLngValue extends ValorPorLocalizacao {
	tipoPCD: string | null;
	municipio: string;
	nomePosto: string;
	valorMin: number;
	valorMax: number;
}

export interface ValorPorNdc {
	nomePosto: string;
	lat: number;
	lng: number;
	precipitacao: number;
	value: number;
}

export interface ValoresBusca {
	municipio: string;
	lat: number;
	lng: number;
	value: number;
	tipoValor: TipoValor;
	unidade: string;
}
export enum TipoValor {
	CLIMATOLOGIA = 'Climatologia',
	Desvio = 'Desvio',
}
export interface ValorPorLocalizacao {
	lat: number;
	lng: number;
	value: number;
}

export interface ValorDesvioInterpolacao extends ValorPorLocalizacao {
	desvio: keyof DesvioDTO;
	nomeMunicipio: string;
}

export interface ValorPorMesoregiao extends ValorPorLocalizacao {
	mesoregiao: string;
	nomePosto: string;
	isPrecipitacao: boolean;
}

export interface ValorPorPosto extends ValorPorLocalizacao {
	nomePosto: string;
	isPrecipitacao: boolean;
}
export type ValoresInterpolacao =
	| LatLngValue
	| ValorPorMesoregiao
	| ValorPorLocalizacao
	| ValorDesvioInterpolacao
	| ValorPorNdc;

export interface LatLngDto {
	lat: number;
	lng: number;
	value: number;
}
export interface InterpolacaoCanvasBounds {
	canvas: HTMLCanvasElement;
	latLngBounds: L.LatLngBounds;
	valores: ValoresInterpolacao[];
	legenda: LegendaCor[];
	variograma: any;
}
export function gerarLegenda(
	variavel: VariavelMeteorologica,
	intervalos: number[],
	regiao: any
): any[] {
	if (!variavelParametros[variavel]) {
		return [];
	}
	const { unidade, valorMax, valorMin } = variavelParametros[variavel];
	let { colormap } = variavelParametros[variavel];

	if (intervalos) {
		if (variavel === 'precipitacao') {
			if (intervalos[0] === 0) {
				colormap = colormap.slice(4, intervalos.length + 3);
				colormap = ['#ffffff'].concat(colormap);
			} else {
				colormap = colormap.slice(4, intervalos.length + 4);
			}
		}
		return colormap.map((value, i) => {
			return {
				cor: value,
				valorMin: intervalos[i],
				valorMax: intervalos[i + 1],
				unidade,
				regiao,
			};
		});
	}
	const range = valorMax! - valorMin!;
	const step = range / (colormap.length - 1);
	return colormap.slice(0, -1).map((value, i) => {
		return {
			cor: value,
			valorMin: (valorMin! + step * i).toFixed(0),
			valorMax: (valorMin! + step * (i + 1)).toFixed(0),
			unidade,
		};
	});
}

export function criarCanvasInterpolacao(
	valores: ValoresInterpolacao[],
	variavel: VariavelMeteorologica,
	regiao: number[][][],
	width: number,
	isValoresResumido = false
): InterpolacaoCanvasBounds {
	const { sigma2, alpha, model } = variavelParametros[variavel];
	let { colormap, valorMin, valorMax, intervalos } =
		variavelParametros[variavel];
	let variograma: {
		t: number[];
		x: number[];
		y: number[];
		nugget: number;
		range: number;
		sill: number;
		A: number;
		n: number;
		model: (
			h: number,
			nugget: number,
			range: number,
			sill: number,
			A: number
		) => number;
		K: number[];
		M: number[];
	};
	if (isValoresResumido) {
		const listaLatLngDto: LatLngDto[] = [];

		valores.map(item => {
			listaLatLngDto.push({ lng: item.lng, lat: item.lat, value: item.value });
		});
		variograma = kriging.train(
			listaLatLngDto.map(value => value.value),
			listaLatLngDto.map(value => value.lng),
			listaLatLngDto.map(value => value.lat),
			model,
			sigma2,
			alpha
		);
	} else {
		variograma = kriging.train(
			valores.map(value => value.value),
			valores.map(value => value.lng),
			valores.map(value => value.lat),
			model,
			sigma2,
			alpha
		);
	}

	if (
		variograma.M.every(i => i === 0) &&
		variograma.K.every(i => i === 0) &&
		variograma.t.every(i => i === i)
	) {
		variograma.M = variograma.M.map(i => 1);
		variograma.K = variograma.K.map(i => 1);
		variograma.nugget = variograma.t[0];
		variograma.sill = variograma.t[0];
	}

	const grid = kriging.grid(regiao, variograma, width);
	const canvas = document.createElement('canvas');
	canvas.width = grid!.data.length;
	canvas.height = grid!.data[0].length;

	if (valorMax === null) valorMax = grid!.zlim[1];
	if (valorMin === null) valorMin = grid!.zlim[0];

	const intervaloExpandido = kriging.expandirIntervalo(
		valorMax,
		intervalos,
		1.3,
		2
	);

	if (variavel === 'precipitacao') {
		if (intervaloExpandido[0] === 0) {
			colormap = colormap.slice(4, intervaloExpandido.length + 3);
			colormap = ['#ffffff'].concat(colormap);
		} else {
			colormap = colormap.slice(4, intervaloExpandido.length + 4);
		}
	}
	const plot = kriging.plot(
		canvas,
		grid!,
		grid!.xlim,
		grid!.ylim,
		colormap,
		valorMin,
		valorMax,

		intervaloExpandido
	);
	return {
		canvas,
		latLngBounds: L.latLngBounds([
			[grid!.ylim[0], grid!.xlim[0]],
			[grid!.ylim[1], grid!.xlim[1]],
		]),
		legenda: gerarLegenda(variavel, intervaloExpandido, regiao),
		valores: valores,

		variograma,
	};
}
