import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	inject,
	Injector,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import {
	Agrupamento,
	LabelVariavelComUnidade,
	ValuesVariaveis,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Select } from '@layout/interfaces/select';
import {
	Formato,
	FormatoVariaveisMeteorologicas,
	TipoGraficos,
	TiposMapas,
	TipoTabelas,
	TipoVariaveisMeteorologicas,
} from '@home/submodulos/dados-meteorologicos/interfaces/relatorioEnum';
import { enumAsSelectOptions, obter_erro_request } from '@utils';
import {
	ComponenteRelatorio,
	ConjuntoLabelsDinamicasPeriodo,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosInterface,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import moment, { Moment } from 'moment/moment';
import { PERIODO_MINIMO_SPI_EM_ANOS } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-grafico-spi/pluviometro-grafico-spi.component';
import { ComponentesRelatoriosService } from '@home/submodulos/dados-meteorologicos/services/componentes-relatorios.service';
import {
	Estacao,
	Municipio,
} from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { ModalRelatorioPostosComponent } from '@home/submodulos/dados-meteorologicos/componentes/modal-relatorio-postos/modal-relatorio-postos.component';
import {
	EPeriodoChuvoso,
	EPeriodosBusca,
} from '@modulos/home/submodulos/dados-meteorologicos/enum';
import { subDays } from 'date-fns';
import { DateTimeUtils } from '@utils/datetime-util';
import { TipoPrecipitacao } from '@shared/enum';
import { Subject } from 'rxjs';

@Component({
	selector: 'seira-estacoes-pluviometricas',
	templateUrl: './estacoes-pluviometricas.component.html',
	styleUrls: ['./estacoes-pluviometricas.component.scss'],
})
export class EstacoesPluviometricasComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
	dynamicComponentContainer!: ViewContainerRef;
	form!: FormGroup;
	private listaRelatorios = inject(
		ComponentesRelatoriosService
	).getRelatorios();
	relatorioAtual?: ComponenteRelatorio;
	relatorioAnterior?: ComponenteRelatorio;
	instanceRelatorio?: InstanciaRelatorio;
	categorias: Select[] = [];
	formatos: Select[] = [];
	variaveis: Select[] = [];
	municipios: Municipio[] = [];
	tipos: Select[] = [];
	tiposPeriodosChuvosos: Select[] = [];
	regioes: Select[] = [];
	microrregioes: Select[] = [];
	mesorregioes: Select[] = [];
	bacias: Select[] = [];
	estacoes: Estacao[] = [];
	loading = false;
	postos: PostosRelatorios[] = [];
	options: Select[] = [];

	informativo: TextoInformativo = {
		label: 'INFORMAÇÕES GERAIS',
		texts: [
			{
				title: 'O que são relatórios?',
				text: 'São documentos horários, diários, mensal ou anual que informam sobre o comportamento das chuvas em determinado local. Compostos por mapas, gráficos, tabelas e textos que retratam o comportamento da chuva.',
			},
		],
	};
	botoesDeExportacao: GroupButton[] = [];

	OpcoesPostos: OptionRadio<'todos_estados' | 'personalizado'>[] = [
		{ label: 'Todos do estado', value: 'todos_estados' },
		{ label: 'Personalizado', value: 'personalizado' },
	];

	variaveisMapa = [
		{
			label: LabelVariavelComUnidade.PRECIPITACAO_ACUMULADA,
			value: ValuesVariaveis.PRECIPITACAO,
		},
		{
			label: LabelVariavelComUnidade.TEMPERATURA_MEDIA_DO_AR,
			value: ValuesVariaveis.TEMPERATURA_DO_AR,
		},
	];

	precipitacoes: Select[] = Object.values(TipoPrecipitacao).map(value => ({
		value,
		name: value.charAt(0).toUpperCase() + value.slice(1),
	}));

	inputsRelatorioInjector!: Injector;
	OpcoesTipoVisualizacao: OptionRadio<'MAPA' | 'GRAFICO' | 'TABELA'>[] = [
		{ label: 'Mapa', value: 'MAPA' },
		{ label: 'Gráfico', value: 'GRAFICO' },
		{ label: 'Tabela', value: 'TABELA' },
	];
	optionsAgrupamento: Select[] = [];
	agrupamentosHelpers: Select[] = [];

	botoesPeriodo: OptionRadio<PeriodosLabel>[] = [
		{ label: 'Diário', value: 'diario' },
		{ label: 'Mensal', value: 'mensal' },
		{ label: 'Anual', value: 'anual' },
	];
	labelAdptativaPeriodo?: ConjuntoLabelsDinamicasPeriodo;
	private _destroyed = new Subject();

	constructor(
		private formBuilder: FormBuilder,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService,
		private cdr: ChangeDetectorRef,
		private componentFactoryResolver: ComponentFactoryResolver
	) {}

	ngOnInit(): void {
		this.setFormControls();
		this.setInjector();

		this.optionsAgrupamento = enumAsSelectOptions(Agrupamento);
		this.agrupamentosHelpers = enumAsSelectOptions(Agrupamento);
		this.tipos = enumAsSelectOptions(TipoTabelas);
		this.variaveis = enumAsSelectOptions(TipoVariaveisMeteorologicas);
		this.tiposPeriodosChuvosos = enumAsSelectOptions(EPeriodoChuvoso);
		this.buscarEstacoes();
		this.observarRadioPostos();
		this.observarCategorias();
		this.observarFormulario();
		this.observarFormatos();
		this.observarTiposPeriodoBusca();

		this.form.get(FormularioRelatorio.AGRUPAMENTO)?.valueChanges.subscribe({
			next: () => this.limparValidadoresAgrupamento(),
		});
	}

	ngOnDestroy(): void {
		this._destroyed.next(undefined);
	}

	private setFormControls(): void {
		const hoje = new Date();
		const umMesAtras = subDays(hoje, 30);

		this.form = this.formBuilder.group({
			[FormularioRelatorio.TIPO]: new FormControl<
				| keyof typeof TipoTabelas
				| keyof typeof TipoGraficos
				| keyof typeof TiposMapas
				| null
			>('PRECIPITACAO_DIARIA'),
			[FormularioRelatorio.VARIAVEL]: new FormControl<string | null>(
				'PRECIPITACAO'
			),
			[FormularioRelatorio.MICRORREGIAO]: new FormControl<string | null>(null),
			[FormularioRelatorio.MESORREGIAO]: new FormControl<string | null>(null),
			[FormularioRelatorio.BACIA]: new FormControl<string | null>(null),
			[FormularioRelatorio.PRECIPITACAO]: new FormControl<string | null>(
				TipoPrecipitacao.ACUMULADA
			),
			[FormularioRelatorio.DATA_INICIO]: new FormControl<Moment>(
				moment(umMesAtras)
			),
			[FormularioRelatorio.DATA_FIM]: new FormControl<Moment>(moment(hoje)),
			[FormularioRelatorio.POSTO]: new FormControl('todos_estados'),
			[FormularioRelatorio.ESTACAO]: new FormControl(),
			[FormularioRelatorio.PERIODO]: new FormControl<moment.Moment | null>(
				moment(hoje)
			),
			[FormularioRelatorio.LOADING_GERAR]: new FormControl<boolean>(false),
			[FormularioRelatorio.VARIAVEL_MAPA]: new FormControl<null | string>(
				ValuesVariaveis.PRECIPITACAO
			),
			[FormularioRelatorio.TIPO_PERIODO]: new FormControl<null | string>(
				'DIARIO'
			),
			[FormularioRelatorio.TIPO_VISUALIZACAO]: new FormControl('MAPA'),
			[FormularioRelatorio.AGRUPAMENTO]: new FormControl('MUNICIPIO_POSTO'),
			[FormularioRelatorio.PERIODO_BUSCA]: new FormControl<PeriodosLabel>(
				'diario'
			),
			[FormularioRelatorio.MUNICIPIO]: new FormControl(null),
			[FormularioRelatorio.PERIODO_CHUVOSO]: new FormControl<null | string>(
				'ANO_CIVIL'
			),
			[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: new FormControl<
				null | string
			>('1'),
		});
	}

	private limparValidadoresAgrupamento(): void {
		[
			FormularioRelatorio.ESTACAO,
			FormularioRelatorio.BACIA,
			FormularioRelatorio.MESORREGIAO,
			FormularioRelatorio.MICRORREGIAO,
			FormularioRelatorio.MUNICIPIO,
			FormularioRelatorio.POSTO,
			FormularioRelatorio.REGIAO_PLUVIOMETRICA,
		].forEach(campoForm => this.limparValidadorCampo(campoForm));
	}

	private limparValidadorCampo(campoRelatorio: FormularioRelatorio): void {
		const campo = this.form.get(campoRelatorio);
		if (campo) {
			campo.clearValidators();
			campo.setErrors(null);
			campo.updateValueAndValidity();
		}
	}

	setVisibilidadePeriodoDiario(visibilidade: boolean): void {
		if (visibilidade) {
			this.botoesPeriodo = [
				{ label: 'Diário', value: 'diario' },
				{ label: 'Mensal', value: 'mensal' },
				{ label: 'Anual', value: 'anual' },
			];
			this.form.get(FormularioRelatorio.PERIODO_BUSCA)?.setValue('diario');
		} else {
			this.botoesPeriodo = [
				{ label: 'Mensal', value: 'mensal' },
				{ label: 'Anual', value: 'anual' },
			];
			this.form.get(FormularioRelatorio.PERIODO_BUSCA)?.setValue('mensal');
		}
	}

	setInjector() {
		this.inputsRelatorioInjector = Injector.create({
			providers: [
				{
					provide: INPUTS_RELATORIOS,
					useValue: {
						form: this.form,
						postos: this.postos,
						estacoes: this.estacoes,
						regioes: this.regioes,
						bacias: this.bacias,
						microrregioes: this.microrregioes,
						mesorregioes: this.mesorregioes,
						agrupamento: this.optionsAgrupamento,
						municipios: this.municipios,
						setLoading: this.setLoading,
					},
				},
			],
		});
	}

	ajustaOptionsMicrorregiaoComBaseEmInstaciaDeRelatorio() {
		if (this.categoriaFormatoTipo(['GRAFICO'], ['CLIMATOLOGIA'])) {
			this.microrregioes.shift();
		}
	}

	get loadingForm() {
		return !!this.form.get('loadingGerar')!.value;
	}

	setLoading(state: boolean) {
		this.form.get('loadingGerar')?.setValue(state);
	}

	ngAfterViewInit() {
		this.relatorioAtual =
			this.listaRelatorios.PLUVIOMETRICO.MAPA.PLUVIOMETRIA_OBSERVADA;
		this.loadDynamicComponent(this.relatorioAtual.componente);
		this.cdr.detectChanges();
	}

	categoriaFormatoTipo(
		formatos: Array<keyof typeof Formato> | null = null,
		tipos: Array<
			| keyof typeof TipoTabelas
			| keyof typeof TipoGraficos
			| keyof typeof TiposMapas
		> | null = null
	) {
		const formato = this.getFormItemValue(
			FormularioRelatorio.TIPO_VISUALIZACAO
		);
		const tipo = this.getFormItemValue('tipo');
		return (
			this.condicaoCategoriaFormatoTipo(formatos, formato) &&
			this.condicaoCategoriaFormatoTipo(tipos, tipo)
		);
	}

	condicaoCategoriaFormatoTipo(array: string[] | null, valorNoForm: string) {
		if (array === null) {
			return true;
		}
		return array.includes(valorNoForm);
	}

	get variaveisPeriodoChuvoso() {
		return this.getFormItemValue('tipo') === 'QUANTIS_PLUVIOMETRICOS';
	}

	get mostrarRegiaoPluviometrica() {
		return (
			this.categoriaFormatoTipo(['GRAFICO'], ['QUANTIS_PLUVIOMETRICOS']) ||
			this.categoriaFormatoTipo(['TABELA'], ['SPI'])
		);
	}

	get mostrarDateRangePicker() {
		return (
			this.categoriaFormatoTipo(
				['MAPA'],
				['DESVIO_PORCENTAGEM', 'DESVIO_MILIMETRO', 'IAPM']
			) || this.categoriaFormatoTipo(['TABELA'], ['PRECIPITACAO_DIARIA'])
		);
	}

	get periodoDiario() {
		if (this.getFormItemValue('periodoBusca') === 'diario') {
			return this.categoriaFormatoTipo(
				['MAPA'],
				['MOSAICO_CHUVAS', 'VALORES_EXTREMOS_PLUVIOMETRIA']
			);
		}
		return;
	}

	get periodoMensal() {
		if (this.getFormItemValue('periodoBusca') === 'mensal') {
			return this.categoriaFormatoTipo(
				['MAPA'],
				['MOSAICO_CHUVAS', 'VALORES_EXTREMOS_PLUVIOMETRIA']
			);
		}
		return;
	}

	get periodoAnual() {
		if (this.getFormItemValue('periodoBusca') === 'anual') {
			return this.categoriaFormatoTipo(
				['MAPA'],
				['MOSAICO_CHUVAS', 'VALORES_EXTREMOS_PLUVIOMETRIA']
			);
		}
		return;
	}

	get variaveisPluviometricas() {
		return this.categoriaFormatoTipo(['MAPA'], ['MOSAICO_CHUVAS']);
	}

	get deveMostrarPeriodoBusca() {
		if (
			this.relatorioAtual?.condicoesMostrarItensForm &&
			this.relatorioAtual.condicoesMostrarItensForm[
				FormularioRelatorio.PERIODO_BUSCA
			]
		) {
			const values = this.form.getRawValue();
			return this.relatorioAtual.condicoesMostrarItensForm[
				FormularioRelatorio.PERIODO_BUSCA
			](values);
		}
		return (
			this.categoriaFormatoTipo(['GRAFICO'], ['MOSAICO_CHUVAS']) ||
			this.categoriaFormatoTipo(
				['MAPA'],
				[
					'PLUVIOMETRIA_OBSERVADA',
					'MOSAICO_CHUVAS',
					'VALORES_EXTREMOS_PLUVIOMETRIA',
				]
			)
		);
	}

	get mostrarAgrupamento() {
		return (
			this.categoriaFormatoTipo(['MAPA'], ['PLUVIOMETRIA_OBSERVADA']) ||
			this.categoriaFormatoTipo(
				['GRAFICO'],
				[
					'PLUVIOMETRIA_OBSERVADA',
					'CLIMATOLOGIA',
					'NUMERO_DIAS_COM_CHUVA',
					'VALORES_EXTREMOS_PLUVIOMETRIA',
					'DESVIO_MILIMETRO',
					'DESVIO_PORCENTAGEM',
					'PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA',
					'PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA',
					'IAPM',
				]
			) ||
			this.categoriaFormatoTipo(['TABELA'], ['DESVIO']) ||
			this.categoriaFormatoTipo(['TABELA'], ['NUMERO_DIAS_COM_CHUVA']) ||
			this.categoriaFormatoTipo(
				['TABELA'],
				[
					'NUMERO_DIAS_COM_CHUVA',
					'CLIMATOLOGIA',
					'VALORES_EXTREMOS_PLUVIOMETRIA',
					'PLUVIOMETRIA_OBSERVADA',
					'IAPM',
				]
			)
		);
	}

	get mostrarDateRangePickerAno() {
		return (
			this.categoriaFormatoTipo(['TABELA'], ['PRECIPITACAO_ANUAL', 'SPI']) ||
			this.categoriaFormatoTipo(['GRAFICO'], ['SPI'])
		);
	}

	get mostrarDateRangePickerMes() {
		return (
			this.categoriaFormatoTipo(['MAPA'], ['CHUVA_ESTADO', 'CLIMATOLOGIA']) ||
			this.categoriaFormatoTipo(['GRAFICO', 'TABELA'], ['CLIMATOLOGIA'])
		);
	}

	get mostrarDatePickerMesAno() {
		return (
			this.categoriaFormatoTipo(['TABELA'], ['PLUVIOMETRICO_DO_ESTADO']) ||
			this.categoriaFormatoTipo(['MAPA'], ['QUANTIS_MENSAL'])
		);
	}

	get mostrarDatePickerAno() {
		return (
			this.categoriaFormatoTipo(
				['GRAFICO'],
				['QUANTIS_ANUAL', 'QUANTIS_MENSAL']
			) ||
			this.categoriaFormatoTipo(
				['TABELA'],
				[
					'PLUVIOMETRICO_POR_POSTO',
					'PRECIPITACAO_ACUMULADA',
					'MEDIA_HISTORICA',
					'QUANTIS_MENSAL',
					'QUANTIS_ANUAL',
				]
			) ||
			this.categoriaFormatoTipo(['MAPA'], ['QUANTIS_ANUAL'])
		);
	}

	get mostrarMunicipios() {
		if (
			this.relatorioAtual?.condicoesMostrarItensForm &&
			this.relatorioAtual.condicoesMostrarItensForm[
				FormularioRelatorio.MUNICIPIO
			]
		) {
			const values = this.form.getRawValue();
			return this.relatorioAtual.condicoesMostrarItensForm[
				FormularioRelatorio.MUNICIPIO
			](values);
		}
		return false;
	}

	get mostrarMicrorregioes() {
		if (
			this.relatorioAtual?.condicoesMostrarItensForm &&
			this.relatorioAtual.condicoesMostrarItensForm[
				FormularioRelatorio.MICRORREGIAO
			]
		) {
			const values = this.form.getRawValue();
			return this.relatorioAtual.condicoesMostrarItensForm[
				FormularioRelatorio.MICRORREGIAO
			](values);
		}
		return this.categoriaFormatoTipo(
			['MAPA'],
			['QUANTIS_MENSAL', 'QUANTIS_ANUAL']
		);
	}
	getMostrarEntidade(tipoFormulario: FormularioRelatorio) {
		const condicoesMostrarItens =
			this.relatorioAtual?.condicoesMostrarItensForm?.[tipoFormulario];

		if (condicoesMostrarItens) {
			const values = this.form.getRawValue();
			return condicoesMostrarItens(values);
		}
		return this.categoriaFormatoTipo(
			['GRAFICO'],
			[
				'PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA',
				'PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA',
			]
		);
	}

	get mostrarMesorregioes() {
		return this.getMostrarEntidade(FormularioRelatorio.MESORREGIAO);
	}

	get mostrarRegioes() {
		return this.getMostrarEntidade(FormularioRelatorio.REGIAO_PLUVIOMETRICA);
	}

	get mostrarBacias() {
		return this.getMostrarEntidade(FormularioRelatorio.BACIA);
	}

	get mostrarSelectDePostos() {
		if (
			this.relatorioAtual?.condicoesMostrarItensForm &&
			this.relatorioAtual.condicoesMostrarItensForm[FormularioRelatorio.ESTACAO]
		) {
			const values = this.form.getRawValue();
			return this.relatorioAtual.condicoesMostrarItensForm[
				FormularioRelatorio.ESTACAO
			](values);
		}
		return (
			this.categoriaFormatoTipo(
				['GRAFICO'],
				['QUANTIS_MENSAL', 'QUANTIS_ANUAL']
			) ||
			this.categoriaFormatoTipo(['TABELA'], ['QUANTIS_MENSAL', 'QUANTIS_ANUAL'])
		);
	}

	get mostrarSelectMapaDePrecipitacoes() {
		return this.categoriaFormatoTipo(['MAPA'], ['CHUVA_ESTADO']);
	}

	get deveMostrarRadioPostos(): boolean {
		return this.categoriaFormatoTipo(
			['TABELA'],
			[
				'PRECIPITACAO_ACUMULADA',
				'PRECIPITACAO_ANUAL',
				'PRECIPITACAO_DIARIA',
				'MEDIA_HISTORICA',
				'PLUVIOMETRICO_POR_POSTO',
				'HISTORICO_DA_SUDENE',
			]
		);
	}

	get tipoPrecipitacaoAnual() {
		return this.getFormItemValue('tipo') === 'PRECIPITACAO_ANUAL';
	}

	observarRadioPostos() {
		this.form.get('posto')?.valueChanges.subscribe(value => {
			if (value === 'todos_estados') {
				this.buscarEstacoes();
			}
		});
	}

	observarCategorias() {
		this.formatos = enumAsSelectOptions(Formato);

		if (this.formatos.length == 1) {
			this.form.patchValue(
				{ [FormularioRelatorio.TIPO_VISUALIZACAO]: this.formatos[0].value },
				{ emitEvent: false }
			);
		}
		const formato = this.getFormItemValue(
			FormularioRelatorio.TIPO_VISUALIZACAO
		);
		this.analiseFormato(formato);
	}

	observarFormatos() {
		this.form
			.get(FormularioRelatorio.TIPO_VISUALIZACAO)
			?.valueChanges.subscribe(formato => {
				this.analiseFormato(formato);
			});
	}

	analiseFormato(formato: string) {
		switch (formato) {
			case 'GRAFICO':
				this.tipos = enumAsSelectOptions(TipoGraficos);
				break;
			case 'MAPA':
				this.tipos = enumAsSelectOptions(TiposMapas);
				break;
			default:
				this.tipos = enumAsSelectOptions(TipoTabelas);
		}
		this.form.patchValue({ tipo: this.tipos[0].value }, { emitEvent: false });
	}

	get isIntervalosDatasValido(): boolean {
		if (!this.dataInicio || !this.dataFim) {
			return false;
		}
		const diferencaInicioFim = DateTimeUtils.subtrairEmMilisegundos(
			this.dataFim,
			this.dataInicio
		);
		const limite = this.limiteDataInicioInferior;

		if (!limite) {
			return true;
		}

		// Correção das horas.
		limite.setHours(0, 0, 0, 0);

		const diferencaMaxInicioFim = DateTimeUtils.subtrairEmMilisegundos(
			this.dataFim,
			limite
		);

		return diferencaInicioFim <= diferencaMaxInicioFim;
	}

	gerarRelatorio() {
		if (!this.isIntervalosDatasValido) {
			this.periodoInfo?.amount;
			if (!this.periodoInfo) {
				this.toastr.info(
					'O intervalo de datas é inválido',
					'Não foi possível gerar resultados'
				);
			}
			const mapeamento: Record<string, string> = {
				day: 'dias',
				month: 'meses',
				year: 'anos',
			};
			this.toastr.info(
				`A diferença entre as datas precisa ser de no máximo ${this.periodoInfo
					?.amount} ${mapeamento[this.periodoInfo?.unit as string]}`,
				'Não foi possível gerar resultados'
			);
			return;
		}
		this.instanceRelatorio?.gerarRelatorio();
	}

	handleSetPeriodoByRelatorioInstance(periodos: PeriodosInterface[]) {
		this.botoesPeriodo = periodos
			.filter(periodo => periodo.amount && periodo.unit)
			.map(periodo => this.mapPeriodo(periodo));
	}

	private mapPeriodo(periodo: PeriodosInterface): OptionRadio<PeriodosLabel> {
		if (periodo.amount === EPeriodosBusca.DIARIO) {
			return { label: 'Diário', value: 'diario' };
		}

		if (periodo.amount === EPeriodosBusca.MENSAL) {
			return { label: 'Mensal', value: 'mensal' };
		}

		return { label: 'Anual', value: 'anual' };
	}

	observarTiposPeriodoBusca() {
		this.form.get('periodoBusca')?.valueChanges.subscribe(value => {
			this.form.get('tipoPeriodo')?.setValue(value.toUpperCase());
		});
	}

	loadDynamicComponent(component: any): void {
		if (this.relatorioAtual !== this.relatorioAnterior) {
			if (this.dynamicComponentContainer.get(0)) {
				this.dynamicComponentContainer.remove(0);
			}
			if (component) {
				const componentFactory =
					this.componentFactoryResolver.resolveComponentFactory(component);
				const componentRef = this.dynamicComponentContainer.createComponent(
					componentFactory,
					undefined,
					this.inputsRelatorioInjector
				);
				this.instanceRelatorio = componentRef.instance as InstanciaRelatorio;
				this.botoesDeExportacao =
					this.instanceRelatorio.botoesDeExportacao || [];
				if (this.relatorioAtual?.periodos) {
					this.handleSetPeriodoByRelatorioInstance([
						this.relatorioAtual!.periodos!.diario,
						this.relatorioAtual!.periodos!.mensal,
						this.relatorioAtual!.periodos!.anual,
					]);
				}
				if (
					!this.instanceRelatorio.postos?.length ||
					!this.instanceRelatorio.estacoes?.length ||
					!this.instanceRelatorio.regioes?.length ||
					!this.instanceRelatorio.microrregioes?.length ||
					!this.instanceRelatorio.mesorregioes?.length ||
					!this.instanceRelatorio.municipios?.length
				) {
					this.instanceRelatorio.postos = this.postos;
					this.instanceRelatorio.estacoes = this.estacoes;
					this.instanceRelatorio.regioes = this.regioes;
					this.instanceRelatorio.microrregioes = this.microrregioes;
					this.instanceRelatorio.mesorregioes = this.mesorregioes;
					this.instanceRelatorio.precipitacao = this.precipitacoes;

					this.instanceRelatorio.municipios = this.municipios;

					if (
						this.instanceRelatorio.agrupamento?.length === 0 ||
						this.instanceRelatorio.agrupamento === undefined
					) {
						this.optionsAgrupamento = this.agrupamentosHelpers;
					} else {
						this.optionsAgrupamento = this.instanceRelatorio.agrupamento!;
					}
					this.instanceRelatorio.agrupamento = this.optionsAgrupamento;
				}
			} else {
				this.instanceRelatorio = undefined;
			}
		}
		this.relatorioAnterior = this.relatorioAtual;
	}

	observarFormulario() {
		this.form.valueChanges.subscribe({
			next: ({
				tipoVisualizacao,
				tipo,
				periodoBusca,
			}: {
				tipoVisualizacao?:
					| keyof typeof Formato
					| keyof typeof FormatoVariaveisMeteorologicas;
				tipo?:
					| keyof typeof TipoTabelas
					| keyof typeof TipoGraficos
					| keyof typeof TiposMapas
					| keyof typeof TipoVariaveisMeteorologicas;
				periodoBusca: PeriodosLabel;
			}) => {
				if (this.listaRelatorios.hasOwnProperty('PLUVIOMETRICO')) {
					const categorias = this.listaRelatorios['PLUVIOMETRICO'] as any;
					if (tipoVisualizacao && categorias.hasOwnProperty(tipoVisualizacao)) {
						const formatos = categorias[tipoVisualizacao];
						if (tipo && formatos.hasOwnProperty(tipo)) {
							this.relatorioAtual = formatos[tipo];
						} else {
							this.relatorioAtual = formatos;
						}
					} else {
						this.relatorioAtual = categorias;
					}
				}

				this.ajustaOptionsMicrorregiaoComBaseEmInstaciaDeRelatorio();
				this.loadDynamicComponent(this.relatorioAtual?.componente);
				this.setLabelAdptativaPeriodo(periodoBusca);
			},
		});
	}

	setLabelAdptativaPeriodo(periodoBusca: PeriodosLabel) {
		switch (periodoBusca) {
			case 'diario':
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Data inicial',
						placeholder: 'Selecione a data inicial',
						dataType: 'day',
					},
					fim: {
						label: 'Data final',
						placeholder: 'Selecione a data final',
						dataType: 'day',
					},
					unico: {
						label: 'Dia',
						placeholder: 'Selecione uma data',
						dataType: 'day',
					},
				};
				break;
			case 'mensal':
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Mês inicial',
						placeholder: 'Início',
						dataType: 'month',
					},
					fim: {
						label: 'Mês final',
						placeholder: 'Selecione uma data',
						dataType: 'month',
					},
					unico: {
						label: '',
						dataType: 'day',
						placeholder: '',
					},
				};
				break;
			case 'anual':
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Ano inicial',
						placeholder: 'Selecione o ano inicial',
						dataType: 'year',
					},
					fim: {
						label: 'Ano final',
						placeholder: 'Selecione o ano final',
						dataType: 'year',
					},
					unico: {
						label: 'Ano',
						placeholder: 'Selecione o ano',
						dataType: 'year',
					},
				};
				break;
		}
	}

	/**
	 * Método para buscar as opções de filtro
	 */
	buscarEstacoes() {
		this.relatoriosService.consultarOpcoesFiltros().subscribe({
			next: resp => {
				this.municipios = resp.municipios;
				this.estacoes = resp.estacoes;
				this.mesorregioes = resp.mesorregioes.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));
				this.regioes = resp.regioes.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));
				this.bacias = resp.bacias.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));
				this.postos = resp.estacoes.map(estacao => ({
					id: estacao.id,
					municipio: estacao.nomeMunicipio,
					nomePosto: estacao.nomePosto,
					tipo: estacao.tipoEstacao,
					responsavel: estacao.responsavel ?? '-',
				}));

				if (this.microrregioes.length < 2) {
					resp.microrregioes.map(val => {
						this.microrregioes.push({
							value: `${val.id}`,
							name: val.nome,
						});
					});
				}
				if (this.estacoes) {
					const estacoesFiltradas = this.estacoes.filter(
						e =>
							e.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL' &&
							e.statusEstacao === 'ATIVA'
					);

					estacoesFiltradas.map(e => {
						this.options.push({
							name: `${e.nomeMunicipio}/${e.nomePosto}`,
							value: e.id.toString(),
						});
					});

					this.form.patchValue({
						estacao: this.options[0].value,
					});
				}
				this.setInjector();
				this.preencherValores();
			},
			error: err => {
				const msg_erro = obter_erro_request(err);
				this.toastr.error(
					msg_erro,
					'Erro ao buscar as informações das estações'
				);
			},
		});
	}

	preencherValores() {
		this.form.get('municipio')?.setValue(this.municipios[0].id);
		this.form.get('estacao')?.setValue(this.options[0].value);
		this.form.get('mesorregiao')?.setValue(this.mesorregioes[0].value);
		this.form.get('microrregiao')?.setValue(this.microrregioes[0].value);
		this.form.get('regiao')?.setValue(this.regioes[0].value);
		this.form.get('bacia')?.setValue(this.bacias[0].value);
	}

	openModal() {
		const closeModal = () => {
			this.modalService.hide();
		};
		const postosSelecionados = (postos: PostosRelatorios[]) => {
			this.postos = postos;
			if (this.instanceRelatorio) {
				this.instanceRelatorio.postos = postos;
			}
			closeModal();
		};
		this.modalService.show(ModalRelatorioPostosComponent, {
			backdrop: 'static',
			class: 'modal-xl modal-dialog-centered bg-transparent',
			initialState: {
				dadosSelecionados: this.postos.filter(posto => posto.selected),
				close: closeModal,
				onPostosSelecionados: postosSelecionados,
			},
		});
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	get dataMinimaSPI() {
		const dataFim: Moment = this.getFormItemValue('dataFim');
		return moment(dataFim)
			.subtract(PERIODO_MINIMO_SPI_EM_ANOS, 'years')
			.toDate();
	}

	get anoInicialMaxDate() {
		const dataFim: Moment = this.getFormItemValue('dataFim');
		return dataFim.toDate();
	}

	get dataInicio() {
		return (
			new Date(this.getFormItemValue(FormularioRelatorio.DATA_INICIO)) || null
		);
	}

	get dataFim() {
		return (
			new Date(this.getFormItemValue(FormularioRelatorio.DATA_FIM)) || null
		);
	}

	get limiteDataInicioInferior() {
		const dataInicioFromForm = this.getFormItemValue(
			FormularioRelatorio.DATA_FIM
		);
		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		) as PeriodosLabel;
		const periodoInfo = this.relatorioAtual?.periodos?.[periodoBusca];
		if (!periodoInfo || !dataInicioFromForm) return null;
		return moment(new Date(dataInicioFromForm))
			.subtract(periodoInfo?.amount, periodoInfo?.unit)
			.toDate();
	}

	get periodoInfo() {
		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		) as PeriodosLabel;
		const periodoInfo = this.relatorioAtual?.periodos?.[periodoBusca];
		return periodoInfo;
	}

	get limiteDataFimSuperior() {
		const dataInicioFromForm = this.getFormItemValue(
			FormularioRelatorio.DATA_INICIO
		);
		if (!this.periodoInfo || !dataInicioFromForm) return null;
		const limiteSuperior = moment(new Date(dataInicioFromForm))
			.add(this.periodoInfo?.amount, this.periodoInfo?.unit)
			.toDate();
		const hoje = new Date();
		if (limiteSuperior > hoje) return hoje;
		return limiteSuperior;
	}

	protected readonly FormularioRelatorio = FormularioRelatorio;
}
