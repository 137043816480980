import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	IapmDTO,
	QuantisCategoriasMapColors,
} from '@home/submodulos/dados-meteorologicos/interfaces';
import * as Highcharts from 'highcharts/highstock';
import { Chart } from 'highcharts';
import { optionsChartIapm } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-iapm/chart-options';

@Component({
	selector: 'seira-grafico-iapm',
	templateUrl: './grafico-iapm.component.html',
	styleUrls: ['./grafico-iapm.component.scss'],
})
export class GraficoIapmComponent {
	@Input() dados!: IapmDTO[];
	@Input() fileName = '';

	isLoading = false;
	chart?: Highcharts.Chart;
	chartOptions = optionsChartIapm();

	@Output() chartInstance = new EventEmitter<Chart>();

	onChartCreated(chart: Chart) {
		this.chart = chart;

		this.chartOptions = {
			...this.chartOptions,
			exporting: {
				enabled: true,
				buttons: {
					contextButton: {
						menuItems: [
							'viewFullscreen',
							'separator',
							'downloadPNG',
							'downloadJPEG',
						],
					},
				},
				filename: this.fileName,
			},
		};

		this.chartInstance.emit(chart);

		this.setSeries();
	}

	setSeries() {
		this.chart?.series.forEach(el => el.remove());

		this.chart?.addSeries({
			type: 'column',
			name: 'IAPM',
			color: QuantisCategoriasMapColors.get('Chuvoso'),
			pointPadding: 0.2,
			data: this.dados.map(dado => ({
				y: Number.parseFloat(dado?.iapm.toFixed(1)),
				name: dado.periodo,
				category: dado.periodo,
			})),
		});
	}
}
