import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ADTColumns,
	ADTSettings,
} from 'angular-datatables/src/models/settings';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettingsWithButton } from '@componentes/table/interfaces/menuOptions';
import { acessarAtributo, defaultLanguageOptionsDatatablesPtBr } from '@utils';
import { Subject } from 'rxjs';
import { TableExportsType } from '@componentes/public-table/table-utils';

@Component({
	selector: 'seira-public-table',
	templateUrl: './public-table.component.html',
	styleUrls: ['./public-table.component.scss'],
})
export class PublicTableComponent
	implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
	@Input() data!: any[];
	@Input() columns!: ADTColumns[];
	@Input() header!: ADTColumns[];
	@ViewChild(DataTableDirective, { static: false }) tabela!: DataTableDirective;
	@ViewChild('tabela', { static: false }) tabelaRef!: ElementRef;
	@Input() searching = true;
	@Input() paging = true;
	@Input() info = true;
	@Input() hasFooter = true;
	@Input() hasHeader = true;
	@Input() scrollX = false;
	@Input() loading = false;
	@Input() orderFixed: any[] = [];
	@Input() noBorderTh = false;
	@Input() spaceReduceTh = false;
	@Input() createdRow?: DataTables.FunctionCreateRow;
	@Input() pageLength?: number;
	@Input() classFooter = 'd-flex justify-content-end align-items-center mt-2';
	@Input() groupBy?: any;
	@Input() orderOption: [number, string] = [0, 'asc'];
	@Input() order?: ADTSettings['order'];
	@Input()
	withBord = true;
	@Output() drawEmitter = new EventEmitter<boolean>();
	dtOptions: ADTSettingsWithButton = {};
	dtTrigger: Subject<any> = new Subject();
	class = 'table';
	@Input() hasComplexHeader = false;
	async gerar(exportType: TableExportsType) {
		const instance = await this.tabela.dtInstance;
		// @ts-ignore
		instance.button(exportType).trigger();
	}

	async removeRow(pk: any, field: string) {
		(await this.tabela.dtInstance)
			.rows(function (idx: any, data: any) {
				return data[field] === pk;
			})
			.remove()
			.draw();
	}
	async pesquisar(itemPesquisado: string) {
		(await this.tabela.dtInstance).search(itemPesquisado).draw();
	}
	async filtrar(condicao: (value: any) => boolean, field: string) {
		const instance: any = await this.tabela.dtInstance;
		if (instance) {
			const data = this.data.filter((el: any) =>
				condicao(acessarAtributo(el, field))
			);
			instance.clear().rows.add(data).draw();
		}
	}

	borderRadius(index: number) {
		let start = '0';
		let end = '0';
		if (!this.noBorderTh) {
			if (index === 0) {
				start = '10px';
			}
			if (index === this.columns.length - 1) {
				end = '10px';
			}
			if (this.hasComplexHeader && index === this.header.length - 1) {
				end = '10px';
			}
		}
		return `border-radius: ${start} ${end} 0 0;`;
	}

	ngOnInit() {
		setTimeout(() => {
			this.dtOptions = this.generateOption(this.data);
		});
	}

	generateOption(data: any[]): ADTSettings {
		const context = this;
		let columnDefs = {};
		const order = {};
		let drawGroup = () => {};
		if (this.groupBy) {
			columnDefs = { columnDefs: [{ visible: false, targets: this.groupBy }] };
			//order = { order: [[this.groupBy, 'asc']] };
			drawGroup = async () => {
				const api = await this.tabela.dtInstance;
				const rows = api.rows({ page: 'current' }).nodes();
				let last: any = null;
				api
					.column(this.groupBy, { page: 'current' })
					.data()
					.each(function (group, i) {
						if (last !== group && i !== undefined) {
							$(rows)
								.eq(i)
								.before(
									`<tr class="group font-size-14 fw-normal" style="background-color: #fff !important; color: #576469 !important;"><td colspan="200">${group}</td></tr>`
								);

							last = group;
						}
					});
			};
		}

		const emitDraw = () => {
			this.drawEmitter.emit(true);
		};
		return {
			createdRow: this.createdRow,
			data,
			searching: this.searching,
			paging: this.paging,
			pagingType: 'simple',
			info: this.info,
			scrollX: this.scrollX,
			orderFixed: this.orderFixed,
			pageLength: this.pageLength,
			order: this.order,
			dom: `${
				this.hasHeader
					? '<"datatable-header justify-content-start border-0"<"ms-sm-3"B>>'
					: ''
			}<"datatable-scroll"t>${
				this.hasFooter
					? `<"${this.classFooter} ${
							this.searching ? '' : 'hiddeSearchField'
					  }"f<"me-3"l><"me-3"i>pr>`
					: ''
			}`,
			language: defaultLanguageOptionsDatatablesPtBr,
			columns: this.columns,
			// @ts-ignore
			buttons: [
				'copy',
				'pdf',
				'csv',
				'print',
				{
					extension: '.txt',
					extend: 'csv',
					text: 'Txt',
					key: '1',
				},
			],
			...columnDefs,
			...order,
			drawCallback: function () {
				emitDraw();
				if (context.loading) {
					$(this).parent().parent().hide();
				} else {
					$(this).parent().parent().show();
				}
				drawGroup();
			},
		};
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.dtTrigger.next(this.dtOptions);
		});
	}

	rerender(data: any[]) {
		this.tabela.dtInstance?.then(dtInstance =>
			dtInstance
				.rows(() => true)
				.remove()
				.rows.add(data)
				.draw()
		);
	}
	refreshTable() {
		this.tabela.dtInstance?.then(instance => {
			instance.destroy();
			this.tabela.dtTrigger.next(this.generateOption(this.data));
		});
	}
	ngOnDestroy(): void {
		this.dtTrigger.unsubscribe();
		this.drawEmitter.emit(false);
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (this.tabela) {
			if (changes['groupBy'] || changes['columns'] || changes['data']) {
				this.refreshTable();
				this.rerender(this.data);
			}
		}
	}
}
