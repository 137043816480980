export const optionsChartDesvio: Highcharts.Options = {
	chart: {
		type: 'column',
	},
	title: {
		text: 'Desvios',
		align: 'center',
	},
	subtitle: {
		text: '',
		align: 'left',
	},
	xAxis: {
		title: {
			text: 'Meses',
		},
	},
	yAxis: {
		title: {
			text: 'Desvio',
		},
	},
	tooltip: {
		valueSuffix: ' mm',
		valueDecimals: 1,
	},
	plotOptions: {
		column: {
			borderWidth: 0,
		},
	},
};
