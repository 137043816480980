import { Component, Input, OnInit } from '@angular/core';
import { IapmDTO, Relatorios } from '../../../interfaces';
import {
	ADTColumns,
	ADTSettings,
} from 'angular-datatables/src/models/settings';

@Component({
	selector: 'seira-tabela-iapm',
	templateUrl: './tabela-iapm.component.html',
	styleUrls: ['./tabela-iapm.component.scss'],
})
export class TabelaIapmComponent implements OnInit {
	@Input() relatorios: Relatorios<IapmDTO> = {
		data: [],
		municipio: '',
	};
	dtOptions: ADTSettings = {};
	dados: IapmDTO[] = [];
	colunas: ADTColumns[] = [];

	ngOnInit(): void {
		this.dados = this.relatorios.data;
		this.colunas = this.handleGenerateColumns();
	}

	private handleGenerateColumns(): ADTColumns[] {
		return [
			{
				data: 'iapm',
				title: 'IAPM (mm)',
				className: 'text-center',
				render: value => Number(value).toFixed(2).replace('.', ','),
			},
			{
				data: 'periodo',
				title: 'Data',
				className: 'text-center',
				orderable: false,
			},
		];
	}
}
